<template>
  <div>
    <div class="header">
      <div class="select-date">
        <div class="calendar-month">
          <date-picker
            v-model="datePicker"
            value-type="date"
            placeholder="Select date"
            class="date-picker"
            style="width:58px;"
            :clearable="false"
          >
            <div slot="input" style="width:58px; height:58px"></div>
            <div slot="icon-calendar">
              <img class="calendar-icon" src="@/assets/svg/calendar2.svg" />
            </div>
            <template v-slot:header="{ emit }">
              <button class="mx-btn mx-btn-text" @click="emit(new Date())">
                Today
              </button>
            </template>
          </date-picker>
          <div class="month">
            {{ this.getMonthFullName(date.getMonth()) }}
            {{ date.getFullYear() }}
          </div>
        </div>
      </div>
      <div class="select-week" style="margin-top: 4px;margin-bottom: 4px;">
        <div class="action" @click="previous" style="margin-right: 12px">
          <img
            src="@/assets/svg/left-arrow.svg"
            style="width:10px; margin-right:1px"
          />
        </div>
        <div class="range-day">
          {{ rangeDate }}
        </div>
        <div class="action" @click="next" style="margin-left: 12px">
          <img
            src="@/assets/svg/right-arrow.svg"
            style="width:10px; margin-left:1px"
          />
        </div>
      </div>
    </div>
    <div class="calendar-card" v-for="(date, index) in dateList" :key="index">
      <div
        @click="onDateClick(index)"
        class="day-of-week"
        v-if="!checkDate(new Date(), date.date)"
      >
        <div class="text">
          <div class="small-text">
            {{ getDayname(date.date.getDay()) }}
          </div>
          <div class="big-text">{{ getDayNumber(date.date.getDate()) }}</div>
          <div class="small-text">
            {{ getMonthName(date.date.getMonth()) }}
            {{ date.date.getFullYear() }}
          </div>
        </div>
      </div>
      <div class="day-of-week" @click="onDateClick(index)" :id="0" v-else>
        <div class="text">
          <div class="small-text">
            {{ getDayname(date.date.getDay()) }}
          </div>
          <div class="big-text">{{ getDayNumber(date.date.getDate()) }}</div>
          <div class="small-text">
            {{ getMonthName(date.date.getMonth()) }}
            {{ date.date.getFullYear() }}
          </div>
        </div>
      </div>
      <div class="range-time-div">
        <div class="range-time" v-if="!isLoading">
          <div
            class="box-item"
            v-for="(item, id) in date.timeSlot"
            @click="onClickViewHistoryRecord(item, index)"
            :key="id"
          >
            <label class="item" v-if="item.status == null">
              {{ getTimeRange(item.startDate) }}
            </label>
            <label class="item" :id="item.status" v-else>
              {{ getTimeRange(item.startDate) }}
            </label>
          </div>
        </div>
        <div v-else></div>
        <div class="background-range-item" @click="onDateClick(index)"></div>
      </div>

      <img
        src="@/assets/svg/right-arrow-color.svg"
        class="arrow-icon"
        @click="onDateClick(index)"
        v-if="!isLoading"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import api from '@/utils/api'
import {
  getDayNameOfWeekWithOutDay,
  getMonthNameOfYear,
  getMonthFullNameOfYear
} from '@/utils/utils'
const actionType = Object.freeze({
  NEXT: 'next',
  PREVIOUS: 'previous',
  CREATE: 'create'
})
export default {
  name: 'DoctorCalendarWeek',
  props: ['data', 'date', 'doctor', 'skipSlot'],
  components: { DatePicker },
  data() {
    return {
      datePicker: null,
      rangeDate: '',
      dateList: [],
      value: '',
      isLoading: false
    }
  },
  created() {
    this.changeDate(this.date, actionType.CREATE)
  },
  watch: {
    datePicker: function(val, oldVal) {
      if (!val) {
        const result = this.changeDate(new Date(), actionType.CREATE)
        this.updateData(result.start, result.end)
      } else {
        const result = this.changeDate(val, actionType.CREATE)
        this.updateData(result.start, result.end)
      }
    },
    data: function(val, oldVal) {
      this.changeDate(this.date, actionType.CREATE)
      this.isLoading = false
    }
  },
  computed: {},
  methods: {
    next() {
      this.isLoading = true
      var result = this.changeDate(this.date, actionType.NEXT)
      this.updateData(result.start, result.end)
    },
    previous() {
      this.isLoading = true
      var result = this.changeDate(this.date, actionType.PREVIOUS)
      this.updateData(result.start, result.end)
    },
    async onClickViewHistoryRecord(appointment, index) {
      if (appointment.status == null) {
        this.onDateClick(index)
        return
      }
      if (appointment.record?.id) {
        this.$router.push({
          name: 'MedicalReport',
          params: { id: appointment.record.id, noti: true }
        })
      } else {
        const historiesRecords = await api.getRecords(
          1,
          100,
          'completed',
          appointment.patient.id
        )
        this.$router.push({
          name: 'MedicalReport',
          params: { id: historiesRecords.data[0].id, noti: true }
        })
      }
    },

    changeDate(date, action) {
      var dt = date
      var month = dt.getMonth()
      var year = dt.getFullYear()
      var day = dt.getDate()
      var start
      var end
      switch (action) {
        case actionType.NEXT:
          start = new Date(year, month, day + 7)
          end = new Date(year, month, day + 14)
          this.rangeDate =
            moment(String(start)).format('DD/MM/YYYY') +
            ' - ' +
            moment(String(end)).format('DD/MM/YYYY')
          this.createDateList(start, end)
          return { start, end }
        case actionType.PREVIOUS:
          start = new Date(year, month, day - 7)
          end = new Date(year, month, day)
          this.rangeDate =
            moment(String(start)).format('DD/MM/YYYY') +
            ' - ' +
            moment(String(end)).format('DD/MM/YYYY')
          this.createDateList(start, end)
          return { start, end }
        case actionType.CREATE:
          start = new Date(year, month, day)
          end = new Date(year, month, day + 7)
          this.rangeDate =
            moment(String(start)).format('DD/MM/YYYY') +
            ' - ' +
            moment(String(end)).format('DD/MM/YYYY')
          this.createDateList(start, end)
          return { start, end }

        default:
          return { start, end }
      }
    },

    createDateList(start, end) {
      this.dateList = []
      for (let i = 0; i < 7; i++) {
        var loop = moment(start)
          .utc(true)
          .add(i, 'd')

        this.dateList.push({
          date: loop.toDate(),
          skipSlot: this.skipSlot.filter(e => {
            return moment(loop)
              .utc(true)
              .isSame(moment(e.startDate).utc(true), 'days')
          }),
          timeSlot: []
        })
      }
      this.dateList.forEach((date, dayIndex) => {
        var dayOfWeek = []
        this.data.forEach((dataList, index) => {
          if (
            moment(date.date)
              .utc(true)
              .isSame(moment(dataList.startDate).utc(true), 'day')
          ) {
            dayOfWeek.push(dataList)
          }
        })
        var result = dayOfWeek.filter(e => {
          return !this.dateList[dayIndex].skipSlot.find(slot => {
            return (
              moment(e.startDate)
                .utc(true)
                .isSame(moment(slot.startDate).utc(true)) &&
              slot.type === 'skip_recur' &&
              slot.recurSkipId === e.id
            )
          })
        })
        this.$set(this.dateList[dayIndex], 'timeSlot', result)
      })
    },
    getMonthFullName(index) {
      return getMonthFullNameOfYear(index)
    },
    getMonthName(index) {
      return getMonthNameOfYear(index)
    },
    getDayname(index) {
      return getDayNameOfWeekWithOutDay(index)
    },
    getDayNumber(day) {
      if (day <= 9) return '0' + day
      return day
    },
    checkDate(date1, date2) {
      return moment(date1).isSame(date2, 'day')
    },
    getTimeRange(date) {
      return (
        moment(date).format('HH:mm') +
        '-' +
        moment(date)
          .add(this.doctor.minPerSlot, 'minutes')
          .format('HH:mm')
      )
    },
    updateData(start, end) {
      this.$emit('updateData', start, end)
    },
    onDateClick(index) {
      this.$emit('showAllSlotTime', this.dateList[index])
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: auto;
  align-content: center;
  align-items: center;
  .select-date {
    .calendar-month {
      display: flex;
      align-items: center;
      .calendar-icon {
        height: 46px;
        width: 46px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        padding: 8px;
      }
      .month {
        font-size: 20px;
        margin-left: 6px;
      }
    }
  }
  .select-week {
    display: flex;
    align-items: center;
    .action {
      display: flex;
      height: 30px;
      width: 30px;
      padding: 10px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      text-align: center;
      justify-content: center;
      align-items: center;
    }
    .range-day {
      font-size: 20px;
      font-weight: 300;
    }
  }
}
@media only screen and (max-width: 560px) {
  .header {
    justify-content: center;
  }
}

.header.center {
  justify-content: center;
}
.calendar-card {
  width: 100%;
  height: 90px;
  margin-top: 14px;
  margin-bottom: 14px;
  background-color: #fff;
  border-radius: 12px;
  padding: 8px;
  display: flex;
}
.day-of-week {
  width: 74px;
  border-style: solid;
  border-width: 2px;
  border-color: #34beb3;
  color: #34beb3;
  border-radius: 12px;
  margin-right: 12px;
  flex-shrink: 0;
  .text {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .small-text {
      font-size: 10px;
      font-weight: 300;
    }
    .big-text {
      font-size: 42px;
      font-weight: 300;
      line-height: 90%;
    }
  }
}
.day-of-week[id='0'] {
  color: #fff;
  background-color: #34beb3;
}

.range-time-div {
  // height: 93px;
  width: 100%;
  position: relative;
  .range-time {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    align-content: flex-start;
    .box-item {
      position: relative;
      z-index: 1;
      height: 33.3333%;
      width: 88px;
      display: flex;
      align-items: center;
      .item {
        width: 80px;
        padding: 2px 4px 2px 4px;
        position: absolute;
        background-color: #34beb3;
        color: #fff;
        border-radius: 30px;
        text-align: center;
        font-size: 10px;
        -webkit-tap-highlight-color: transparent;
      }
      .item[id='0'] {
        // background-color: #cacaca;
        background-color: #e58f90;
      }
      .item[id='5'] {
        background-color: #e58f90;
      }
    }
  }
  .background-range-item {
    position: absolute;
    // background-color: #e58f90;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
}

.arrow-icon {
  height: 100%;
  width: 8px;
  margin-right: 2px;
  margin-left: 2px;
  padding: 0;
}
</style>
