<template>
  <div class='db-container'>
    <div class='top-page'>
      <span>Drug Set</span>
      <button class='btn-add-drug-set' @click="$router.push({ name: 'DrugSet', params: {id: 'create'} })">
        <img
          class='mr-2'
          src='@/assets/svg/add-white.svg'
        />
        Add Drug Set
      </button>
    </div>
    <div
      v-for='set in drugSet'
      :key='set.id'
      class='drug-item-set'
    >
      <div class='header'>
        <span>{{ set.name }}</span>
        <div class="btn-action-group">
          <button class='btn-edit-drug-set mr-2' @click="$router.push({ name: 'DrugSet', params: {id: set.id} })">
            Edit
          </button>
          <button class='btn-delete-drug-set' @click='deleteDrugSet(set.id)'>
            <img
              src='@/assets/svg/remove-drug.svg'
              class='remove-drug-img'
            />
          </button>
        </div>
      </div>
      <div class='table-drug-set'>
        <b-table
          :data='set.drugSetItem'
          hoverable
          mobile-cards
        >
          <b-table-column v-slot='props' field='name' label='Drug Name'>
            {{ props.row.drugItem.name }}
          </b-table-column>
          <b-table-column v-slot='props' field='unit' label='Unit'>
            {{ props.row.unit }}
          </b-table-column>
          <b-table-column v-slot='props' field='frequency' label='Frequency'>
            {{ props.row.frequency.name }} {{ props.row.frequencyTime.name }}
          </b-table-column>
          <b-table-column v-slot='props' field='duration' label='Duration'>
            {{ props.row.duration ? props.row.duration + ' day' : 'Continue' }}
          </b-table-column>
          <b-table-column v-slot='props' field='qty' label='Total Qty.'>
            {{ props.row.qty }}
          </b-table-column>
          <template slot='empty'>
            <div>Drug basket is empty.</div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'
import Swal from 'sweetalert2'

export default {
  name: 'DashboardDrugSet',
  data() {
    return {
      drugSet: []
    }
  },
  async created() {
    await this.getDrugSet()
  },
  methods: {
    async getDrugSet() {
      const drugSet = await api.getDrugSet()
      this.drugSet = drugSet.data
    },
    async deleteDrugSet(id) {
      const result = await Swal.fire({
        title:
          '<div style="font-size: 24px;"><strong>ยืนยันการลบ</strong></div>',
        html: '',
        showCancelButton: true,
        focusCancel: true,
        focusConfirm: false,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#D0A488',
        confirmButtonColor: '#c3c3c3'
      })
      if (result.isConfirmed) {
        await api.deleteDrugSetById(id)
        this.$buefy.toast.open({
          message: 'Delete successfully',
          type: 'is-success'
        })
        await this.getDrugSet()
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.db-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
}

.top-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #dbd6d3;
  padding-bottom: 15px;
  margin-bottom: 15px;

  * {
    font-size: 22px;
    font-weight: lighter;
  }
}

.btn-add-drug-set {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 22px;
  border: none;
  color: #ffffff;
  background: #D0A488;
  padding: 5px 20px;
  outline: none;
  cursor: pointer;
  height: 44px;
  font-size: 16px;
  font-weight: 300;
}

.btn-edit-drug-set {
  display: inline-flex;
  align-items: center;
  border-radius: 22px;
  border: solid 1px #979797;
  color: #979797;
  background: transparent;
  padding: 5px 20px;
  outline: none;
  cursor: pointer;
  height: 36px;
}

.btn-delete-drug-set {
  display: inline-flex;
  align-items: center;
  height: 36px;
  cursor: pointer;
  border: none;
}
.btn-action-group {
  display: flex;
  align-items: center;
}
.drug-item-set {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  border-bottom: solid 1px #dbd6d3;
  padding-bottom: 30px;
  margin-bottom: 15px;
}
</style>
<style lang='scss'>
.table-drug-set {
  .b-table .table {
    border-radius: 20px !important;
  }
}
</style>
