<template>
  <div class="message-containner">
    <img
      v-for="(imgUrl, index) in imgUrls"
      alt=""
      :key="index"
      :src="imgUrl"
      class="media"
      @click="showImgLightBox(index)"
    />
    <b-modal v-model="isImageModalActive">
      <div class="image-container">
        <img class="image" :src="imgUrls[index]" alt="" />
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['imgUrls'],
  data() {
    return {
      isImageModalActive: false,
      index: 0,
    }
  },
  methods: {
    showImgLightBox(index) {
      this.isImageModalActive = true
      this.index = index
    },
  },
}
</script>

<style scoped>
.media {
  height: auto;
  cursor: pointer;
  width: 100%;
  padding: 5px;
  margin: 0px !important;
  border: 0px !important;
  max-width: 300px;
}
.message-containner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  width: 80%;
}
</style>
