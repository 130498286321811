import Vue from 'vue'
import VueRouter from 'vue-router'
import Symptom from '@/views/Symptom'
import MedicalReport from '@/views/MedicalReport'
import Register from '@/views/Register'
import Login from '@/views/Login'
import Logout from '@/views/Logout'
import RegistrationStatus from '@/views/RegistrationStatus'
import Dashboard from '@/views/Dashboard'
import SummaryCaseCompleted from '@/views/SummaryCaseCompleted'
import DashboardHome from '@/components/DashboardHome'
import DashboardFinance from '@/components/DashboardFinance'
import DashboardPersonal from '@/components/DashboardPersonal'
import DashboardSettings from '@/components/DashboardSettings'
import Policy from '@/components/Policy'
import PolicyPhysician from '@/components/Policy-Physician'
import PolicyApplicant from '@/components/Policy-Applicant'
import ForgotPassword from '@/views/ForgotPassword'
import ResetPassword from '@/views/ResetPassword'
import RecordDetail from '@/views/RecordDetail'
import Reconnection from '@/views/Reconnection'
import PostRegister from '@/views/PostRegister'
import Notification from '@/views/Notification'
import DashboardScheduler from '@/components/DashboardScheduler'
import HowToPDF from '@/components/HowToPDF'
import WaitingQueueList from '@/views/WaitingQueueList'
import ChatBox from '@/views/chat/ChatBox'
import CallChatPage from '@/views/chat/CallChatPage'
import Recommendation from '@/views/Recommendation'
import ContentList from '@/views/content/contentList'
import ContentPage from '@/views/content/content'
import { auth } from '../utils/firebase'
import DrugSet from '@/views/DrugSet'
import DrugDetail from '@/views/DrugDetail'
import DashboardDrugSet from '@/views/DashboardDrugSet'

Vue.use(VueRouter)

const routes = [
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { notRequiredAuth: true }
  },
  {
    path: '/post-register',
    name: 'PostRegister',
    component: PostRegister
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { notRequiredAuth: true }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: { notRequiredAuth: true }
  },
  {
    path: '/record/:id',
    name: 'RecordDetail',
    component: RecordDetail
  },
  {
    path: '/symptom/:id',
    name: 'Symptom',
    component: Symptom
  },
  {
    path: '/drug-detail/:id',
    name: 'DrugDetail',
    component: DrugDetail
  },
  {
    path: '/drug-set/:id',
    name: 'DrugSet',
    component: DrugSet
  },
  {
    path: '/medical-report/:id',
    name: 'MedicalReport',
    component: MedicalReport
  },
  {
    path: '/medical-report/:id/preview',
    name: 'MedicalReportPreview',
    component: MedicalReport,
    props: {
      previewMode: true
    }
  },
  {
    path: '/medical-report/:id/join-room/:ticketId',
    name: 'MedicalReportJoinRoom',
    component: MedicalReport,
    props: {
      previewMode: true,
      joinRoom: true
    }
  },
  {
    path: '/registration-status',
    name: 'RegistrationStatus',
    component: RegistrationStatus,
    meta: { notRequiredAuth: true }
  },
  {
    path: '/dashboard',
    component: Dashboard,
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: DashboardHome
      },
      {
        path: '/dashboard/finance',
        name: 'DashboardFinance',
        component: DashboardFinance
      },
      {
        path: '/dashboard/personal',
        name: 'DashboardPersonal',
        component: DashboardPersonal
      },
      {
        path: '/dashboard/scheduler',
        name: 'DashboardScheduler',
        component: DashboardScheduler
      },
      {
        path: '/dashboard/drug-set',
        name: 'DashboardDrugSet',
        component: DashboardDrugSet
      },
      {
        path: '/dashboard/settings',
        name: 'DashboardSettings',
        component: DashboardSettings
      },
      {
        path: '/dashboard/notification',
        name: 'Notification',
        component: Notification
      },
      {
        path: '/dashboard/waiting-queue-list',
        name: 'WaitingQueueList',
        component: WaitingQueueList
      },
      {
        path: '/dashboard/contents',
        name: 'ContentListPage',
        component: ContentList
      },
      {
        path: '/dashboard/contents/:path+',
        name: 'ContentPage',
        component: ContentPage
      }
    ]
  },
  {
    path: '/how-to',
    name: 'HowToPDF',
    component: HowToPDF,
    meta: { notRequiredAuth: true }
  },
  {
    path: '/policy',
    name: 'Policy',
    component: Policy,
    meta: { notRequiredAuth: true }
  },
  {
    path: '/policy/physician',
    name: 'PolicyPhysician',
    component: PolicyPhysician,
    meta: { notRequiredAuth: true }
  },
  {
    path: '/policy/applicant',
    name: 'PolicyApplicant',
    component: PolicyApplicant,
    meta: { notRequiredAuth: true }
  },
  {
    path: '/summary-case-completed',
    name: 'SummaryCaseCompleted',
    component: SummaryCaseCompleted
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { notRequiredAuth: true }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { notRequiredAuth: true }
  },
  {
    path: '/reconnection',
    name: 'ReconnectionPage',
    component: Reconnection,
    meta: { notRequiredAuth: true }
  },
  {
    path: '/chat/:id',
    name: 'ChatBox',
    component: ChatBox
  },
  {
    path: '/call-chat/:id',
    name: 'CallChatPage',
    component: CallChatPage
  },
  {
    path: '/recommendation/:recommendationId',
    name: 'Recommendation',
    component: Recommendation
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  // Save token from ios App to localStorage
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  if (to.query.token) {
    localStorage.setItem('access-token', to.query.token)
  }
  if (to.query.refreshToken) {
    localStorage.setItem('refresh-token', to.query.refreshToken)
  }
  if (to.query.userId) {
    localStorage.setItem('user-id', to.query.userId)
  }
  if (to.query.firebaseToken) {
    localStorage.setItem('firebase-token', to.query.firebaseToken)
    auth.signInWithCustomToken(to.query.firebaseToken)
  }
  if (['Logout', 'ReconnectionPage'].includes(to.name)) {
    next()
  } else {
    const isAuthenticated = localStorage.getItem('access-token')
    const isRequiredToAuth = to.matched.some(
      record => !record.meta.notRequiredAuth
    )

    if (isRequiredToAuth) {
      if (!isAuthenticated) {
        next({ name: 'Login' })
      } else {
        next()
      }
    } else {
      next()
    }
    // f (isRequiredToAuth && !isAuthenticated) {
    //   next({ name: 'Login' })
    // } else if (!isRequiredToAuth && isAuthenticated) {
    //   next({ name: 'Dashboard' })
    // } else {
    //   next()
    // }
  }
})

export default router
