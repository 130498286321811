<template>
  <div class="containner">
    <img src="@/assets/svg/underconstruction.svg" alt="underconstruction" />
    <span>เกิดปัญหาการเชื่อมต่อกับคนไข้</span>
    <span>กำลังเชื่อมต่อใหม่</span>
    <span>กรุณารอสักครู่...</span>
    <img src="@/assets/img/lifelinewaitscreen.gif" alt="underconstruction" />
  </div>
</template>

<script>
export default {
  name: 'Reconnection'
}
</script>

<style scpoed>
.containner {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.containner img {
  margin-bottom: 15px;
}
.containner span {
  margin: 15px 0;
}
</style>
