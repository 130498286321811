import Vue from 'vue'
import moment from 'moment'
import bignumber from 'bignumber.js'

Vue.filter('period', val => {
  switch (val) {
    case 'day':
      return 'วัน'
    case 'week':
      return 'สัปดาห์'
    case 'month':
      return 'เดือน'
  }
})

Vue.filter('calAge', val => {
  const birthday = new Date(val)
  const ageDifMs = Date.now() - birthday.getTime()
  const ageDate = new Date(ageDifMs)
  return `${Math.abs(
    ageDate.getUTCFullYear() - 1970
  )} ปี ${ageDate.getUTCMonth() + 1} เดือน`
})

Vue.filter('gender', val => {
  switch (val) {
    case 'male':
      return 'ชาย'
    case 'female':
      return 'หญิง'
    case 'prefer_not_to_say':
      return 'ไม่ระบุ'
    default:
      return '-'
  }
})

Vue.filter('toDateFormat', (val, format = 'DD/MM/YYYY') => {
  return moment(val).local().format(format)
})

Vue.filter('joinSymptoms', arrSymptoms => {
  return arrSymptoms?.join(', ') ?? ''
})

Vue.filter('numberWithCommas', (value, format = 0, isPad = false, currency) => {
  if (!value) return 0
  const numberFormatted = bignumber(value).toFormat(format)
  if (
    isPad &&
    bignumber(numberFormatted).isZero() &&
    !bignumber(value).isZero()
  ) {
    let decimal = '1'
    while (decimal.length < format) {
      decimal = `0${decimal}`
    }
    return currency ? `< ${currency}0.${decimal}` : `< 0.${decimal}`
  }
  if (currency) {
    if (bignumber(numberFormatted).isNegative()) {
      return `-${currency}${bignumber(numberFormatted).times(-1)}`
    }
    return `${currency}${numberFormatted}`
  }
  return numberFormatted
})
