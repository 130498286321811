<template>
  <div></div>
</template>

<script>
import { auth } from '@/utils/firebase'
import Swal from 'sweetalert2'
export default {
  name: 'Logout',
  async created() {
    const result = await Swal.fire({
      title:
        '<div style="font-size: 24px;"><strong>Are you sure you want to log out?</strong></div>',
      html:
        'You will not receive appointment notifications after log out. We suggest you to set status to offline instead.',
      showCancelButton: true,
      focusCancel: true,
      focusConfirm: false,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#34beb3',
      confirmButtonColor: '#c3c3c3'
    })

    if (result.isConfirmed) {
      localStorage.removeItem('access-token')
      localStorage.removeItem('refresh-token')
      localStorage.removeItem('user-id')
      localStorage.removeItem('firebase-token')
      auth.signOut()
      try {
        if (window.webkit) {
          window.webkit.messageHandlers.logOutHandler.postMessage(
            'Hello from JavaScript'
          )
        } else {
          this.$router.push({ name: 'Login' })
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped></style>
