<template>
  <div id="forgot-password-page">
    <div class="forgot-password-container">
      <div>
        <div class="form-ctn">
          <img src="@/assets/img/forgot-password-cover.png" />
          <div class="forgot-password-form">
            <div class="forgot-password-title">
              Forgot password ?
            </div>
            <div class="forgot-password-subtitle">
              Enter your e-mail address and we’ll send you code to reset your
              password.
            </div>
            <ValidationObserver v-slot="{ invalid }" ref="form">
              <b-field label="Email">
                <ValidationProvider
                  name="Email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <b-input v-model="email" rounded />
                  <span class="error-msg">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
              <b-button
                class="cancel-btn"
                rounded
                @click="onCancel"
              >
                ยกเลิก
              </b-button>
              <b-button
                :disabled="invalid"
                :loading="isLoading"
                type="is-secondary"
                class="next-btn"
                rounded
                @click="submit"
              >
                ตกลง
              </b-button>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      isLoading: false
    }
  },
  methods: {
    async submit() {
      this.isLoading = true
      try {
        const data = await api.postRequestResetPasswordCode(this.email)
        if (!data.success) {
          this.$refs.form.setErrors({ Email: ["can't find this email"] })
        } else {
          this.$router.push({ name: 'ResetPassword' })
        }
      } catch (e) {
        console.log(e)
        this.$refs.form.setErrors({ Email: ["can't find this email"] })
      }
      this.isLoading = false
    },
    onCancel() {
      localStorage.removeItem('access-token')
      try {
        this.$router.push({ name: 'Login' })
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style lang="scss">
#forgot-password-page {
  .label {
    color: #8f6f56 !important;
    font-weight: normal !important;
  }
}
</style>

<style lang="scss" scoped>
.forgot-password-container {
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  padding: 32px 0;
}
.back {
  color: #888888;
  position: absolute;
  top: 40px;
  left: 30px;
  line-height: 30px;
  cursor: pointer;
  img {
    vertical-align: middle;
    margin-right: 10px;
  }
}
.forgot-password-title {
  font-size: 25px;
  color: #8f6f56;
  width: 100%;
  margin-top: 40px;
}
.forgot-password-subtitle {
  line-height: 30px;
  font-weight: 100;
  color: #8f6f56;
  margin-top: 10px;
  margin-bottom: 30px;
}
.forgot-password-form {
  width: 80%;
  margin: 0 auto;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #d2d2d2;
  margin: 40px 0;
}
.cancel-btn {
  height: 45px;
  box-shadow: 0;
  margin-right: 20px;
  width: calc(50% - 10px);
}
.next-btn {
  height: 45px;
  box-shadow: 0;
  width: calc(50% - 10px);
}
.form-ctn {
  margin-top: 50px;
}
/* Less than mobile */
@media only screen and (max-width: 768px) {
  .back {
    top: 10px;
    left: 1rem;
    img {
      vertical-align: middle;
      margin-right: 0;
    }
  }
}
</style>
