<template>
  <div class="summary-status-container">
    <div class="summary-status-box">
      <div class="sum-title">Summary Cases Completed</div>
      <div class="sum-subtitle">การให้คำปรึกษาทางการแพทย์เสร็จสมบูรณ์</div>
      <img
        src="@/assets/img/summary-case-completed.png"
        class="completed-image"
      />
      <div class="btn-box">
        <b-button
          rounded
          expanded
          @click="$router.push({ name: 'WaitingQueueList' })"
        >
          <span class="btn-text">กลับสู่เมนูหลัก</span>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SummaryCaseCompleted'
}
</script>

<style lang="scss" scoped>
.summary-status-container {
  display: flex;
  align-items: center;
  height: 100vh;
}
.summary-status-box {
  color: #535353;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  padding: 10px;
}
.sum-title {
  color: #34beb3;
  margin-bottom: 17px;
  font-size: 35px;
  font-weight: bold;
}
.sum-subtitle {
  font-size: 25px;
}
.completed-image {
  margin-top: 15px;
  max-height: 50vh;
}
.btn-box {
  width: 300px;
  margin: 50px auto 0;
  .btn-text {
    color: #979797;
  }
}
/* Less than mobile */
@media (max-width: 768px) {
  .summary-status-box {
    font-size: 30px;
  }
  .sum-subtitle {
    font-size: 20px;
  }
}
</style>
