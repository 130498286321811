export default {
  author: 'SkinX Team',
  title: 'NEW DERCOS ANTI-DANDRUFF SHAMPOO – แชมพูขจัดรังแคสูตรอ่อนโยนจากแบรนด์เวชสำอางดังจากประเทศฝรั่งเศส VICHY',
  image: require('./image.png'),
  createdAt: new Date('2023-10-31'),
  content: `จัดการสาเหตุของการเกิดรังแค (Dandruff and Seborrheic Dermatitis) อย่างลงตัวตามพยาธิสภาพ (Pathology) ของการเกิดรังแค
1. Rebalance Scalp Microbiome – Decrease Malassezia sp. ด้วย Selenium Disulfide
2. Rebalances Sebum Composition – Selenium Disulfide และ Vitamin E ป้องกัน Sebum Preoxidation
3. Improves Barrier Function – Ceramide R ผ่านการทดสอบด้านประสิทธิภาพในการขจัดรังแกและรักษาโรค Seborrheic Dermatitis โดยมีประสิทธิภาพที่ใกล้เคียงกับ การใช้แชมพูยา Ketoconazole และ Zinc pyrithione แต่มีความพึงพอใจจากผู้ใช้สูงกว่า เนื่องจากมีผลด้าน Hair Quality ที่ดีกว่า เช่น ความนุ่มของเส้นผม, ความหนาของผมโดยรวม, และเส้นผมไม่ติดพันกัน นอกจากนี้ NEW DERCOS ANTI-DANDRUFF SHAMPOO ยังมีข้อมูลการศึกษาถึงการใช้ในการ maintenance หลังการรักษาด้วย Ketoconazole ว่าสามารถลดปริมาณรังแคได้อย่างต่อเนื่องและป้องกันการกลับมาเป็นซ้ำ รวมถึงปรับสมดุล Scalp Microbiome ได้เมื่อเทียบกับ Vehicle Shampoo ท่านสามารถเข้าถึง Full publication ได้ที่ LINK ดังต่อไปนี้ https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10234447/

![NEW DERCOS ANTI-DANDRUFF SHAMPOO – แชมพูขจัดรังแคสูตรอ่อนโยนจากแบรนด์เวชสำอางดังจากประเทศฝรั่งเศส VICHY](${require('./image.png')})`
}
