var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"forgot-password-page"}},[_c('div',{staticClass:"forgot-password-container"},[_c('div',[_c('div',{staticClass:"form-ctn"},[_c('img',{attrs:{"src":require("@/assets/img/forgot-password-cover.png")}}),_c('div',{staticClass:"forgot-password-form"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-field',{attrs:{"label":"Code (Sent to your email)"}},[_c('ValidationProvider',{attrs:{"name":"Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"rounded":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-field',{attrs:{"label":"New password"}},[_c('ValidationProvider',{attrs:{"name":"New Password","rules":"required|passwordStrength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"password","rounded":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Confirm new password"}},[_c('ValidationProvider',{attrs:{"name":"Confirm new password","rules":("required|is:" + _vm.password)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"password","rounded":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"next-btn",attrs:{"disabled":invalid,"loading":_vm.isLoading,"type":"is-secondary","rounded":"","expanded":""},on:{"click":_vm.submit}},[_vm._v(" Confirm ")])]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }