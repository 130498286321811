export default {
  author: 'SkinX Team',
  title: 'ส่งต่อความพึงพอใจจากผู้ใช้จริง',
  image: require('./review.png'),
  createdAt: new Date('2023-02-14'),
  content: `SkinX ขอส่งต่อความรู้สึกดี ๆ ด้วยการแบ่งปันการแสดงความคิดเห็นและคำชื่นชมจากผู้รับบริการหลังจากได้รับคำปรึกษาปัญหาผิวจากคุณหมอผิวหนังประจำ SkinX

  ขอขอบคุณที่ท่านเข้ามาเป็นส่วนหนึ่งที่สำคัญกับ SkinX และเราสัญญาว่าจะดูแลท่านเป็นอย่างดีให้สมกับที่ท่านเข้ามาเป็นกำลังสำคัญให้กับเรา🤍
  
  #แอปฯหมอผิว #SkinXThailand #ดูแลผิวดูแลคุณอย่างเข้าใจ

  ![SkinX Doctor Review](${require('./review.png')})`
}
