<template>
  <div
    :class="['calendar-containner', { disabled: !isActiveCalendar }]"
    ref="calendarContainner"
  >
    <!-- <transition name="fadeHeight">
      <div class="div" v-if="isActiveCalendar"></div>
    </transition> -->
    <transition name="fadeHeight">
      <calendar-view
        :show-date="showDate"
        :items="currentSlot"
        :enableDateSelection="false"
        @click-date="onClickDate"
        @click-item="onClickDateItem"
        :showTimes="false"
        :enableDragDrop="false"
        :time-format-options="{ hour: '2-digit', minute: '2-digit' }"
        locale="en-US"
        :itemTop="isMobile ? '3em' : '3.7em'"
        :itemContentHeight="isMobile ? '2em' : '2em'"
      >
        <div
          slot="item"
          slot-scope="t"
          :style="
            `top: ${t.top}; ${
              t.value.itemRow === 2
                ? 'border: 1px solid #34BEB3 !important; color: #34BEB3; background-color: #fff !important;'
                : ''
            }`
          "
          :class="['cv-item', ...t.value.classes]"
          @click="onClickDateItem(t.value)"
          v-if="t.value.itemRow < 3"
        >
          {{ t.value.itemRow === 2 ? 'view more' : t.value.title }}
        </div>
        <div slot="item" v-else></div>
        <div slot="header" slot-scope="props" class="header-containner">
          <div class="calendar-control">
            <button
              class="control-botton current"
              ref="calendarHeader"
              @click="setShowDate(props.headerProps.currentPeriod)"
            >
              today
            </button>
            <button
              class="control-botton previouse"
              @click="setShowDate(props.headerProps.previousFullPeriod)"
            >
              <img src="@/assets/svg/prev-arrow.svg" alt="&larr;" />
            </button>
            <button
              class="control-botton next"
              @click="setShowDate(props.headerProps.nextFullPeriod)"
            >
              <img src="@/assets/svg/next-arrow.svg" alt="&rarr;" />
            </button>
          </div>
          <div class="month-detail">
            {{ props.headerProps.periodLabel }}
          </div>
          <div class="switch" v-if="canSwitchActive"></div>
        </div>
        <div slot="dayContent" slot-scope="day" style="flex: 1">
          <b-tooltip
            :label="getHolidayDescription(day)"
            append-to-body
            multilined
            class="holiday-info"
            v-if="getHolidayDescription(day)"
            ><img class="holiday-icon" src="@/assets/svg/holiday-info.svg"
          /></b-tooltip>
        </div>
      </calendar-view>
    </transition>
    <!-- <transition name="xfade">
      <div v-show="!isActiveCalendar">
        <div class="header-containner" style="border-radius: 20px">
          <div class="switch">
            <b-field>
              <b-switch
                type="is-success"
                v-model="isActive"
                :disabled="disabled"
                :true-value="true"
                :false-value="false"
              >
              </b-switch>
            </b-field>
          </div>
        </div>
      </div>
    </transition> -->
  </div>
</template>

<script>
import { getFirstDayOfMonth } from '@/utils/utils'
import moment from 'moment'

var extraStyle

export default {
  name: 'DoctorCalendar',
  props: [
    'timeSlot',
    'skipSlot',
    'isActiveCalendar',
    'disabled',
    'canSwitchActive',
    'holiday'
  ],
  data() {
    return {
      isMobile: window.matchMedia('(max-width: 768px)').matches,
      showDate: new Date(),
      isShowModal: false,
      chosenDate: '31 August 2018',
      chosenDateItems: []
    }
  },
  created() {
    setTimeout(() => {
      this.$refs.calendarHeader.click()
    }, 500)
  },
  updated() {
    console.log('updating')
    extraStyle = document.createElement('style')
    extraStyle.innerHTML = this.createStyleForHoliday()
    document.getElementsByTagName('head')[0].appendChild(extraStyle)
  },
  destroyed() {
    extraStyle.remove()
  },
  computed: {
    isActive: {
      get() {
        return this.isActiveCalendar
      },
      set(flag) {
        this.$emit('setDisableCalendar', flag)
      }
    },
    currentSlot() {
      let a = this.timeSlot.reduce((accum, current) => {
        if (current.deleted) {
          return accum
        }
        if (current.recurType === 'weekly') {
          const firstDayOfMonth = getFirstDayOfMonth(
            this.showDate,
            current.recurData.dayOfWeeks[0]
          )
          let startDay =
            current.startDate &&
            moment(current.startDate).isAfter(firstDayOfMonth, 'd')
              ? moment(current.startDate).utc()
              : firstDayOfMonth.add(-1, 'weeks')
          let safety = 1
          while (
            startDay.isBefore(
              moment(this.showDate)
                .endOf('M')
                .add(7, 'd')
            ) &&
            safety < 7
          ) {
            const startDate = moment(startDay)
              .utc(true)
              .set({
                millisecond: current.recurData.startTime
              })
              .local()
            const endDate = moment(startDay)
              .utc(true)
              .set({
                millisecond: current.recurData.endTime
              })
              .local()
            const checkStartNotMatch = !this.skipSlot.find(
              slot => startDate.isSame(moment(slot.startDate)) && !slot.deleted
            )
            const checkEndNotMatch = !this.skipSlot.find(
              slot => endDate.isSame(moment(slot.endDate)) && !slot.deleted
            )
            const checkSlotIdNotSkip = !this.skipSlot.find(
              slot => slot.recurSkipId === current.id
            )
            // console.log('check start', checkStartNotMatch)
            // console.log('check end', checkEndNotMatch)
            // console.log('check skip id', checkSlotIdNotSkip)
            // console.log('current.busy', current.busy)
            if (
              ((checkStartNotMatch && checkEndNotMatch) ||
                checkSlotIdNotSkip) &&
              (!current.busy || current.busy.type !== 'skip_recur')
            ) {
              accum.push({
                id: current.id || accum.length + 'abc',
                startDate: startDate.format('yyyy-MM-DD HH:mm:SS'),
                endDay: endDate.format('yyyy-MM-DD HH:mm:SS'),
                style: 'font-size: 0.7em',
                title: `${startDate.format('HH:mm')}`,
                recurring: true,
                classes: current.busy
                  ? ['unavaliable-item']
                  : ['avaliable-item'],
                busy: current.busy
              })
            }

            startDay = startDay.add(7, 'days')
            safety++
          }
        } else {
          const startDate = moment(current.startDate)
          const endDate = moment(current.endDate)
          const checkStartNotMatch = !this.skipSlot.find(slot =>
            startDate.isSame(moment(slot.startDate))
          )
          const checkEndNotMatch = !this.skipSlot.find(slot =>
            endDate.isSame(moment(slot.endDate))
          )
          const checkSlotIdNotSkip = !this.skipSlot.find(
            slot => slot.recurSkipId === current.id
          )
          // console.log('check start', checkStartNotMatch)
          // console.log('check end', checkEndNotMatch)
          // console.log('check skip id', checkSlotIdNotSkip)
          // console.log('current.busy', current.busy)
          if (
            ((checkStartNotMatch && checkEndNotMatch) || checkSlotIdNotSkip) &&
            (!current.busy || current.busy.type !== 'skip_recur')
          ) {
            accum.push({
              id: current.id || accum.length + 'abc',
              startDate: startDate.format('yyyy-MM-DD HH:mm:SS'),
              endDay: endDate.format('yyyy-MM-DD HH:mm:SS'),
              style: 'font-size: 0.7em',
              title: `${startDate.format('HH:mm')}`,
              recurring: false,
              classes: current.busy ? ['unavaliable-item'] : ['avaliable-item'],
              busy: current.busy
            })
          }
        }
        return accum
      }, [])
      const x = moment()
      a = this.onlyUnique(a)
      console.log(moment().diff(x, 'seconds'))
      return a
    }
  },
  watch: {
    currentSlot: function(val, oldVal) {
      if (this.chosenDate) {
        this.$emit('onItemChange', this.chosenDate, this.findCalendarItem())
      }
    }
    // 'this.$refs.calendarContainner.offsetWidth': function (val, oldVal) {
    //   console.log(val, oldVal)
    // },
  },
  methods: {
    findCalendarItem() {
      return this.currentSlot
        .filter(slot =>
          moment(slot.startDate).isSame(moment(this.chosenDate), 'date')
        )
        .map(slot => {
          slot.endDate = slot.endDay
          return slot
        })
    },
    setShowDate(d) {
      this.showDate = d
      this.$emit(
        'onChangeMonth',
        moment(d)
          .startOf('M')
          .startOf('week')
          .toDate(),
        moment(d)
          .endOf('M')
          .endOf('week')
          .toDate()
      )
    },
    onlyUnique(slot) {
      const transformedSlot = slot.reduce((accum, current) => {
        accum[`${current.startDate};:;${current.endDay}`] = current
        return accum
      }, {})
      return Object.values(transformedSlot)
    },
    onClickDate(selectedDate) {
      if (this.disabled) {
        return
      }
      this.chosenDate = selectedDate
      this.isShowModal = true
      this.$emit('onClickDate', this.chosenDate, this.findCalendarItem(), true)
    },
    onClickDateItem(calendarItems) {
      if (this.disabled) {
        return
      }
      this.chosenDate = calendarItems.startDate
      this.isShowModal = true
      this.$emit(
        'onClickDateItem',
        this.chosenDate,
        this.findCalendarItem(),
        true
      )
    },
    getHolidayDescription(dayObj) {
      return this.holiday?.find(
        value =>
          moment(value.date)
            .startOf('day')
            .diff(moment(dayObj.day).startOf('day'), 'day') === 0
      )?.description
    },
    createStyleForHoliday() {
      let html = ''
      if (this.holiday) {
        for (const holiday of this.holiday) {
          const holidayMoment = moment(holiday.date).startOf('day')
          const date =
            holidayMoment.date().toString().length < 2
              ? `0${holidayMoment.date()}`
              : `${holidayMoment.date()}`
          const month =
            (holidayMoment.month() + 1).toString().length < 2
              ? `0${holidayMoment.month() + 1}`
              : `${holidayMoment.month() + 1}`
          html += `.cv-day.d${holidayMoment.year()}-${month}-${date} {color: #FFBD37;} `
        }
      }
      return html
    }
  }
}
</script>
<style>
.calendar-containner {
  width: 100%;
  height: 45em;
}
.switch {
  margin-right: 0px !important;
}
.switch .field .switch .control-label {
  padding: 0px;
}
.cv-wrapper {
  font-weight: 200;
}
.cv-day {
  background-color: #fff;
}
.cv-day.dow0 {
  color: #ff0000;
  /*background-color: #ffbcbc;*/
}
.cv-day.dow6 {
  color: #ac6ced;
  /*background-color: #ede3f1;*/
}
.cv-day.outsideOfMonth {
  opacity: 0.3;
  /*background-color: #ddd;*/
}
.cv-day.today {
  color: #34beb3;
  border: 1px solid #34beb3;
}
.cv-weekdays {
  overflow: hidden !important;
}
.cv-header-days {
  background-color: #fff !important;
}
.cv-header-day.dow0 {
  color: #ff0000 !important;
}
.cv-wrapper {
  border-radius: 20px;
}
.cv-day-number,
.cv-header-day {
  font-size: 20px !important;
  padding: 0.5em !important;
}
.cv-item {
  border-radius: 20px;
  padding: 3px 8px !important;
  border: 0px !important;
  text-align: center;
  color: #ffffff;
  width: calc(50% / 7 - 5px) !important;
  margin-left: 0.7em;
  font-size: 10px !important;
  min-width: 70px;
}
.avaliable-item {
  background-color: #34beb3 !important;
  border: 1px solid #34beb3 !important;
}
.unavaliable-item {
  background-color: #e58f90 !important;
  border: 1px solid #e58f90 !important;
}
.holiday-info {
  padding-top: 0.5em;
}
@media (max-width: 768px) {
  .calendar-containner {
    height: 25em;
  }
  .cv-day-number,
  .cv-header-day {
    font-size: 11px !important;
    padding: 0.2em !important;
  }
  .cv-item {
    width: calc(80% / 7) !important;
    min-width: 5px;
    margin-left: 4px;
    font-size: 6px !important;
    padding: 1px !important;
    overflow: hidden;
  }
  .holiday-info {
    padding-top: 0px;
    width: 10px;
  }
}
</style>
<style scoped>
.disabled {
  opacity: 0.5;
}
.header-containner {
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas: 'control month switch';
  padding: 10px;
}
.calendar-control {
  grid-area: control;
  place-self: center;
  width: 100%;
  display: flex;
}
.control-botton {
  background-color: transparent;
  border: 0px;
  font-size: large;
  min-width: 50px;
}
.control-botton:hover {
  background-color: #efefef;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.7;
}
.current {
  background-color: #efefef;
  border-radius: 5px;
}
.month-detail {
  place-self: center;
  grid-area: month;
}
.switch {
  place-self: center end;
  grid-area: switch;
}

.xfade-enter-active {
  animation: fade-in 2s;
}
.xfade-leave-active {
  animation: fade-in 2s reverse;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 1s;
  max-height: 100%;
  min-height: 45px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  max-height: 0px;
}
/* .slide-fade-enter, .slide-fade-leave-to */
/* { */
/* transform: translateX(10px);
  opacity: 0;
} */
</style>
