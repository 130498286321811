export default {
  author: 'SkinX Team',
  title: 'SkinX Sharing with You ชวนอัปเดตงานวิจัยด้านผิวหนัง!"',
  image: require('./image.png'),
  createdAt: new Date('2023-05-25'),
  content: `SkinX ชวนหมอผิวมาอัปเดทความรู้กับงานวิจัยด้านผิวหนัง

 

  หัวข้อ ‘Influence of COVID-19 mRNA vaccination on the efficacy and safety of Botulinum toxin type A injections’
  
  คลิกอ่านเพิ่มเติมได้ที่ : https://shorturl.at/agCQW
  
  https://pubmed.ncbi.nlm.nih.gov/35866341/ 
  
  

  ![SkinX Doctor Review](${require('./image.png')})`
}
