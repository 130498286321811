<template>
  <div id="register-page">
    <div
      v-show="this.platform !== 'ios' || this.step > 0"
      class="back"
      @click="clickBackBtn"
    >
      <img src="@/assets/svg/back.svg" />
      back
    </div>
    <div class="register-container">
      <div>
        <div class="regis-title">Registration</div>
        <div id="registration-steps" class="steps">
          <b-steps
            v-model="step"
            :has-navigation="false"
            :mobile-mode="null"
            type="is-secondary"
            animated
          >
            <b-step-item :step="1">
              <div class="form-container">
                <RegisterFormTermsAndCondition @next="onNextState" />
              </div>
            </b-step-item>
            <b-step-item :step="2">
              <div class="form-container">
                <RegisterFormEmail @next="onNextState" :isLoading="isLoading" />
              </div>
            </b-step-item>
            <b-step-item :step="3">
              <div class="form-container">
                <RegisterFormDetail
                  :hospitals="hospitals"
                  :specialties="specialties"
                  :expertises="expertises"
                  :isLoading="isLoading"
                  :procedureExpertises="procedureExpertises"
                  @next="onSubmit"
                />
              </div>
            </b-step-item>
          </b-steps>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterFormEmail from '@/components/RegisterFormEmail'
import RegisterFormDetail from '@/components/RegisterFormDetail'
import RegisterFormTermsAndCondition from '@/components/RegisterFormTermsAndCondition'
import api from '@/utils/api'
import { dataURItoBlob } from '@/utils/utils'

export default {
  name: 'Register',
  components: {
    RegisterFormTermsAndCondition,
    RegisterFormEmail,
    RegisterFormDetail,
  },
  data() {
    return {
      step: 0,
      form: {},
      isLoading: false,
      hospitals: [],
      specialties: [],
      expertises: [],
      platform: '',
      procedureExpertises: [],
    }
  },
  mounted() {
    const userAgent = window.navigator.userAgent.toLowerCase()
    const ios = /iphone|ipod|ipad/.test(userAgent)
    if (ios) {
      this.platform = 'ios'
    }
  },
  async created() {
    ;[
      this.hospitals,
      this.specialties,
      this.expertises,
      this.procedureExpertises,
    ] = await Promise.all([
      api.getHospitals(),
      api.getSpecialties(),
      api.getExpertises(),
      api.getprocedureExpertises(),
    ])
  },
  methods: {
    onNextState(data) {
      this.form = { ...this.form, ...data }
      this.step += 1
    },
    async onSubmit(data) {
      this.isLoading = true
      try {
        this.form = { ...this.form, ...data }
        const formData = new FormData()
        formData.append('email', this.form.email)
        formData.append('password', this.form.password)
        formData.append(
          'name',
          `${this.form.prefix} ${this.form.name} ${this.form.surname}`
        )
        formData.append('nickname', this.form.nickname)
        formData.append('telNo', this.form.tel)
        formData.append('licenseNo', this.form.licenseNo)
        formData.append('hospitalId', this.form.hospital.id)
        formData.append('specialtyId', this.form.speciality.id)
        this.form.expertises.forEach((ex) => {
          formData.append('expertiseIds[]', ex.id)
        })
        formData.append('picture', dataURItoBlob(this.form.pictureImageData))
        formData.append('certPicture1', this.form.certificationFiles[0])
        formData.append('certPicture2', this.form.certificationFiles[1])
        formData.append('certPicture3', this.form.certificationFiles[2])
        formData.append('certPicture4', this.form.certificationFiles[3])
        formData.append('isAcceptedTerm', this.form.isAcceptedTerm)
        formData.append('isClinicCase', this.form.isClinicCase)
        this.form.procedureExpertises.forEach((ex) => {
          formData.append('procedureExpertiseIds[]', ex.id)
        })
        await api.registDoctor(formData)
        localStorage.removeItem('registerEmailSession')
        localStorage.removeItem('registerDetailSession')
        this.$router.push({ name: 'RegistrationStatus' })
      } catch (e) {
        console.log(e)
        this.$buefy.toast.open({
          type: 'is-danger',
          message: e.response?.data.message ?? 'Unexpected Error',
        })
      }
      this.isLoading = false
    },
    clickBackBtn() {
      if (this.step === 0) {
        this.$router.push({ name: 'Login' })
      } else {
        this.step -= 1
      }
    },
  },
}
</script>

<style lang="scss">
#registration-steps {
  .is-active {
    .step-marker {
      color: white !important;
      background: #d0a488 !important;
    }
  }
  .step-marker {
    border: 0 !important;
  }
}
#register-page {
  .label {
    color: #8f6f56 !important;
    font-weight: normal !important;
  }
}
</style>

<style lang="scss" scoped>
.register-container {
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  padding: 32px 0;
}
.back {
  color: #888888;
  position: absolute;
  top: 40px;
  left: 30px;
  line-height: 30px;
  cursor: pointer;
  img {
    vertical-align: middle;
    margin-right: 10px;
  }
}
.regis-title {
  font-size: 33px;
  color: #8f6f56;
  width: 100%;
  text-align: center;
}
.steps {
  margin-top: 60px;
}
.form-container {
  margin-top: 50px;
}
/* Less than mobile */
@media only screen and (max-width: 768px) {
  .back {
    top: 10px;
    left: 1rem;
    img {
      vertical-align: middle;
      margin-right: 0;
    }
  }
}
</style>
