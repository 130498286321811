<template>
  <div class="modal-containner">
    <div class="modal-card">
      <div class="modal-header">Choose time for Appointment</div>
      <div class="modal-chosen-date">
        {{ chosenDate | toDateFormat('DD MMMM YYYY') }}
      </div>
      <div class="divider"></div>
      <div class="modal-content">
        <div class="modal-time-slot-row">
          <div class="time-slot-time" style="font-size: 14px">Time</div>
          <div class="time-slot-switch">Repeat</div>
        </div>
        <div
          class="modal-time-slot-row"
          v-for="item in chosenDateItemsSorted"
          :key="item.id"
        >
          <div class="time-slot-switch">
            {{ item.recurring ? 'weekly' : 'Today' }}
          </div>
          <div class="time-slot-time">
            {{ item.startDate | toDateFormat('HH:mm') }} -
            {{ item.endDate | toDateFormat('HH:mm') }}
          </div>
          <div class="time-slot-del">
            <div
              class="remove-time"
              v-if="item.canDeleteOne || item.canDeleteAll"
            >
              <b-button class="del-btn" @click="onDeleteTime(item)">
                {{ deleteList.find(list => list.id === deleteAction).name }}
              </b-button>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                  <b-button
                    class="drop-down-btn"
                    :icon-right="active ? 'menu-up' : 'menu-down'"
                  />
                </template>

                <b-dropdown-item
                  aria-role="list"
                  class="drop-down-item"
                  @click="onSelectDropDwon(deleteList[0].id)"
                  :disabled="!item.canDeleteOne"
                  >{{ deleteList[0].name }}</b-dropdown-item
                >
                <b-dropdown-item
                  aria-role="list"
                  class="drop-down-item"
                  :disabled="!item.recurring || !item.canDeleteAll"
                  @click="onSelectDropDwon(deleteList[1].id)"
                  >{{ deleteList[1].name }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <div class="remove-time" v-else style="color: #ef696a">
              This slot booked
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="modal-add-slot">
          <div class="modal-add-slot-label1">Time Start</div>
          <div class="modal-add-slot-label2">Time End</div>
          <div class="modal-add-slot-label3">
            <img src="@/assets/svg/repeat.svg" alt="f" style="width: 20px" />
            Repeat
          </div>
          <div class="modal-add-slot-col1">
            <b-field>
              <b-select placeholder="Select a hour" rounded v-model="startDate">
                <option
                  :value="startTime"
                  v-for="(startTime, index) in startSlot"
                  :key="index"
                >
                  {{ startTime | toDateFormat('HH:mm') }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="modal-add-slot-col2">
            <b-field>
              <b-select placeholder="Select a minute" rounded v-model="endDate">
                <option
                  :value="endTime"
                  v-for="(endTime, index) in endSlot"
                  :key="index"
                >
                  {{ endTime | toDateFormat('HH:mm') }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="modal-add-slot-col3">
            <b-field>
              <b-switch
                type="is-success"
                v-model="isRepeat"
                :true-value="true"
                :false-value="false"
              >
              </b-switch>
            </b-field>
          </div>
          <div class="modal-add-slot-col4">
            <b-button type="is-success" rounded expanded @click="onAddTime"
              >Add</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['chosenDate', 'chosenDateItems', 'minPerSlot'],
  data() {
    return {
      startDate: moment(),
      endDate: moment(),
      isRepeat: false,
      avaliableTime: [],
      deleteList: [
        {
          id: 1,
          name: 'ลบวันนี้เท่านั้น'
        },
        {
          id: 2,
          name: 'ลบเวลานี้ทั้งหมด'
        }
      ],
      deleteAction: 1
    }
  },
  created() {
    const current = moment(this.chosenDate)
    const chosenDate = moment(this.chosenDate)
    let safety = 0
    while (current.get('date') === chosenDate.get('date') && safety < 100) {
      this.avaliableTime.push(moment(current))
      current.add(this.minPerSlot, 'minutes')
      safety++
    }
    this.avaliableTime.push(moment(current))
    if (this.minPerSlot === 20) {
      this.startDate = this.avaliableTime[24]
      this.endDate = this.avaliableTime[25]
    } else {
      this.startDate = this.avaliableTime[12]
      this.endDate = this.avaliableTime[13]
    }
  },
  computed: {
    startSlot() {
      return this.avaliableTime
    },
    endSlot() {
      return this.avaliableTime.filter(time => time.isAfter(this.startDate))
    },
    chosenDateItemsSorted() {
      return [...this.chosenDateItems].sort((a, b) =>
        a.startDate.localeCompare(b.startDate)
      )
    }
  },
  methods: {
    onSelectDropDwon(id) {
      this.deleteAction = id
    },
    onAddTime() {
      this.$emit('onAddTime', this.startDate, this.endDate, this.isRepeat)
    },
    onDeleteTime(item) {
      this.$emit('onDeleteTime', item, this.deleteAction === 2)
    }
  }
}
</script>
<style>
.switch {
  margin-right: 0px !important;
}
.time-slot-switch .field .switch .control-label {
  padding: 0px !important;
}
</style>
<style scoped>
.modal-containner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drop-down-btn {
  border-radius: 0px 30px 30px 0px;
  height: 30px;
  min-width: 20px;
}
.modal-header {
  font-size: 20px;
  line-height: 31px;
  color: #3e4a59;
  text-align: center;
  font-weight: bold;
}
.modal-chosen-date {
  font-size: 16px;
  line-height: 26px;
  color: #3c4857;
  text-align: center;
}
.modal-card {
  width: 450px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}
.modal-content {
  width: 100%;
  max-height: 300px;
}
.modal-time-slot-row {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr 5fr;
  grid-template-areas: 'time switch  del';
  align-items: center;
  margin: 5px 0;
  font-size: 14px;
  font-weight: 300;
}
.time-slot-label1 {
  grid-area: label1;
}
.time-slot-time {
  grid-area: time;
}
.time-slot-switch {
  grid-area: switch;
  justify-self: center;
}
.time-slot-label2 {
  grid-area: label2;
}
.time-slot-del {
  grid-area: del;
  justify-self: end;
}
.divider {
  width: 100%;
  height: 0.5px;
  background-color: #ebebeb;
  margin: 15px 0;
}
.modal-add-slot {
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 20% 25%;
  grid-template-areas: 'label1 label2 label3 .' 'col1 col2 col3 col4';
  align-items: center;
  column-gap: 1%;
  row-gap: 12px;
  margin: 5px 0;
}
.modal-add-slot-label1 {
  grid-area: label1;
}
.modal-add-slot-label2 {
  grid-area: label2;
}
.modal-add-slot-label3 {
  grid-area: label3;
}
.modal-add-slot-col1 {
  grid-area: col1;
}
.modal-add-slot-col2 {
  grid-area: col2;
}
.modal-add-slot-col3 {
  grid-area: col3;
}
.modal-add-slot-col4 {
  grid-area: col4;
}
.del-btn {
  border-radius: 30px 0px 0px 30px;
  min-width: 80px;
  height: 30px;
  font-size: 14px;
  font-weight: 300;
  color: #ef696a;
}
.drop-down-item {
  font-size: 14px;
  line-height: 13px;
  color: #ef696a;
}
.drop-down-item:hover {
  font-size: 14px;
  background: #fc8086;
  color: #ffffff;
}
@media (max-width: 767px) {
  .drop-down-btn {
    padding: 11px 11px;
  }
  .time-slot-time {
    font-size: 12px;
  }
  .del-btn {
    padding: 0px 8px;
  }
  .modal-add-slot {
    width: 100%;
    display: grid;
    grid-template-columns: 33% 33% 33% 33%;
    grid-template-areas: 'label1 label2 label3 ' 'col1 col2 col3 ' 'col4 col4 col4';
    align-items: center;
    column-gap: 1%;
    row-gap: 12px;
    margin: 5px 0;
  }
  .modal-content {
    max-height: calc(100vh - 400px);
  }
}
</style>
