<template>
  <div>
    <div class="header">
      <img src="@/assets/svg/back-white.svg" @click="$router.go(-1)" />
      Medical Report History
    </div>
    <div v-if="record" class="main-content">
      <div class="label">Patient</div>
      <div class="card">
        <div class="columns is-multiline">
          <div class="column">
            <div class="card-line">
              <span class="key">Patient name</span>
              {{ record.patient.name }}
            </div>
            <div class="card-line">
              <span class="key">Age</span>
              {{ record.patient.birthDate | calAge }}
            </div>
            <div class="card-line">
              <span class="key">Gender</span>
              {{ record.patient.gender | gender }}
            </div>
          </div>
          <div class="column">
            <div class="card-line">
              <span class="key">Underlying disease</span>
              {{ record.patient.congenitalDisease }}
            </div>
            <div class="card-line">
              <span class="key">Drug/Food allergy</span>
              {{ record.patient.drugAllergy }}
            </div>
          </div>
        </div>
      </div>
      <div class="label">By Doctor</div>
      <div class="card">
        <div class="columns is-multiline">
          <div class="column">
            <div class="card-line">
              <span class="key"> Name </span>
              {{ record.doctor.name }}
            </div>
          </div>
          <div class="column">
            <div class="card-line">
              <span class="key"> Hospital </span>
              {{ record.doctor.hospital.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="label">Medical Report</div>
      <div class="card">
        <b-field label="Chief complaint" class="field">
          <span class="pd-value">
            {{ record.symptoms | joinSymptoms }}
          </span>
        </b-field>
        <b-field label="Present illness" class="field">
          <span class="pd-value">
            {{
              record.summary && record.summary.illness
                ? record.summary.illness
                : '-'
            }}
          </span>
        </b-field>
        <b-field label="PE" class="field">
          <div
            v-if="record.pictures && record.pictures.length"
            style="display: flex;"
          >
            <div
              v-for="(pic, index) in record.pictures"
              :key="index"
              style="padding: 12px;"
            >
              <img
                :src="record.thumbnailPictures[index]"
                @click="openPicModal(index)"
                @error="
                  record.thumbnailPictures[index] = record.pictures[index]
                "
                class="pe-img"
              />

              <vue-easy-lightbox
                :visible="isOpenModalSymptomPic[index]"
                :imgs="pic"
                @hide="handleHide"
              ></vue-easy-lightbox>
              <b-image
                :src="pic"
                style="top: -99999px; position: absolute;"
              ></b-image>
            </div>
          </div>
          <span v-else class="pd-value"> - </span>
        </b-field>
        <b-field label="Diagnosis" class="field">
          <div v-if="diagnosisTags.length">
            <b-taglist>
              <b-tag
                v-for="(d, index) in diagnosisTags"
                :key="index"
                type="is-secondary"
                class="custom-tag"
                :closable="false"
                rounded
              >
                {{ typeof d === 'string' ? d : d.term }}
              </b-tag>
            </b-taglist>
          </div>
          <span v-else class="pd-value"> - </span>
        </b-field>
        <b-field label="Recommendation" class="field">
          <span class="pd-value">
            {{
              record.summary && record.summary.recommendation
                ? record.summary.recommendation
                : '-'
            }}
          </span>
        </b-field>
        <b-field label="Drug and Skin cares" class="field">
          <div v-if="record.summary && record.summary.drugs">
            <b-table :data="record.summary.drugs" bordered mobile-cards>
              <b-table-column field="name" label="Drug Name" v-slot="props">
                {{ props.row.name }}
              </b-table-column>
              <b-table-column
                field="frequency"
                label="Frequency"
                v-slot="props"
              >
                {{ props.row.frequency.name }},
                {{ props.row.frequencyTime.name }}
              </b-table-column>
              <b-table-column
                field="instruction"
                label="Instruction"
                v-slot="props"
              >
                {{ props.row.instruction || '-' }}
              </b-table-column>
              <b-table-column field="duration" label="Duration" v-slot="props">
                {{ props.row.duration }} วัน
              </b-table-column>
              <b-table-column field="qty" label="Total Qty." v-slot="props">
                {{ props.row.qty }} {{ props.row.unit }}
              </b-table-column>
            </b-table>
          </div>
          <span v-else class="pd-value"> - </span>
        </b-field>
        <!-- <div class="field">
          <div class="label">
            Chief complaint
          </div>
          <div>

          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'RecordDetail',
  data() {
    return {
      record: null,
      isOpenModalSymptomPic: [false, false, false, false]
    }
  },
  async created() {
    this.record = await api.getRecordById(this.$route.params.id)
  },
  computed: {
    diagnosisTags() {
      if (this.record && this.record.summary) {
        return [
          ...this.record.summary.diagnoses,
          ...this.record.summary.otherDiagnoses
        ]
      }
      return []
    }
  },
  methods: {
    openPicModal(i) {
      this.$set(this.isOpenModalSymptomPic, i, true)
    },
    handleHide() {
      this.isOpenModalSymptomPic = [false, false, false, false]
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 70px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  box-shadow: 0 -1px 11px 0 rgba(0, 0, 0, 0.1);
  background-color: #cea488;
  position: relative;
  justify-content: space-around;

  img {
    position: absolute;
    left: 25px;
    cursor: pointer;
  }
}
.main-content {
  margin: 0 auto;
  max-width: 1500px;
  padding: 25px;
  color: #3e4a59;
}
.card {
  padding: 20px 25px;
  border-radius: 20px;
  background-color: #ffffff;
  margin-bottom: 20px;
}
.field {
  margin-bottom: 25px !important;
  .pd-value {
    padding-left: 5px;
  }
}
.card-line {
  color: #9b9b9b;
  margin-bottom: 5px;
  .key {
    font-weight: bold;
    color: #3e4a59;
  }
}
.pe-img {
  border-radius: 10%;
  width: 100px;
  cursor: zoom-in;
}
.custom-tag {
  max-width: 100%;
  white-space: break-spaces;
  height: auto;
  span {
    max-width: calc(100% - 16px);
  }
}
</style>
