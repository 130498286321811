<template>
  <div>
    <a
      class="message-containner"
      v-for="(file, index) in fileUrls"
      :key="index"
      :href="file.url"
      target="_blank"
    >
      <img src="@/assets/img/download-btn.png" class="icon" alt="" />
      <div class="file-detail">
        <span class="text-overflow">{{ file.name }}</span>
        <span class="text-overflow"
          >SIZE: {{ (file.size / (1024 * 1024)).toFixed(2) }} MB</span
        >
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: ['fileUrls'],
  data() {
    return {
      isImageModalActive: false,
      index: 0
    }
  },
  methods: {
    showImgLightBox(index) {
      this.isImageModalActive = true
      this.index = index
    },
    openNewTab(link) {
      window.open(link)
    }
  }
}
</script>

<style scoped>
.media {
  height: auto;
  cursor: pointer;
  width: 50%;
  padding: 5px;
  margin: 0px !important;
  border: 0px !important;
}
.icon {
  flex: 0;
  height: 45px;
  width: auto;
}
.file-detail {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-left: 20px;
  overflow: hidden;
}
.text-overflow {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}
.message-containner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 450px;
  cursor: pointer;
}
</style>
