export default {
  author: 'SkinX Team',
  title: 'LIENJANG COSMETICS ผลิตภัณฑ์ดูแลผิวจากเกาหลี',
  image: require('./cover-lienjang.jpg'),
  createdAt: new Date('2023-03-7'),
  content: `SkinX Store ชวนหมอผิวมาทำความรู้จักกับผลิตภัณฑ์จาเกาหลี กับแบรนด์ LIENJANG COSMETICS อัดแน่นไปด้วยส่วนผสมสุดเข้มข้น ช่วยลดฝ้าลึก จุดด่างดำสะสม รอยคล้ำใต้ตาแบบติดสปีด สามารถจัดการกับปัญหาผิวสะสมแก้ยากของสาว ๆ อย่างมีประสิทธิภาพ ด้วยรายละเอียดข้อมูลที่เจาะลึก จัดเต็มกับทุกความสามารถของผลิตภัณฑ์ ให้มีความเข้าใจมากยิ่งขึ้น เพื่อผลลัพธ์ที่ดีที่สุดในการให้คำปรึกษาและแนะนำผู้รับบริการได้รับการดูแลรักษาอย่างตรงจุด

  ศึกษารายละเอียดผลิตภัณฑ์เพิ่มเติมได้ที่ <http://shorturl.at/dhvHV>
  
  #SkinXapp #SkinXตัวจริงเรื่องผิว

  ![SkinX Doctor Review](${require('./cover-lienjang.jpg')})`
}
