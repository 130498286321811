<template>
  <div class="modal-containner">
    <div class="modal-card">
      <div class="modal-header">Choose time for Appointment</div>
      <div class="modal-chosen-date">
        {{ chosenDate | toDateFormat('DD MMMM YYYY') }}
      </div>
      <div class="divider"></div>
      <div class="modal-content">
        <div class="modal-time-slot-containner">
          <div
            :class="[
              'modal-time-slot',
              {
                deactive: item.busy,
                active: seletedItem && item && seletedItem.id === item.id,
              },
            ]"
            v-for="(item, index) in chosenDateItems"
            @click="onSlectItem(item)"
            :key="index"
          >
            {{ item.startDate | toDateFormat('HH:mm') }}
          </div>
        </div>
        <div class="divider"></div>
        <div class="modal-footer-containner">
          <div
            class="modal-footer-button modal-footer-button-outline"
            @click="onCancel"
          >
            Cancel
          </div>
          <div
            :class="[
              'modal-footer-button',
              {
                disabled: !seletedItem,
              },
            ]"
            @click="onConfirm"
          >
            Confirm
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['chosenDate', 'chosenDateItems', 'seletedItem'],
  data() {
    return {}
  },
  computed: {},
  methods: {
    onSlectItem(item) {
      if (item.busy) {
        return
      }
      this.$emit('onSlectItem', item)
    },
    onCancel() {
      this.$emit('onCancel', {})
    },
    onConfirm() {
      if (!this.seletedItem) {
        return
      }
      this.$emit('onConfirm', this.seletedItem)
    },
  },
}
</script>
<style>
.switch {
  margin-right: 0px !important;
}
.time-slot-switch .field .switch .control-label {
  padding: 0px !important;
}
@media (max-width: 768px) {
  .modal-content {
    margin: 0 !important;
  }
}
</style>

<style scoped>
.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.deactive {
  opacity: 0.5;
}
.modal-containner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-header {
  font-size: 20px;
  line-height: 31px;
  color: #3e4a59;
  text-align: center;
  font-weight: normal;
}
.modal-chosen-date {
  font-size: 18px;
  line-height: 26px;
  color: #3c4857;
  text-align: center;
  font-weight: normal;
  color: #e58f90;
}
.modal-card {
  width: 450px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}
.modal-content {
  width: 100%;
}
.divider {
  width: 100%;
  height: 0.5px;
  background-color: #ebebeb;
  margin: 15px 0;
}
.modal-time-slot-containner {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  max-height: 300px;
  overflow: scroll;
}
.modal-time-slot {
  width: 30%;
  height: 24px;
  margin: 6px 1%;
  background: #ffffff;
  border: 0.5px solid #d0d0d0;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  color: #3e4a59;
  cursor: pointer;
}
.active {
  background: #e58f90;
  color: #ffffff;
}
.modal-footer-containner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-footer-button {
  border: 0px;
  background: #34beb3;
  border-radius: 19px;
  width: 111px;
  height: 30px;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin: 0 4.5px;
  cursor: pointer;
}
.modal-footer-button-outline {
  background: #ffffff;
  border: 0.5px solid #d0d0d0;
  color: #3c4857;
}
@media (max-width: 767px) {
  .modal-card {
    border-radius: 0px;
  }
  .modal-time-slot-containner {
    max-height: calc(100vh - 400px);
  }
  .modal-content,
  .modal-card {
    margin: 0 !important;
  }
}
</style>
