<template>
  <div class="card">
    <div class="content">
      <div class="detail">
        <div class="waiting-name">{{ patientName }}</div>
        <div class="waiting-symptoms">
          <span style="color: #9b9b9b">อาการ</span>
          {{ symptoms }}
        </div>
        <div class="waiting-detail">
          <div class="waiting-type-tag">
            <div>{{ type === 'video' ? 'Video call' : 'Chat' }}</div>
          </div>
          <div v-if="!appointmentDate" class="waiting-walkin-tag">
            <div>Walk-in Case</div>
          </div>
          <div v-if="appointmentDate" class="waiting-appointment">
            <div class="waiting-appointment-tag">Appointment Case</div>
            <div class="appointment-datetime" style="color: #9b9b9b">
              {{ showAppointmentDate }}
            </div>
          </div>
          <div v-if="showWaitingTime" class="waiting-time">
            Waiting time {{ showWaitingTime }}
          </div>
        </div>
      </div>
      <b-button
        :disabled="!enabled"
        type="is-app-success"
        rounded
        @click="
          $router.push({
            name: 'MedicalReportJoinRoom',
            params: { id: recordId, ticketId: ticketId },
          })
        "
        >Join now</b-button
      >
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'WaitingQueueListItem',
  props: [
    'patientName',
    'appointmentDate',
    'symptoms',
    'waitingTime',
    'patientJoinAt',
    'enabled',
    'recordId',
    'ticketId',
    'type',
  ],
  data() {
    return {
      timer: null,
      showWaitingTime: null,
    }
  },
  created() {
    this.timer = setInterval(this.setShowWaitingTime, 1000)
  },
  beforeDestroy() {
    if (this.timer !== null) {
      clearInterval(this.timer)
    }
  },
  computed: {
    showAppointmentDate() {
      return moment(this.appointmentDate).format('DD/MM/YYYY HH:mmน.')
    },
  },
  methods: {
    setShowWaitingTime() {
      const diff = moment.duration(moment().diff(moment(this.patientJoinAt)))
      const hours = `${diff.hours()}`.padStart(2, '0')
      const minutes = `${diff.minutes()}`.padStart(2, '0')
      const seconds = `${diff.seconds()}`.padStart(2, '0')
      this.showWaitingTime = `${hours}:${minutes}:${seconds}`
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  background: white;
  border-radius: 10px;
  padding: 16px;
  margin-top: 16px;
}
.content {
  display: flex;
  align-items: center;
}
.detail {
  flex: 1;
}
.waiting-name {
  font-size: 16px;
  font-weight: 400;
}
.waiting-symptoms {
  font-size: 14px;
  font-weight: 200;
}
.waiting-detail {
  display: flex;
  align-items: baseline;
  font-size: 14px;
  font-weight: 200;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.waiting-appointment {
  display: flex;
  color: #9b9b9b;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 768px) {
    margin-right: 16px;
    align-items: baseline;
  }
}
.waiting-appointment-tag {
  display: flex;
  color: white;
  background: #e59f91;
  padding: 2px 16px;
  border-radius: 16px;
  margin-top: 8px;
  @media only screen and (min-width: 768px) {
    margin-right: 8px;
  }
}
.waiting-type-tag {
  display: flex;
  color: #34beb3;
  background: transparent;
  padding: 2px 16px;
  border: 1px solid #34beb3;
  border-radius: 20px;
  margin-top: 8px;
  @media only screen and (min-width: 768px) {
    margin-right: 8px;
  }
}
.waiting-walkin-tag {
  display: flex;
  color: white;
  background: #c4c4c4;
  padding: 2px 16px;
  border-radius: 16px;
  margin-top: 8px;
  @media only screen and (min-width: 768px) {
    margin-right: 8px;
  }
}
.appointment-datetime {
  @media only screen and (min-width: 768px) {
    margin-left: 8px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 8px;
  }
}
.waiting-time {
  margin-top: 8px;
  color: #ff434e;
}
</style>
