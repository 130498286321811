<template>
  <div class="howto-containner">
    <div class="header">
      <span style="margin-right: 12px">New Feature</span>
      <img src="@/assets/skinx-logo.png" alt="logo" />
    </div>
    <span class="subheader"
      >สร้างเวลานัดหมาย
      เพื่อให้ผู้รับบริการสามารถเลือกนัดหมายพบแพทย์ได้เองโคยอัติโนมัติ</span
    >
    <div class="divider" />
    <div>
      <iframe
        :width="width"
        height="315"
        src="https://www.youtube.com/embed/iLbyXbawONU"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      width: window.innerWidth - 32 >= 560 ? 560 : window.innerWidth - 32,
    }
  },
}
</script>
<style scoped>
.howto-containner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: #fff;
  width: fit-content;
  border-radius: 20px;
  margin: auto;
  font-weight: 300;
}
.divider {
  width: 150px;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 24px;
  background: #e7a191;
}
.subheader {
  font-style: italic;
  margin-top: 8px;
  color: #3e4a59;
}
.header {
  font-style: italic;
  color: #3e4a59;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.header img {
  width: 108px;
}
</style>
