<template>
  <div class='rp-container'>
    <div class='header'>
      <div>
        <img
          src='@/assets/svg/back-black.svg'
          @click='back'
        />
        {{ !isNaN(Number($route.params.id)) ? 'Edit' : 'Add' }} Drug Set
      </div>
    </div>
    <div class='white-box'>
      <div class='drug-set-header'>
        <div class='label mb-2'>
          Drug set name
        </div>
      </div>
      <b-input v-model='form.drugSetName' type='text' rounded />
    </div>
    <div
      class='white-box'
    >
      <div class='drug-set-header'>
        <div class='label mb-2'>
          Drug and Skin cares (Suggestion)
        </div>
      </div>
      <div class='search'>
        <div class='search-input'>
          <b-autocomplete
            ref='drugSearchInput'
            v-model='drugSearchInput'
            v-debounce:300ms='searchDrugs'
            :data='filteredDrugData'
            :loading='isDrugSearchInputLoading'
            rounded
            open-on-focus
            clear-on-select
            @select='addDrug'
          >
            <template slot-scope='props'>
              <div>
                {{ props.option.tradeName }}
                <div
                  class='drug-generic-name'
                  v-if='props.option.drugItemType !== 2'
                >
                  {{ props.option.name }}
                </div>
              </div>
            </template>
            <template slot='empty'> No results found</template>
          </b-autocomplete>
        </div>
        <div class='search-ic'>
          <img src='@/assets/svg/search-white.svg' />
        </div>
      </div>

      <div
        v-if='drugGroups.length'
        id='add-drug-set-selector'
        class='drug-group drug-group-selector'
      >
        <VueSlickCarousel v-bind='carouselSettings' ref='carouselDrugGroup'>
          <template #prevArrow>
            <img src="@/assets/svg/prev-arrow.svg" @click="showPrev('carouselDrugGroup')"/>
          </template>
          <template #nextArrow>
            <img src="@/assets/svg/next-arrow.svg" @click="showNext('carouselDrugGroup')"/>
          </template>
          <div
            v-for='(dg, i) in drugGroups'
            :key='i'
            :class="[
              'dg-tag',
              { 'dg-tag-active': drugGroupActiveId === dg.id },
            ]"
            @click='filterByDrugGroup(dg.id)'
          >
            {{ dg.name }}
          </div>
        </VueSlickCarousel>
      </div>

      <div class='table-box'>
        <b-field label='Drug set' />
        <div
          id='medical-report-drugs-table'
          class='table-content'
          ref='drugsCart'
        >
          <b-table
            :data='form.drugs'
            hoverable
            mobile-cards
          >
            <b-table-column v-slot='props' field='name' label='Drug Name'>
              <span class='drug-name'
                    @click="$router.push({ name: 'DrugDetail', params: { id: form.drugs[props.index].drugItemId }})">
                              {{ props.row.tradeName }}
              </span>
            </b-table-column>
            <b-table-column
              v-slot='props'
              field='frequencyId'
              label='Frequency *'
              width='160'
            >
              <div class='frequency-selector-box'>
                <div>
                  <b-select
                    v-model='form.drugs[props.index].usedPrefixId'
                  >
                    <option
                      v-for="prefix in [...drugPrefix, { id: null, name: '-' }]"
                      :value='prefix.id'
                      :key='prefix.id'
                    >
                      {{ prefix.name }}
                    </option>
                  </b-select>
                </div>
                <div
                  v-if="form.drugs[props.index].type === 'Oral'"
                  class='freq-unit-amount'
                >
                  <b-input
                    v-model='form.drugs[props.index].frequencyAmount'
                    type='number'
                    rounded
                  />
                  <div class='unit'>
                    <b-select
                      v-model='form.drugs[props.index].usedUnitId'
                    >
                      <option
                        v-for="unit in [...drugUnit, { id: null, name: '-' }]"
                        :value='unit.id'
                        :key='unit.id'
                      >
                        {{ unit.name }}
                      </option>
                    </b-select>
                  </div>
                </div>
                <div class='frequency-selector'>
                  <b-select
                    v-model='form.drugs[props.index].frequencyId'
                  >
                    <option v-for='f in frequencies' :value='f.id' :key='f.id'>
                      {{ f.name }}
                    </option>
                  </b-select>
                </div>

                <div class=''>
                  <b-select
                    v-model='form.drugs[props.index].frequencyTimeId'
                  >
                    <option
                      v-for='ft in frequenciesTimes'
                      :value='ft.id'
                      :key='ft.id'
                    >
                      {{ ft.name }}
                    </option>
                  </b-select>
                </div>
              </div>
            </b-table-column>
            <b-table-column
              v-slot='props'
              field='duration'
              label='Duration *'
              width='140px'
            >
              <b-dropdown
                aria-role='list'
                position='is-top-right'
              >
                <button class='button' slot='trigger' slot-scope='{ active }'>
                  <span v-if='form.drugs[props.index].duration || form.drugs[props.index].duration === 0'>
                    {{ form.drugs[props.index].duration === 0 ? 'Continue' : form.drugs[props.index].duration }}
                    <span v-if="form.drugs[props.index].duration !== 'Continue' && form.drugs[props.index].duration">
                      วัน
                    </span>
                  </span>
                  <span v-else> เลือกระยะเวลา </span>
                  <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                </button>
                <b-dropdown-item
                  custom
                  :focusable='false'
                  aria-role='listitem'
                  class='dropdown-duration-item'
                >
                  <b-input
                    v-model='form.drugs[props.index].duration'
                    type='number'
                    class='dropdown-duration-input'
                  />
                  <span> วัน </span>
                </b-dropdown-item>
                <b-dropdown-item
                  ria-role='listitem'
                  class='dropdown-duration-item'
                  @click="form.drugs[props.index].duration = 'Continue'"
                >
                  Continue
                </b-dropdown-item>
              </b-dropdown>
            </b-table-column>
            <b-table-column
              v-slot='props'
              field='total'
              label='Total Qty. *'
              width='120'
              centered
            >
              <b-input
                v-model='form.drugs[props.index].qty'
                type='number'
                rounded
              />
            </b-table-column>
            <b-table-column
              v-slot='props'
              field='note'
              label='Instruction'
              width='150'
              centered
            >
              <b-input
                v-model='form.drugs[props.index].note'
                type='text'
                rounded
              />
            </b-table-column>
            <b-table-column
              v-slot='props'
              field='close'
              width='60px'
              centered
            >
              <img
                src='@/assets/svg/remove-drug.svg'
                class='remove-drug-img'
                @click='removeDrug(props.index)'
              />
            </b-table-column>
            <template slot='empty'>
              <div>Drug basket is empty.</div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div style='display: flex; justify-content: center'>
      <b-button class='cancel-btn'
                @click='back'
      >
        ยกเลิก
      </b-button>
      <b-button :loading='isLoading' class='save-btn' @click='submit'>
        บันทึก
      </b-button>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import api from '@/utils/api'
import { mapActions, mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'DrugSet',
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      form: {
        drugSetName: '',
        drugs: []
      },
      carouselSettings: {
        dots: false,
        variableWidth: true,
        swipeToSlide: true,
        draggable: true,
        infinite: false
      },
      drugs: [],
      drugGroups: [],
      frequencies: [],
      frequenciesTimes: [],
      drugGroupActiveId: null,
      isDrugSearchInputLoading: false,
      drugUnit: [],
      drugPrefix: [],
      drugSearchInput: ''
    }
  },
  async created() {
    this.setLoading(true)
    let freqs = { frequencies: [], times: [] }
    try {
      ;[
        freqs,
        this.drugGroups,
        this.drugUnit,
        this.drugPrefix
      ] = await Promise.all([
        api.getFrequencies(),
        api.getDrugGroups(),
        api.getDrugUnit(),
        api.getDrugPrefix()
      ])
      this.frequencies = freqs.frequencies
      this.frequenciesTimes = freqs.times
      if (!isNaN(Number(this.$route.params.id))) {
        const drugSet = await api.getDrugSetById(this.$route.params.id)
        this.form.drugSetName = drugSet.name
        this.form.drugs = drugSet.drugSetItem.map(dsi => ({
          type: dsi.drugItem.drug.type,
          tradeName: dsi.drugItem.name,
          frequencyId: dsi.frequency ? dsi.frequency.id : null,
          usedPrefixId: dsi.usedPrefix ? dsi.usedPrefix.id : null,
          usedUnitId: dsi.usedUnit ? dsi.usedUnit.id : null,
          unit: dsi.unit ?? null,
          frequencyTimeId: dsi.frequencyTime ? dsi.frequencyTime.id : null,
          frequencyAmount: dsi.frequencyAmount,
          drugItemId: dsi.drugItemId,
          duration: dsi.duration,
          qty: dsi.qty,
          note: dsi.note
        }))
      }
    } catch (e) {
      console.error(e)
      this.setLoading(false)
      await Swal.fire(
        'Error',
        `Can not get data.<br/>Please contact admin for help.<br/>recordId: ${this.$route.params.id}`,
        'error'
      )
      this.$router.go(-1)
      return
    }
    await this.searchDrugs()
    this.setLoading(false)
  },
  computed: {
    ...mapState({
      user: 'user',
      isLoading: 'isLoading'
    }),
    filteredDrugData() {
      if (this.form.drugs.length) {
        return this.drugs.filter(d => {
          return !this.form.drugs.map(formDiag => formDiag.drugItemId).includes(d.drugItemId)
        })
      }
      return this.drugs
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'setLoading',
      getUser: 'getUser'
    }),
    showPrev(ref) {
      this.$refs[ref].prev()
    },
    showNext(ref) {
      this.$refs[ref].next()
    },
    async searchDrugs() {
      this.isDrugSearchInputLoading = true
      this.drugs = await api.getDrugs({
        q: this.drugSearchInput,
        groupId: this.drugGroupActiveId,
        hospitalId: this.user.doctor.hospital.id
      })
      this.isDrugSearchInputLoading = false
    },
    addDrug(option) {
      console.log('option', option)
      if (!option) return
      const drug = {
        type: option.type,
        tradeName: option.tradeName,
        frequencyId: option.frequency ? option.frequency.id : null,
        usedPrefixId: option.usedPrefix ? option.usedPrefix.id : null,
        usedUnitId: option.usedUnit ? option.usedUnit.id : null,
        unit: option.unit ?? null,
        frequencyTimeId: option.frequencyTime ? option.frequencyTime.id : null,
        frequencyAmount: option.type === 'Oral' ? 1 : null,
        drugItemId: option.drugItemId,
        duration: option.duration,
        qty: option.qty,
        note: option.note || option.instruction || null
      }
      this.form.drugs.push(drug)
      this.$buefy.toast.open({
        message: 'Drug added to cart',
        type: 'is-success'
      })
    },
    removeDrug(index) {
      this.form.drugs.splice(index, 1)
    },
    filterByDrugGroup(drugGroupId) {
      if (this.drugGroupActiveId === drugGroupId) {
        this.drugGroupActiveId = null
        this.searchDrugs()
      } else {
        this.drugGroupActiveId = drugGroupId
        this.searchDrugs()
        this.$refs.drugSearchInput.focus()
      }
    },
    async submit() {
      const payload = {
        name: this.form.drugSetName,
        drugSetItem: this.form.drugs.map(i => {
          return {
            ...i,
            duration: i.duration === 'Continue' ? 0 : i.duration
          }
        })
      }
      try {
        this.setLoading(true)
        if (!isNaN(Number(this.$route.params.id))) {
          await api.updateDrugSetById(this.$route.params.id, payload)
        } else {
          await api.createDrugSet(payload)
        }
        this.$buefy.toast.open({
          message: 'Create successfully',
          type: 'is-success'
        })
      } catch (e) {
        let message = e.message
        if (Array.isArray(e.response?.data.message)) {
          message = e.response?.data.message.join('<br>')
        } else if (e.response?.data.message) {
          message = e.response?.data.message
        }
        this.$buefy.toast.open({
          type: 'is-danger',
          message: message,
          duration: 5000,
          pauseOnHover: true
        })
      } finally {
        this.setLoading(false)
      }
    },
    back() {
      if (this.$route.params.previousPage) {
        this.$router.push(this.$route.params.previousPage)
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.rp-container {
  padding: 25px;
  max-width: 1500px;
  margin: 0 auto;

  .header {
    color: #3e4a59;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;

    & > :first-child {
      flex-grow: 1;
    }

    & > :nth-last-child(n + 2) {
      margin-right: 12px;
    }

    img {
      line-height: 30px;
      vertical-align: middle;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

@media (max-width: 769px) {
  .rp-container {
    padding: 25px 15px;
  }
  .header {
    font-size: 15px;

    img {
      max-width: 24px !important;
    }
  }
}

.white-box {
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 20px 30px 30px;
  margin-bottom: 30px;

  .symptom-img {
    border-radius: 10%;
    cursor: zoom-in;
  }
}

.search {
  display: flex;

  .search-input {
    display: inline-block;
    width: calc(100% - 40px - 10px);
  }

  .search-ic {
    width: 40px;
    height: 40px;
    background-color: #d0a488;
    display: inline-block;
    border-radius: 50%;
    margin-left: 10px;
    text-align: center;
    padding: 10px;
  }
}

.table-box {
  margin-top: 40px;

  .table-content {
    padding: 0px;
    border-radius: 20px;
    background: white;

    .remove-drug-img {
      margin: 3px auto 0;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    .frequency-selector-box {
      height: fit-content;
      display: flex;
      justify-content: flex-end;

      .frequency-selector {
        width: auto;
      }

      .frequency-selector:first-child {
        margin-right: 10px;
      }
    }
  }
}

.follow-up-box {
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    line-height: 40px;
    vertical-align: middle;
  }

  .fl-input {
    width: fit-content;
    margin-left: 15px;
  }
}

.drug-group {
  width: 94%;
  margin: 25px auto 0;

  .dg-tag {
    border-radius: 22px;
    border: solid 1px #979797;
    color: #979797;
    padding: 5px 20px;
    outline: none;
    cursor: pointer;
  }

  .dg-tag-active {
    border: solid 1px #d8a283;
    color: #fff;
    background-color: #d8a283;
  }
}

.drug-set-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
@media only screen and (max-width: 768px) {
  .drug-set-header {
    flex-direction: column;
  }
}

.btn-add-drug-set {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 22px;
  border: none;
  color: #ffffff;
  background: #D0A488;
  padding: 5px 20px;
  outline: none;
  cursor: pointer;
  height: 36px;
}

.btn-edit-drug-set {
  display: inline-flex;
  align-items: center;
  border-radius: 22px;
  border: solid 1px #979797;
  color: #979797;
  background: #ffffff;
  padding: 5px 20px;
  outline: none;
  cursor: pointer;
  height: 36px;
}

.drug-group {
  width: 94%;
  margin: 25px auto 0;

  .dg-tag {
    border-radius: 22px;
    border: solid 1px #979797;
    color: #979797;
    padding: 5px 20px;
    outline: none;
    cursor: pointer;
  }

  .dg-tag-active {
    border: solid 1px #d8a283;
    color: #fff;
    background-color: #d8a283;
  }
}

.save-btn {
  border: none;
  border-radius: 23.5px;
  color: #ffffff;
  background: #34BEB3;
  display: inline-block;
  padding: 5px 25px;
  text-align: center;
  font-weight: lighter;
  cursor: pointer;
  width: 200px;
}

.cancel-btn {
  border: 1px solid #979797;
  border-radius: 23.5px;
  color: #979797;
  background: transparent;
  display: inline-block;
  padding: 5px 25px;
  text-align: center;
  font-weight: lighter;
  cursor: pointer;
  margin-right: 10px;
  width: 200px;
}

.drug-name {
  cursor: pointer;

  &:hover {
    color: #d8a283;
    text-decoration: underline;
  }
}
</style>
