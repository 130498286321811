export default {
  author: 'SkinX Team',
  title: 'มั่นใจ รอยสิวจางใน ใน 2 สัปดาห์ ‼️',
  image: require('./x-roy-2.png'),
  createdAt: new Date('2023-03-01'),
  content: `ใช้ดีเห็นผลไวไม่เกินใจแน่นอน!

  ช่วยรักษารอยแผลเป็นจากสิว
  
  กระตุ้นการสร้างคอลลาเจน ทำให้แผลหลุมสิวดูตื้นขึ้นได้
  
  เห็นผลภายใน 2 สัปดาห์
  
  ไม่ทิ้งรอยแผลเป็น
  
  ทุกส่วนผสมคัดสรรมาอย่างดี ปราศจากสารเคมีที่ก่อให้เกิดการระคายเคือง
  
  Dragon’s Blood - สารสกัดจากต้นเลือดมังกร ช่วยสมานแผลให้หายเร็ว ช่วยยับยั้งเชื้อแบคทีเรียได้
  
  Allium cepa - สารสกัดจากหัวหอม ช่วยลดรอยแผลเป็นและจุดด่างดำ
  
  Aloe vera Extract - ทำให้ผิวชุ่มชื้น ลดการอักเสบหลังเกิดสิว ลดรอยดำและรอยแดง
  
  Centella asiatica - ยับยั้งกระบวนการเกิดแผลเป็นนูน ลดรอยหมองคล้ำ รอยด่างดำ
  
  Allantoin ลดอาการแพ้คันบาดแผล และเสริมสร้างเนื้อเยื่อขึ้นมาใหม่
  
  Cocos nucifera Oil - ลดอาการผิวแห้ง ผิวแตก ผิวลอก ผิวเป็นขุย
  
   
  
  #SkinXapp #SkinXตัวจริงเรื่องผิว #xroy


  ![x-roy](${require('./x-roy-2.png')})`
}
