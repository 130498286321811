<template>
  <div class="db-container">
    <div class="is-title">
      <span> Appointment Calendar</span>
      <div class="d-flex">
        <div style="margin-bottom: 5px; margin-right: 5px">
          On/Off Appointment
        </div>
        <b-field>
          <b-switch
            type="is-success"
            v-model="doctor.canReserve"
            :true-value="true"
            :false-value="false"
            @input="updateDoctor"
          >
          </b-switch>
        </b-field>
      </div>
    </div>
    <div class="divider" />

    <div class="columns is-desktop">
      <div class="column is-12">
        <div class="column is-12">
          <div style="margin-bottom: 5px">Select your slot time</div>
          <div class="value">
            <b-radio
              name="minPerSlot"
              :native-value="20"
              :disabled="
                !isEdit || Object.keys(haveFutureAppointment).length > 0
              "
              style="margin-right: 30px"
              @input="onChangeInput(20)"
              v-model="doctor.minPerSlot"
            >
              3 Slots per hour
            </b-radio>
            <b-radio
              name="minPerSlot"
              :native-value="30"
              :disabled="Object.keys(haveFutureAppointment).length > 0"
              @input="onChangeInput(30)"
              v-model="doctor.minPerSlot"
            >
              2 Slots per hour
            </b-radio>
          </div>
        </div>
        <div class="column">
          <DoctorCalendar
            :timeSlot="avaliableSlot"
            :skipSlot="skipSlot"
            :isActiveCalendar="doctor.canReserve"
            :canSwitchActive="false"
            :holiday="holidays"
            @onItemChange="onItemChange"
            @onChangeMonth="fetchNewItem"
            @setDisableCalendar="onCloseAppointment"
            @onClickDate="
              (chosenDate, chosenDateItems) =>
                onItemChange(chosenDate, chosenDateItems, true)
            "
            @onClickDateItem="
              (chosenDate, chosenDateItems) =>
                onItemChange(chosenDate, chosenDateItems, true)
            "
          />
        </div>
      </div>
    </div>
    <b-modal
      v-model="isShowModal"
      :width="640"
      scroll="keep"
      :can-cancel="['escape', 'outside']"
    >
      <AddTimeSlot
        :chosenDate="chosenDate"
        :chosenDateItems="chosenDateItems"
        :minPerSlot="doctor.minPerSlot"
        @onAddTime="onAddTime"
        @onDeleteTime="onDeleteTime"
      />
    </b-modal>
    <div style="display: flex; justify-content: center">
      <b-button class="cancel-btn" @click="handleCancelEdit"> Cancel </b-button>
      <b-button :loading="isLoading" class="save-btn" @click="submit">
        Confirm
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '@/utils/api'
import { getDayNameOfWeek, getDayOfWeek } from '@/utils/utils'
import DoctorCalendar from './DoctorCalendar'
import moment from 'moment'
import Swal from 'sweetalert2'
import AddTimeSlot from './AddTimeSlot'

const mobileMatcher = window.matchMedia('(max-width: 768px)')

export default {
  name: 'DashboardScheduler',
  components: {
    DoctorCalendar,
    AddTimeSlot
    // CalendarView,
    // CalendarViewHeader,
  },
  data() {
    return {
      minPerSlot: 30,
      canReserve: true,
      haveFutureAppointment: {},
      haveFutureAppointment2: {},
      selectedSlot: [],
      isLoading: false,
      chosenDate: '31 August 2018',
      chosenDateItems: [],
      isShowModal: false,
      doctor: {},
      isEdit: true,
      currentStartDate: moment(),
      currentEndDate: moment(),
      holidays: [],
      fetchedHolidays: []
    }
  },
  computed: {
    ...mapState({
      user: 'user'
    }),
    skipSlot() {
      return this.selectedSlot.filter(slot => slot.type === 'skip_recur')
    },
    avaliableSlot() {
      return this.selectedSlot.filter(slot => slot.type === 'available')
    }
  },
  async created() {
    this.doctor = await api.getDoctorById(this.user.doctor.id)
    mobileMatcher.addEventListener('change', this.onMobile)
    this.getAppointments()
  },
  unmounted() {
    mobileMatcher.removeEventListener('change', this.onMobile)
  },
  methods: {
    ...mapActions({
      getUser: 'getUser'
    }),

    onItemChange(chosenDate, chosenDateItems, flag) {
      if (moment(chosenDate, 'DD MMMM YYYYY').isBefore(moment(), 'd')) {
        return
      }
      this.chosenDate = chosenDate
      console.log(this.chosenDateItems)
      this.chosenDateItems = chosenDateItems.map(current => {
        const temp = moment(current.startDate, 'yyyy-MM-DD HH:mm:SS').format(
          'x'
        )
        const temp2 = moment(current.startDate, 'yyyy-MM-DD HH:mm:SS').format(
          'ddddHHmm'
        )
        current.canDeleteAll = true
        current.canDeleteOne = true
        if (
          (this.haveFutureAppointment[temp] && !current.recurring) ||
          (this.haveFutureAppointment2[temp2] && current.recurring)
        ) {
          current.canDeleteAll = false
          current.canDeleteOne = false
        }
        if (
          this.haveFutureAppointment2[temp2] &&
          current.recurring &&
          !moment(this.haveFutureAppointment2[temp2].appointmentDate).isSame(
            moment(current.startDate, 'yyyy-MM-DD HH:mm:SS'),
            'd'
          )
        ) {
          current.canDeleteOne = true
        }
        return current
      })
      if (flag === true) {
        this.isShowModal = true
      }
    },
    onChangeInput() {
      Swal.fire({
        title: 'Create Appointment Time',
        html: `Select number of slots per hour. <br/><br/>

2 Slots per Hours (10:00 and 10:30) or <br/> 3 Slots per Hours (10:00, 10:20, and 10:40)<br/>

If the number of slots per hour has changed, <br/> all time slots will be deleted.
`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$set(this.doctor, 'canReserve', true)
          this.updateDoctor()
          this.selectedSlot.forEach((slot, index) => {
            this.$set(this.selectedSlot[index], 'deleted', true)
            this.$set(this.selectedSlot[index], 'dirty', true)
          })
        }
      })
    },
    onCloseAppointment(flag) {
      this.doctor.canReserve = flag
    },
    async fetchNewItem(startDate, endDate) {
      this.currentStartDate = startDate
      this.currentEndDate = endDate
      const { data } = await api.getAvailableAll(startDate, endDate)
      if (!this.fetchedHolidays.includes(moment(startDate).year())) {
        this.holidays = this.holidays.concat((await api.getHolidays(moment(startDate).year())).data)
        this.fetchedHolidays.push(moment(startDate).year())
      }
      if (!this.fetchedHolidays.includes(moment(endDate).year())) {
        this.holidays = this.holidays.concat((await api.getHolidays(moment(endDate).year())).data)
        this.fetchedHolidays.push(moment(endDate).year())
      }
      if (this.selectedSlot.length === 0) {
        this.selectedSlot = data
      } else {
        const toAddItem = data.filter(
          d => !this.selectedSlot.find(slot => slot.id === d.id)
        )
        this.selectedSlot.push(...toAddItem)
      }
    },
    async getAppointments() {
      const data = await api.getAppointments(1, 10000)
      this.holidays = (await api.getHolidays(moment().year())).data
      this.fetchedHolidays.push(moment().year())
      this.haveFutureAppointment = data.reduce((accum, current) => {
        if (current.status === 0 || current.status === 5) {
          accum[
            moment(current.appointmentDate)
              ?.format('x')
              .toString()
          ] = current
        }
        return accum
      }, {})
      this.haveFutureAppointment2 = data.reduce((accum, current) => {
        if (current.status === 0 || current.status === 5) {
          accum[
            moment(current.appointmentDate)
              ?.format('ddddHHmm')
              .toString()
          ] = current
        }
        return accum
      }, {})
    },
    onAddSkip(startDate, endDate, skipId) {
      this.selectedSlot.push({
        title: 'doctor booking avaliable slot',
        type: 'skip_recur',
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        recurSkipId: skipId,
        dirty: true
      })
    },
    findRecureIndex(slotToDelete) {
      const startTimeToDelete =
        moment(slotToDelete.startDate, 'yyyy-MM-DD HH:mm:SS')
          .utc()
          .format('x') -
        moment(slotToDelete.startDate, 'yyyy-MM-DD HH:mm:SS')
          .utc()
          .startOf('day')
          .format('x')

      const _endDateTmp1 = moment(slotToDelete.endDate, 'yyyy-MM-DD HH:mm:SS')
        .utc()
        .format('x')
      let _endDateTmp2 = moment(slotToDelete.endDate, 'yyyy-MM-DD HH:mm:SS')
        .utc()
        .startOf('day')
        .format('x')
      if (
        moment(slotToDelete.endDate, 'yyyy-MM-DD HH:mm:SS')
          .utc()
          .format('HH:mm:ss') === '00:00:00'
      ) {
        _endDateTmp2 = moment(slotToDelete.endDate, 'yyyy-MM-DD HH:mm:SS')
          .utc()
          .startOf('day')
          .subtract(1, 'days')
          .format('x')
      }
      const endTimeToDelete = _endDateTmp1 - _endDateTmp2
      const dayOfWeekToDelete = moment(
        slotToDelete.startDate,
        'yyyy-MM-DD HH:mm:SS'
      )
        .utc()
        .get('day')
      return this.selectedSlot.findIndex(
        slot =>
          slot.recurData &&
          getDayOfWeek(slot.recurData.dayOfWeeks[0]) === dayOfWeekToDelete &&
          slot.recurData.startTime === startTimeToDelete &&
          slot.recurData.endTime === endTimeToDelete &&
          !slot.deleted
      )
    },
    onDeleteTime(slotToDelete, deleteAll) {
      console.log('onDeleteTime')
      console.log('slotToDelete', slotToDelete)
      console.log('deleteAll', deleteAll)
      if (deleteAll) {
        const indexToDelete = this.findRecureIndex(slotToDelete)
        this.$set(this.selectedSlot[indexToDelete], 'deleted', true)
        this.$set(this.selectedSlot[indexToDelete], 'dirty', true)
      } else {
        if (slotToDelete.recurring) {
          const indexToDelete = this.findRecureIndex(slotToDelete)
          const startTimeToDelete = moment(
            slotToDelete.startDate,
            'yyyy-MM-DD HH:mm:SS'
          )
          const endTimeToDelete = moment(
            slotToDelete.endDate,
            'yyyy-MM-DD HH:mm:SS'
          )
          if (this.selectedSlot[indexToDelete].id) {
            this.onAddSkip(
              startTimeToDelete,
              endTimeToDelete,
              this.selectedSlot[indexToDelete].id
            )
          } else {
            this.selectedSlot.splice(indexToDelete, 1)
          }
        } else {
          const startTimeToDelete = moment(
            slotToDelete.startDate,
            'yyyy-MM-DD HH:mm:SS'
          )
          const endTimeToDelete = moment(
            slotToDelete.endDate,
            'yyyy-MM-DD HH:mm:SS'
          )
          const indexToDelete = this.selectedSlot.findIndex(slot => {
            return (
              startTimeToDelete.isSame(moment(slot.startDate)) &&
              endTimeToDelete.isSame(moment(slot.endDate)) &&
              !slot.deleted
            )
          })
          if (this.selectedSlot[indexToDelete].id) {
            this.$set(this.selectedSlot[indexToDelete], 'dirty', true)
            this.$set(this.selectedSlot[indexToDelete], 'deleted', true)
          } else {
            this.selectedSlot.splice(indexToDelete, 1)
          }
        }
      }
    },
    onAddTime(startDate, endDate, isRepeat) {
      let isContainTime = false
      let containText = ''
      const skipIds = this.selectedSlot
        .filter(s => s.type === 'skip_recur')
        .map(s => s.recurSkipId)
      const _selectedSlot = this.selectedSlot.filter(s => {
        return (
          ((!!s.recurData &&
            s.recurData.dayOfWeeks.includes(
              moment(startDate)
                .format('dddd')
                .toLowerCase()
            ) &&
            (isRepeat ||
              (moment(s.startDate).isBefore(moment(startDate)) &&
                moment(s.endDate).isAfter(moment(startDate))))) ||
            moment(startDate).format('YYYY-MM-DD') ===
              moment(s.startDate).format('YYYY-MM-DD')) &&
          !skipIds.includes(s.id) &&
          s.type === 'available'
        )
      })
      const startCompare = moment(
        '1970-01-01 ' + moment(startDate).format('HH:mm:ss')
      )
      const endCompare = moment(
        '1970-01-01 ' + moment(endDate).format('HH:mm:ss')
      )
      for (const slot of _selectedSlot) {
        let slotStartDate = moment(slot.startDate)
        let slotEndDate = moment(slot.endDate)
        if (slot.recurData) {
          slotStartDate = moment(slot.startDate).set({
            millisecond: slot.recurData.startTime
          })
          slotEndDate = moment(slot.startDate).set({
            millisecond: slot.recurData.endTime
          })
        }
        slotStartDate = moment('1970-01-01 ' + slotStartDate.format('HH:mm:ss'))
        slotEndDate = moment('1970-01-01 ' + slotEndDate.format('HH:mm:ss'))
        const isSlotStartBetweenStartDate = slotStartDate.isBetween(
          startCompare,
          endCompare
        )
        const isSlotEndBetweenEndDate = slotEndDate.isBetween(
          startCompare,
          endCompare
        )
        const isStartDateBetweenSlotStart = startCompare.isBetween(
          slotStartDate,
          slotEndDate
        )
        const isEndDateBetweenSlotEnd = endCompare.isBetween(
          slotStartDate,
          slotEndDate
        )
        const isDuplicate =
          startCompare.isSame(slotStartDate) || endCompare.isSame(slotEndDate)
        isContainTime =
          isDuplicate ||
          isSlotStartBetweenStartDate ||
          isSlotEndBetweenEndDate ||
          isStartDateBetweenSlotStart ||
          isEndDateBetweenSlotEnd
        if (isContainTime) {
          containText =
            slotStartDate.format('HH:mm') + ' - ' + slotEndDate.format('HH:mm')
          break
        }
      }
      if (isContainTime) {
        Swal.fire({
          html:
            '<div class="noti-message">Duplicate time slot<br>' +
            containText +
            '</div>',
          width: '24rem',
          icon: 'error'
        })
        return
      }
      let safty = 100
      startDate = moment.utc(startDate)
      endDate = moment.utc(endDate)
      while (startDate.isBefore(endDate) && safty > 0) {
        safty--
        // const st = moment(startDate)

        // const et = moment(startDate).add(this.doctor.minPerSlot, 'minutes')

        if (isRepeat) {
          // const index = this.selectedSlot.findIndex((slot) => {
          //   return (
          //     moment(slot.startDate).isSame(startDate, 'm') && !slot.deleted
          //   )
          // })
          // const index2 = this.findRecureIndex({ startDate: st, endDate: et })
          // if (~index) {
          //   if (this.selectedSlot[index]?.type === 'skip_recur') {
          //     this.$set(this.selectedSlot[index], 'deleted', true)
          //     this.$set(this.selectedSlot[index], 'dirty', true)
          //   }
          //   startDate.add(this.doctor.minPerSlot, 'minutes').toDate()
          //   continue
          // }
          // if (
          //   ~index2 &&
          //   startDate.isSame(this.selectedSlot[index2].startDate, 'd')
          // ) {
          //   startDate.add(this.doctor.minPerSlot, 'minutes').toDate()
          //   continue
          // }
          const dayOfWeek = getDayNameOfWeek(startDate.get('day'))
          this.selectedSlot.push({
            title: 'doctor booking avaliable slot',
            type: 'available',
            recurType: 'weekly',
            recurInterval: 1,
            startDate: moment(startDate).startOf('d'),
            recurData: {
              dayOfWeeks: [dayOfWeek],
              startTime: startDate.diff(moment(startDate).startOf('day')),
              endTime: moment(startDate)
                .add(this.doctor.minPerSlot, 'minutes')
                .diff(moment(startDate).startOf('day'))
            },
            dirty: true
          })
          startDate.add(this.doctor.minPerSlot, 'minutes')
        } else {
          // const index = this.selectedSlot.findIndex((slot) => {
          //   return (
          //     moment(slot.startDate).isSame(startDate, 'm') && !slot.deleted
          //   )
          // })
          // const index2 = this.findRecureIndex({ startDate: st, endDate: et })
          // if (~index) {
          //   if (this.selectedSlot[index]?.type === 'skip_recur') {
          //     this.$set(this.selectedSlot[index], 'deleted', true)
          //     this.$set(this.selectedSlot[index], 'dirty', true)
          //   }
          //   startDate.add(this.doctor.minPerSlot, 'minutes').toDate()
          //   continue
          // }
          // if (
          //   ~index2 &&
          //   startDate.isSame(this.selectedSlot[index2].startDate, 'd')
          // ) {
          //   startDate.add(this.doctor.minPerSlot, 'minutes').toDate()
          //   continue
          // }
          this.selectedSlot.push({
            title: 'doctor booking avaliable slot',
            type: 'available',
            startDate: startDate.toDate(),
            endDate: startDate.add(this.doctor.minPerSlot, 'minutes').toDate(),
            dirty: true
          })
        }
      }
      this.isShowModal = false
    },
    onMobile(m) {
      if (m.matches) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    onEdit() {
      this.isEdit = true
    },
    async submit() {
      this.isLoading = true
      try {
        await api.postScheduler(this.selectedSlot.filter(slot => slot.dirty))
        await Swal.fire({ title: 'success', icon: 'success' })
      } catch (e) {
        console.log(e.response.data.message)
        await Swal.fire('fail', e.response.data.message, 'error')
      } finally {
        this.selectedSlot = []
        this.fetchNewItem(this.currentStartDate, this.currentEndDate)
        this.isLoading = false
      }
    },
    async updateDoctor() {
      this.isLoading = true
      try {
        await api.updateDoctorReserveStatus({
          minPerSlot: this.doctor.minPerSlot,
          canReserve: this.doctor.canReserve
        })
        ;[this.doctor] = await Promise.all([
          api.getDoctorById(this.user.doctor.id),
          this.getUser()
        ])
      } catch (e) {
        console.log(e.response.data.message)
        await Swal.fire('fail', e.response.data.message, 'error')
      } finally {
        this.isLoading = false
      }
    },
    handleCancelEdit() {
      this.isEdit = false
      this.selectedSlot = []
      this.fetchNewItem(this.currentStartDate, this.currentEndDate)
    }
  }
}
</script>
<style lang="scss" scoped>
.db-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
}
.divider {
  width: 100%;
  height: 2px;
  background-color: #dbd6d3;
  margin: 15px 0;
}
.is-title {
  color: #3e4a59;
  font-size: 20px;
  display: flex;
  justify-content: space-between;

  .edit-btn {
    border-radius: 15px;
    border: 1px solid #34beb3;
    color: #34beb3;
    width: fit-content;
    display: inline-block;
    padding: 5px 25px;
    text-align: center;
    font-size: 13px;
    cursor: pointer;
  }
}
.save-btn {
  border-radius: 15px;
  background-color: #34beb3;
  color: #fff;
  width: 120px;
  display: inline-block;
  padding: 5px 25px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
}
.cancel-btn {
  border-radius: 15px;
  background-color: #fff;
  color: #4a4a4a;
  width: 120px;
  display: inline-block;
  padding: 5px 25px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  margin-right: 10px;
}

.logout-btn {
  margin: 20px auto 0;
  display: block;
  width: 200px;
  height: 40px;
}
.d-flex {
  display: flex;
}

/* Less than mobile */
@media only screen and (max-width: 769px) {
  .db-container {
    padding: 0px;
    width: 100%;
  }
}
</style>
