export default {
  author: 'SkinX Team',
  title: 'Congrats on your success with Doctor of the month',
  image: require('./doctor-of-the-month.png'),
  createdAt: new Date('2023-03-7'),
  content: `SkinX Team ขอแสดงความยินดีกับคุณทั้ง 3 ท่าน

  แพทย์หญิงพรรณวดี ทองเจริญศิริกุล
  
  แพทย์หญิงชวพร สุดโนรีกุล
  
  แพทย์หญิงรัตติยา เตชะขรเกียรติ
  
  จากกิจกรรม Doctor Rewards ประจำเดือนกุมภาพันธ์ที่ผ่าน คุณหมอสามารถทำ Doctor Performance Score ได้เพิ่มขึ้นตามเงื่อนไขของทาง SkinX โดยที่สามารถรับให้คำปรึกษากับผู้รับบริการได้จำนวนเพิ่มขึ้น
  
  ขอแสดงความยินดีและขอส่งต่อความรู้สึกดี ๆ เพื่อเป็นกำลังใจ แทนคำขอบคุณให้กับคุณหมอที่เข้ามาเป็นส่วนหนึ่งกับ SkinX และการเติบโตครั้งนี้ได้รับการร่วมมือจากท่านเป็นอย่างดี ซึ่งทุกคำปรึกษาและคำแนะนของท่านล้วนเป็นประโยชน์กับเราอย่างมาก
  
   
  
  SkinX Team สัญญาว่าจะดูแลท่านเป็นอย่างดี ให้สมกับที่ท่านเข้ามาเป็นกำลังสำคัญให้กับเรา
  
  #แอปฯหมอผิว #SkinXThailand #ดูแลผิวดุแลคุณอย่างเข้าใจ
  

  ![doctor-of-the-month](${require('./doctor-of-the-month.png')})`
}
