export default {
  author: 'SkinX Team',
  title: '25th World Congress of Dermatology 2023 at Singapore',
  image: require('./image.jpg'),
  createdAt: new Date('2023-07-05'),
  content: `งานประชุมสากลทางด้านโรคผิวหนังที่จัดขึ้นเป็นครั้งที่ 25 โดยมีการจัดขึ้นทุก ๆ 4ปี (ซึ่งคุณหมอหลาย ๆ ท่านตั้งชื่อให้ว่าเป็นงานโอลิมปิกของหมอผิว)

  ความน่าสนใจภายในงานประกอบด้วยการแสดงบูธนวัตกรรมจากบริษัททางด้านเทคโนโลยีและผลิตภัณฑ์สำหรับดูแลผิวหนัง, การนำเสนอผลงานวิจัยทางการแพทย์, การอบรมและสัมมนาเชิงวิชาการจากผู้เชี่ยวชาญด้านโรคผิวหนัง และกิจกรรมต่าง ๆ เพื่อสร้างพื้นที่ให้ผู้เข้าร่วมงานและผู้เชี่ยวชาญที่เกี่ยวข้องสามารถแลกเปลี่ยนประสบการณ์และความรู้
  
  ทั้งนี้ในส่วนของประเทศไทย ดร.นพ.สาโรช สุวรรณสุทธิ หรือคุณหมอพลัง แพทย์เฉพาะทางด้านผิวหนังและ Medical Director ของ SkinX ได้รับเชิญให้เป็นตัวแทนในการขึ้นบรรยายในหัวข้อ "Teledermatology, when is not better?"
  
  
  ![25th World Congress of Dermatology 2023 at Singapore](${require('./image.jpg')})
  
  
  ซึ่งหัวข้อบรรยายเกี่ยวกับการเติบโตของวงการแอปปรึกษาหมอผิวในประเทศไทยนับตั้งแต่สถานการณ์ Covid 19 ถึงปัจจุบัน และทิศทางการพัฒนาต่อยอดในอนาคต นับเป็นเกียรติแก่วงการแพทย์ผิวหนังของไทย`
}
