<template>
  <div class="r-container">
    <div class="header">
      <div>
        <img src="@/assets/svg/back-black.svg" @click="handleBackButton()" />
        Add Recommendation
      </div>
      <img
        v-if="this.$route.params.recommendationId"
        src="@/assets/svg/remove.svg"
        style="margin-right: 0px;"
        @click="showDeleteDialog()"
      />
    </div>
    <ValidationObserver ref="formObserver">
      <div class="white-box">
        <b-field label="Title Recommendation" class="mg-t-25px">
          <ValidationProvider
            name="Title Recommendation"
            rules="required"
            v-slot="{ errors }"
          >
            <b-input
              id="medical-report-rounded-text-area"
              maxlength="50"
              :has-counter="false"
              v-model="form.title"
            />
            <span class="error-msg">{{ errors[0] }}</span>
          </ValidationProvider>
        </b-field>
      </div>
      <div class="white-box">
        <b-field label="Recommendation" class="mg-t-25px">
          <ValidationProvider
            name="Recommendation"
            rules="required"
            v-slot="{ errors }"
          >
            <b-input
              id="medical-report-rounded-text-area"
              type="textarea"
              v-model="form.detail"
            />
            <span class="error-msg">{{ errors[0] }}</span>
          </ValidationProvider>
        </b-field>
      </div>
      <div class="buttons" ref="btnGroup">
        <div class="cancel-btn" @click="handleBackButton()">
          ยกเลิก
        </div>
        <b-button class="confirm-btn" @click="submit">
          ยืนยัน
        </b-button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import api from '@/utils/api'
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  data() {
    return {
      form: {
        title: '',
        detail: ''
      }
    }
  },
  async created() {
    if (this.$route.params.recommendationId) {
      this.setLoading(true)
      this.form = await api.getSummaryRecommendation(
        this.$route.params.recommendationId
      )
      this.setLoading(false)
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'setLoading'
    }),
    handleBackButton() {
      if (this.$route.params.previousPage) {
        this.$router.push(this.$route.params.previousPage)
      } else {
        this.$router.go(-1)
      }
    },

    record() {},
    cancle() {},
    async showDeleteDialog() {
      Swal.fire({
        html:
          '<div class="noti-message">' +
          `ต้องการลบรายการ
Recommendation
<br/>ใช่ หรือ ไม่` +
          '</div>',
        icon: 'warning',
        showCancelButton: true,
        width: '26rem',
        padding: '12px',
        confirmButtonColor: '#34BEB3',
        confirmButtonText: 'ลบ',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deleteRecommendation()
        }
      })
    },

    async submit() {
      this.$refs.formObserver.validate().then(async valid => {
        if (valid) {
          if (this.$route.params.recommendationId) {
            const result = await api.updateSummaryRecommendation({
              id: parseInt(this.$route.params.recommendationId),
              title: this.form.title,
              detail: this.form.detail
            })
            this.showDialog(
              result?.success,
              'Success',
              'อัปเดต Recommendation สำเร็จ'
            )
          } else {
            const result = await api.postSummaryRecommendation({
              doctorId: parseInt(this.user.doctor.id),
              title: this.form.title,
              detail: this.form.detail
            })
            console.log(result)
            this.showDialog(
              result?.success,
              'Success',
              'เพิ่ม Recommendation สำเร็จ',
              result?.message
            )
          }
        }
      })
    },
    async deleteRecommendation() {
      const result = await api.deleteSummaryRecommendation(
        parseInt(this.$route.params.recommendationId)
      )
      this.showDialog(result?.success, 'Deleted!', 'ลบ Recommendation สำเร็จ')
    },

    async showDialog(success, title, text, errorMessage) {
      if (success) {
        await Swal.fire({
          title,
          text,
          icon: 'success',
          width: '26rem',
          padding: '12px',
          confirmButtonColor: '#34BEB3',
          confirmButtonText: 'ตกลง'
        })
        this.$router.go(-1)
      } else {
        await Swal.fire({
          title: 'Error!',
          text: errorMessage ?? 'เกิดข้อผิดพลาด',
          icon: 'error',
          width: '26rem',
          padding: '12px',
          confirmButtonColor: '#d33',
          confirmButtonText: 'ตกลง'
        })
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.r-container {
  height: 100%;
  padding: 25px;
  max-width: 1500px;
  margin: 0 auto;
}
.header {
  color: #3e4a59;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;

  & > :first-child {
    flex-grow: 1;
  }

  img {
    line-height: 30px;
    vertical-align: middle;
    margin-right: 20px;
    cursor: pointer;
  }
}
.white-box {
  height: auto;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 10px 30px 30px;
  margin-bottom: 30px;

  .symptom-img {
    border-radius: 10%;
    cursor: zoom-in;
  }
}

.mg-t-25px {
  margin-top: 25px;
}
.buttons {
  max-width: 415px;
  width: 100%;
  margin: 50px auto 23px auto;
  display: flex;
  justify-content: space-around;

  .cancel-btn {
    width: 48%;
    text-align: center;
    border-radius: 20px;
    border: solid 1px #979797;
    color: #979797;
    cursor: pointer;
    padding: 10px;
  }
  .confirm-btn {
    width: 48%;
    text-align: center;
    border-radius: 20px;
    background-color: #34beb3;
    color: #fff;
    cursor: pointer;
    padding: 10px;
    margin-bottom: 0;
    height: 100%;
  }
  .disabled {
    background-color: #979797;
  }
}
@media (max-width: 1025px) {
  .white-box {
    padding: 15px;
  }
}
@media (max-width: 769px) {
  .r-container {
    padding: 25px 15px;
  }
  .header {
    font-size: 15px;
    img {
      max-width: 24px !important;
    }
  }
}
</style>
