<template>
  <div>
    <div class="header">
      <div class="title">ข่าวสารจาก SkinX</div>
    </div>
    <div class="divider" />
    <div v-if="Object.keys(contents).length === 0" class="no-content-container">
      <div class="no-content-title">ไม่มีข่าวสาร</div>
    </div>
    <div class="content-list">
      <div v-for="key in Object.keys(contents)" :key="key">
        <div>
          <router-link :to="'/dashboard/contents/' + key">
            <div class="content-box">
              <img :src="contents[key].image" :alt="contents[key].title" />
              <div class="title">
                {{ contents[key].title }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contents from '../../assets/contents'

export default {
  name: 'ContentList',
  data() {
    return {
      contents
    }
  },
  created() {
    console.log(this.contents)
    // for (const index in contents) {
    //   if (process.env.NODE_ENV === 'production' && index.includes('-test-')) {
    //     continue
    //   }
    //   this.contents[index] = contents[index]
    // }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.title {
  font-size: 20px;
}

.no-content-container {
  display: flex;
  min-height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .no-content-title {
    margin-top: 16px;
  }
}
.content-list {
  margin: auto;
  padding-right: 40px;
  padding-left: 16px;
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 50% 50%;
  row-gap: 32px;
  column-gap: 28px;
  margin-top: 32px;
}
.content-box {
  max-width: 540px;
  width: 100%;
  left: 276px;
  top: 4498px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  place-items: top center;
  img {
    // height: 70%;
    object-fit: cover;
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  .title {
    min-height: 60px;
    text-align: start;
    margin: 10px 14px;
    text-overflow: ellipsis;
    font-weight: 300;
  }
}
@media (max-width: 576px) {
  .content-list {
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 100%;
    justify-items: center;
    padding-right: 16px;
  }
}
</style>
