export default {
  author: 'SkinX Team',
  title: 'SkinX Sharing with You ชวนอัปเดตงานวิจัยด้านผิวหนัง!',
  image: require('./image.png'),
  createdAt: new Date('2023-05-25'),
  content: `SkinX ชวนหมอผิวมาอัปเดทความรู้กับงานวิจัยด้านผิวหนัง

 

  หัวข้อ ‘Modulation of amygdala activity for emotional faces due to botulinum toxin type A injections that prevent frowning’
  
  คลิกอ่านเพิ่มเติมได้ที่ : https://shorturl.at/boBHT  
  
  https://www.nature.com/articles/s41598-023-29280-x

  ![SkinX Doctor Review](${require('./image.png')})`
}
