import { render, staticRenderFns } from "./MedicalReportInstructionModal.vue?vue&type=template&id=692a0820&scoped=true&"
import script from "./MedicalReportInstructionModal.vue?vue&type=script&lang=js&"
export * from "./MedicalReportInstructionModal.vue?vue&type=script&lang=js&"
import style0 from "./MedicalReportInstructionModal.vue?vue&type=style&index=0&id=692a0820&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "692a0820",
  null
  
)

export default component.exports