<script>
import { Carousel3d } from 'vue-carousel-3d'

export default {
  name: 'Carousel3d',
  extends: Carousel3d,
  mounted () {
    if (!('ontouchstart' in window)) {
      this.$el.removeEventListener('mousedown', this.handleMousedown)
      this.$el.addEventListener('mousedown', this.newHandleMousedown)
    }
  },
  methods: {
    newHandleMousedown (e) {
      this.mousedown = true
      this.dragStartX = ('ontouchstart' in window) ? e.touches[0].clientX : e.clientX
      this.dragStartY = ('ontouchstart' in window) ? e.touches[0].clientY : e.clientY
    }
  }
}
</script>
