<template>
  <div>
    <div v-for="(item, index) in data" :key="index">
      <div class="rc">
        <div class="item-containner">
          <div
            class="columns is-mobile is-multiline rc-item"
            style="padding: 11px 15px"
            @click="onClickViewHistoryRecord(item)"
          >
            <div class="column rc-column is-12">
              <div
                class="text"
                style="
                  display: flex;
                  justify-content: space-between;
                  font-size: 16px;
                "
              >
                <div class="value-name" style="width: 90%">
                  {{ item.patient.name || item.user.tel || '-' }}
                  <div class="dot" v-if="checkReadMessage(item)"></div>
                </div>
              </div>
            </div>
            <div class="column rc-column is-12">
              <div
                style="display: flex; justify-content: space-between"
                v-if="item.status == 5"
              >
                <div class="type-appointment" :id="item.status">
                  <span class="value"> Appointment date </span>
                  <span class="value">
                    <span v-if="item.ref">
                      <span
                        :style="item.ref ? 'text-decoration: line-through' : ''"
                      >
                        {{
                          item.ref.appointmentDate
                            | toDateFormat('DD/MM/YYYY HH:mm')
                        }}
                      </span>
                      &rarr;
                    </span>
                    <span>
                      {{
                        item.appointmentDate | toDateFormat('DD/MM/YYYY HH:mm')
                      }}
                    </span>
                  </span>
                </div>
              </div>
              <div
                style="display: flex; justify-content: space-between"
                v-if="item.status == 0"
              >
                <div class="type-appointment" :id="item.status">
                  <span class="value"> Waiting to confirm </span>
                  <span class="value">
                    <span v-if="item.ref">
                      <span
                        :style="item.ref ? 'text-decoration: line-through' : ''"
                      >
                        {{
                          item.ref.appointmentDate
                            | toDateFormat('DD/MM/YYYY HH:mm')
                        }}
                      </span>
                      &rarr;
                    </span>
                    <span>
                      {{
                        item.appointmentDate | toDateFormat('DD/MM/YYYY HH:mm')
                      }}
                    </span>
                  </span>
                </div>
              </div>
              <div
                style="display: flex; justify-content: space-between"
                v-if="item.status == 4"
              >
                <div class="type-appointment" :id="item.status">
                  <span class="value"> Cancel Appointment date </span>
                  <span class="value">
                    <span v-if="item.ref">
                      <span
                        :style="item.ref ? 'text-decoration: line-through' : ''"
                      >
                        {{
                          item.ref.appointmentDate
                            | toDateFormat('DD/MM/YYYY HH:mm')
                        }}
                      </span>
                      &rarr;
                    </span>
                    <span>
                      {{
                        item.appointmentDate | toDateFormat('DD/MM/YYYY HH:mm')
                      }}
                    </span>
                  </span>
                </div>
              </div>
              <div
                style="display: flex; justify-content: space-between"
                v-if="item.missedCall"
              >
                <div class="type-appointment" :id="4">
                  <span class="value"> Missed call date </span>
                  <span class="value">
                    <span>
                      {{ item.createdAt | toDateFormat('DD/MM/YYYY HH:mm') }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="menu" v-if="window.width > 780">
            <b-dropdown
              aria-role="list"
              :mobile-modal="false"
              :position="
                index === data.length - 1 ? 'is-top-left' : 'is-bottom-left'
              "
            >
              <template #trigger>
                <div class="menu-btn" role="button">
                  <img src="@/assets/svg/menu-dot-option.svg" alt="" />
                </div>
              </template>
              <b-dropdown-item
                aria-role="listitem"
                @click="reCall(item.missedCall ? item : item.record)"
              >
                <div class="media">
                  <img
                    class="media-left icon-menu"
                    src="@/assets/svg/phone.svg"
                    alt=""
                  />
                  <div class="media-content text-menu">แจ้งให้โทรอีกครั้ง</div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                @click="reCallLink(item.missedCall ? item : item.record)"
              >
                <div class="media">
                  <img
                    class="media-left icon-menu"
                    src="@/assets/svg/video-call.svg"
                    alt=""
                  />
                  <div class="media-content text-menu">
                    ส่งลิงก์ VDO Call สำรอง
                  </div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                @click="onClickViewHistoryRecord(item)"
              >
                <div class="media">
                  <img
                    class="media-left icon-menu"
                    src="@/assets/svg/stethoscope.svg"
                    alt=""
                  />
                  <div class="media-content text-menu">ดูสรุปผลการรักษา</div>
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                @click="manageAppointment()"
              >
                <div class="media">
                  <img
                    class="media-left icon-menu"
                    src="@/assets/svg/calendar3.svg"
                    alt=""
                  />
                  <div class="media-content text-menu">เลื่อนนัดหมาย</div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div
            v-else
            class="menu-btn"
            @click="openMenu(item)"
            style="margin: 0px"
          >
            <img src="@/assets/svg/menu-dot-option.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="menuMobile"
      centered
      :width="260"
      :can-cancel="['escape', 'outside']"
    >
      <div class="containner">
        <div class="ccard">
          <div
            class="item-menu"
            @click="
              reCall(selectItem.missedCall ? selectItem : selectItem.record)
              menuMobile = false
            "
          >
            <img
              class="icon-menu"
              src="@/assets/svg/phone.svg"
              alt=""
              style="margin: 0px 16px 0px 0px"
            />
            <div class="text-menu">แจ้งให้โทรอีกครั้ง</div>
          </div>
          <div
            class="item-menu"
            @click="
              onClickViewHistoryRecord(selectItem)
              menuMobile = false
            "
          >
            <img
              class="icon-menu"
              src="@/assets/svg/stethoscope.svg"
              alt=""
              style="margin: 0px 16px 0px 0px"
            />
            <div class="text-menu">
              ดูสรุปผลการรักษา
            </div>
          </div>
          <div
            class="item-menu"
            aria-role="listitem"
            @click="
              reCallLink(selectItem.missedCall ? selectItem : selectItem.record)
              menuMobile = false
            "
          >
            <img
              class="icon-menu"
              src="@/assets/svg/video-call.svg"
              alt=""
              style="margin: 0px 16px 0px 0px"
            />
            <div class="text-menu">
              ส่งลิงก์ VDO Call สำรอง
            </div>
          </div>
          <div
            class="item-menu"
            @click="
              manageAppointment()
              menuMobile = false
            "
          >
            <img
              class="icon-menu"
              src="@/assets/svg/calendar3.svg"
              alt=""
              style="margin: 0px 16px 0px 0px"
            />
            <div class="text-menu">เลื่อนนัดหมาย</div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import api from '@/utils/api'
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'

export default {
  props: ['data'],
  data() {
    return {
      status: {
        0: 'In progress',
        1: 'Done',
        2: 'Absent',
        3: 'Change',
        4: 'Cancel',
        5: 'Confirm'
      },
      statusColor: {
        0: 'green',
        1: 'blue',
        2: 'grey',
        3: 'grey',
        4: 'red',
        5: 'blue'
      },
      url: '',
      menuMobile: false,
      window: {
        width: 0,
        height: 0
      },
      selectItem: {}
    }
  },
  async created() {
    await window.addEventListener('resize', this.handleResize)
    this.window.width = window.innerWidth
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapActions({
      setLoading: 'setLoading'
    }),
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    openMenu(item) {
      this.menuMobile = true
      this.selectItem = item
    },
    checkReadMessage(item) {
      return !(item.doctorReadNotification ?? true)
    },
    getAppointmentStatus(status) {
      if (status === 0) {
        return 'In progress'
      } else if (status === 4) {
        return 'Cancel'
      } else if (status === 5) {
        return 'Confirm'
      }
    },
    manageAppointment() {
      // var text = encodeURIComponent(escape('สวัสดีครับ ต้องการปรึกษา'))
      // var r = `https://line.me/R/oaMessage/@934jvavp/?${text}`
      window.location = 'https://lin.ee/AAiOVZp'
      // window.location = `https://line.me/R/oaMessage/@934jvavp/?${text}`
    },
    async reCallLink(recordId) {
      this.setLoading(true)
      if (recordId == null) {
        await Swal.fire({
          html: '<div class="noti-message">' + 'error' + '</div>',
          width: '24rem',
          icon: 'error'
        })
      }
      const result = await api.reCallLink(recordId.id)
      if (result?.success) {
        this.url = `${process.env.VUE_APP_TWILIO_DEMO_URL}/room/demo_${result.record.id}`

        await Swal.fire({
          html:
            '<div class="noti-message">' +
            `SkinX พัฒนา Link เสริมสำหรับเคส Miss Call<br/><br/>โดยสามารถปรึกษาเคสได้ผ่าน Link ด้านล่าง<br/><br/>ระบบนี้ไม่ได้จำกัดเวลาแต่หากปรึกษาเรียบร้อย สามารถวางสาย
<br/><br/>และเข้ามาสรุปการรักษาในแอปและได้ค่าปรึกษาตามปกติค่ะ
<br/><br/>
**หากติดปัญหามีข้อสงสัยเพิ่มเติม ติดต่อ <a href="https://lin.ee/AAiOVZp"> Admin <img

src="https://firebasestorage.googleapis.com/v0/b/skinx-test.appspot.com/o/0000_messages%2FLINE_APP_iOS.png?alt=media"

width="20"

height="20"

style="margin-right: 4px"

/> </a> ได้เลยค่ะ <br/>` +
            '</div>',
          title: 'ส่ง Link ให้คนไข้สำเร็จ !',
          width: '50rem',
          icon: 'success'
        }).then(() => {
          if (window.webkit) {
            window.webkit.messageHandlers.onOpenBrowser.postMessage(this.url)
          } else {
            window.location.href = this.url
          }
        })
      } else {
        await Swal.fire({
          html: '<div class="noti-message">' + 'error' + '</div>',
          width: '24rem',
          icon: 'error'
        })
      }
      this.setLoading(false)
    },
    async reCall(recordId) {
      this.setLoading(true)
      if (recordId == null) {
        await Swal.fire({
          html: '<div class="noti-message">' + 'error' + '</div>',
          width: '24rem',
          icon: 'error'
        })
      }
      const result = await api.reCall(recordId.id)
      if (result?.success) {
        await Swal.fire({
          html:
            '<div class="noti-message">' +
            'Notification has been sent!' +
            '</div>',
          width: '24rem',
          icon: 'success'
        })
      } else {
        await Swal.fire({
          html: '<div class="noti-message">' + 'error' + '</div>',
          width: '24rem',
          icon: 'error'
        })
      }
      this.setLoading(false)
    },
    iosCopyToClipboard() {
      const el = this.$refs.urlBox
      console.log(el)
      // const range = document.createRange()

      // el.contentEditable = true
      // el.readOnly = false
      // range.selectNodeContents(el)

      // var s = window.getSelection()
      // s.removeAllRanges()
      // s.addRange(range)
      el.select()
      el.setSelectionRange(0, 99999)

      document.execCommand('copy')
      this.$buefy.toast.open({
        message: 'url copied to clipboard',
        type: 'is-success'
      })
    },
    async onClickViewHistoryRecord(appointment) {
      if (appointment.missedCall) {
        await api.updateDoctorReadNotificationOnRecord(appointment.id)
        // this.$router.push({
        //   name: 'MedicalReport',
        //   params: { id: appointment.id, noti: true }
        // })
        this.$router.push({
          name: 'Symptom',
          params: { id: appointment.id, backToNoti: true }
        })
      } else if (appointment.record?.id) {
        await api.updateDoctorReadNotificationOnAppointment(appointment.id)
        this.$router.push({
          name: 'MedicalReport',
          params: { id: appointment.record.id, noti: true }
        })
      } else {
        await api.updateDoctorReadNotificationOnAppointment(appointment.id)
        const historiesRecords = await api.getRecords(
          1,
          100,
          'completed',
          appointment.patient.id
        )
        this.$router.push({
          name: 'MedicalReport',
          params: { id: historiesRecords.data[0].id, noti: true }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/* Less than mobile */
.item-containner {
  width: 100%;
  display: grid;
  grid-template-columns: calc(100% - 35px) 35px;
  grid-template-areas: 'detail menu';
  align-items: center;
}
.menu-btn {
  width: 28px;
  height: 28px;
  border: 1px solid #9b9b9b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rc-column {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 300;
}
.rc {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 11px 15px;
  margin-bottom: 12px;
  border-radius: 10px;
}
.w-46px {
  width: 46px;
}
.type-appointment {
  width: 70%;
  font-size: 14px;
}
.type-appointment[id='5'] {
  color: #34beb3;
}
.type-appointment[id='0'] {
  color: #9b9b9b;
}
.type-appointment[id='4'] {
  color: #ef696a;
}
.dot {
  height: 12px;
  width: 12px;
  margin: 0px 12px;
  flex-shrink: 0;
  background-color: #ff4e4f;
  border-radius: 50%;
}
.menu {
  grid-area: menu;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.rc-item {
  grid-area: detail;
  margin: 0px !important;
  padding: 0px !important;
  .avatar {
    width: 46px;
    height: 46px;
    border-radius: 50%;
  }
  .text {
    .key {
      color: #9b9b9b;
      font-size: 12px;
    }
    .value-name {
      display: flex;
      align-items: center;
      flex-direction: row;
      font-size: 16px;
      color: #3e4a59;
    }
    .value {
      font-size: 14px;
      color: #9b9b9b;
    }
  }
}
.icon-menu {
  width: 20px;
  height: 20px;
}
.text-menu {
  color: #3e4a59;
  font-size: 13px;
  font-weight: 300;
}
.noti-message {
  color: #3e4a59;
  font-size: 24px;
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dadadabd;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dadada;
}
.containner {
  width: 100%;
  height: 100%;
}
.ccard {
  width: 90%;
  height: 100%;
  color: #3e4a59;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  padding: 20px 0px;
  .item-menu {
    padding: 10px 20px;
    display: flex;
    align-content: center;
    align-items: center;
  }
  .item-menu:hover {
    background-color: rgb(245, 245, 245);
  }
}

/* Less than mobile */
@media only screen and (max-width: 768px) {
  .rc-column {
    justify-content: normal;
  }
  .rc-item {
    .status {
      width: 100%;
      .completed {
        width: 100%;
        text-align: left;
        img {
          line-height: 27px;
          vertical-align: middle;
          margin-right: 10px;
        }
        .status-msg {
          display: inline-block;
        }
      }
      .pending {
        width: 100%;
        max-width: 165px;
      }
    }
  }
}
</style>
