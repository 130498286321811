<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <b-field label="ID Number or Passport Number">
        <ValidationProvider
          name="ID Number or Passport Number"
          rules="required"
          v-slot="{ errors }"
        >
          <b-input v-model="form.idNumber" type="text" rounded />
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <div class="upload-btn-ctn">
        <ValidationProvider
          name="ID Number or Passport Number Photo"
          rules="required"
          v-slot="{ errors }"
        >
          <b-upload
            v-model="form.idCardFile"
            accept="image/*"
            @input="file => previewImage(file, 'idCardImgData')"
          >
            <div
              type="is-primary"
              class="button is-primary is-rounded upload-btn"
              rounded
            >
              <div class="inside">
                <img src="@/assets/svg/upload-photo.svg" /> Upload Photo
              </div>
            </div>
          </b-upload>
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div v-if="form.idCardImgData" class="preview-img-ctn">
        <img :src="form.idCardImgData" />
        <img
          src="@/assets/svg/close-img.svg"
          class="close-btn"
          @click="clickClosePreviewImage('idCardImgData', 'idCardFile')"
        />
      </div>
      <div class="divider" />
      <b-field label="Bank Account (for money transfer)">
        <ValidationProvider
          name="Bank Account"
          :rules="{
            required: true,
            containIn: { array: banks.map(b => b.name) }
          }"
          v-slot="{ errors }"
        >
          <b-autocomplete
            v-model="bankInput"
            :data="filteredBanksData"
            field="name"
            clearable
            rounded
            open-on-focus
            @select="option => (form.bank = option)"
          >
            <template slot="empty">
              No results found
            </template>
          </b-autocomplete>
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <b-field label="Bank Account Number (for money transfer)">
        <ValidationProvider
          name="Bank Account Number"
          rules="required|numeric"
          v-slot="{ errors }"
        >
          <b-input v-model="form.bankAccNumber" type="text" rounded />
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <div class="upload-btn-ctn">
        <ValidationProvider
          name="Bank Account Photo"
          rules="required"
          v-slot="{ errors }"
        >
          <b-upload
            v-model="form.bookBankFile"
            accept="image/*"
            @input="file => previewImage(file, 'bookBankImgData')"
          >
            <div
              type="is-primary"
              class="button is-primary is-rounded upload-btn"
              rounded
            >
              <div class="inside">
                <img src="@/assets/svg/upload-photo.svg" /> Upload Photo
              </div>
            </div>
          </b-upload>
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div v-if="form.bookBankImgData" class="preview-img-ctn">
        <img :src="form.bookBankImgData" />
        <img
          src="@/assets/svg/close-img.svg"
          class="close-btn"
          @click="clickClosePreviewImage('bookBankImgData', 'bookBankFile')"
        />
      </div>
      <div class="divider" />
      <b-button
        :loading="isLoading"
        :disabled="invalid"
        type="is-secondary"
        class="submit-btn"
        rounded
        expanded
        @click="$emit('submit', form)"
      >
        Submit
      </b-button>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'RegisterFormUpload',
  props: {
    banks: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bankInput: '',
      form: {
        idNumber: '',
        idCardFile: null,
        idCardImgData: null,
        bank: '',
        bankAccNumber: '',
        bookBankFile: null,
        bookBankImgData: null,
        isAcceptedTerm: false
      }
    }
  },
  computed: {
    filteredBanksData() {
      return this.banks.filter(
        b => b.name.toLowerCase().indexOf(this.bankInput.toLowerCase()) >= 0
      )
    }
  },
  methods: {
    previewImage(file, key) {
      const reader = new FileReader()
      reader.onload = e => {
        this.form[key] = e.target.result
      }
      reader.readAsDataURL(file)
    },
    clickClosePreviewImage(keyData, keyModel) {
      this.form[keyData] = null
      this.form[keyModel] = null
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-btn-ctn {
  display: block;
  text-align: center;
}
.upload-btn {
  .inside {
    line-height: 29px;
    img {
      vertical-align: middle;
    }
  }
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #d2d2d2;
  margin: 50px 0;
}
.submit-btn {
  height: 60px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  margin-top: 60px;
}
.preview-img-ctn {
  position: relative;
  margin-top: 20px;
  width: fit-content;
  margin: 20px auto 0;
  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}
</style>
