export default {
  author: 'SkinX Team',
  title: 'ห้ามพลาด !! กิจกรรม Happy New You ชวนคุณหมอแชร์เรื่องผิว',
  image: require('./doctor-journey.jpg'),
  createdAt: new Date('2023-01-20'),
  content: `พลาดไม่ได้ !!
  
  SkinX Doctor Journey ประจำเดือนมกราคม 

  ชวนคุณหมอมาแชร์เรื่องผิวไปกับ SkinX ในแคมเปญ Happy New You แชร์เคล็ดลับดูแลผิว เปลี่ยนตัวเองให้ปังกว่าเดิม🥳 เชิญชวนหมอผิวมาร่วมแบ่งปันสาระความรู้ รวมไปถึงเคล็ดลับในการดูแลผิวตามหัวข้อต่าง ๆ ที่ท่านสนใจ เพื่อส่งต่อประโยชน์ดี ๆ ให้กับเหล่าคนรักผิวผ่านทุกแพลตฟอร์มของ SkinX โดยสามารถเลือกรูปแบบคอนเทนต์ได้ตามที่ท่านถนัด ไม่ว่าจะเป็นเขียนบทความสั้น ๆ เพื่อส่งต่อให้กับทีม SkinX นำไปต่อยอดและจัดทำภาพประกอบ พร้อมให้เครดิตคอนเทนต์สาระดี ๆ จากท่าน 
  
  อัดคลิปแนะนำความรู้ตามสไตล์สาย TikTok โดยทางทีม SkinX สามารถช่วยท่านตัดต่อคลิปวิดีโอได้ 
  
  ถ้าหากคิดหัวข้อไม่ออกสามารถเข้าไปเลือกได้จากลิงก์นี้ https://bit.ly/3CPWami 
  
  เข้าร่วมกิจกรรมนี้ สามารถแลกรับโค๊ดส่วนลดค่าใช้จ่ายไม่จำกัดขั้นต่ำบนแอปฯ SkinX  
  
  #กิจกรรมดี ๆ แบบนี้รอไม่ได้แล้ว #เริ่มเลยยยย 

  ![Happy New You](${require('./doctor-journey.jpg')})`
}
