<template>
  <div id="register-page">
    <div class="register-container">
      <div>
        <div class="regis-title">Registration</div>
        <div id="registration-steps" class="steps">
          <b-steps
            v-model="step"
            :has-navigation="false"
            :mobile-mode="null"
            type="is-secondary"
            animated
          >
            <b-step-item :step="1" />
            <b-step-item :step="2" />
            <b-step-item :step="3" />
            <b-step-item :step="4">
              <div class="form-container">
                <RegisterFormUpload
                  :isLoading="isLoading"
                  :banks="banks"
                  @submit="onSubmit"
                />
              </div>
            </b-step-item>
          </b-steps>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterFormUpload from '@/components/RegisterFormUpload'
import { mapActions } from 'vuex'
import api from '@/utils/api'

export default {
  name: 'PostRegister',
  components: {
    RegisterFormUpload
  },
  data() {
    return {
      step: 3,
      form: {},
      isLoading: false,
      banks: [],
      platform: ''
    }
  },
  mounted() {
    const userAgent = window.navigator.userAgent.toLowerCase()
    const ios = /iphone|ipod|ipad/.test(userAgent)
    if (ios) {
      this.platform = 'ios'
    }
  },
  async created() {
    this.banks = await api.getBanks()
  },
  methods: {
    ...mapActions({
      getUser: 'getUser'
    }),
    async onSubmit(data) {
      this.isLoading = true
      try {
        this.form = { ...this.form, ...data }
        const formData = new FormData()
        formData.append('idNumber', this.form.idNumber)
        formData.append('bankAccId', this.form.bank.id)
        formData.append('bankAccNo', this.form.bankAccNumber)
        formData.append('bankAccPicture', this.form.bookBankFile)
        formData.append('idPicture', this.form.idCardFile)
        await api.postRegistDoctor(formData)
        await this.getUser()
        this.$router.push({ name: 'Dashboard' })
      } catch (e) {
        console.log(e)
        this.$buefy.toast.open({
          type: 'is-danger',
          message: e.response?.data.message ?? 'Unexpected Error'
        })
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
#registration-steps {
  .is-active {
    .step-marker {
      color: white !important;
      background: #d0a488 !important;
    }
  }
  .step-marker {
    border: 0 !important;
  }
}
#register-page {
  .label {
    color: #8f6f56 !important;
    font-weight: normal !important;
  }
}
</style>

<style lang="scss" scoped>
.register-container {
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  padding: 32px 0;
}
.back {
  color: #888888;
  position: absolute;
  top: 40px;
  left: 30px;
  line-height: 30px;
  cursor: pointer;
  img {
    vertical-align: middle;
    margin-right: 10px;
  }
}
.regis-title {
  font-size: 33px;
  color: #8f6f56;
  width: 100%;
  text-align: center;
}
.steps {
  margin-top: 60px;
}
.form-container {
  margin-top: 50px;
}
/* Less than mobile */
@media only screen and (max-width: 768px) {
  .back {
    top: 10px;
    left: 1rem;
    img {
      vertical-align: middle;
      margin-right: 0;
    }
  }
}
</style>
