<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="upload-img-profile-ctn">
        <ValidationProvider
          name="profile picture"
          rules="required"
          v-slot="{ errors }"
        >
          <b-modal v-model="showCropper" :width="640"
            ><div class="modal-card cropper-card">
              <div class="cropper-wrapper">
                <cropper
                  class="cropper"
                  :src="cropperImg"
                  ref="cropper"
                  :stencil-props="{
                    aspectRatio: 180 / 272,
                  }"
                />
              </div>
              <div class="cropper-footer">
                <b-button type="is-app-success" rounded @click="crop"
                  >ตกลง</b-button
                >
              </div>
            </div>
          </b-modal>
          <b-upload
            v-model="form.pictureFile"
            accept="image/*"
            @input="(file) => showCropperModal(file)"
          >
            <div v-if="!form.pictureImageData" class="upload-img-profile">
              <img src="@/assets/svg/avartar.svg" />
              <div class="overlay">
                <div class="outline">อัพโหลด</div>
              </div>
            </div>
            <div v-else class="upload-img-profile">
              <img :src="form.pictureImageData" class="preview" />
            </div>
          </b-upload>
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="text-upload">
        upload profile picture with a gown in the white background
      </div>
      <b-field label="License Number">
        <ValidationProvider
          name="License Number"
          rules="required|numeric"
          v-slot="{ errors }"
        >
          <b-input
            v-model="form.licenseNo"
            type="text"
            rounded
            @input="handleChange()"
          />
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <b-field label="Hospital">
        <ValidationProvider
          name="Hospital"
          :rules="{
            required: true,
            containIn: { array: hospitals.map((h) => h.name) },
          }"
          v-slot="{ errors }"
        >
          <b-autocomplete
            v-model="hospitalInput"
            :data="filteredHospitalData"
            field="name"
            clearable
            rounded
            open-on-focus
            @select="
              (option) => {
                form.hospital = option
                handleChange()
              }
            "
          >
            <template slot="empty"> No results found </template>
          </b-autocomplete>
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <b-field label="ปรึกษาด้านความงาม" v-if="isHospitalCanPorcedure">
        <div>
          <b-radio
            v-model="form.isClinicCase"
            name="isClinicCase"
            :native-value="true"
          >
            Yes
          </b-radio>
          <b-radio
            v-model="form.isClinicCase"
            name="isClinicCase"
            :native-value="false"
          >
            No
          </b-radio>
        </div>
      </b-field>
      <b-field label="Specialty">
        <ValidationProvider
          name="Specialty"
          :rules="{
            required: true,
            containIn: { array: specialties.map((h) => h.name) },
          }"
          v-slot="{ errors }"
        >
          <b-autocomplete
            v-model="specialtyInput"
            :data="filteredSpecialtiesData"
            field="name"
            clearable
            rounded
            open-on-focus
            @select="
              (option) => {
                form.speciality = option
                handleChange()
              }
            "
          >
            <template slot="empty"> No results found </template>
          </b-autocomplete>
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <div>
        <div class="label">Area of Expertise (เลือกได้มากกว่าหนึ่งอย่าง)</div>
        <b-taglist v-if="form.expertises.length">
          <b-tag
            v-for="(ex, index) in form.expertises"
            :key="index"
            type="is-primary"
            closable
            rounded
            @close="closeExpertisesTag(index)"
          >
            {{ ex.name }}
          </b-tag>
        </b-taglist>
        <ValidationProvider
          name="Area of Expertise"
          :rules="{ containIn: { array: expertises.map((h) => h.name) } }"
          v-slot="{ errors }"
        >
          <b-autocomplete
            ref="expertise-input"
            v-model="expertiseInput"
            :data="filteredExpertisesData"
            field="name"
            placeholder="เลือกได้มากกว่าหนึ่งอย่าง"
            clearable
            rounded
            open-on-focus
            clear-on-select
            @select="onSelectedExpertises"
          >
            <template slot="empty"> No results found </template>
          </b-autocomplete>
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="divider" />
      <div v-if="form.isClinicCase && isHospitalCanPorcedure">
        <div class="label">โปรแกรมที่รับปรึกษา (เลือกได้มากกว่าหนึ่งอย่าง)</div>
        <b-taglist v-if="form.procedureExpertises.length">
          <b-tag
            v-for="(ex, index) in form.procedureExpertises"
            :key="index"
            type="is-primary"
            :closable = "!isDefaultExpertise(ex)"
            rounded
            @close="closeExpertisesTagProcedure(index)"
          >
            {{ ex.name }}
          </b-tag>
        </b-taglist>
        <b-autocomplete
          ref="expertise-input"
          v-model="procedureExpertisesInput"
          :data="filteredProcedureExpertisesData"
          field="name"
          placeholder="เลือกได้มากกว่าหนึ่งอย่าง"
          clearable
          rounded
          open-on-focus
          clear-on-select
          @select="onSelectedExpertisesProcedure"
        >
          <template slot="empty"> No results found </template>
        </b-autocomplete>
      </div>
      <div class="divider" />
      <b-field label="Additional Certifications">
        <div class="upload-btn-ctn">
          <b-upload
            v-model="form.certificationFiles"
            accept="image/*"
            multiple
            @input="previewCertImage"
          >
            <div
              type="is-primary"
              class="button is-primary is-rounded upload-btn"
              rounded
            >
              <div class="inside">
                <img src="@/assets/svg/upload-photo.svg" /> Upload Photo
              </div>
            </div>
          </b-upload>
        </div>
      </b-field>
      <div
        v-if="form.certificationImgData.length"
        class="columns is-multiline is-mobile"
      >
        <div
          v-for="(img, index) in form.certificationImgData"
          :key="index"
          class="column is-6"
        >
          <div class="preview-img-ctn">
            <img :src="img" />
            <img
              src="@/assets/svg/close-img.svg"
              class="close-btn"
              @click="clickCloseCertPreviewImage(index)"
            />
          </div>
        </div>
      </div>
      <div class="divider" />
      <b-button
        :disabled="invalid"
        :loading="isLoading"
        type="is-secondary"
        class="next-btn"
        rounded
        expanded
        @click="handleSubmit()"
      >
        Submit
      </b-button>
    </ValidationObserver>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import { DEFAULT_PROCEDURE_EXPERTISE_ID } from '@/utils/constants'

export default {
  components: { Cropper },
  name: 'RegisterForm3',
  props: {
    hospitals: {
      type: Array,
      default: () => [],
    },
    specialties: {
      type: Array,
      default: () => [],
    },
    expertises: {
      type: Array,
      default: () => [],
    },
    procedureExpertises: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    hospitals() {
      this.handleDefaultProcedureExpertises()
    }
  },
  data() {
    return {
      showCropper: false,
      registerSession: {},
      hospitalInput: '',
      specialtyInput: '',
      expertiseInput: '',
      procedureExpertisesInput: '',
      form: {
        licenseNo: '',
        hospital: '',
        speciality: '',
        expertises: [],
        procedureExpertises: [],
        pictureFile: null,
        pictureImageData: null,
        certificationFiles: [],
        certificationImgData: [],
        isClinicCase: true,
      },
      cropperImg: null,
    }
  },
  created() {
    const registerSession = JSON.parse(
      localStorage.getItem('registerDetailSession')
    )
    if (registerSession) {
      this.registerSession = registerSession
      this.form.licenseNo = registerSession.licenseNo ?? ''
      this.form.hospital = registerSession.hospital ?? ''
      this.hospitalInput = registerSession.hospital?.name ?? ''
      this.form.speciality = registerSession.speciality ?? ''
      this.specialtyInput = registerSession.speciality?.name ?? ''
      this.form.expertises = registerSession.expertises ?? []
    }
  },
  computed: {
    filteredHospitalData() {
      return this.hospitals.filter(
        (hospital) =>
          hospital.name
            .toLowerCase()
            .indexOf(this.hospitalInput.toLowerCase()) >= 0
      )
    },
    filteredSpecialtiesData() {
      return this.specialties.filter(
        (specialty) =>
          specialty.name
            .toLowerCase()
            .indexOf(this.specialtyInput.toLowerCase()) >= 0
      )
    },
    filteredExpertisesData() {
      return this.expertises.filter((expertise) => {
        return (
          expertise.name
            .toLowerCase()
            .indexOf(this.expertiseInput.toLowerCase()) >= 0 &&
          !this.form.expertises.map((e) => e.id).includes(expertise.id)
        )
      })
    },
    filteredProcedureExpertisesData() {
      return this.procedureExpertises.filter((expertise) => {
        return (
          expertise.name
            .toLowerCase()
            .indexOf(this.procedureExpertisesInput.toLowerCase()) >= 0 &&
          !this.form.procedureExpertises.map((e) => e.id).includes(expertise.id)
        )
      })
    },
    isHospitalCanPorcedure() {
      return (
        this.form.hospital &&
        this.form.hospital.id &&
        this.hospitals.find((hospital) => hospital.id === this.form.hospital.id)
          .canProcedure
      )
    },
  },
  methods: {
    handleChange() {
      this.handleDefaultProcedureExpertises()
      const form = { ...this.form }
      delete form.pictureFile
      delete form.pictureImageData
      delete form.certificationFiles
      delete form.certificationImgData
      localStorage.setItem('registerDetailSession', JSON.stringify(form))
    },
    handleDefaultProcedureExpertises() {
      if (this.isHospitalCanPorcedure) {
        for (const procedure of DEFAULT_PROCEDURE_EXPERTISE_ID) {
          if (!this.form.procedureExpertises.find(e => e.id === procedure)) {
            this.form.procedureExpertises.push(this.procedureExpertises.find(e => e.id === procedure))
          }
        }
      }
    },
    handleSubmit() {
      if (!this.isHospitalCanPorcedure) {
        this.form.isClinicCase = false
      }
      this.$emit('next', this.form)
    },
    showCropperModal(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.cropperImg = e.target.result
        this.showCropper = true
      }
      reader.readAsDataURL(file)
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult()
      this.showCropper = false
      this.cropperImg = null
      this.form.pictureImageData = canvas.toDataURL()
    },
    previewCertImage(files) {
      this.form.certificationImgData = []
      files.forEach((file) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          if (this.form.certificationImgData.length >= 4) {
            this.$delete(this.form.certificationFiles, 0)
            this.$delete(this.form.certificationImgData, 0)
          }
          this.form.certificationImgData.push(e.target.result)
        }
        reader.readAsDataURL(file)
      })
    },
    onSelectedExpertises(option) {
      if (!option) return
      this.form.expertises.push(option)
      this.handleChange()
    },
    onSelectedExpertisesProcedure(option) {
      if (!option) return
      this.form.procedureExpertises.push(option)
      this.handleChange()
    },
    closeExpertisesTag(index) {
      this.form.expertises.splice(index, 1)
    },
    closeExpertisesTagProcedure(index) {
      this.form.procedureExpertises.splice(index, 1)
    },
    clickCloseCertPreviewImage(index) {
      this.$delete(this.form.certificationFiles, index)
      this.$delete(this.form.certificationImgData, index)
    },
    isDefaultExpertise(expertise) {
      return DEFAULT_PROCEDURE_EXPERTISE_ID.includes(expertise.id)
    },
  },
}
</script>
<style>
.vicp-img {
  object-fit: contain;
}
.vicp-wrap {
  max-width: 100%;
}
@media only screen and (max-width: 768px) {
  .db-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .vicp-wrap {
    max-width: 100%;
    height: auto !important;
  }
}
</style>

<style lang="scss" scoped>
.next-btn {
  height: 60px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  margin-top: 60px;
}
.upload-img-profile-ctn {
  width: 180px;
  height: 272px;
  display: block;
  margin: 0 auto 20px;
  overflow: hidden;
  border-radius: 10px;
  .upload-img-profile {
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      width: 180px;
      height: 272px;
      border-radius: 10px;
      text-align: center;
      color: white;
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      cursor: pointer;
    }
    .preview {
      object-fit: cover;
      width: 180px;
      height: 272px;
      border-radius: 10px;
    }
  }
}
.text-upload {
  width: 220px;
  margin: 10px auto 40px;
  text-align: center;
  color: #ff434e;
  font-weight: 100;
  font-size: 12px;
}
.preview-img-ctn {
  position: relative;
  margin-top: 20px;
  width: fit-content;
  margin: 20px auto 0;
  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}

.cropper {
  background: #ddd;
}

.cropper-card {
  width: auto;
  padding: 16px;
  background: white;
}

.cropper-wrapper {
  height: 380px;
  background: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cropper-footer {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outline {
  border: solid 1px white;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 100px;
  font-size: 14px;
}
</style>
