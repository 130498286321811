<template>
  <div>
    <div v-show="IsIOS" class="dowload-app-header" @click="openAppStore">
      สามารดดาวน์โหลด Application สำหรับ Video call ได้แล้วที่
      <img
        src="@/assets/img/download-on-app-store.png"
        class="download-app-btn"
      />
    </div>
    <div class="rg-status-container">
      <div class="rg-status-box">
        <div class="fs-35px" v-if="isDisplayTitle">Register completed.</div>
        <div class="fs-30px">Waiting for approval</div>
        <img src="@/assets/img/waiting-for-approval.png" />
        <div class="description">
          System will generate and send link to confirm submission in your
          e-mail.
        </div>
        <div class="mg-t-30px">
          <b-button type="is-app-success" @click="onCancel">
            กลับสู่หน้าเข้าสู่ระบบ
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RegistrationStatus',
  data() {
    return {
      isDisplayTitle: true,
      IsIOS: false
    }
  },
  created() {
    if (this.$route.query.tag === 'FROM_LOGIN') {
      this.isDisplayTitle = false
      const userAgent = window.navigator.userAgent.toLowerCase()
      const ios = /iphone|ipod|ipad/.test(userAgent)
      if (ios) {
        this.IsIOS = 'ios'
      }
    }
  },
  methods: {
    openAppStore() {
      window.location =
        'https://apps.apple.com/us/app/skinx-for-doctor/id1532716344'
    },
    onCancel() {
      localStorage.removeItem('access-token')
      try {
        this.$router.push({ name: 'Login' })
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rg-status-container {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 60px);
}
.rg-status-box {
  color: #535353;
  font-weight: bold;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  padding: 30px 10px;
}
.fs-35px {
  font-size: 35px;
}
.fs-30px {
  font-size: 30px;
}
.mg-t-30px {
  margin-top: 30px;
}
.description {
  font-weight: 100;
  margin-top: 30px;
}
</style>
