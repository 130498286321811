<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="term-container">
        <RegisterTermsAndConditions />
      </div>
      <ValidationProvider
        name="Terms and conditions"
        rules="checked"
        v-slot="{ errors }"
      >
        <b-checkbox v-model="form.isAcceptedTerm" size="is-small">
          Accept terms and conditions
        </b-checkbox>
        <div class="error-msg">{{ errors[0] }}</div>
      </ValidationProvider>
      <b-button
        :disabled="invalid"
        type="is-secondary"
        class="next-btn"
        rounded
        expanded
        @click="$emit('next', form)"
      >
        Next
      </b-button>
    </ValidationObserver>
  </div>
</template>

<script>
import RegisterTermsAndConditions from '@/components/RegisterTermsAndConditions'

export default {
  name: 'RegisterFormTermAndCondition',
  components: {
    RegisterTermsAndConditions
  },
  data() {
    return {
      form: {
        isAcceptedTerm: false
      }
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.next-btn {
  height: 60px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  margin-top: 15px;
}

.term-container {
  height: 500px;
  margin-bottom: 15px;
}
</style>
