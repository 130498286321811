<template>
  <div class="containner">
    <div class="ccard">
      <div class="in-card">
        <div class="title-date">
          {{ getDateFormat(this.data.date) }}
        </div>
        <div class="title-secsion">
          Booked
        </div>
        <div class="range-time" v-if="getSlot.booked.length > 0">
          <div
            class="box-item"
            @click="onClickViewHistoryRecord(item)"
            v-for="(item, index) in getSlot.booked"
            :key="index"
          >
            <label class="item" :id="5">
              {{ getTimeRange(item.startDate) }}
            </label>
          </div>
        </div>
        <div class="empty" v-else>-</div>
        <div class="divider" />
        <div class="title-secsion">
          Available
        </div>
        <div class="range-time" v-if="getSlot.available.length > 0">
          <div
            class="box-item"
            v-for="(item, index) in getSlot.available"
            :key="index"
          >
            <label class="item" :id="item.status">
              {{ getTimeRange(item.startDate) }}
            </label>
          </div>
        </div>
        <div class="empty" v-else>-</div>
        <div class="divider" />
        <div class="title-secsion">
          Waiting confirm
        </div>
        <div class="range-time" v-if="getSlot.wait.length > 0">
          <div
            class="box-item"
            @click="onClickViewHistoryRecord(item)"
            v-for="(item, index) in getSlot.wait"
            :key="index"
          >
            <label class="item" :id="0">
              {{ getTimeRange(item.startDate) }}
            </label>
          </div>
        </div>
        <div class="empty" v-else>-</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMonthFullNameOfYear } from '@/utils/utils'
import api from '@/utils/api'
import moment from 'moment'
export default {
  props: ['data', 'doctor'],
  data() {
    return {}
  },
  computed: {
    getSlot() {
      var available = []
      var booked = []
      var wait = []
      this.data.timeSlot.forEach(e => {
        if (e.status == null) {
          available.push(e)
        } else if (e.status === 5) {
          booked.push(e)
        } else if (e.status === 0) wait.push(e)
      })
      return {
        available: available,
        booked: booked,
        wait: wait
      }
    }
  },
  methods: {
    getDateFormat(date) {
      return (
        date.getDate() +
        ' ' +
        getMonthFullNameOfYear(date.getMonth()) +
        ' ' +
        date.getFullYear()
      )
    },
    async onClickViewHistoryRecord(appointment) {
      if (appointment.status == null) return
      if (appointment.record?.id) {
        this.$router.push({
          name: 'MedicalReport',
          params: { id: appointment.record.id, noti: true }
        })
      } else {
        const historiesRecords = await api.getRecords(
          1,
          100,
          'completed',
          appointment.patient.id
        )
        this.$router.push({
          name: 'MedicalReport',
          params: { id: historiesRecords.data[0].id, noti: true }
        })
      }
    },
    getTimeRange(date) {
      return (
        moment(date).format('HH:mm') +
        '-' +
        moment(date)
          .add(this.doctor.minPerSlot, 'minutes')
          .format('HH:mm')
      )
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dadadabd;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dadada;
}
.ccard {
  width: 90%;
  height: 100%;
  color: #3e4a59;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  padding: 20px;
}
.in-card {
  width: 100%;
  height: inherit;
  overflow-y: auto;
}
.title-secsion {
  font-size: 18px;
  color: #3e4a59;
  margin-bottom: 4px;
}
.title-date {
  font-size: 20px;
  color: #3e4a59;
  text-align: center;
  margin-bottom: 20px;
}
.divider {
  width: 99%;
  height: 1px;
  background-color: #ebebeb;
  margin-top: 24px;
  margin-bottom: 24px;
}
.empty {
  font-size: 18px;
  color: #3e4a59;
  margin: 4px 0;
}

.containner {
  width: 100%;
  height: 100%;
}
.range-time {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  .box-item {
    position: relative;
    height: 31px;
    width: 108px;
    display: flex;
    align-items: center;
    .item {
      width: 98px;
      padding: 3px 4px 3px 4px;
      position: absolute;
      background-color: #34beb3;
      color: #fff;
      border-radius: 30px;
      text-align: center;
      font-size: 12px;
      -webkit-tap-highlight-color: transparent;
    }
    .item[id='0'] {
      background-color: #cacaca;
    }
    .item[id='5'] {
      background-color: #e58f90;
    }
  }
}
</style>
