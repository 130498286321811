import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/utils/api'
import { DoctorStatus } from '../utils/constants'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    doctorStatus: DoctorStatus.online,
    isDoctorOnlineStatus: true,
    isOpenQueue: true,
    user: null,
    totalSummary: null,
    countWaitingQueue: 0
  },
  actions: {
    setLoading({ commit }, value) {
      commit('SET_LOADING', value)
    },
    getUser({ commit }) {
      return new Promise((resolve, reject) => {
        return api
          .getAuthMe()
          .then(data => {
            commit('SET_USER', data)
            commit('SET_IS_DOCTOR_ONLINE_STATUS', data.doctor.status !== 0)
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    setDoctorStatus({ commit }, value) {
      commit('SET_DOCTOR_STATUS', value)
    },
    setIsDoctorOnlineStatus({ commit }, value) {
      commit('SET_IS_DOCTOR_ONLINE_STATUS', value)
    },
    setTotalSummary({ commit }, value) {
      commit('SET_TOTAL_SUMMARY', value)
    },
    setCountWaitingQueue({ commit }, value) {
      commit('SET_COUNT_WAITING_QUEUE', value)
    },
    setIsOpenQueue({ commit }, value) {
      commit('SET_IS_OPEN_QUEUE', value)
    }
  },
  mutations: {
    SET_USER: (state, data) => {
      state.user = data
    },
    SET_LOADING: (state, value) => {
      state.isLoading = value
    },
    SET_DOCTOR_STATUS: (state, value) => {
      state.doctorStatus = value
    },
    SET_IS_DOCTOR_ONLINE_STATUS: (state, value) => {
      state.isDoctorOnlineStatus = value
    },
    SET_TOTAL_SUMMARY: (state, value) => {
      state.totalSummary = value
    },
    SET_COUNT_WAITING_QUEUE: (state, value) => {
      state.countWaitingQueue = value
    },
    SET_IS_OPEN_QUEUE: (state, value) => {
      state.isOpenQueue = value
    }
  }
})
