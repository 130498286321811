<template>
  <div class='rp-container'>
    <div class='header'>
      <div>
        <img
          src='@/assets/svg/back-black.svg'
          @click='$router.back()'
        />
        Drug Detail
      </div>
    </div>
    <div class='white-box' v-if='drug'>
      <div class='label mb-3'>
        {{ drug.tradeName }}
      </div>
      <img
        class='image-box mb-3'
        :src='drug.picture'
        @click='$router.back()'
      />
      <div class='label mb-1'>Detail</div>
      <div class='content mb-5'>{{ drug.drugDetail || '-' }}</div>
      <div class='label mb-1'>Indication</div>
      <div class='content mb-5'>{{ drug.indication || '-' }}</div>
      <div class='label mb-1'>Dosage</div>
      <div class='content mb-5'>
        {{ dosage }}
      </div>
      <div class='label mb-1'>Precautions</div>
      <div class='content mb-5'>{{ drug.precaution || '-' }}</div>
      <div class='label mb-1'>Side Effects</div>
      <div class='content mb-5'>{{ drug.sideEffect || '-' }}</div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'
import Swal from 'sweetalert2'

export default {
  name: 'DrugDetail',
  data() {
    return {
      drug: null
    }
  },
  computed: {
    dosage() {
      const usedPrefix = this.drug.usedPrefix?.captionTh || ''
      let frequencyAmount = ''
      if (this.drug.type === 'Oral') {
        frequencyAmount = this.drug.frequencyAmount || '' + ' ' + this.drug.usedUnit?.captionTh || ''
      }
      const frequency = this.drug.frequency?.caption || ''
      const frequencyTime = this.drug.frequencyTime?.caption || ''
      return `${usedPrefix} ${frequencyAmount} ${frequency} ${frequencyTime}`
    }
  },
  async created() {
    try {
      const drugDetail = await api.getDrugByDrugItemId(this.$route.params.id)
      this.drug = drugDetail[0]
      this.drug.picture = drugDetail[0].picture[0] ?? process.env.VUE_APP_DEFAULT_DRUG_PICTURE
      console.log('drug', this.drug)
    } catch (e) {
      console.log('error get drug item', e)
      await Swal.fire(
        'Error',
        `Can not get data.<br/>Please contact admin for help.<br/>recordId: ${this.$route.params.id}`,
        'error'
      )
      this.$router.go(-1)
    }
  }
}
</script>

<style lang='scss' scoped>

.rp-container {
  padding: 25px;
  max-width: 1500px;
  margin: 0 auto;

  .header {
    color: #3e4a59;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;

    & > :first-child {
      flex-grow: 1;
    }

    & > :nth-last-child(n + 2) {
      margin-right: 12px;
    }

    img {
      line-height: 30px;
      vertical-align: middle;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

@media (max-width: 769px) {
  .rp-container {
    padding: 25px 15px;
  }
  .header {
    font-size: 15px;

    img {
      max-width: 24px !important;
    }
  }
}

.label {
  font-size: 18px;
  color: #3E4A59;
}

.content {
  font-size: 16px;
  color: #888888;
}

.white-box {
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 20px 30px 30px;
  margin-bottom: 30px;
}

.image-box {
  border: 1px solid #D2D2D2;
  border-radius: 22px;
}
</style>
