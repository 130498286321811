<template>
  <div :style="cssVars">
    <div class="doctor-profile">
      <img
        :src="user.doctor.picture"
        :class="['doctor-img', { 'doctor-img-offline': !isDoctorOnlineStatus }]"
      />
      <div class="doctor-name">
        {{ user.doctor.name }}
      </div>
      <div class="doctor-status">
        <div class="doctor-status-tag">{{ doctorStatusText }}</div>
      </div>
      <div class="doctor-queue-status">
        Queue status :
        <span
          :class="['queue-status', { 'close-queue-status': !isOpenQueue }]"
          >{{ isOpenQueue ? 'Open' : 'Close' }}</span
        >
      </div>
      <div id="db-doctor-profile-switch" class="doctor-switch">
        <span>
          {{ isDoctorOnlineStatus ? 'Online' : 'Offline' }}
        </span>
        <b-switch
          :value="isDoctorOnlineStatus"
          type="is-success"
          @click.native.prevent="switchStatus"
        />
      </div>
    </div>
    <div class="rating-container">
      <div class="columns is-mobile">
        <div class="column rating-box">
          <div class="top">
            <img class="rating-icon" src="@/assets/svg/star.svg" />
            <span>{{ user.doctor.rating | numberWithCommas(2) }}</span>
          </div>
          <div class="bottom">
            {{ user.doctor.reviewAmount | numberWithCommas }} รีวิว
          </div>
        </div>
        <div class="column center-line is-paddingless is-narrow" />
        <div class="column rating-box">
          <div class="top">
            <img class="rating-icon" src="@/assets/svg/stethoscope.svg" />
            <span>{{
              totalSummary ? totalSummary.totalCases : 0 | numberWithCommas
            }}</span>
          </div>
          <div class="bottom">
            จำนวนครั้งที่ปรึกษา
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '@/utils/api'
import { DoctorStatus } from '../utils/constants'
import Swal from 'sweetalert2'

export default {
  name: 'DashboardDoctorProfile',
  computed: {
    ...mapState({
      user: 'user',
      isDoctorOnlineStatus: 'isDoctorOnlineStatus',
      totalSummary: 'totalSummary',
      isOpenQueue: 'isOpenQueue'
    }),
    doctorStatusText() {
      return Object.keys(DoctorStatus).find(
        key => DoctorStatus[key] === this.$store.state.doctorStatus
      )
    },
    cssVars() {
      let doctorStatusColor
      switch (this.$store.state.doctorStatus) {
        case DoctorStatus.online:
          doctorStatusColor = '#7dc778'
          break
        case DoctorStatus.offline:
          doctorStatusColor = '#a6a6a6'
          break
        default:
          doctorStatusColor = '#fec400'
          break
      }

      return {
        '--doctor-status-tag-bg': doctorStatusColor
      }
    }
  },
  methods: {
    ...mapActions({
      getUser: 'getUser',
      setIsDoctorOnlineStatus: 'setIsDoctorOnlineStatus'
    }),
    async switchStatus() {
      const value = !this.isDoctorOnlineStatus
      let status = 0
      if (value) {
        status = 1
        this.$ga.event({
          eventCategory: 'Online',
          eventAction: 'Click',
          eventLabel: 'Online'
        })
      } else {
        this.$ga.event({
          eventCategory: 'Offline',
          eventAction: 'Click',
          eventLabel: 'Offline'
        })
      }
      try {
        await api.patchDoctorStatus(status)
        await this.getUser()
      } catch (e) {
        console.error(e)
        Swal.fire(
          'Network error',
          'please reconnect network and try again.',
          'error'
        )
      }
      // this.setIsDoctorOnlineStatus(value !== 0)
    }
  }
}
</script>

<style lang="scss">
#db-doctor-profile-switch {
  .switch input[type='checkbox']:not(:checked) + .check.is-success {
    background: #efc0ad;
  }
}
</style>

<style lang="scss" scoped>
.doctor-profile {
  color: #3e4a59;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  padding: 15px 15px 30px;

  .doctor-img {
    width: 112px;
    height: 112px;
    object-position: top;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid #7dc778;
  }
  .doctor-img-offline {
    border: 5px solid #efc0ad;
  }
  .doctor-name {
    font-size: 20px;
    margin-top: 15px;
  }
  .doctor-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    span {
      margin-right: 10px;
    }
  }
  @media (max-width: 1023px) {
    .doctor-switch {
      display: none;
    }
  }
  .doctor-status {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .doctor-status-tag {
      color: white;
      background: var(--doctor-status-tag-bg);
      padding: 4px 16px;
      border-radius: 16px;
    }
  }

  .doctor-queue-status {
    margin-top: 8px;
    .queue-status {
      color: #7dc778;
    }
    .close-queue-status {
      color: #ff3e3e;
    }
  }
}
.rating-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  .center-line {
    width: 1px;
    background-color: #e8e8e8;
  }
  .rating-box {
    padding: 20px;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    .top {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 12px;
      }
      span {
        color: #3e4a59;
        font-weight: bold;
        margin-top: 10px;
      }
    }
    .bottom {
      color: #3e4a59;
      font-size: 13px;
      font-weight: 100;
      margin-top: 8px;
      text-align: center;
      padding-left: 5px;
    }
    .rating-icon {
      width: 26px;
      height: 26px;
    }
  }
}
/* Less than tablet */
@media (max-width: 1023px) {
  .rating-container {
    margin-bottom: 12px;
    .rating-box {
      border-bottom: none;
    }
  }
}
</style>
