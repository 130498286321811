<template>
  <div id="app">
    <b-loading v-model="isLoading" is-full-page />
    <div v-if="isDisplayView" class="main-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { db } from '@/utils/firebase'

export default {
  name: 'App',
  model: {
    doctorStatusUnsubscribe: null
  },
  async created() {
    if (!this.user && !this.$route.meta.notRequiredAuth) {
      const user = await this.getUser()
      const isRequiredAdditionInformation =
        !user.doctor.bankAccNo ||
        !user.doctor.idPicture ||
        !user.doctor.bankAccPicture
      if (isRequiredAdditionInformation) {
        this.$router.push({ name: 'PostRegister' })
      }
    }
  },
  beforeDestroy() {
    if (this.doctorStatusUnsubscribe !== null) {
      this.doctorStatusUnsubscribe()
    }
  },
  computed: {
    ...mapState({
      user: 'user',
      isLoading: 'isLoading'
    }),
    isDisplayView() {
      return (
        this.$route.meta.notRequiredAuth ||
        (this.user && Object.keys(this.user).length)
      )
    }
  },
  watch: {
    user(newUser, _) {
      this.doctorStatusUnsubscribe = db
        .collection('doctor-status')
        .doc(`${newUser.doctor.id}`)
        .onSnapshot(snapshot => {
          if (snapshot.exists) {
            const nextStatus = snapshot.get('status')
            this.setDoctorStatus(nextStatus)
            this.setIsDoctorOnlineStatus(nextStatus !== 0)
            this.setCountWaitingQueue(snapshot.get('queueCount'))
            this.setIsOpenQueue(snapshot.get('isOpenQueue'))
          }
        })
    }
  },
  methods: {
    ...mapActions({
      getUser: 'getUser',
      setIsDoctorOnlineStatus: 'setIsDoctorOnlineStatus',
      setDoctorStatus: 'setDoctorStatus',
      setCountWaitingQueue: 'setCountWaitingQueue',
      setIsOpenQueue: 'setIsOpenQueue'
    })
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  min-height: 100vh;
}
</style>

<style lang="scss">
.divider {
  width: 100%;
  height: 2px;
  background-color: #dbd6d3;
  margin: 15px 0;
}
</style>
