import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAnalytics from 'vue-analytics'

import '@/utils/veeValidate'
import '@/utils/vueFilter'

import Buefy from 'buefy'
import VueDebounce from 'vue-debounce'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import 'buefy/dist/buefy.css'
import '@/assets/scss/buefy.scss'
import '@/assets/css/main.css'
import 'vue-advanced-cropper/dist/style.css'
// import logRocket from 'logrocket'
import VConsole from 'vconsole'
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'

import 'vue-simple-calendar/static/css/default.css'

import VueEasyLightbox from 'vue-easy-lightbox'
import VueMarkdown from 'vue-markdown'

// LogRocket.init('mstkql/skinx')
Vue.use(Buefy)
Vue.use(VueDebounce)
Vue.use(VueAnalytics, {
  id: 'UA-179704050-1',
  router,
  autoTracking: {
    pageviewTemplate(route) {
      return {
        page: route.name,
        title: document.title,
        location: window.location.href
      }
    }
  },
  commands: {
    trackEvent(eventCategory, eventAction, eventLabel, eventValue) {
      this.$ga.event({
        eventCategory,
        eventAction,
        eventLabel,
        eventValue
      })
    }
  }
})
Vue.use(VueEasyLightbox)

Vue.component('CalendarView', CalendarView)
Vue.component('CalendarViewHeader', CalendarViewHeader)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('vue-markdown', VueMarkdown)

Vue.config.productionTip = false
// logRocket.init('mstkql/skinx')
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-new
  new VConsole()
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
