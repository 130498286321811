<template>
  <div>
    <div class="dowload-app-header" @click="openAppStore">
      สามารดดาวน์โหลด Application สำหรับ Video call ได้แล้วที่
      <img
        src="@/assets/img/download-on-app-store.png"
        class="download-app-btn"
      />
    </div>
    <div class="login-container">
      <div id="login-page">
        <img src="@/assets/img/logo.png" class="logo-img" />
        <ValidationObserver ref="formObserver">
          <div class="form-container">
            <b-field label="Email">
              <ValidationProvider name="Email" rules="required">
                <b-input
                  v-model="email"
                  type="email"
                  rounded
                  @keyup.enter.native="login"
                />
              </ValidationProvider>
            </b-field>
            <b-field label="Password" class="mg-t-28px">
              <ValidationProvider name="Password" rules="required">
                <b-input
                  v-model="password"
                  type="password"
                  rounded
                  @keyup.enter.native="login"
                />
              </ValidationProvider>
            </b-field>
            <b-button
              :loading="isLoading"
              type="is-secondary"
              class="login-btn"
              rounded
              expanded
              @click="login"
            >
              Login
            </b-button>
            <div
              class="forgot-password"
              @click="$router.push({ name: 'ForgotPassword' })"
            >
              Forgot Password
            </div>
            <div class="login-divider" />
            <b-button
              type="is-secondary"
              class="create-new-acc-btn"
              rounded
              expanded
              outlined
              @click="$router.push({ name: 'Register' })"
            >
              Create New Account
            </b-button>
          </div>
        </ValidationObserver>
        <div class="powered-by">
          <span>
            Powered by
          </span>
          <img src="@/assets/img/powered-by.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'
import { auth } from '@/utils/firebase'
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      isLoading: false
    }
  },
  beforeCreate() {
    localStorage.removeItem('access-token')
    localStorage.removeItem('refresh-token')
    localStorage.removeItem('user-id')
    if (window.webkit) {
      window.webkit.messageHandlers.gotoLoginHandler.postMessage(
        'Hello from JavaScript'
      )
    }
  },
  methods: {
    ...mapActions({
      getUser: 'getUser'
    }),
    async login() {
      this.isLoading = true
      const formValid = await this.$refs.formObserver.validate()
      if (!formValid) {
        this.isLoading = false
        return
      }
      this.$ga.event({
        eventCategory: 'Login',
        eventAction: 'Click',
        eventLabel: 'Login',
        eventValue: 1
      })
      try {
        const data = await api.postLogin(this.email, this.password)
        localStorage.setItem('access-token', data.access_token)
        localStorage.setItem('refresh-token', data.refreshToken)
        localStorage.setItem('user-id', data.user.id)
        localStorage.setItem('firebase-token', data.firebaseToken)
        auth.signInWithCustomToken(data.firebaseToken)
        const user = await this.getUser()
        const isRequiredAdditionInformation =
          !user.doctor.bankAccNo ||
          !user.doctor.idPicture ||
          !user.doctor.bankAccPicture
        if (isRequiredAdditionInformation) {
          this.$router.push({ name: 'PostRegister' })
        } else {
          this.$router.push({ name: 'Dashboard' })
        }
      } catch (e) {
        console.error(e)
        if (
          e.response?.data.statusCode === 403 &&
          e.response?.data.message === "Doctor isn't approve yet"
        ) {
          this.$router.push({
            name: 'RegistrationStatus',
            query: { tag: 'FROM_LOGIN' }
          })
          return
        }
        this.$buefy.toast.open({
          type: 'is-danger',
          message: e.response?.data.message ?? 'Unexpected Error'
        })
      }
      this.isLoading = false
    },
    openAppStore() {
      window.location =
        'https://apps.apple.com/us/app/skinx-for-doctor/id1532716344'
    }
  }
}
</script>

<style lang="scss">
// Overider
#login-page {
  .label {
    color: #8f6f56 !important;
    font-weight: normal !important;
  }
}
</style>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  max-width: 525px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 20px 0;
}
.logo-img {
  width: 100%;
}
.form-container {
  margin-top: 60px;
  padding: 0 75px;
}
.mg-t-28px {
  margin-top: 28px;
}
.login-btn {
  height: 60px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  margin-top: 30px;
}
.create-new-acc-btn {
  height: 60px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  margin-top: 30px;
}
.forgot-password {
  color: #888888;
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin-top: 30px;
  &:hover {
    text-decoration: underline;
  }
}
.login-divider {
  width: 100%;
  height: 1px;
  background-color: #d2d2d2;
  margin-top: 30px;
}
.powered-by {
  color: #888888;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  img {
    width: 350px;
    display: block;
    margin: 0 auto;
  }
}
/* Less than mobile */
@media only screen and (max-width: 768px) {
  .logo-img {
    width: 90%;
    margin: 0 auto;
    display: block;
  }
  .form-container {
    margin-top: 40px;
    padding: 0 25px;
  }
  .login-btn {
    margin-top: 30px;
  }
  .forgot-password {
    margin-top: 30px;
  }
  .login-divider {
    margin-top: 30px;
  }
  .create-new-acc-btn {
    margin-top: 30px;
  }
}
</style>
