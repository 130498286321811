<template>
  <div class="left-msg" v-if="message.senderType === 'Patient'">
    <div
      class="balloon balloon-tranpasrent"
      v-if="message.messageType === 'picture'"
    >
      <Picture :imgUrls="message.messageData" />
    </div>
    <div
      class="balloon balloon-tranpasrent"
      v-else-if="message.messageType === 'video'"
    >
      <Video :videoUrls="message.messageData" />
    </div>
    <div class="balloon" v-else-if="message.messageType === 'file'">
      <File :fileUrls="message.messageData" />
    </div>
    <div class="balloon" v-else-if="message.type === 'typing'">
      <Typing />
    </div>
    <div class="balloon" v-else>
      <span class="text-link" ref="textRender"></span>
    </div>
  </div>
  <div
    class="right-msg"
    v-else-if="
      message.senderType === 'Doctor' ||
        (message.senderType === 'System' && message.messageType != 'event')
    "
  >
    <div>
      <div v-if="message.isRead" class="seen">Read</div>
      <div class="timestamp">
        {{ message.createdAt | toDateFormat('HH:mm') }}
      </div>
    </div>
    <div
      class="balloon balloon-tranpasrent"
      v-if="message.messageType === 'picture'"
    >
      <Picture
        :imgUrls="message.messageData"
        :style="`opacity: ${message.isPreview ? '0.1' : '1'}`"
      />
    </div>
    <div
      class="balloon balloon-tranpasrent"
      v-else-if="message.messageType === 'video'"
    >
      <Video
        :videoUrls="message.messageData"
        :style="`opacity: ${message.isPreview ? '0.1' : '1'}`"
      />
    </div>
    <div
      class="balloon balloon-right"
      v-else-if="message.messageType === 'file'"
    >
      <File
        :fileUrls="message.messageData"
        :style="`opacity: ${message.isPreview ? '0.1' : '1'}`"
      />
    </div>
    <div class="balloon balloon-right" v-else>
      <span class="text-link" ref="textRender"></span>
    </div>
  </div>
  <div
    v-else-if="
      message.senderType === 'System' && message.messageType === 'event'
    "
  >
    <div>
      <span>
        {{ message.message }}
      </span>
    </div>
  </div>
</template>

<script>
import Picture from './Picture'
import Video from './Video'
import File from './File'
import Typing from './Typing'
export default {
  props: ['message', 'isRead'],
  components: { Picture, Typing, Video, File },
  computed: {
    body: function() {
      const URLMatcherHttp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gim
      const withLinks =
        (this.item.body &&
          this.message.message.replace(
            URLMatcherHttp,
            match => `<a href="${match}" target="_blank">${match}</a>`
          )) ||
        ''
      return withLinks
    }
  },
  watch: {
    'message.message': function(newBody, oldBody) {
      if (newBody !== oldBody) {
        setTimeout(() => {
          this.isLink()
        }, 0)
      }
    }
  },
  mounted() {
    if (
      this.message.messageType !== 'typing' &&
      this.message.type !== 'picture' &&
      this.message.type !== 'video'
    ) {
      this.isLink()
    }
  },
  methods: {
    isLink() {
      const URLMatcherHttp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gim
      const withLinks =
        (this.message.message &&
          this.message.message.replace(
            URLMatcherHttp,
            match => `<a href="${match}" target="_blank">${match}</a>`
          )) ||
        ''
      if (this.$refs.textRender && this.message.message.match(URLMatcherHttp)) {
        this.$refs.textRender.innerHTML = withLinks
      } else if (this.$refs.textRender) {
        this.$refs.textRender.innerText = withLinks
      }
      return withLinks
    }
  }
}
</script>

<style lang="scss" scoped>
.balloon {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 10px 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 90%;
  border-radius: 20px;
  white-space: break-spaces;
  word-wrap: break-word;
}
.balloon-tranpasrent {
  float: left;
  background: transparent;
  box-shadow: none;
  color: #fff;
  max-width: 300px;
}
.left-msg {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  .timestamp {
    margin-left: 10px;
    margin-bottom: 14px;
    line-height: 0.9;
    color: #9b9b9b;
  }
}
.right-msg {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  .balloon-right {
    float: left;
    background: #4db2a0;
    color: #fff;
  }
  .timestamp {
    margin-right: 10px;
    margin-bottom: 14px;
    line-height: 0.9;
    color: #9b9b9b;
  }
  .seen {
    color: #9b9b9b;
    line-height: 0.9;
  }
}
.right-msg .text-link ::v-deep a {
  color: #fff;
  text-decoration: underline;
}
.left-msg .text-link ::v-deep a {
  text-decoration: underline;
}
.date-divider {
  width: 100%;
  height: 1px;
  background: #9b9b9b;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  span {
    background-color: #ecf4f2;
    position: absolute;
    top: -15px;
    width: 80px;
    left: calc(50% - 40px);
    text-align: center;
  }
}
</style>
