<template>
  <div>
    <div v-if="record" class="rp-container">
      <div v-show="isAutoSaving" class="auto-saving">Saving...</div>
      <div v-if="!isShowCarledarFollowup">
        <div class="header">
          <div>
            <img
              src="@/assets/svg/back-black.svg"
              @click="handleBackButton()"
            />
            Medical Report
          </div>
          <b-button
            v-if="!$route.query.inCallRoom && !$route.query.inChatRoom"
            type="is-app-success"
            rounded
            @click="handleGotoChat()"
            >Chat History</b-button
          >
          <b-button
            v-if="joinRoom"
            type="is-app-success"
            rounded
            @click="handleJoinRoom()"
            >Join now</b-button
          >
        </div>
        <div class="patient">
          <div class="dp-inline-block-mobile">
            <div class="columns">
              <div class="column">
                <div>
                  <div class="columns">
                    <div class="column is-4 is-paddingless-mobile">
                      <div class="text">
                        ชื่อผู้ป่วย
                        <span>
                          {{ record.patient.name }}
                        </span>
                      </div>
                      <div class="text">
                        อายุ
                        <span>
                          {{ record.patient.birthDate | calAge }}
                        </span>
                      </div>
                      <div class="text">
                        เพศ
                        <span>
                          {{ record.patient.gender | gender }}
                        </span>
                      </div>
                    </div>
                    <div class="column is-paddingless-mobile">
                      <div class="text">
                        Underlying disease
                        <div v-if="!isEditCongenitalDisease">
                          <span v-if="record.congenitalDisease">
                            {{ record.congenitalDisease }}
                          </span>
                          <span v-else>None</span>
                        </div>
                        <img
                          v-if="
                            !record.completed &&
                              !isPreview &&
                              !isEditCongenitalDisease
                          "
                          src="@/assets/svg/edit-grey.svg"
                          class="edit-btn"
                          width="20px"
                          height="20px"
                          @click="isEditCongenitalDisease = true"
                        />
                        <b-input
                          v-if="isEditCongenitalDisease"
                          v-model="congenitalDiseaseInput"
                          size="is-small"
                          rounded
                        />
                        <b-button
                          v-if="isEditCongenitalDisease"
                          type="is-app-success"
                          size="is-small"
                          rounded
                          @click="
                            updateRecord({
                              congenitalDisease: congenitalDiseaseInput
                            })
                          "
                        >
                          ตกลง
                        </b-button>
                        <b-button
                          v-if="isEditCongenitalDisease"
                          size="is-small"
                          rounded
                          @click="isEditCongenitalDisease = false"
                        >
                          ยกเลิก
                        </b-button>
                      </div>
                      <div class="text">
                        Drug/Food allergy
                        <div v-if="!isEditDrugAllergy">
                          <span v-if="record.drugAllergy">
                            {{ record.drugAllergy }}
                          </span>
                          <span v-else>N/A</span>
                        </div>
                        <img
                          v-if="
                            !record.completed &&
                              !isPreview &&
                              !isEditDrugAllergy
                          "
                          src="@/assets/svg/edit-grey.svg"
                          class="edit-btn"
                          width="20px"
                          height="20px"
                          @click="isEditDrugAllergy = true"
                        />
                        <b-input
                          v-if="isEditDrugAllergy"
                          v-model="drugAllergyInput"
                          size="is-small"
                          rounded
                        />
                        <b-button
                          v-if="isEditDrugAllergy"
                          type="is-app-success"
                          size="is-small"
                          rounded
                          @click="
                            updateRecord({ drugAllergy: drugAllergyInput })
                          "
                        >
                          ตกลง
                        </b-button>
                        <b-button
                          v-if="isEditDrugAllergy"
                          size="is-small"
                          rounded
                          @click="isEditDrugAllergy = false"
                        >
                          ยกเลิก
                        </b-button>
                      </div>
                      <div class="text">
                        ชื่อแพทย์ผู้รักษา
                        <span>
                          {{ record.doctor.name }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="histories.length">
              <div class="history-title">ประวัติการรักษา</div>
              <div class="histories-container">
                <div
                  v-for="(h, i) in histories"
                  :key="i"
                  class="histories"
                  @click="
                    $router.push({ name: 'RecordDetail', params: { id: h.id } })
                  "
                >
                  <div>
                    แพทย์ผู้รักษา {{ h.doctor.name }} อาการ
                    {{ h.symptoms.join() }} วันที่เข้ารับการรักษา
                    {{ h.createdAt | toDateFormat }}
                  </div>
                  <div>></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ValidationObserver ref="formObserver">
          <div class="white-box">
            <div class="mg-t-25px">
              <b-field class="symptom-field" label="Chief complaint">
                <b-input
                  :value="record.symptoms | joinSymptoms"
                  type="textarea"
                  custom-class="input-text"
                  rounded
                  disabled
                  rows="1"
                  ref="symptomRef"
                  @input="inputSymptomChange()"
                />
              </b-field>
            </div>
            <div class="mg-t-25px">
              <b-field label="Present illness">
                <b-input
                  id="medical-report-rounded-text-area"
                  v-model="form.illness"
                  :disabled="record.completed || isPreview"
                  type="textarea"
                  rounded
                  @blur="autoSave"
                />
              </b-field>
            </div>
            <div class="mg-t-25px">
              <b-field label="PE">
                <div>
                  <div v-if="record.pictures.length" class="mg-bt-20px">
                    <div style="display: flex;">
                      <div
                        v-for="(pic, index) in record.pictures"
                        :key="index"
                        style="padding: 12px;"
                      >
                        <img
                          :src="record.thumbnailPictures[index]"
                          @click="openPicModal(index)"
                          @error="
                            record.thumbnailPictures[index] =
                              record.pictures[index]
                          "
                          class="symptom-img"
                        />
                        <b-image
                          :src="pic"
                          style="top: -99999px; position: absolute;"
                        ></b-image>

                        <vue-easy-lightbox
                          :visible="isOpenModalSymptomPic[index]"
                          :imgs="pic"
                          @hide="handleHide"
                        ></vue-easy-lightbox>
                      </div>
                    </div>
                  </div>
                  <b-input
                    v-model="form.peText"
                    :disabled="record.completed || isPreview"
                    :placeholder="
                      record.completed || isPreview ? 'ไม่ระบุ' : 'เพิ่มข้อความ'
                    "
                    type="text"
                    rounded
                    @blur="autoSave"
                  />
                </div>
              </b-field>
            </div>
            <div class="mg-t-25px">
              <div class="label">Diagnosis *</div>
              <b-taglist v-show="diagnosisTags.length">
                <b-tag
                  v-for="(d, index) in diagnosisTags"
                  :key="index"
                  type="is-secondary"
                  class="custom-tag"
                  :closable="!record.completed && !isPreview"
                  rounded
                  @close="closeDiagnosisTag(d)"
                >
                  {{ typeof d === 'string' ? d : d.term }}
                </b-tag>
              </b-taglist>
              <div
                v-show="
                  !diagnosisTags.length && (record.completed || isPreview)
                "
              >
                ไม่ระบุ
              </div>
              <b-autocomplete
                ref="diagnosisAutocomplete"
                v-if="!record.completed && !isPreview"
                v-model="diagnosisInput"
                v-debounce:300ms="searchDiagnosis"
                :data="filteredDiagnosisData"
                :disabled="record.completed || isPreview"
                field="term"
                clearable
                rounded
                open-on-focus
                clear-on-select
                @select="selectDiagnosis"
                @keyup.enter.native="selectDiagnosis(diagnosisInput)"
              >
                <template slot="empty">No results found</template>
              </b-autocomplete>
              <span v-if="isDiagnosisError" class="error-msg">
                Diagnosis is required
              </span>
            </div>
          </div>
          <div class="white-box">
            <div class="mg-t-25px">
              <div class="label">
                Teledermatology not recommended
                <b-tooltip
                  label="กดปุ่มนี้เมื่อวินิจฉัยว่าอาการไม่เหมาะที่จะให้การรักษาโดย “การแพทย์ทางไกล”"
                  type="is-light"
                  multilined
                >
                  <img class="info-icon" src="@/assets/svg/info.svg" />
                </b-tooltip>
              </div>
              <div>
                <b-switch
                  v-model="isTeleNotRecommended"
                  type="is-success"
                  @input="onTeleNotRecChange"
                  :disabled="record.completed || isPreview"
                />
              </div>
            </div>
            <div class="mg-t-25px">
              <span class="label">
                Discount
                <b-taglist class="discount-tag-list">
                  <b-tag
                    v-if="!isPreview || discountPercentage === 100"
                    :type="discountPercentage === 100 && 'is-secondary'"
                    class="discount-tag"
                    @click.native="clickDiscountTag(100)"
                  >
                    Free
                  </b-tag>
                  <b-tag
                    v-if="!isPreview || discountPercentage === 30"
                    :type="discountPercentage === 30 && 'is-secondary'"
                    class="discount-tag"
                    @click.native="clickDiscountTag(30)"
                  >
                    30%
                  </b-tag>
                  <b-tag
                    v-if="!isPreview || discountPercentage === 50"
                    :type="discountPercentage === 50 && 'is-secondary'"
                    class="discount-tag"
                    @click.native="clickDiscountTag(50)"
                  >
                    50%
                  </b-tag>
                  <b-tag
                    v-if="!isPreview || discountPercentage === 0"
                    :type="discountPercentage === 0 && 'is-secondary'"
                    class="discount-tag"
                    @click.native="clickDiscountTag(0)"
                  >
                    No discount
                  </b-tag>
                </b-taglist>
              </span>
            </div>
            <div class="divider mg-t-25px" />
            <div class="label mg-t-25px">Recommendation *</div>
            <ValidationProvider
              name="Recommendation"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="recommendation">
                <div class="tag-group" v-if="!isPreview">
                  <div
                    :class="['recommendation-tag', 'recommendation-tag-active']"
                    @click="addRecommendation()"
                  >
                    <img
                      class="add"
                      src="@/assets/svg/add-white.svg"
                      @click="addRecommendation()"
                    />
                    Add
                  </div>
                  <div
                    @click="activeRecommendation(index)"
                    v-for="(item, index) in recommendations"
                    v-bind:key="index"
                    :class="[
                      'recommendation-tag',
                      {
                        'recommendation-tag-active':
                          RecommendationGroupActiveIndex === index
                      }
                    ]"
                  >
                    {{ item.title }}
                    <img
                      class="edit"
                      :src="
                        RecommendationGroupActiveIndex === index
                          ? require('@/assets/svg/edit-white.svg')
                          : require('@/assets/svg/edit-grey.svg')
                      "
                      @click="updateRecommendation(item)"
                    />
                  </div>
                </div>
                <b-input
                  id="medical-report-rounded-text-area"
                  v-model="form.recommendation"
                  :disabled="record.completed || isPreview"
                  type="textarea"
                  rounded
                  @blur="autoSave"
                />
              </div>
              <span class="error-msg">{{ errors[0] }}</span>
            </ValidationProvider>

            <b-field label="โน้ตสำหรับแพทย์เห็นเท่านั้น" class="mg-t-25px">
              <b-input
                v-model="form.note"
                :disabled="isPreview"
                type="text"
                rounded
                @blur="autoSave"
              />
            </b-field>
            <div class="followup-containner">
              Follow up
              <b-switch
                v-model="isFollowUp"
                type="is-success"
                :true-value="true"
                :false-value="false"
                :disabled="record.completed || isPreview"
                style="margin-left: 15px; margin-right: 33px"
              />
            </div>
            <div class="followup-containner">
              Select date/time
              <div v-if="isFollowUp">
                <div
                  v-if="form.followUpDateTime"
                  class="followup-datetime"
                  @click="onSelectFollowupDateTime"
                >
                  {{ form.followUpDateTime | toDateFormat('DD/MM/YYYY HH:mm') }}
                </div>
                <div
                  v-else
                  class="followup-datetime"
                  @click="onSelectFollowupDateTime"
                ></div>
              </div>
            </div>
            <!-- <b-field label="Follow Up" class="mg-t-25px">
            <b-datetimepicker
              ref="followUpPicker"
              rounded
              v-model="form.followUpDateTime"
              placeholder="Select a date"
              icon="calendar-today"
              locale="th-TH"
              :class="followUpError ? 'dt' : ''"
              :timepicker="followUpTimepicker"
              :min-datetime="minDateOfFollowUp"
              :disabled="isPreview"
              @blur="autoSave"
              :mobile-native="false"
            >
              <template #right>
                <b-button
                  label="Done"
                  icon-left="check"
                  type="is-success"
                  outlined
                  @click="closeFollowUpDatePicker"
                />
              </template>
            </b-datetimepicker>
          </b-field> -->
            <span class="error-msg" v-if="followUpError"
              >Please select another time slots</span
            >
          </div>
          <DrugTable
            v-if="!joinRoom"
            mode="drug"
            :drugs="form.drugs"
            :record="record"
            :drugGroups="drugGroups"
            :isTeleNotRecommended="isTeleNotRecommended"
            :drugList="filteredDrugData"
            @removeDrug="removeDrug"
            @addDrug="addDrug"
            @searchDrugs="searchDrugs"
            @drugTableChanged="autoSave"
            :isPreview="isPreview"
          />
          <div style="height: 50px; display: block"></div>
          <DrugTable
            v-if="!joinRoom"
            mode="operatives"
            :drugs="form.operatives"
            :record="record"
            :isTeleNotRecommended="isTeleNotRecommended"
            :drugList="filteredOperativesData"
            @removeDrug="removeOperatives"
            @addDrug="addOperatives"
            @searchDrugs="searchOperatives"
            @drugTableChanged="autoSave"
            :isPreview="isPreview"
          />
          <div v-if="!record.completed" class="buttons" ref="btnGroup">
            <div
              v-if="!joinRoom"
              class="cancel-btn"
              @click="handleBackButton()"
            >
              ยกเลิก
            </div>
            <b-button
              v-if="!isPreview"
              v-bind:class="[
                'confirm-btn',
                this.$route.query.inCallRoom || this.$route.query.inChatRoom
                  ? 'disabled'
                  : ''
              ]"
              :loading="isLoading || isAutoSaving"
              @click="preview"
            >
              แสดงตัวอย่าง
            </b-button>
            <b-button
              v-if="isPreview && !joinRoom"
              :loading="isLoading || isAutoSaving"
              class="confirm-btn"
              @click="submit"
            >
              {{ isAutoSaving ? 'Auto Saving' : 'ยืนยัน' }}
            </b-button>
          </div>
        </ValidationObserver>
      </div>
      <div v-else>
        <div class="header">
          <div>
            <img
              src="@/assets/svg/back-black.svg"
              @click="handleBackButton()"
            />
            Select Appointment Date And Time
          </div>
        </div>
        <div>
          <DoctorCalendar
            :timeSlot="avaliableSlot"
            :skipSlot="skipSlot"
            :isActiveCalendar="true"
            :disabled="false"
            :canSwitchActive="false"
            :holiday="holidays"
            @onChangeMonth="fetchNewItem"
            @onClickDate="onClickDate"
            @onClickDateItem="onClickDate"
          />
        </div>
      </div>
    </div>
    <b-modal
      v-model="isShowSlotModal"
      :width="640"
      scroll="keep"
      :can-cancel="['escape', 'outside']"
      custom-class="slot-modal"
    >
      <SelectTimeSolt
        :chosenDate="chosenDate"
        :chosenDateItems="chosenDateItems"
        :seletedItem="selectedSlot"
        @onConfirm="closeModal"
        @onCancel="closeModal"
        @onSlectItem="onSlectItem"
      />
    </b-modal>
  </div>
</template>

<script>
import api from '@/utils/api'
import { mapActions, mapState } from 'vuex'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import DrugTable from '@/components/DrugTable'
import Swal from 'sweetalert2'
import DoctorCalendar from '@/components/DoctorCalendar'
import SelectTimeSolt from '@/components/SelectTimeSolt'
import moment from 'moment'

export default {
  name: 'MedicalReport',
  props: ['previewMode', 'joinRoom'],
  components: { DrugTable, DoctorCalendar, SelectTimeSolt },
  data() {
    return {
      recordId: null,
      record: null,
      isOpenModalSymptomPic: [false, false, false, false],
      form: {
        chiefComplaint: '',
        illness: '',
        diagnoses: [],
        otherDiagnoses: [],
        recommendation: '',
        note: '',
        drugs: [],
        operatives: [],
        followUpDateTime: null
      },
      avaliableSlot: [],
      skipSlot: [],
      isFollowUp: false,
      diagnoses: [],
      diagnosisInput: '',
      isLoading: false,
      isAutoSaving: false,
      drugs: [],
      operatives: [],
      minDateOfFollowUp: new Date(),
      isEditCongenitalDisease: false,
      isEditDrugAllergy: false,
      congenitalDiseaseInput: '',
      drugAllergyInput: '',
      isDiagnosisError: false,
      histories: [],
      drugGroups: [],
      isTeleNotRecommended: false,
      discountPercentage: 0,
      followUpError: null,
      isShowCarledarFollowup: false,
      isShowSlotModal: false,
      selectedSlot: {},
      chosenDate: '31 August 2018',
      chosenDateItems: [],
      recommendations: [],
      RecommendationGroupActiveIndex: -1,
      debounceAutoSave: null,
      preloadImagesList: null,
      holidays: [],
      fetchedHolidays: []
    }
  },
  computed: {
    ...mapState({
      user: 'user'
    }),
    isPreview() {
      return this.$props?.previewMode ?? false
    },
    filteredDiagnosisData() {
      if (this.form.diagnoses.length) {
        const notContainData = this.diagnoses.filter(d => {
          return !this.form.diagnoses
            .map(formDiag => formDiag.id)
            .includes(d.id)
        })
        if (this.diagnosisInput) {
          return [this.diagnosisInput, ...notContainData]
        }
        return notContainData
      }
      if (this.diagnosisInput) {
        return [this.diagnosisInput, ...this.diagnoses]
      }
      return this.diagnoses
    },
    diagnosisTags() {
      return [...this.form.diagnoses, ...this.form.otherDiagnoses]
    },
    filteredDrugData() {
      if (this.form.drugs.length) {
        return this.drugs.filter(d => {
          return !this.form.drugs.map(formDiag => formDiag.id).includes(d.id)
        })
      }
      return this.drugs
    },
    filteredOperativesData() {
      if (this.form.operatives.length) {
        return this.operatives.filter(d => {
          return !this.form.operatives
            .map(formDiag => formDiag.id)
            .includes(d.id)
        })
      }
      return this.operatives
    }
  },
  watch: {
    '$route.params.id': {
      handler: function(id) {
        this.init(id)
      },
      deep: true
    }
  },
  async created() {
    await this.init(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      setLoading: 'setLoading',
      getUser: 'getUser'
    }),
    async init(id) {
      console.log('router', this.$router)
      this.recordId = id
      this.record = null
      this.isLoading = false
      this.isAutoSaving = false
      this.setLoading(true)
      try {
        ;[
          this.record,
          this.diagnoses,
          this.drugGroups,
          this.recommendations
        ] = await Promise.all([
          api.getRecordById(id),
          api.getDiagnoses(),
          api.getDrugGroups(),
          api.getSummaryRecommendationByDoctorId(this.user.doctor.id)
        ])
        console.log('this.record', this.record)
      } catch (e) {
        console.error(e)
        this.setLoading(false)
        await Swal.fire(
          'Error',
          `Can not get data.<br/>Please contact admin for help.<br/>recordId: ${id}`,
          'error'
        )
        this.$router.go(-1)
        return
      }

      this.inputSymptomChange()
      await this.searchDrugs()
      await this.searchOperatives()
      if (this.record.deletedAt && !this.joinRoom) {
        this.$router.push({
          name: 'Symptom',
          params: { id: id, showToDashboras: true }
        })
      }
      const historiesRecords = await api.getRecords(
        1,
        100,
        'completed',
        this.record.patient.id
      )
      this.setLocalRecordToCurrentRecord()
      this.histories = historiesRecords.data
      this.congenitalDiseaseInput = this.record.congenitalDisease
      this.drugAllergyInput = this.record.drugAllergy
      if (
        typeof this.record.doctorFeeDiscount === 'number' &&
        this.record.doctorFeeDiscountType === 'PERCENTAGE'
      ) {
        this.discountPercentage = this.record.doctorFeeDiscount
      }
      if (this.record.summary) {
        this.isTeleNotRecommended = this.record.summary.isTeleNotRecommended
        if (this.record.summary.drugs && this.record.summary.drugs.length) {
          this.record.summary.drugs = this.record.summary.drugs.map(d => {
            return {
              ...d,
              ...d.drug,
              id: d.drugItemId,
              drugItemId: d.drugItemId,
              frequencyId: d.frequency ? d.frequency.id : null,
              frequencyTimeId: d.frequencyTime ? d.frequencyTime.id : null,
              usedPrefixId: d.usedPrefix ? d.usedPrefix.id : null,
              usedUnitId: d.usedUnit ? d.usedUnit.id : null
            }
          })
        }
        if (
          this.record.summary.operatives &&
          this.record.summary.operatives.length
        ) {
          this.record.summary.operatives = this.record.summary.operatives.map(
            d => {
              return {
                ...d,
                ...d.drug,
                id: d.drugItemId,
                drugItemId: d.drugItemId,
                frequencyId: d.frequency ? d.frequency.id : null,
                frequencyTimeId: d.frequencyTime ? d.frequencyTime.id : null,
                usedPrefixId: d.usedPrefix ? d.usedPrefix.id : null,
                usedUnitId: d.usedUnit ? d.usedUnit.id : null
              }
            }
          )
        }
        if (this.record.summary.followUpDateTime) {
          this.record.summary.followUpDateTime = moment(
            this.record.summary.followUpDateTime
          )
          this.isFollowUp = true
        }

        this.form = {
          ...this.form,
          ...this.record.summary
        }
      }
      this.holidays = (await api.getHolidays(moment().year())).data
      this.fetchedHolidays.push(moment().year())
      this.setLoading(false)
    },
    closeModal(item) {
      this.isShowSlotModal = false
      if (item.startDate) {
        this.form.followUpDateTime = moment(
          item.startDate,
          'yyyy-MM-DD HH:mm:SS'
        )
        this.isShowCarledarFollowup = false
        this.autoSave()
      } else {
        this.selectedSlot = {}
        this.selectedSlot = this.avaliableSlot.find(slot =>
          moment(slot.startDate).isSame(this.record.summary.followUpDateTime)
        )
      }
    },
    onSlectItem(item) {
      this.selectedSlot = item
    },
    onClickDate(chosenDate, chosenDateItems) {
      this.chosenDateItems = chosenDateItems
      if (moment(chosenDate, 'DD MMMM YYYYY').isBefore(moment(), 'd')) {
        return
      }
      if (!chosenDateItems || chosenDateItems.length === 0) {
        const startDate = moment(chosenDate).startOf('d')
        const endDate = moment(chosenDate).endOf('d')
        this.chosenDateItems = []
        while (startDate.isBefore(endDate)) {
          this.chosenDateItems.push({
            id:
              startDate.format('X').toString() +
              this.chosenDateItems.length.toString(),
            title: 'doctor booking avaliable slot',
            type: 'available',
            startDate: startDate.toDate(),
            endDate: startDate.add(this.record.doctor.minPerSlot, 'm').toDate()
          })
        }
      }
      this.isShowSlotModal = true
      this.chosenDate = chosenDate
    },
    async fetchNewItem(startDate, endDate) {
      const [{ data: avaliableData }, bookingData] = await Promise.all([
        await api.getDoctorSlotTime(startDate, endDate, this.user.doctor.id),
        await api.getAppointmentsByMonth(startDate, endDate)
      ])
      const temp = avaliableData.filter(
        d =>
          !bookingData.find(data =>
            moment(d.startDate).isSame(moment(data.appointmentDate), 'm')
          )
      )
      const temp2 = bookingData.map(d => ({
        busy: {
          type: d.type,
          startDate: moment(d.appointmentDate),
          endDate: moment(d.appointmentDate)
        },
        title: 'doctor booking avaliable slot',
        type: 'available',
        startDate: moment(d.appointmentDate),
        endDate: moment(d.appointmentDate)
      }))
      this.avaliableSlot = [...temp, ...temp2].sort((a, b) => {
        if (moment(a.startDate).isBefore(moment(b.startDate))) {
          return -1
        }
        if (moment(a.startDate).isAfter(moment(b.startDate))) {
          return 1
        }
        return 0
      })
      this.selectedSlot = this.avaliableSlot.find(slot =>
        moment(slot.startDate).isSame(this.record.summary.followUpDateTime)
      )
      if (!this.fetchedHolidays.includes(moment(startDate).year())) {
        this.holidays = this.holidays.concat(
          (await api.getHolidays(moment(startDate).year())).data
        )
        this.fetchedHolidays.push(moment(startDate).year())
      }
      if (!this.fetchedHolidays.includes(moment(endDate).year())) {
        this.holidays = this.holidays.concat(
          (await api.getHolidays(moment(endDate).year())).data
        )
        this.fetchedHolidays.push(moment(endDate).year())
      }
    },

    onSelectFollowupDateTime() {
      if (this.record.completed || this.isPreview) {
        return
      }
      this.isShowCarledarFollowup = true
    },
    handleBackButton() {
      if (this.$route.query.inCallRoom === 'true') {
        window.webkit.messageHandlers.onCloseWebview.postMessage('')
        return
      }
      if (this.$route.query.inChatRoom === 'true') {
        this.$router.go(-1)
        return
      }
      if (this.isShowCarledarFollowup) {
        this.isShowCarledarFollowup = false
        return
      }
      if (this.isPreview) {
        // this.$router.go(-1)
        this.$router.push({ name: 'MedicalReport', params: { id: this.$route.params.id } })
      } else {
        if (this.$route.params.noti ?? false) {
          this.$router.push({ name: 'Notification' })
        } else {
          this.$router.push({ name: 'Dashboard' })
        }
      }
    },
    openPicModal(i) {
      this.$set(this.isOpenModalSymptomPic, i, true)
    },
    validateForm() {
      return new Promise(resolve => {
        if (!this.diagnosisTags.length) {
          this.isDiagnosisError = true
          resolve(false)
        } else {
          this.isDiagnosisError = false
        }
        this.$refs.formObserver.validate().then(valid => {
          if (!valid) {
            setTimeout(() => {
              const errors = Object.entries(this.$refs.formObserver.errors)
                .map(([key, value]) => ({ key, value }))
                .filter(error => error.value.length)
              this.$refs.formObserver.refs[errors[0].key].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
              })
            }, 100)
          }
          resolve(valid)
        })
      })
    },
    async autoSave(option) {
      if (this.debounceAutoSave) {
        this.isAutoSaving = false
        clearTimeout(this.debounceAutoSave)
      }
      // if (!await this.validaiteForm()) return
      this.debounceAutoSave = setTimeout(async () => {
        try {
          this.isAutoSaving = true
          this.debounceAutoSave = null
          if (option?.isDrugTable) {
            if (option.drug) {
              this.form.drugs[option.index] = option.drug
            } else if (option.operative) {
              this.form.operatives[option.index] = option.operative
            }
          }
          // const data = {
          //   ...this.form,
          //   recordId: this.record.id,
          //   diagnoses: this.form.diagnoses.map(d => d.id),
          //   drugs: [
          //     ...this.form.drugs.map(d => ({
          //       ...d,
          //       drugId: d.id,
          //       duration: d.duration === 'Continue' ? 0 : parseInt(d.duration)
          //     })),
          //     ...this.form.operatives.map(operative => ({
          //       ...operative,
          //       drugId: operative.id,
          //       duration: null,
          //       frequency: null,
          //       frequencyAmount: null
          //     }))
          //   ],
          //   isTeleNotRecommended: this.isTeleNotRecommended,
          //   doctorFeeDiscount: this.discountPercentage,
          //   doctorFeeDiscountType: 'PERCENTAGE'
          // }
          // await api.postSummary(data)
          await this.setLocalRecord({
            ...this.form,
            isTeleNotRecommended: this.isTeleNotRecommended,
            doctorFeeDiscount: this.discountPercentage,
          })
          this.followUpError = null
        } catch (e) {
          if (JSON.parse(e.response.data.message).code === 'SUM01') {
            this.followUpError = 'Please select another time slots'
            Swal.fire(
              'Already booked !',
              'Please select another time slots',
              'error'
            )
          }
        } finally {
          this.isAutoSaving = false
        }
      }, 500)
    },
    async preview() {
      this.isLoading = true
      if (this.$route.query.inCallRoom || this.$route.query.inChatRoom) {
        await Swal.fire(
          "You can't submit a report during the call",
          'Please try again after the consultation finish',
          'error'
        )
        this.isLoading = false
        return
      }

      const formValid = await this.validateForm()
      if (!formValid) {
        this.isLoading = false
        return
      }
      try {
        const data = {
          ...this.form,
          recordId: this.record.id,
          diagnoses: this.form.diagnoses.map(d => d.id),
          drugs: [
            ...this.form.drugs.map(d => ({
              ...d,
              drugId: d.id,
              duration: d.duration === 'Continue' ? 0 : parseInt(d.duration)
            })),
            ...this.form.operatives.map(operative => ({
              ...operative,
              drugId: operative.id,
              duration: null,
              frequency: null,
              frequencyAmount: null
            }))
          ],
          isTeleNotRecommended: this.isTeleNotRecommended,
          doctorFeeDiscount: this.discountPercentage,
          doctorFeeDiscountType: 'PERCENTAGE'
        }
        await api.postSummary(data)
        await this.removeLocalRecord()
        this.followUpError = null
        await this.$router.push({
          name: 'MedicalReportPreview',
          params: { id: this.record.id }
        })
      } catch (e) {
        if (e.response?.data?.message?.includes('SUM01') && JSON.parse(e.response.data.message).code === 'SUM01') {
          this.followUpError = 'Please select another time slots'
          await Swal.fire(
            'Already booked !',
            'Please select another time slots',
            'error'
          )
        } else {
          this.$buefy.toast.open({
            type: 'is-danger',
            message: e.response?.data.message ?? 'Unexpected Error'
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    async submit() {
      this.isLoading = true
      const formValid = await this.validateForm()
      if (!formValid) {
        this.isLoading = false
        return
      }
      this.$ga.event({
        eventCategory: 'Summary report',
        eventAction: 'Click',
        eventLabel: 'Confirm case'
      })
      if (this.debounceAutoSave) {
        clearTimeout(this.debounceAutoSave)
      }
      try {
        const data = {
          ...this.form,
          recordId: this.record.id,
          diagnoses: this.form.diagnoses.map(d => d.id),
          drugs: [
            ...this.form.drugs.map(d => ({
              ...d,
              drugId: d.id,
              duration: d.duration === 'Continue' ? 0 : parseInt(d.duration)
            })),
            ...this.form.operatives.map(operative => ({
              ...operative,
              drugId: operative.id,
              duration: null,
              frequency: null,
              frequencyAmount: null
            }))
          ],
          isTeleNotRecommended: this.isTeleNotRecommended,
          doctorFeeDiscount: this.discountPercentage,
          doctorFeeDiscountType: 'PERCENTAGE'
        }
        await api.putRecordToCompleted(this.record.id, data)
        await this.getUser()
        await this.removeLocalRecord()
        this.$router.push({ name: 'SummaryCaseCompleted' })
      } catch (e) {
        console.error(e)
        this.$buefy.toast.open({
          type: 'is-danger',
          message: e.response?.data.message ?? 'Unexpected Error'
        })
      }
      this.isLoading = false
    },
    selectDiagnosis(option) {
      this.isDiagnosisError = false
      if (!option) return
      if (typeof option === 'string') {
        this.form.otherDiagnoses.push(option)
      } else {
        this.form.diagnoses.push(option)
      }
      this.diagnosisInput = ''
      this.searchDiagnosis()
      this.autoSave()
    },
    addDrug(option, showToast = true) {
      if (!option) return
      option.frequencyId = option.frequency ? option.frequency.id : null
      option.usedPrefixId = option.usedPrefix ? option.usedPrefix.id : null
      option.usedUnitId = option.usedUnit ? option.usedUnit.id : null
      option.frequencyTimeId = option.frequencyTime
        ? option.frequencyTime.id
        : null
      option.frequencyAmount = option.type === 'Oral' ? 1 : null
      // const newDrugs = [option, ...this.form.drugs]
      // console.log('psdflp[sldp[flp[sdl', option)
      this.form.drugs.push(option)
      if (showToast) {
        this.$buefy.toast.open({
          message: 'Drug added to cart',
          type: 'is-success'
        })
      }
      this.autoSave()
    },
    addOperatives(option) {
      if (!option) return
      option.frequencyId = option.frequency ? option.frequency.id : null
      option.usedPrefixId = option.usedPrefix ? option.usedPrefix.id : null
      option.usedUnitId = option.usedUnit ? option.usedUnit.id : null
      option.frequencyTimeId = option.frequencyTime
        ? option.frequencyTime.id
        : null
      option.frequencyAmount = option.type === 'Oral' ? 1 : null
      // const newDrugs = [option, ...this.form.drugs]
      this.form.operatives.push(option)
      this.$buefy.toast.open({
        message: 'Procedure added to cart',
        type: 'is-success'
      })
      this.autoSave()
    },
    closeDiagnosisTag(diag) {
      if (typeof diag === 'string') {
        const index = this.form.otherDiagnoses.indexOf(diag)
        this.form.otherDiagnoses.splice(index, 1)
      } else {
        const index = this.form.diagnoses.indexOf(diag)
        this.form.diagnoses.splice(index, 1)
      }
      if (!this.diagnosisTags.length) {
        this.isDiagnosisError = true
      }
      this.autoSave()
    },
    removeDrug(index) {
      this.form.drugs.splice(index, 1)
      this.autoSave()
    },
    removeOperatives(index) {
      this.form.operatives.splice(index, 1)
      this.autoSave()
    },
    async searchDiagnosis(text) {
      this.diagnoses = await api.getDiagnoses(text)
    },
    async searchDrugs(drugSearchInput, drugGroupActiveId) {
      this.isDrugSearchInputLoading = true
      this.drugs = await api.getDrugs({
        q: drugSearchInput,
        groupId: drugGroupActiveId,
        hospitalId: this.record.doctor.hospital.id
      })
      this.isDrugSearchInputLoading = false
    },
    async searchOperatives(drugSearchInput, drugGroupActiveId) {
      this.isDrugSearchInputLoading = true
      this.operatives = await api.getDrugs({
        q: drugSearchInput,
        groupId: drugGroupActiveId,
        type: 2,
        hospitalId: this.record.doctor.hospital.id
      })
      this.isDrugSearchInputLoading = false
    },
    async updateRecord(data) {
      try {
        this.isAutoSaving = true
        const result = await api.putRecordById(this.record.id, data)
        if (result.success) {
          if (data.congenitalDisease) {
            this.record.congenitalDisease = data.congenitalDisease
            this.isEditCongenitalDisease = false
          } else {
            this.record.drugAllergy = data.drugAllergy
            this.isEditDrugAllergy = false
          }
        }
      } catch (e) {
        console.error('updateRecord error', e)
      } finally {
        this.isAutoSaving = false
      }
    },
    async filterByDrugGroup(drugGroupId, drugGroupActiveId) {
      await this.searchDrugs()
    },
    clickDiscountTag(percentage) {
      if (!this.record.completed) {
        this.discountPercentage = percentage
        this.autoSave()
      }
    },
    onTeleNotRecChange() {
      if (this.isTeleNotRecommended) {
        this.form.recommendation =
          'แพทย์วินิจฉัยว่าอาการของท่านไม่เหมาะที่จะให้การรักษาโดย “การแพทย์ทางไกล” แนะนำให้เข้ารับการตรวจเพิ่มเติมที่โรงพยาบาลโดยท่านสามารถนำผลสรุปการรักษาเป็นข้อมูลยื่นเพิ่มเติมให้กับโรงพยาบาลได้'
        this.form.drugs = []
        this.discountPercentage = 100
        this.activeRecommendation(-1)
      } else {
        this.discountPercentage = 0
        this.form.recommendation = ''
      }
      this.autoSave()
    },
    inputSymptomChange() {
      setTimeout(() => {
        const inputDom = this.$refs.symptomRef.$el.children[0]
        inputDom.style.cssText = 'height:auto;'
        const height = inputDom.scrollHeight + 2
        inputDom.style.cssText = 'height:' + height + 'px'
      }, 0)
    },
    closeFollowUpDatePicker() {
      this.$refs.followUpPicker.toggle()
    },
    handleHide() {
      this.isOpenModalSymptomPic = [false, false, false, false]
    },
    async handleJoinRoom() {
      const loadingComponent = this.$buefy.loading.open({
        container: this.$refs.cnt
      })
      const ticketId = this.$route.params.ticketId
      await api.joinRoom(ticketId)
      if (this.record.type === 'chat') {
        this.$router.replace({
          name: 'CallChatPage',
          params: {
            id: this.record.id
          }
        })
      } else {
        await api.createRoomToken(this.record.id)
      }
      await api.callPatient(ticketId)
      loadingComponent.close()
    },
    async handleGotoChat() {
      this.$router.push({
        name: 'ChatBox',
        params: { id: this.record.chatId, viewOnly: 'true' }
      })
    },
    activeRecommendation(index) {
      this.RecommendationGroupActiveIndex = index
      this.form.recommendation = this.recommendations[index].detail
      this.autoSave()
    },
    async addRecommendation() {
      if (!this.record.summary) {
        this.setLoading(true)
        await this.autoSave()
        this.setLoading(false)
      }
      this.$router.push({
        name: 'Recommendation',
        previousPage: {
          name: this.$router.history.current.name,
          params: this.$router.history.current.params
        }
      })
    },
    updateRecommendation(data) {
      this.$router.push({
        name: 'Recommendation',
        params: {
          recommendationId: data.id,
          previousPage: {
            name: this.$router.history.current.name,
            params: this.$router.history.current.params
          }
        }
      })
    },
    handleHistoryClick(h) {
      if (h.id !== this.record.id) {
        this.$router.push({
          name: 'MedicalReport',
          params: {
            id: h.id
          }
        })
      }
    },
    getLocalRecord() {
      const key = 'localRecord_' + this.recordId
      const localRecord = localStorage.getItem(key)

      if (!localRecord) {
        return null
      }
      const objLocalRecord = JSON.parse(localRecord)
      const now = new Date()
      if (now.getTime() > objLocalRecord.expiry) {
        localStorage.removeItem(key)
        return null
      } else {
        return objLocalRecord.value
      }
    },
    setLocalRecord(data) {
      const key = 'localRecord_' + this.recordId
      const ttl = 7 * 24 * 60 * 60 * 1000
      const item = {
        value: data,
        expiry: new Date().getTime() + ttl
      }
      localStorage.setItem(key, JSON.stringify(item))
    },
    removeLocalRecord() {
      localStorage.removeItem('localRecord_' + this.recordId)
    },
    setLocalRecordToCurrentRecord() {
      const localRecord = this.getLocalRecord()
      if (localRecord) {
        this.record.summary = { ...this.record.summary, ...localRecord }
        this.record.doctorFeeDiscount = localRecord.doctorFeeDiscount
        this.isTeleNotRecommended = localRecord.isTeleNotRecommended
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.debounceAutoSave)
  }
}
</script>

<style lang="scss">
#medical-report-drugs-table {
  .table {
    border: 0;
    background: none;
    tr {
      box-shadow: none;
      td {
        .frequency-selector-box {
          .frequency-selector {
            .select {
              width: 100px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
.drug-group-selector {
  .slick-slider {
    .slick-list {
      .slick-track {
        .slick-slide {
          margin: 0 5px;
        }
      }
    }
  }
}
#medical-report-rounded-text-area {
  border-radius: 20px;
}
.freq-unit-amount {
  position: relative;
  .unit {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #6f6f6f;
    select {
      border: 0;
      background-color: transparent;
    }
  }
  .control {
    input {
      width: 150px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.followup-containner {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 4px 0;
}
.followup-datetime {
  min-width: 132px;
  min-height: 31px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 19.5px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 7px;
  font-size: 13px;
}
.header {
  color: #3e4a59;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: baseline;

  & > :first-child {
    flex-grow: 1;
  }
  & > :nth-last-child(n + 2) {
    margin-right: 12px;
  }

  img {
    line-height: 30px;
    vertical-align: middle;
    margin-right: 20px;
    cursor: pointer;
  }
}
.rp-container {
  padding: 25px;
  max-width: 1500px;
  margin: 0 auto;
}
.patient {
  padding: 20px 25px;
  border-radius: 20px;
  background-color: #ffffff;
  margin-top: 30px;
  margin-bottom: 30px;
  .avatar {
    width: 94px;
    height: 94px;
  }
  .text {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    span {
      color: #9b9b9b;
      padding-left: 10px;
    }
  }
}
.white-box {
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 20px 30px 30px;
  margin-bottom: 30px;

  .symptom-img {
    border-radius: 10%;
    width: 100px;
    cursor: zoom-in;
  }
}
.buttons {
  max-width: 415px;
  width: 100%;
  margin: 23px auto 0;
  display: flex;
  justify-content: space-around;

  .cancel-btn {
    width: 48%;
    text-align: center;
    border-radius: 20px;
    border: solid 1px #979797;
    color: #979797;
    cursor: pointer;
    padding: 10px;
  }
  .confirm-btn {
    width: 48%;
    text-align: center;
    border-radius: 20px;
    background-color: #34beb3;
    color: #fff;
    cursor: pointer;
    padding: 10px;
    margin-bottom: 0;
    height: 100%;
  }
  .disabled {
    background-color: #979797;
  }
}
.auto-saving {
  position: fixed;
  right: 10px;
  top: 10px;
  font-size: 13px;
}
.mg-t-25px {
  margin-top: 25px;
}
.mg-bt-20px {
  margin-bottom: 20px;
}
.dp-inline-block {
  display: inline-block;
}
.edit-btn {
  cursor: pointer;
}
.dropdown-duration-item {
  min-width: fit-content;
}
.dropdown-duration-input {
  width: 55px;
  display: inline-block;
  line-height: 40px;
  margin-right: 10px;
}
.history-title {
  font-weight: bold;
  margin-bottom: 25px;
}
.histories-container {
  max-height: 200px;
  overflow: auto;
}
.histories {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  color: #9b9b9b;
  div {
    padding: 10px;
  }
}
.histories:hover {
  background-color: #f7f7f7;
}
.custom-tag {
  max-width: 100%;
  white-space: break-spaces;
  height: auto;
  span {
    max-width: calc(100% - 16px);
  }
}
.drug-generic-name {
  font-size: 12px;
  color: #808080;
}
.info-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.discount-tag-list {
  display: inline-block;
  margin-left: 10px;
}
.discount-tag {
  cursor: pointer;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #d2d2d2;
}
.table-column-total {
  position: relative;
  .unit {
    position: absolute;
    top: 9px;
    right: -45px;
    color: #6f6f6f;
  }
}
.freq-unit-amount {
  position: relative;
  .unit {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #6f6f6f;
  }
}
.t-al-center {
  text-align: center;
}
.recommendation {
  width: 100%;
  display: flex;
  flex-direction: column;

  .tag-group {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    flex-flow: wrap;
  }
  .recommendation-tag {
    display: flex;
    align-items: center;
    border-radius: 22px;
    border: solid 1px #979797;
    color: #979797;
    padding: 5px 20px;
    margin-right: 8px;
    margin-bottom: 8px;
    outline: none;
    cursor: pointer;
  }
  .recommendation-tag-active {
    border: solid 1px #d8a283;
    color: #fff;
    background-color: #d8a283;
  }
  .edit {
    width: 16px;
    height: 16px;
    margin-left: 16px;
  }
  .add {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

/* Less than tablet */
@media (max-width: 1025px) {
  .patient {
    padding: 15px;
  }
  .white-box {
    padding: 15px;
  }
  .table-column-total {
    .unit {
      right: 10px;
    }
  }
  .freq-unit-amount {
    position: relative;
    .unit {
      position: absolute;
      top: 0px;
      right: 0px;
      color: #6f6f6f;
    }
  }
}

.symptom-field ::v-deep .input-text {
  border-radius: 23px;
  resize: none;
}
.picture-line {
  width: 50%;
}

@media (max-width: 769px) {
  .rp-container {
    padding: 25px 15px;
  }
  .header {
    font-size: 15px;
    img {
      max-width: 24px !important;
    }
  }
}

/* Less than mobile */
@media (max-width: 768px) {
  .is-paddingless-mobile {
    padding: 0 15px;
  }
  .dp-inline-block-mobile {
    display: inline-block;
    width: 100%;
  }
  .picture-line {
    width: 100%;
  }
  .follow-up-box {
    flex-direction: column;
    align-items: baseline;
    .fl-input {
      margin-left: 0;
    }
    .w-100pct-mobile {
      width: 100%;
    }
    .mg-t-25px-mobile {
      margin-top: 25px;
    }
  }
}
</style>
<style>
.dt input {
  border-color: red;
}
@media (max-width: 767px) {
  .slot-modal {
    height: 100vh;
    width: 100%;
  }
  .slot-modal .modal-content {
    max-height: 100vh;
    width: 100%;
  }
}
</style>
