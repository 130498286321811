export default {
  author: 'SkinX Team',
  title: 'สิว (Acne) ปัญหาผิวกวนใจ เกิดทีไรแก้ยากทุกที!',
  image: require('./สิวคืออะไร.jpg'),
  createdAt: new Date('2021-05-13'),
  content: `# สิว (Acne) ปัญหาผิวกวนใจ เกิดทีไรแก้ยากทุกที!

![สิว Acne](${require('./acne.jpg')})

“สิว” ต้นเหตุที่ทำให้หลายคนรู้สึกเสียความมั่นใจ เพราะคนส่วนใหญ่ยังเชื่อว่าคนเป็นสิวที่ลำตัว หรือหน้ามีสิวเกิดจากการไม่รักษาความสะอาด แต่ที่จริงแล้วความสกปรกไม่ใช่สาเหตุส่วนใหญ่ของการเกิดสิว!

ในบทความนี้ SkinX จะพาไปรู้จักกับสิว ว่าสิวคืออะไร ต้นตอของสิวเกิดจากอะไร สิวมีกี่ประเภท ขึ้นที่ใดได้บ้าง แล้ววิธีรักษาสิวแต่ละประเภทคืออะไร เหมือนหรือต่างกันอย่างไรบ้าง?

สารบัญ
1. [สิว คืออะไร](#สิว-คืออะไร)
2. [สาเหตุการเกิดสิว เกิดจากอะไร?](#สาเหตุการเกิดสิว-เกิดจากอะไร)
3. [วิธีรักษาสิว](#วิธีรักษาสิว)
   1. [วิธีรักษาสิวด้วยยา](#วิธีรักษาสิวด้วยยา)
   2. [วิธีรักษาสิวด้วยการกดสิว](#วิธีรักษาสิวด้วยการกดสิว)
   3. [วิธีรักษาสิวด้วยการเลเซอร์สิว](#วิธีรักษาสิวด้วยการเลเซอร์สิว)
   4. [วิธีรักษาสิวด้วยการปรึกษาแพทย์](#วิธีรักษาสิวด้วยการปรึกษาแพทย์)
4. [สิวมีกี่ประเภท](#สิวมีกี่ประเภท)
   1. [สิวไม่อักเสบ](#สิวไม่อักเสบ)
   2. [สิวอักเสบ](#สิวอักเสบ)
5. [บริเวณที่มักเกิดสิว](#บริเวณที่มักเกิดสิว)
  1. [สิวที่คาง](#สิวที่คาง)
  2. [สิวที่หน้าผาก](#สิวที่หน้าผาก)
  3. [สิวที่จมูก](#สิวที่จมูก)
  4. [สิวที่แก้ม](#สิวที่แก้ม)
  5. [สิวที่ปาก](#สิวที่ปาก)
  6. [สิวที่คอ](#สิวที่คอ)
  7. [สิวที่หลัง](#สิวที่หลัง)
6. [คําถามที่พบบ่อยเกี่ยวกับสิว](#คําถามที่พบบ่อยเกี่ยวกับสิว)
  1. [สิวฮอร์โมน รักษาอย่างไร?](#สิวฮอร์โมน-รักษาอย่างไร)
  2. [สิวเห่อเต็มหน้า ทําไงดี?](#สิวเห่อเต็มหน้า-ทําไงดี)
  3. [ผิวไม่แข็งแรง เป็นสิวง่าย ดูแลอย่างไร?](#ผิวไม่แข็งแรง-เป็นสิวง่าย-ดูแลอย่างไร)
7. [สรุป](#สรุป)


## สิว คืออะไร

![หน้าสิว](${require('./สิวบนหน้า.jpg')})

สิวคืออะไร? สิว (Acne) เป็นภาวะการเกิดความผิดปกติบริเวณรูขุมขนและต่อมไขมันในรูขุมขน เมื่อเกิดความผิดปกติจากสาเหตุต่างๆ จะทำให้รูขุมขนอุดตันจนเกิดเป็นสิว บางครั้งการอุดตันอาจจะทำให้ต่อมไขมันในบริเวณนั้นติดเชื้อและเกิดการอักเสบขึ้นจนกลายเป็นสิวอักเสบได้

อาการของสิวจะเห็นตุ่มเล็กๆ เป็นสิวขึ้นในบริเวณที่มีรูขุมขน เราจึงเห็นสิวอยู่ที่บริเวณที่มีขนขึ้นอยู่บ่อยๆ ทั้งหน้าเป็นสิว เป็นสิวที่หลัง บริเวณคอ และหน้าอก บริเวณที่เป็นสิวอาจจะมีรอยแดงรอบๆ มีหนองเป็นจุดขาวอยู่ตรงกลางตุ่มสิว หรือไม่มีอาการดังกล่าวเลยก็ได้ ขึ้นอยู่กับประเภทของสิว

## สาเหตุการเกิดสิว เกิดจากอะไร?

สิวเกิดจากอะไร? สาเหตุการเกิดสิว มีมากมายหลายประการ สามารถเกิดจากสาเหตุเดียว หรือเกิดร่วมกันหลายๆสาเหตุก็ได้ ซึ่งสาเหตุการเกิดสิวนี้สามารถแบ่งออกได้เป็นปัจจัยภายใน และปัจจัยภายนอก

-   สาเหตุการเกิดสิวจากปัจจัยภายใน


สิวเกิดจากปัจจัยบางอย่างภายในร่างกาย ทั้งกรรมพันธุ์ ฮอร์โมน หรือสาเหตุอื่นๆ ที่ทำให้เกิดน้ำมันส่วนเกินบริเวณผิว และการผลัดเซลล์ผิวที่ผิดปกติ

ฮอร์โมนมีผลกับการเกิดสิวอย่างมากทั้งในผู้หญิงและผู้ชาย ผู้ชายมักเกิดจากฮอร์โมนเพศชายที่เรียกว่าเทสโทสเตอโรน (Testosterone) ปกติแล้วฮอร์โมนนี้จะมีหน้าที่ทำให้ร่างกายแสดงออกถึงความเป็นเพศชาย ทั้งกระตุ้นปัจจัยต่างๆ เกี่ยวกับอวัยวะเพศชายเพิ่มมวลกระดูกและกล้ามเนื้อ หรือทำให้ขนบนร่างกายของผู้ชายดกมากขึ้น

แต่ในขณะเดียวกัน ต่อมไขมันจะไวต่อการเพิ่มระดับของฮอร์โมนเทสโทสเตอโรนอย่างมาก เมื่อเทสโทสเตอโรนมากขึ้น ต่อมไขมันก็จะทำงานมากขึ้น ทำให้บริเวณผิวมีน้ำมันเคลือบอยู่มากเกินไปจนเกิดสิว เป็นสาเหตุที่ว่าทำไมเรามักจะพบสิวในวัยรุ่นผู้ชายที่ฮอร์โมนกำลังเพิ่มขึ้นสูงนั่นเอง

แล้วน้ำมันบนผิวเกี่ยวอะไรกับสิว? สิวเกิดจากการอุดตันของรูขุมขน น้ำมันเหล่านี้เองที่ทำให้เกิดการอุดตัน เนื่องจากความมันจะทำให้สิ่งสกปรก หรือเซลล์ที่ตายบนผิวหนังจับตัวกันเป็นก้อนจนเกิดการอุดตันที่รูขุมขน เมื่อต่อมไขมันยังคงสร้างน้ำมันเรื่อยๆ น้ำมันเหล่านั้นจะไม่มีทางระบายออก และทับถมปิดรูขุมขนจนเกิดเป็นสิวขึ้นมา

นอกจากนี้ น้ำมันเหล่านี้ยังทำให้แบคทีเรียที่ผิวหนังเพิ่มมากขึ้น โดยเฉพาะแบคทีเรียตัวการที่ทำให้เกิดสิวอย่าง “P. acne (Propionibacterium acne)” ที่จะทำให้ต่อมไขมันติดเชื้อได้ง่าย เมื่อเวลาผ่านไปจะเกิดเป็นสิวอักเสบขึ้นมานั่นเอง

ส่วนในผู้หญิง สิวเกิดจากฮอร์โมนได้เช่นเดียวกัน เมื่อฮอร์โมนเพศหญิงอย่างเอสโตรเจน (Estrogen) หรือโปรเจสเตอโรน (Progesterone) เปลี่ยนแปลงไป ก็จะทำให้ต่อมไขมันผลิตน้ำมันออกมามากจนเกิดเป็นสิวได้ เป็นสาเหตุที่ว่าทำไมในผู้หญิงจึงเกิดสิวได้บ่อยกว่าผู้ชาย และมักพบในช่วงก่อนเป็นประจำเดือน ช่วงตั้งครรภ์ระยะแรกๆ หรือช่วงที่มีความผิดปกติบริเวณมดลูกหรือรังไข่

ส่วนเหตุผลด้านกรรมพันธุ์นั้นมีหลากหลายปัจจัย บางคนอาจจะมีกรรมพันธุ์ที่ต่อมไขมันผลิตน้ำมันเยอะตั้งแต่ต้น กรรมพันธุ์ที่ต่อมไขมันตอบสนองกับฮอร์โมนได้ดีกว่าปกติ หรือกรรมพันธุ์เกี่ยวกับการผลัดเซลล์ผิวที่ทำให้เกิดสิวได้ง่าย ลักษณะเหล่านี้สามารถถ่ายทอดได้ทางกรรมพันธุ์ทั้งหมด

ถ้าพ่อหรือแม่คนใดคนหนึ่งเป็นสิวในวัยผู้ใหญ่ ลูกก็มีแนวโน้มที่จะเป็นสิวในวัยผู้ใหญ่ได้ แต่ถ้าพ่อและแม่เป็นสิวในวัยผู้ใหญ่ทั้งคู่ ลูกมีแนวโน้มที่จะเป็นสิวในวัยผู้ใหญ่ที่อาการรุนแรงกว่าพ่อและแม่ได้ ซึ่งปัจจัยเหล่านี้ไม่สามารถควบคุมได้ เวลารักษาสิวจึงต้องรักษาและป้องกันการเกิดสิวที่ปลายเหตุต่อไป

-   สาเหตุการเกิดสิวปัจจัยภายนอก


ปัจจัยภายนอกร่างกายก็ทำให้ผิวมัน หรือผลัดเซลล์ผิวผิดปกติจนรูขุมขนอุดตันเกิดสิวได้เช่นกัน เช่น การไม่ล้างหน้าหรือทำความสะอาดผิวหนังบ่อยเท่าที่ควร การขัดหน้าหรือล้างหน้าบ่อยเกินไปหรือผิดวิธี ใช้ผลิตภัณฑ์เกี่ยวกับผิวหนังที่ทำให้การผลัดเซลล์ผิวผิดปกติ ใช้เครื่องสำอางที่ทำให้รูขุมขนอุดตัน

นอกจากนี้ยังเกิดจากการใช้สิ่งของที่กดทับผิวหนังเป็นประจำ จนเกิดการอุดตันและเกิดสิว อย่างการใส่หน้ากากอนามัย ผ้าคาดหัว หรือสะพายเป้เป็นประจำก็ทำให้เกิดสิวที่หลังได้

เหตุผลอื่นๆที่กระตุ้นให้เกิดสิวได้ก็จะเป็นผลข้างเคียงจากโรค จากการใช้ยาบางชนิด สภาพแวดล้อม หรือแม้แต่การสูบบุหรี่ ดื่มเครื่องดื่มแอลกอฮอล์ หรือทานอาหารไขมันสูงก็สามารถทำให้เป็นสิวในทางอ้อมได้ด้วยเช่นกัน

## วิธีรักษาสิว

How to รักษาสิว! เป็นสิวรักษายังไง? วิธีแก้สิว รักษาสิวมีด้วยกันหลายวิธี ทั้งการใช้ยา การกดสิว ใช้เลเซอร์ โดยแต่ละวิธีการสามารถใช้รักษาร่วมกันได้ เพื่อให้ผลลัพธ์การรักษาออกมาดีที่สุด

### วิธีรักษาสิวด้วยยา

![วิธีลดสิว](${require('./ลดสิว.jpg')})

วิธีลดสิวด้วยการใช้ยา มีทั้งยาแบบใช้ทา และยาเม็ดสำหรับทาน แต่จะเลือกการรักษาแบบไหนนั้นขึ้นอยู่กับความรุนแรงของสิว โดยแพทย์ผิวหนังจะพิจารณาจ่ายยาต่างๆ ให้ตามอาการ ซึ่งปกติก็จะนิยมใช้ทั้งยาทาและยาสำหรับทานควบคู่กันไป

ยารักษาสิวทั้งแบบทาและแบบทาน ส่วนใหญ่จะออกฤทธิ์ลดการผลิตไขมันของต่อมไขมัน และฆ่าเชื้อแบคทีเรียเพื่อแก้ไขสิวอักเสบที่เกิดจากการติดเชื้อ

สิ่งหนึ่งที่ผู้ที่ต้องการรักษาสิวต้องรู้ คือการรักษาสิวด้วยยาจะใช้เวลาค่อนข้างนาน อาจจะใช้เวลาหลายเดือน หรืออาจจะเป็นปีกว่าสิวบนใบหน้าจะหายไปทั้งหมด แต่ก็จะทำให้สิวค่อยๆหาย ลดการเกิดแผลเป็นหรือรอยจากสิวได้

ดังนั้นผู้ที่ต้องการรักษาสิวต้องอดทนรอผลลัพธ์ และใช้ยาทั้งยาทาและยารับประทานตามที่แพทย์สั่งอย่างเคร่งครัด

ยาที่นิยมใช้กันเพื่อรักษาสิว มีดังนี้

-   ยาในกลุ่มอนุพันธุ์วิตามินเอ (Retinoids หรือ Retinoid-like drugs) มีทั้งแบบทาเป็นครีม เจล โลชั่น และแบบเม็ดใช้ทาน ยาตัวนี้จะช่วยลดสิวได้ ออกฤทธิ์ลดการอุดตันของรูขุมขน ลดแบคทีเรีย P. acne ต้นเหตุการเกิดสิว ทั้งยังช่วยลดความมันได้ด้วย ทั้งนี้ยาในกลุ่มอนุพันธุ์วิตามินเอแบบเม็ดมีผลข้างเคียงที่ค่อนข้างอันตราย การใช้ยาจึงต้องให้แพทย์เป็นผู้จ่ายยาเท่านั้น

-   ยาปฏิชีวนะ (Antibiotics) เป็นยาใช้ฆ่าเชื้อแบคทีเรียต้นเหตุของการเกิดสิว ช่วยลดรอยแดงบริเวณที่เป็นสิว และช่วยลดอาการอักเสบด้วย นิยมใช้ร่วมกับ เบนโซอิลเพอร์ออกไซด์ (Benzoyl Peroxide) ที่ออกฤทธิ์ลดความมัน และฆ่าเชื้อแบคทีเรียเหมือนกัน เพื่อป้องกันเชื้อดื้อยา

-   กรดอะซีลาอิก (Azelaic acid) คือกรดธรรมชาติที่ใช้ฆ่าเชื้อแบคทีเรียเช่นกัน แต่ตัวนี้จะเป็นกรดอ่อนๆที่สร้างขึ้นจากยีสต์ เป็นยารักษาสิวที่นิยมใช้ในผู้ที่กำลังตั้งครรภ์หรือให้นมบุตร เพราะยาจะไม่มีผลข้างเคียงกับเด็กนั่นเอง

-   กรดซาลิไซลิก (Salicylic acid) หลายคนอาจจะรู้จักในชื่อยาลอกผิว เพราะเป็นกรดอ่อนที่ช่วยในเรื่องการผลัดเซลล์ผิว กรดตัวนี้จะช่วยลดรูขุมขนอุดตันจนเกิดสิวได้ กรดซาลิกไซลิกมักมาในรูปแบบยาทาที่ต้องล้างออกเพราะถ้าทาทิ้งไว้อาจทำให้เกิดการระคายเคืองได้


### วิธีรักษาสิวด้วยการกดสิว

![วิธีการรักษาสิว](${require('./รักษาสิว.jpg')})

การรักษาสิวที่หน้าที่นิยมกันมากวิธีหนึ่งคือการกดสิว เพราะเป็นวิธีที่หลายคนเข้าใจว่าสามารถทำเองได้ง่ายๆที่บ้าน แต่ความเป็นจริงแล้วไม่ใช่เลย การเกิดรอยสิว อย่างรอยแดง หรือรอยดำจากสิว มักเกิดจากการกดสิวด้วยตนเองที่ผิดวิธีทั้งสิ้น

วิธีแก้สิวด้วยการกดสิว ควรกดโดยแพทย์ผิวหนัง เพราะการกดเองที่บ้าน อาจทำให้ผิวหนังช้ำ เกิดแผลระหว่างกดจนทำให้เกิดแผลเป็น อีกทั้งการกดสิวอาจทำให้สิวอักเสบมากขึ้น และเป็นสิวบริเวณที่กดสิวเพิ่มขึ้นด้วย

หากกดสิวกับแพทย์ผิวหนัง แพทย์จะฆ่าเชื้อก่อนและหลังการกดสิว เพื่อลดโอกาสการเกิดสิวหรือการติดเชื้อเพิ่ม ในขณะเดียวกัน แพทย์จะเลือกกดสิวเฉพาะสิวที่เป็นสิวอุดตัน หรือสิวอักเสบที่หายจากอาการอักเสบแล้ว และเลี่ยงการกดสิวอักเสบ เนื่องจากจะทำให้เกิดการอักเสบมากขึ้นได้

ส่วนสิวที่เกิดจากการอักเสบขนาดใหญ่ใต้ผิวหนัง อย่างสิวหัวช้าง หรือสิวซีสต์ จะไม่สามารถรักษาด้วยการกดสิวได้ ต้องใช้ยาทานควบคู่ไปกับยาทา หากมีหนองอยู่ใต้ผิวหนังก็ต้องเจาะเอาหนองออกโดยแพทย์เท่านั้น

### วิธีรักษาสิวด้วยการเลเซอร์สิว

![ลดสิว](${require('./การลดสิว.jpg')})

การรักษาสิวด้วยเลเซอร์ เป็นวิธีลดสิวนวัตกรรมใหม่ที่สามารถรักสิวได้หลายแบบ ซึ่งจะนิยมใช้รักษาสิวบนใบหน้ามากกว่าที่อื่นๆ เลเซอร์ในปัจจุบันมีทั้งเลเซอร์แสงบำบัด ที่ใช้รักษาสิวอักเสบและฆ่าเชื้อสิว

หรือเลเซอร์คาร์บอนไดออกไซด์ที่ช่วยระบายน้ำมันที่สร้างจากต่อมไขมัน ช่วยรักษาสิวอุดตันทั้งยังลดการเกิดหลุมสิวและแผลเป็น ซึ่งเป็นผลข้างเคียงที่พบมากหลังเป็นสิวอุดตัน

นอกจากรักษาสิวแล้ว เลเซอร์บางชนิดยังช่วยรักษารอยสิว ทั้งรอยดำ รอยแดง หรือหลุมสิวหลังจากสิวหายแล้วได้อีกด้วย แต่เลเซอร์เหล่านี้มักจะทำให้ผิวบางและไวต่อแสงในช่วงแรกๆหลังทำ สำหรับผู้ที่อยากรักษาสิวด้วยเลเซอร์ก็ควรปรึกษาแพทย์ก่อนตัดสินใจ

### วิธีรักษาสิวด้วยการปรึกษาแพทย์

การปรึกษาแพทย์เป็นทางเลือกที่ดีที่สุดในการรักษาสิวก่อนตัดสินใจรักษาสิวด้วยวิธีใดๆก็ตาม เพราะแพทย์เฉพาะทางด้านผิวหนังเป็นผู้เชี่ยวชาญจริง ที่สามารถบอกได้ว่าปัญหาสิวของเราเกิดจากอะไร ควรเริ่มรักษาที่ตรงไหน มีทางเลือกการรักษาใดบ้าง ทำอย่างไรสิวจึงจะไม่ดื้อยา และดีต่อสภาพผิวในระยะยาว

หลายคนคิดว่าการปรึกษาแพทย์เป็นเรื่องใหญ่มาก จะต้องเคลียร์ตารางให้ว่างเพื่อนัดเวลากับแพทย์ แถมยังต้องเดินทางไปโรงพยาบาลหรือคลินิคอีก

แต่จริงๆ แล้ววิธีการรักษาสิวด้วยการปรึกษาแพทย์เป็นเรื่องเล็กนิดเดียว เพียงเข้าแอพพลิเคชั่น [SkinX](https://skinx.app/) ก็สามารถเลือกปรึกษากับแพทย์ผิวหนังได้มากถึง 210 ท่านที่เป็นแพทย์เฉพาะทางผู้เชี่ยวชาญจากสถานพยาบาลชั้นนำทั่วประเทศ แก้ปัญหาสิวโดยผู้เชี่ยวชาญเพื่อให้เห็นผลลัพธ์ที่ดีกว่า

## สิวมีกี่ประเภท

นอกจากจะรู้วิธีแก้ปัญหาสิว ยังควรรู้ด้วยว่าสิวที่กำลังเป็นอยู่เป็นสิวประเภทใด เพื่อให้สามารถแจ้งสภาพอาการของสิวกับแพทย์ได้คร่าวๆ ประเมินความรุนแรงของสิว และพอจะทราบสาเหตุการเกิดสิวด้วยตนเองได้

สิวแต่ละแบบจะมีลักษณะของสิวแตกต่างกันไป โดยจะแยกเป็นกลุ่มใหญ่ๆ 2 กลุ่ม ได้แก่ สิวไม่อักเสบ และสิวอักเสบ

### สิวไม่อักเสบ

สิวไม่อักเสบ คือสิวที่เกิดจากการอุดตันของรูขุมขน แต่ไม่มีอาการอักเสบร่วมด้วย โดยสิวไม่อักเสบ สามารถแบ่งออกได้เป็นหลายชนิด ได้แก่

-   สิวอุดตัน


![รูปภาพสิว สิวอุดตัน Comedone](${require('./comedone.jpg')})

สิวอุดตัน ภาษาอังกฤษจะเรียกว่า “Comedone” เป็นสิวที่เกิดจากการอุดตันของน้ำมันจากต่อมไขมัน เซลล์ที่ถูกผลัดออก และสิ่งสกปรก โดยสิวอุดตันเกิดได้จากหลายสาเหตุ ทั้งความมันส่วนเกินบนใบหน้า การผลัดเซลล์ผิวที่ผิดปกติ การแพ้สารเคมี หรือเกิดจากฮอร์โมน สิวอุดตันสามารถแบ่งออกได้เป็น 2 ลักษณะ ได้แก่

-   สิวหัวเปิด (Open Comedone) - เป็นสิวอุดตันที่สามารถมองเห็นหัวสิวได้จากภายนอก บางครั้งหัวสิวจะทำปฏิกิริยากับอากาศจนเกิดเป็นสีดำ จึงเรียกสิวแบบนี้กันว่าสิวหัวดำ ซึ่งสิวแบบนี้สามารถทำให้เกิดผลข้างเคียงเป็นรอยสิวและหลุดสิวได้ง่ายเมื่อรักษาหายแล้ว

-   สิวหัวปิด (Closed Comedone) - เป็นสิวอุดตันที่ไม่สามารถมองเห็นหัวสิวจากภายนอกได้ จะเป็นตุ่มนูนเล็กๆ มีสีเดียวกับผิวหนัง หรือสีขาว จึงนิยมเรียกว่าสิวหัวขาว สิวไม่มีหัว หรือสิวไต ซึ่งเป็นสิวที่พัฒนาอยู่ใต้ผิวหนัง รักษาได้ยาก แต่ถ้าไม่รักษาจะกลายเป็นสิวอักเสบได้


-   สิวผด


สิวผด (Acne Estivalis) เป็นสิวไม่อักเสบที่จะเกิดเป็นระยะ มักจะเกิดในเวลาที่อากาศร้อน และยุบลงไปเองเมื่ออากาศเย็นขึ้น

เพราะสิวผดแบบนี้มักจะเกิดจากต่อมเหงื่อที่ไม่สามารถระบายเหงื่อออกทางรูขุมขนได้หมด เหงื่อพวกนี้จึงอุดตันและเกิดเป็นตุ่มสิวผด แต่เมื่ออากาศเย็นลง ต่อมเหงื่อไม่ได้สร้างเหงื่อมากเท่าเดิม สิวผดเหล่านี้จึงจะหายไปเอง

บางครั้งสิวผดอาจเกิดจากแบคทีเรีย P. acne สิ่งแวดล้อม อาการแพ้ การพักผ่อนน้อย หรือการขัดผิว ถูกผิวมากเกินไป ซึ่งสิวผดเหล่านี้ไม่สามารถรักษาได้ด้วยการกดสิวเหมือนกับสิวอุดตัน อาจจะต้องรักษาโดยการใช้ยาฆ่าเชื้อแบคทีเรียหรือปรับเปลี่ยนพฤติกรรมที่ทำให้เกิดสิวแทน

-   สิวหิน


สิวหิน หรือที่เรียกว่าสิวเม็ดข้าวสาร (Milia หรือ Milium Cysts) ในไทยจะเรียกว่าเป็นสิว แต่บางครั้งก็จะนับเป็นซีสต์ชนิดหนึ่ง สิวหินจะมีลักษณะเป็นก้อนเม็ดเล็กๆ สีขาว มักจะขึ้นอยู่ใกล้ดวงตา แก้ม หรือจมูก ก้อนสิวจะเป็นตุ่มนูนขนาดเล็ก สีเดียวกับผิวหนัง และจะแข็งเป็นก้อน

ส่วนการรักษาสิวหินนี้ ไม่สามารถบีบออกเองได้เหมือนสิวอื่นๆ ต้องรักษาด้วยการใช้เลเซอร์ ใช้ความเย็นจี้ หรือใช้สารเคมีลอกผิวที่เป็นสิวหินออก ซึ่งต้องรักษาโดยแพทย์ผิวหนังเท่านั้น

### สิวอักเสบ

สิวอักเสบ ภาษาอังกฤษเรียกว่า “Inflamed acne หรือ Inflammatory acne” คือสิวที่เกิดจากการอุดตันในรูขุมขน และมีอาการอักเสบบริเวณผิวหนังหรือต่อมไขมันร่วมด้วย จากการติดเชื้อแบตทีเรีย P. acne หรือ C.acne (Cutibacterium acnes) ซึ่งสิวอักเสบ มีหลายชนิด ดังนี้

-   สิวตุ่มแดง


![รูปภาพสิว สิวตุ่มแดง Papule](${require('./Papule.jpg')})

สิวตุ่มแดง (Papule) คือสิวอักเสบที่พัฒนามาจากสิวอุดตันแบบต่างๆ มีอาการอักเสบบนผิวหนังตื้นๆ ยังไม่ทำให้เกิดหนอง หรือการอักเสบในผิวหนังชั้นที่อยู่ลึกลงไป ลักษณะสิวตุ่มแดงจะเป็นตุ่มสิวอักเสบเม็ดเล็กๆ สีแดง และทำให้รู้สึกเจ็บเล็กน้อยเมื่อสัมผัสโดนสิว

-   สิวหัวหนอง


![รูปภาพสิว สิวหัวหนอง Pustule](${require('./Pustule.jpg')})

สิวหัวหนอง (Pustule) เป็นสิวขนาดเล็ก ลักษณะสิวจะมีจุดสีขาวเหลืองอยู่ตรงกลางซึ่งก็คือหนอง ส่วนรอบๆจะเป็นสีแดง เมื่อจับจะรู้สึกเจ็บ เป็นสิวที่พัฒนามาจากสิวอุดตันเช่นกัน แต่จะมีระดับการอักเสบที่มากกว่าสิวตุ่มแดง

-   สิวหัวช้าง


![รูปภาพสิว สิวหัวช้าง Nodule](${require('./Nodule.jpg')})

สิวหัวช้าง (Nodule) คือสิวอักเสบที่การอักเสบลุกลามจนเข้าไปถึงผิวหนังด้านใน ทำให้เป็นก้อนตุ่มนูนขนาดใหญ่สีชมพู ที่สามารถมองเห็นได้ด้วยตาเปล่า สิวแบบนี้เวลาเป็นจะเจ็บมาก กดก็ไม่ได้ เพราะจะไม่มีอะไรออกมา ทั้งยังทำให้อาการอักเสบลุกลามมากขึ้น สิวเม็ดใหญ่และอักเสบมากกว่าเดิม

สิวหัวช้างเป็นสิวที่รักษาได้ยาก จะต้องใช้ยาทาควบคู่กับยาปฏิชีวนะ หรือยาในกลุ่มอนุพันธุ์วิตามินเอที่ออกฤทธิ์ได้รุนแรงกว่า

-   สิวซีสต์


สิวซีสต์ (Cyst acne) สิวซีสต์ไม่ใช่ซีสต์ เป็นคนละชนิดกับซีสต์ที่เกิดจากความผิดปกติของต่อมไขมันซึ่งเรียกว่า Sebaceous Cyst แต่สิวซีสต์นี้ก็สามารถเกิดได้จากการติดเชื้อและอักเสบบริเวณต่อมไขมันเช่นกัน บางคนจึงเรียกว่า “สิวต่อมไขมัน”

สิวซีสต์จะมีลักษณะเป็นตุ่มบวมขนาดใหญ่คล้ายกับสิวหัวช้าง แต่จะมีอาการอักเสบมากกว่าจนเกิดหนองอยู่ใต้ผิวหนัง นับว่าเป็นสิวชนิดที่รุนแรงที่สุด สิวซีสต์จะเป็นไตแข็ง แต่ไม่แข็งเท่าสิวหัวช้าง เมื่อสัมผัสจะรู้สึกเจ็บปวด และสัมผัสได้ว่ามีของเหลวอยู่ด้านใน

การรักษาสิวซีสต์อาจใช้เวลามากถึง 3 เดือน เพราะต้องเริ่มจากการทานยาควบคุมการอักเสบ ใช้ยาทาลดความมันและควบคุมปริมาณเชื้อแบคทีเรียบนผิวหนัง บางครั้งแพทย์อาจให้ฉีดยาในกลุ่มสเตียรอยด์เพื่อลดอักเสบและลดอาการเจ็บปวด หลังจากอาการอักเสบดีขึ้นก็อาจจะต้องเจาะเอาหนองด้านในออกมาด้วย

นอกจากสิวเหล่านี้แล้ว หลายคนคงเคยได้ยินสิวที่เรียกว่า  “สิวสเตียรอยด์” กันมาบ้าง สิวสเตียรอยด์ หรือสิวติดสาร แท้จริงแล้วไม่ใช่สิว เพียงแต่เป็นภาวะความผิดปกติบนผิวหนังที่ลักษณะอาการคล้ายกับสิวเท่านั้น สิวสเตียรอยด์มักเกิดจากการใช้ผลิตภัณฑ์ที่ใช้ทาลงบนผิวต่อเนื่องกันเป็นเวลานาน ในบริเวณที่ทาซ้ำๆจะเกิดเป็นตุ่มนูนแดงคล้ายกับสิวตุ่มแดง ในบางกรณีอาจเป็นตุ่มหนอง หรือตุ่มพุพองได้

วิธีการรักษาสิวสเตียรอยด์ทำได้ด้วยการหยุดใช้ผลิตภัณฑ์ที่มีส่วนผสมของสเตียรอยด์ หรือให้ปรึกษาแพทย์เพื่อปรับระยะเวลาการใช้สารสเตียรอยด์

ส่วนการรักษาความผิดปกติบริเวณที่ทาสเตียรอยด์นั้น ให้คอยดูแลเหมือนเป็นผิวที่อ่อนแอ แพ้ง่าย ใช้ผลิตภัณฑ์ที่ไม่ผสมสารเคมีรุนแรง ไม่ผสมน้ำหอม หรือสารก่ออาการแพ้อื่นๆ หลีกเลี่ยงการให้ผิวถูกแสง เพียงเท่านี้สิวสเตียรอยด์ก็จะหายไปเองได้เมื่อเวลาผ่านไป

## บริเวณที่มักเกิดสิว

สิวไม่ได้เกิดขึ้นแต่ที่ใบหน้าเท่านั้น แต่สามารถเกิดขึ้นในที่อื่นๆได้ด้วย ซึ่งสิวแต่ละที่ก็มีสาเหตุการเกิดส่วนใหญ่ที่แตกต่างกันไป ดังนี้

### สิวที่คาง

![เป็นสิวที่คาง](${require('./สิวที่คาง.jpg')})

สิวที่คางส่วนใหญ่จะเกิดจากฮอร์โมน เมื่อฮอร์โมนเพิ่มขึ้น ต่อมไขมันผลิตน้ำมันมากขึ้นก็จะทำให้เกิดสิวที่คางได้มาก เนื่องจากคางเป็นส่วนหนึ่งของทีโซน (T - zone) หรือบริเวณบนใบหน้าที่จะผลิตน้ำมันออกมามากกว่าปกติ ทำให้เมื่อหน้าเป็นสิว จะพบบริเวณ T - zone เป็นหลัก ซึ่งได้แก่คาง จมูก และหน้าผากนั่นเอง

นอกจากนี้สิวที่คางยังเกิดจากสิ่งสกปรกได้มาก เนื่องจากคางเป็นตำแหน่งบนใบหน้าที่คนเรามักจับเล่นโดยไม่รู้ตัวเช่นเวลาเท้าคาง อีกทั้งคางยังเป็นตำแหน่งที่สัมผัสกับหน้ากากอนามัยโดยตรง เมื่อจำเป็นต้องใส่หน้ากากบ่อยๆจะทำให้สิ่งสกปรกสะสมจนรูขุมขนอุดตันและเกิดเป็นสิวในที่สุด

### สิวที่หน้าผาก

![เป็นสิวที่หน้าผาก](${require('./สิวที่หน้าผาก.jpg')})

สิวที่หน้าผากมักจะเกิดจากฮอร์โมนและความมันสะสม เช่นเดียวกับสิวที่คาง นอกจากเรื่องความมันแล้วหน้าผากก็เป็นบริเวณที่สัมผัสกับสิ่งสกปรกมาก เนื่องจากเป็นจุดที่มีเหงื่อเยอะ

อีกทั้งยังเป็นจุดที่ถ้าไว้ผมหน้าม้า ใส่หมวก หรือใส่ผ้าคาดศีรษะ ก็จะทำให้สิ่งสกปรก อย่างเหงื่อไคลและมลภาวะสะสมอยู่ที่หน้าผากมากขึ้น ทำให้เกิดสิวได้ง่ายกว่าบริเวณอื่นๆ

### สิวที่จมูก

![เป็นสิวที่จมูก](${require('./สิวที่จมูก.jpg')})

จมูกก็เป็นอีกส่วนหนึ่งที่อยู่บริเวณ T - zone ทำให้สิวที่จมูกสามารถเกิดขึ้นได้จากความมันที่มากเกินไป และเกิดจากฮอร์โมนได้เหมือนกับสิวที่คางและที่หน้าผาก

สิวที่จมูกส่วนใหญ่จะเป็นสิวอุดตันชนิดหัวเปิด และสิวเสี้ยน (Trichostasis Spinulosa) ซึ่งสิวเสี้ยนนี้ไม่ใช่สิว แต่เป็นความผิดปกติของรากขน ทำให้ในรูขุมขนนั้นมีขนขึ้นหลายเส้น และจับตัวกับน้ำมันจากต่อมไขมันรวมถึงสิ่งสกปรกและเซลล์ที่ตายแล้ว จนเกิดเป็นก้อนคล้ายสิวอยู่รอบๆเส้นขนเหล่านั้นอีกทีหนึ่ง

### สิวที่แก้ม

![เป็นสิวที่แก้ม](${require('./สิวที่แก้ม.jpg')})

ผู้ที่มีหน้าสิว หรือสิวขึ้นเยอะๆที่ใบหน้า มักจะมีสิวที่แก้มเยอะ เพราะสิวที่แก้มมักจะเกิดจากสิ่งสกปรกเป็นหลัก เนื่องจากแก้มเป็นบริเวณที่ต้องสัมผัสกับหลายสิ่ง ทั้งสัมผัสกับหมอนเวลานอน สัมผัสกับเส้นผม กรอบแว่น หรือสัมผัสกับโทรศัพท์ที่เป็นแหล่งรวมสิ่งสกปรกและเชื้อโรคต่างๆด้วย สิวที่แก้มสามารถเกิดได้จากฮอร์โมนหรือน้ำมันส่วนเกินได้เช่นกัน แต่จะน้อยกว่าพื้นที่ในกลุ่ม T - zone

### สิวที่ปาก

![เป็นสิวที่ปาก](${require('./สิวที่ปาก.jpg')})

สิวที่ปากมักจะอยู่บริเวณรอบๆริมฝีปาก เกิดจากสิ่งสกปรกได้มากเหมือนกันกับสิวที่แก้ม เพราะปากเป็นบริเวณที่เราใช้ทานข้าว เมื่ออาหารสัมผัสรอบๆปาก ก็อาจทำให้เกิดสิวได้หากรักษาความสะอาดได้ไม่ดีพอ

นอกจากนี้สิวที่ปากยังสามารถเกิดจากการแพ้ผลิตภัณฑ์ต่างๆได้ด้วย ทั้งยาสีฟัน ลิปสติก หรือน้ำยาบ้วนปาก บางครั้งก็อาจเกิดจากการใส่หน้ากากอนามัยเป็นเวลานานได้เช่นกัน

### สิวที่คอ

![เป็นสิวที่คอ](${require('./สิวที่คอ.jpg')})

สิวที่คอ เป็นปัญหาที่มักเกิดจากเส้นผมเป็นส่วนใหญ่ เพราะเป็นบริเวณที่สิ่งสกปรกจากเส้นผมและหนังศีรษะไหลมาสะสมอยู่ หากไว้ผมยาว ชอบปล่อยผม เส้นผมจะทำให้ผิวหนังอับ จนเหงื่อไคลสะสมและเกิดเป็นสิวขึ้นมา นอกจากนี้ยังอาจเกิดจากการแพ้ยาสระผม หรือผลิตภัณฑ์ที่ใช้กับเส้นผมและหนังศีรษะอื่นๆ ด้วย

### สิวที่หลัง

![เป็นสิวที่หลัง](${require('./สิวที่หลัง.jpg')})

สิวที่หลังสามารถเกิดได้จากทั้งความมัน ฮอร์โมน และเหงื่อไคล

สิวที่หลังสามารถเกิดจากเหงื่อไคลได้ง่ายกว่าที่อื่นๆ เนื่องจากเป็นที่ที่อยู่ในร่มผ้าตลอดเวลา บางครั้งเหงื่อไคล รวมถึงสิ่งสกปรก น้ำมันบนผิวหนัง และเซลล์ผิวที่ตายแล้ว อาจจับตัวกันจนสะสมเกิดเป็นสิวขึ้นมา ยิ่งคนที่อยู่ในที่ร้อน หรือสะพายเป้ติดหลังอยู่ตลอดเวลา จะยิ่งเกิดสิวที่หลังได้ง่ายมาก

นอกเหนือจากนี้ ตำแหน่งการเกิดสิวในที่ต่างๆสามารถเกิดจากกรรมพันธุ์ได้เช่นกัน เนื่องจากบริเวณที่ต่อมไขมันสร้างน้ำมันมาก การตอบสนองต่อฮอร์โมน ลักษณะการผลัดเซลล์ผิวในแต่ละบริเวณ สามารถกำหนดได้โดยลักษณะทางกรรมพันธุ์ทั้งหมด

## คําถามที่พบบ่อยเกี่ยวกับสิว

### สิวฮอร์โมน รักษาอย่างไร?

วิธีรักษาสิวฮอร์โมนไม่ได้ต่างจากการรักษาสิวทั่วไปเลย เนื่องจากสิวฮอร์โมนจะเกิดจากน้ำมันส่วนเกิด ทำให้สามารถเกิดได้ทั้งสิวไม่อักเสบ และสิวอักเสบ การรักษาจึงเป็นการรักษาตามประเภทของสิวที่เป็นมากกว่า

สิ่งที่สำคัญที่สุดของวิธีลดสิวฮอร์โมนคือควรใช้ผลิตภัณฑ์ควบคุมความมัน หรือยาที่ช่วยลดน้ำมันบนใบหน้าควบคู่ไปด้วย เพราะระดับฮอร์โมนมีผลอย่างมากต่อการผลิตน้ำมันของต่อมไขมัน ถ้าแก้ที่ระดับฮอร์โมนไม่ได้ ก็ต้องแก้ไขที่ความมันแทน เมื่อควบคุมความมันได้แล้ว สิวฮอร์โมนก็จะเกิดน้อยลงได้นั่นเอง

### สิวเห่อเต็มหน้า ทําไงดี?

สิวเห่อเต็มหน้า ควรเริ่มที่การหาสาเหตุ ถ้าสิวเห่อขึ้นมาเยอะมากแสดงว่าต้องการเกิดความผิดปกติบางอย่างกับผิวในบริเวณนั้น ให้ลองสังเกตตัวเองดูว่าช่วงก่อนเกิดสิวทำอะไรกับผิวหรือเปล่า จับผิวบ่อยไหม ล้างบ่อยๆ ถูกแรงๆ หรือเปลี่ยนครีมบำรุงผิวและเครื่องสำอางหรือเปล่า ถ้าใช่ก็ให้สงสัยไว้ก่อนว่านี่คือสาเหตุ

นอกจากพฤติกรรมที่เกี่ยวกับผิวแล้ว บางครั้งพฤติกรรมการนอน การกิน หรือความเครียดก็ทำให้เกิดสิวได้เช่นกัน แต่ถ้าสังเกตแล้วยังไม่รู้ว่าสิวที่เป็นเกิดจากอะไรก็สามารถปรึกษากับแพทย์ผิวหนังได้

เมื่อรู้สาเหตุแล้วก็ควรใช้วิธีลดสิวด้วยการปรับเปลี่ยนพฤติกรรมเพื่อหลีกเลี่ยงสาเหตุของการเกิดสิว ร่วมกับการรักษาสิว ถ้าเป็นสิวจำนวนมาก เป็นสิวหิน หรือเป็นสิวอักเสบรุนแรงอย่างสิวหัวช้างและสิวซีสต์ ก็ควรปรึกษาแพทย์เพื่อรักษาและให้แพทย์จ่ายยาให้ในกรณีที่จำเป็น

ซึ่งการปรึกษากับแพทย์ผ่านแอพฯ SkinX เอง แพทย์ก็สามารถจ่ายยาให้ได้เหมือนกับการไปพบแพทย์ตามปกติเลย

### ผิวไม่แข็งแรง เป็นสิวง่าย ดูแลอย่างไร?

สำหรับผู้ที่ผิวไม่แข็งแรง ผิวแพ้ง่าย เป็นสิวง่าย ต้องดูแลผิวให้ดีเป็นพิเศษ เพื่อป้องกันสิวและภาวะอื่นๆที่สามารถเกิดขึ้นได้กับผิวหนัง

วิธีสังเกตว่าผิวแพ้ง่ายหรือไม่ ให้ดูว่าเมื่อเปลี่ยนผลิตภัณฑ์ที่ใช้กับผิวหนังแล้ว ผิวแสบแดงง่ายไหม ถ้าใช่ก็มีแนวโน้มที่ผิวจะแพ้ง่าย นอกจากอาการแสบแดงแล้ว บางครั้งอาจจะผิวลอกเป็นขุยง่าย สิวขึ้นบ่อย หรือเป็นผื่นแพ้บ่อยครั้งเมื่อเปลี่ยนผลิตภัณฑ์ที่ใช้กับผิว

ผิวแพ้ง่ายบางครั้งไม่สามารถแก้ได้ เพราะอาจเป็นลักษณะของผิวที่ถูกกำหนดโดยพันธุกรรม แต่เราสามารถดูแลตนเองไม่ให้ผิวอ่อนแอกว่าเดิมได้ โดยการหลีกเลี่ยงสารเคมีก่ออาการแพ้ อย่างพวกสารกันบูด หรือน้ำหอมที่อยู่ในผลิตภัณฑ์เกี่ยวกับผิว ไม่ขัดผิว หรือใช้ผลิตภัณฑ์สครัปผิวบ่อยๆ

ควรหลีกเลี่ยงครีมที่มีสารเร่งการผลัดผิว อย่าง AHA หรือ AHB ใช้ครีมกันแดดเป็นประจำ และใช้ผลิตภัณฑ์สำหรับผิวแพ้ง่ายเป็นหลัก เพียงเท่านี้ก็สามารถดูแลผิวแพ้ง่ายให้ไม่เป็นสิวง่ายเท่าเดิมได้แล้ว

## สรุป

สิวเป็นความผิดปกติที่เกิดจากการอุดตันของรูขุมขน เป็นภาวะที่สามารถเกิดขึ้นได้กับทุกคน และเป็นความผิดปกติที่สามารถรักษาได้ การรักษาสิวอาจจะใช้เวลานานสักหน่อย แต่ถ้าปรึกษาแพทย์ผิวหนัง รักษาสิวอย่างถูกวิธี ดูแลตัวเองอย่างดี ทำตามที่แพทย์แนะนำอย่างเคร่งครัด สิวก็จะกลายเป็นเพียงเรื่องสิวๆ สำหรับคุณ

ทำเรื่องสิวให้เป็นเรื่องปกติ แก้ปัญหาสิวด้วยการปรึกษาแพทย์ผิวหนังเพื่อรักษาสิวกับแอพฯ SkinX สะดวก รวดเร็ว ปลอดภัย รอรับยาได้ที่บ้าน ดาวน์โหลดแอพฯ ได้ง่ายๆ แค่คลิ๊กเดียว!

ขอบคุณข้อมูลบางส่วนจาก

1.  [https://my.clevelandclinic.org/health/diseases/12233-acne#:~:text=Grade%201%20(mild)%3A%20mostly,along%20with%20occasionally%20inflamed%20nodules](https://my.clevelandclinic.org/health/diseases/12233-acne#:~:text=Grade%201%20(mild)%3A%20mostly,along%20with%20occasionally%20inflamed%20nodules).

2.  [https://my.clevelandclinic.org/health/diseases/21737-cystic-acne#:~:text=Cystic%20acne%20is%20a%20type,most%20severe%20type%20of%20acne](https://my.clevelandclinic.org/health/diseases/21737-cystic-acne#:~:text=Cystic%20acne%20is%20a%20type,most%20severe%20type%20of%20acne).

3.  [https://my.clevelandclinic.org/health/drugs/4812-corticosteroids](https://my.clevelandclinic.org/health/drugs/4812-corticosteroids)

4.  [https://my.clevelandclinic.org/health/diseases/21792-hormonal-acne](https://my.clevelandclinic.org/health/diseases/21792-hormonal-acne)

5.  [https://dermnetnz.org/topics/steroid-acne#:~:text=Steroid%20acne%20is%20the%20name,the%20use%20of%20anabolic%20steroids](https://dermnetnz.org/topics/steroid-acne#:~:text=Steroid%20acne%20is%20the%20name,the%20use%20of%20anabolic%20steroids).

6.  [https://www.mayoclinic.org/diseases-conditions/acne/symptoms-causes/syc-20368047](https://www.mayoclinic.org/diseases-conditions/acne/symptoms-causes/syc-20368047)

7.  [https://www.nhs.uk/conditions/acne/](https://www.nhs.uk/conditions/acne/)

8.  [https://www.niams.nih.gov/health-topics/acne](https://www.niams.nih.gov/health-topics/acne)

9.  [https://www.verywellhealth.com/nodular-acne-15817](https://www.verywellhealth.com/nodular-acne-15817)`
}
