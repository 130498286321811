<template>
  <div class="db-container">
    <div v-if="doctor && Object.keys(doctor).length">
      <ValidationObserver ref="formObserver">
        <div class="is-title">
          <span> ข้อมูลส่วนตัว </span>
          <div
            v-if="!isEdit"
            v-ga="
              $ga.commands.trackEvent.bind(this, 'Logout', 'Click', 'Logout', 1)
            "
            @click="$router.push({ name: 'Logout' })"
            class="edit-btn"
          >
            Logout
          </div>
        </div>
        <div class="divider" />
        <div class="content">
          <div v-if="!isEdit">
            <div v-if="doctor.picture" class="upload-img-profile-ctn">
              <img class="upload-img-profile" :src="doctor.picture" />
            </div>
            <img v-else src="@/assets/svg/avartar.svg" />
          </div>
          <div v-else class="upload-img-profile-ctn">
            <b-modal v-model="showCropper" :width="640"
              ><div class="modal-card cropper-card">
                <div class="cropper-wrapper">
                  <cropper
                    class="cropper"
                    :src="cropperImg"
                    ref="cropper"
                    :stencil-props="{
                      aspectRatio: 180 / 272,
                    }"
                  />
                </div>
                <div class="cropper-footer">
                  <b-button type="is-app-success" rounded @click="crop"
                    >ตกลง</b-button
                  >
                </div>
              </div>
            </b-modal>

            <b-upload
              v-model="form.pictureFile"
              accept="image/*"
              @input="(file) => showCropperModal(file)"
            >
              <div v-if="!form.pictureFile" class="upload-img-profile">
                <img v-if="doctor.picture" :src="doctor.picture" />
                <img v-else src="@/assets/img/avatar.png" />
                <div class="overlay">
                  <div class="outline">อัพโหลด</div>
                </div>
              </div>
              <div v-else class="upload-img-profile">
                <img :src="form.pictureImageData" class="preview" />
              </div>
            </b-upload>
          </div>
          <div v-if="isEdit" class="text-upload">
            upload profile picture with a gown in the white background
          </div>
          <div class="columns is-desktop">
            <div class="column is-4">Name</div>
            <div class="column value">
              {{ user.doctor.name }}
            </div>
          </div>
          <div class="columns is-desktop">
            <div class="column is-4">Phone Number</div>
            <div class="column value">
              {{ user.telNo }}
            </div>
          </div>
          <div class="columns is-desktop">
            <div class="column is-4">Email</div>
            <div class="column value">
              {{ user.email }}
            </div>
          </div>
          <div class="columns is-desktop">
            <div class="column is-4">Hospital</div>
            <div v-if="!isEdit" class="column value">
              {{ doctor.hospital.name }}
            </div>
            <div v-else>
              <ValidationProvider
                name="Hospital"
                :rules="{
                  required: true,
                  containIn: { array: hospitals.map((h) => h.name) },
                }"
                v-slot="{ errors }"
              >
                <b-autocomplete
                  v-model="hospitalInput"
                  :data="filteredHospitalData"
                  field="name"
                  clearable
                  rounded
                  open-on-focus
                  @select="onHospitalSelected"
                >
                  <template slot="empty"> No results found </template>
                </b-autocomplete>
                <span class="error-msg">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns is-desktop" v-if="isHospitalCanPorcedure">
            <div class="column is-4">ปรึกษาโรคผิวหนัง</div>
            <div v-if="!isEdit" class="column value">
              {{ doctor.isNormalCase ? 'Yes' : 'No' }}
            </div>
            <div v-else>
              <ValidationProvider
                name="isNormalCase"
                :rules="{
                  required: isHospitalCanPorcedure,
                }"
                v-slot="{ errors }"
              >
                <div class="column value">
                  <b-radio
                    v-model="form.isNormalCase"
                    name="isNormalCase"
                    :native-value="true"
                  >
                    Yes
                  </b-radio>
                  <b-radio
                    v-model="form.isNormalCase"
                    name="isNormalCase"
                    :native-value="false"
                  >
                    No
                  </b-radio>
                </div>
                <span class="error-msg">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns is-desktop" v-if="isHospitalCanPorcedure">
            <div class="column is-4">ปรึกษาด้านความงาม</div>
            <div v-if="!isEdit" class="column value">
              {{ doctor.isClinicCase ? 'Yes' : 'No' }}
            </div>
            <div v-else>
              <ValidationProvider
                name="isNormalCase"
                :rules="{
                  required: isHospitalCanPorcedure,
                }"
                v-slot="{ errors }"
              >
                <div class="column value">
                  <b-radio
                    v-model="form.isClinicCase"
                    name="isClinicCase"
                    :native-value="true"
                  >
                    Yes
                  </b-radio>
                  <b-radio
                    v-model="form.isClinicCase"
                    name="isClinicCase"
                    :native-value="false"
                  >
                    No
                  </b-radio>
                </div>
                <span class="error-msg">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns is-desktop" v-if="isHospitalCanPorcedure">
            <div class="column is-4">โปรแกรมที่รับปรึกษา</div>
            <div class="column value">
              <b-taglist class="tagContainer">
                <b-tag
                  v-for="(e, i) in form.procedureExpertises"
                  :key="i"
                  type="is-tag"
                  :closable="isEdit && !isDefaultExpertise(e)"
                  rounded
                  @close="closeProcedureExpertisesTag(i)"
                >
                  {{ e.name }}
                </b-tag>
              </b-taglist>
              <div v-if="isEdit">
                <ValidationProvider
                  name="Expertise of Procedure"
                  :rules="{
                    required: form.procedureExpertises.length === 0,
                    isNotEmpty: { array: form.procedureExpertises },
                  }"
                  v-slot="{ errors }"
                >
                  <b-autocomplete
                    ref="expertise-input"
                    v-model="procedureExpertiseInput"
                    :data="filteredProcedureExpertisesData"
                    field="name"
                    placeholder="เลือกได้มากกว่าหนึ่งอย่าง"
                    clearable
                    rounded
                    open-on-focus
                    clear-on-select
                    @select="onSelectedProcedureExpertises"
                  >
                    <template slot="empty"> No results found </template>
                  </b-autocomplete>
                  <span class="error-msg">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="columns is-desktop">
            <div class="column is-4">Sub specialty</div>
            <div class="column value" v-if="!isEdit">
              {{ doctor.specialty.name }}
            </div>
            <div v-else>
              <ValidationProvider
                name="Specialty"
                :rules="{
                  required: true,
                  containIn: { array: specialties.map((h) => h.name) },
                }"
                v-slot="{ errors }"
              >
                <b-autocomplete
                  v-model="specialtyInput"
                  :data="filteredSpecialtiesData"
                  field="name"
                  clearable
                  rounded
                  open-on-focus
                  @select="(option) => (form.specialtyId = option.id)"
                >
                  <template slot="empty"> No results found </template>
                </b-autocomplete>
                <span class="error-msg">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns is-desktop">
            <div class="column is-4">Area of Expertise</div>
            <div class="column value">
              <b-taglist class="tagContainer">
                <b-tag
                  v-for="(e, i) in form.expertises"
                  :key="i"
                  type="is-tag"
                  :closable="isEdit"
                  rounded
                  @close="closeExpertisesTag(i)"
                >
                  {{ e.name }}
                </b-tag>
              </b-taglist>
              <div v-if="isEdit">
                <ValidationProvider
                  name="Area of Expertise"
                  :rules="{
                    containIn: { array: expertises.map((h) => h.name) },
                  }"
                  v-slot="{ errors }"
                >
                  <b-autocomplete
                    ref="expertise-input"
                    v-model="expertiseInput"
                    :data="filteredExpertisesData"
                    field="name"
                    placeholder="เลือกได้มากกว่าหนึ่งอย่าง"
                    clearable
                    rounded
                    open-on-focus
                    clear-on-select
                    @select="onSelectedExpertises"
                  >
                    <template slot="empty"> No results found </template>
                  </b-autocomplete>
                  <span class="error-msg">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="columns is-desktop">
            <div class="column is-4">
              Education<br />(เรียงจากปีล่าสุดไปหลังสุด)
            </div>
            <div class="column value is-8">
              <div
                v-if="form.education.length === 0 && !isEdit"
                class="list-group"
              >
                <div class="list-group-item">ไม่มีข้อมูลการศึกษา</div>
              </div>
              <draggable
                tag="transition-group"
                :componentData="componentData"
                :list="form.education"
                class="list-group"
                draggable=".item"
                :animation="100"
                :move="() => isEdit"
                @start="dragging = true"
                @end="dragging = false"
              >
                <div
                  :class="{
                    'list-group-item': true,
                    item: true,
                    editing: isEdit,
                  }"
                  v-for="(e, i) in form.education"
                  :key="e.id"
                >
                  <!-- <div v-if="isEdit" class="prefix">
                      <img
                        src="@/assets/svg/icon_draggable.svg"
                        width="16px"
                        height="16px"
                      />
                    </div> -->
                  <div class="body">
                    <span v-if="!isEdit">{{ e.value }}</span>
                    <b-input
                      v-if="isEdit"
                      custom-class="input-text"
                      v-model="e.value"
                      expanded
                      type="textarea"
                      placeholder="เพิ่ม วุฒิบัตร,ความเชี่ยวชาญ"
                      ref="input-text"
                      rows="5"
                      @input="inputChange(i, $event)"
                      rounded
                    />
                  </div>
                  <div v-if="isEdit" class="suffix">
                    <img
                      src="@/assets/svg/icon_draggable.svg"
                      width="16px"
                      height="16px"
                    />
                  </div>
                  <div v-if="isEdit" class="suffix" @click="removeEducation(i)">
                    <img
                      src="@/assets/svg/remove-drug.svg"
                      width="16px"
                      height="16px"
                    />
                  </div>
                </div>

                <div
                  v-if="isEdit"
                  slot="footer"
                  class="list-group-item education-input-container"
                  role="group"
                  key="footer"
                >
                  <b-input
                    v-model="educationInput"
                    class="education-input"
                    expanded
                    custom-class="input-text-add"
                    type="textarea"
                    placeholder="เพิ่ม วุฒิบัตร,ความเชี่ยวชาญ"
                    ref="input-text-add"
                    rows="5"
                    @input="inputAddChange($event)"
                    rounded
                  />
                  <div class="add-btn" @click="addEducation">Add</div>
                </div>
              </draggable>
            </div>
          </div>
          <div class="columns is-desktop">
            <div class="column is-4">Doctor Fee</div>
            <div v-if="!isEdit" class="column value">
              Video call {{ form.fee.videoCall }} THB
            </div>
            <div v-if="!isEdit" class="column value">
              Chat {{ form.fee.chat }} THB
            </div>
            <div v-else class="column value">
              <div class="fee-containner">
                <b-switch
                  style="grid-area: switch"
                  type="is-success"
                  v-model="form.isVideoCall"
                  :true-value="true"
                  :false-value="false"
                >
                </b-switch>
                <div class="switch-label">Video call</div>
                <div class="price-containner">
                  <button
                    class="btn"
                    style="grid-area: btn1; align-self: end"
                    @click="onChangePrice(-50, 'videoCall')"
                  >
                    -
                  </button>
                  <input
                    type="text"
                    class="price-input"
                    :disabled="true"
                    :value="form.fee.videoCall"
                  />
                  <button
                    class="btn"
                    style="grid-area: btn2"
                    @click="onChangePrice(50, 'videoCall')"
                  >
                    +
                  </button>
                </div>
              </div>
              <div class="fee-containner">
                <b-switch
                  style="grid-area: switch"
                  type="is-success"
                  v-model="form.isChat"
                  :true-value="true"
                  :false-value="false"
                >
                </b-switch>
                <div class="switch-label">Chat</div>
                <div class="price-containner">
                  <button
                    class="btn"
                    style="grid-area: btn1; align-self: end"
                    @click="onChangePrice(-50, 'chat')"
                  >
                    -
                  </button>
                  <input
                    type="text"
                    class="price-input"
                    :disabled="true"
                    :value="form.fee.chat"
                  />
                  <button
                    class="btn"
                    style="grid-area: btn2"
                    @click="onChangePrice(50, 'chat')"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-desktop">
            <div class="column is-4">Online Schedule</div>
            <div class="column value">
              <div
                v-for="(day, key) in schedule"
                :key="key"
                class="day-of-week"
              >
                <div class="day-name">
                  {{ DAY_OF_WEEK_TEXT[key] }}
                </div>
                <div>
                  <div
                    v-for="t in day"
                    :key="`${DAY_OF_WEEK[i]}-${t.start}-${t.end}`"
                    class="time-container"
                  >
                    <div class="time">
                      {{
                        (t.start / HOURS_MILLISECONDS)
                          .toString()
                          .padStart(2, '0')
                      }}.00 -
                      {{
                        (t.end / HOURS_MILLISECONDS)
                          .toString()
                          .padStart(2, '0')
                      }}.00
                    </div>
                    <div
                      v-if="isEdit"
                      class="remove-time"
                      @click="removeTime(t)"
                    >
                      <img
                        src="@/assets/svg/remove-drug.svg"
                        width="16px"
                        height="16px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                   {
                    width: 'min-content';
                  }
                "
              >
                <div
                  v-if="isEdit"
                  class="time-add-container columns is-desktop is-tablet"
                >
                  <div class="time-add-item column">
                    <div class="item-title">Day</div>
                    <b-select rounded expanded v-model="inputDay">
                      <option v-for="d in DAY_OF_WEEK" :value="d" :key="d">
                        {{ DAY_OF_WEEK_TEXT[d] }}
                      </option>
                    </b-select>
                  </div>
                  <div class="time-add-item column">
                    <div class="item-title">Time Start</div>
                    <b-select rounded expanded v-model="inputStartTime">
                      <option
                        v-for="t in TIME_RANGE"
                        :value="t"
                        :key="`start-${t}`"
                      >
                        {{ t.toString().padStart(2, '0') }}.00
                      </option>
                    </b-select>
                  </div>
                  <div class="time-add-item column">
                    <div class="item-title">Time End</div>
                    <b-select rounded expanded v-model="inputEndTime">
                      <option
                        v-for="t in TIME_RANGE"
                        :value="t"
                        :key="`end-${t}`"
                      >
                        {{ t.toString().padStart(2, '0') }}.00
                      </option>
                    </b-select>
                  </div>
                  <div class="column">
                    <div class="add-btn" @click="addSchedule">Add</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <b-button
        type="is-app-success"
        rounded
        class="logout-btn"
        v-if="!isEdit"
        @click="onEdit"
      >
        แก้ไข
      </b-button>
      <div style="display: flex; justify-content: center" v-else>
        <b-button class="cancel-btn" @click="handleCancelEdit">
          ยกเลิก
        </b-button>
        <b-button :loading="isLoading" class="save-btn" @click="submit">
          บันทึก
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '@/utils/api'
// import VueSlideBar from 'vue-slide-bar'
import draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid'
import { DialogProgrammatic as Dialog } from 'buefy'
import { dataURItoBlob } from '@/utils/utils'
import { Cropper } from 'vue-advanced-cropper'
import Swal from 'sweetalert2'
import { DEFAULT_PROCEDURE_EXPERTISE_ID } from '@/utils/constants'

const mobileMatcher = window.matchMedia('(max-width: 768px)')

export default {
  name: 'DashboardPersonal',
  components: {
    // VueSlideBar,
    draggable,
    Cropper,
    // CalendarView,
    // CalendarViewHeader,
  },
  data() {
    return {
      showCropper: false,
      doctor: {},
      slider: {
        data: [250, 300, 350, 400, 450, 500, 550, 600, 650, 700],
        range: [
          { label: 250 },
          { label: 300, isHide: true },
          { label: 350, isHide: true },
          { label: 400 },
          { label: 450, isHide: true },
          { label: 500, isHide: true },
          { label: 550 },
          { label: 600, isHide: true },
          { label: 650, isHide: true },
          { label: 700 },
        ],
      },
      sliderTimeout: null,
      isEdit: false,
      hospitals: [],
      specialties: [],
      expertises: [],
      procedureExpertises: [],
      hospitalInput: '',
      specialtyInput: '',
      expertiseInput: '',
      procedureExpertiseInput: '',
      educationInput: '',
      dragging: false,
      isMobile: mobileMatcher.matches,
      componentData: {
        props: {
          type: 'transition',
          name: 'flip-list',
        },
      },
      form: {
        hospitalId: null,
        specialtyId: null,
        expertises: [],
        fee: {
          videoCall: 0,
          chat: 0,
        },
        pictureFile: null,
        pictureImageData: null,
        education: [],
        onlineTimesheet: [],
        procedureExpertises: [],
        isNormalCase: 'no',
        isClinicCase: 'no',
        minPerSlot: 30,
        canReserve: true,
        isVideoCall: true,
        isChat: false,
      },
      haveFutureAppointment: [],
      isLoading: false,
      DAY_OF_WEEK: [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ],
      DAY_OF_WEEK_TEXT: {
        monday: 'MON',
        tuesday: 'TUE',
        wednesday: 'WED',
        thursday: 'THU',
        friday: 'FRI',
        saturday: 'SAT',
        sunday: 'SUN',
      },
      HOURS_MILLISECONDS: 1000 * 60 * 60,
      TIME_RANGE: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
      inputDay: 'monday',
      inputStartTime: 8,
      inputEndTime: 8,
      cropperImg: null,
      chosenDate: '31 August 2018',
    }
  },
  computed: {
    ...mapState({
      user: 'user',
    }),
    schedule() {
      const groupByDay = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      }
      this.form.onlineTimesheet.forEach((t) => {
        groupByDay[t.dayOfWeek].push(t)
      })
      Object.keys(groupByDay).forEach((k) => {
        if (groupByDay[k].length === 0) {
          delete groupByDay[k]
        }
      })
      Object.values(groupByDay).forEach((d) =>
        d.sort((a, b) => a.start - b.start)
      )

      return groupByDay
    },
    filteredHospitalData() {
      return this.hospitals.filter(
        (hospital) =>
          hospital.name
            .toLowerCase()
            .indexOf(this.hospitalInput.toLowerCase()) >= 0
      )
    },
    filteredSpecialtiesData() {
      return this.specialties.filter(
        (specialty) =>
          specialty.name
            .toLowerCase()
            .indexOf(this.specialtyInput.toLowerCase()) >= 0
      )
    },
    filteredExpertisesData() {
      return this.expertises.filter((expertise) => {
        return (
          expertise.name
            .toLowerCase()
            .indexOf(this.expertiseInput.toLowerCase()) >= 0 &&
          !this.form.expertises.map((e) => e.id).includes(expertise.id)
        )
      })
    },
    filteredProcedureExpertisesData() {
      return this.procedureExpertises.filter((expertise) => {
        return (
          expertise.name
            .toLowerCase()
            .indexOf(this.procedureExpertiseInput.toLowerCase()) >= 0 &&
          !this.form.procedureExpertises.map((e) => e.id).includes(expertise.id)
        )
      })
    },
    isHospitalCanPorcedure() {
      return this.hospitals.find(
        (hospital) => hospital.id === this.form.hospitalId
      ).canProcedure
    },
  },
  async created() {
    ;[
      this.hospitals,
      this.specialties,
      this.expertises,
      this.procedureExpertises,
      this.doctor,
    ] = await Promise.all([
      api.getHospitals(),
      api.getSpecialties(),
      api.getExpertises(),
      api.getprocedureExpertises(),
      api.getDoctorById(this.user.doctor.id),
    ])

    this.setForm()
    mobileMatcher.addEventListener('change', this.onMobile)
  },
  unmounted() {
    mobileMatcher.removeEventListener('change', this.onMobile)
  },
  methods: {
    ...mapActions({
      getUser: 'getUser',
    }),
    onChangePrice(value, field) {
      if (this.form.fee[field] === 'free' && value > 0) {
        this.form.fee[field] = 250
      } else if (
        this.form.fee[field] !== 'free' &&
        (this.form.fee[field] < 1000 || value < 0)
      ) {
        this.form.fee[field] += value
        if (this.form.fee[field] < 250) {
          this.form.fee[field] = 'free'
        }
      }
    },
    onMobile(m) {
      if (m.matches) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    onEdit() {
      this.isEdit = true
      this.form.education.forEach((edu, i) => this.inputChange(i))
    },
    setForm() {
      this.hospitalInput = this.doctor.hospital.name
      this.specialtyInput = this.doctor.specialty.name
      this.form.hospitalId = this.doctor.hospital.id
      this.form.specialtyId = this.doctor.specialty.id
      this.form.education =
        this.doctor.education.map((edu) => ({ id: uuidv4(), value: edu })) ?? []
      this.$set(this.form, 'expertises', this.doctor.expertises)
      this.$set(
        this.form,
        'procedureExpertises',
        this.doctor.procedureExpertises
      )
      this.form.onlineTimesheet = this.doctor.onlineTimesheet
      this.form.isNormalCase = this.doctor.isNormalCase
      this.form.isClinicCase = this.doctor.isClinicCase
      this.form.fee.videoCall =
        this.doctor.fee?.find((fee) => fee.type === 'video call')?.price ||
        'free'
      this.form.fee.chat =
        this.doctor.fee?.find((fee) => fee.type === 'chat')?.price || 'free'
      this.form.isVideoCall = this.doctor.isVideoCall
      this.form.isChat = this.doctor.isChat
    },
    onInputFreeFee(val) {
      if (val) {
        this.form.fee = 0
        this.$ga.event({
          eventCategory: 'Doctor fee',
          eventAction: 'Click',
          eventLabel: 'Free',
        })
      } else {
        this.form.fee = this.doctor.fee || 250
        this.$ga.event({
          eventCategory: 'Doctor fee',
          eventAction: 'Click',
          eventLabel: this.form.fee,
        })
      }
    },
    onSelectedExpertises(option) {
      if (!option) return
      this.form.expertises.push(option)
    },
    onSelectedProcedureExpertises(option) {
      if (!option) return
      this.form.procedureExpertises.push(option)
    },
    closeExpertisesTag(index) {
      if (this.form.expertises.length === 1) {
        return
      }
      this.form.expertises.splice(index, 1)
    },
    closeProcedureExpertisesTag(index) {
      if (this.form.procedureExpertises.length === 1) {
        return
      }
      this.form.procedureExpertises.splice(index, 1)
    },
    showCropperModal(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.cropperImg = e.target.result
        this.showCropper = true
      }
      reader.readAsDataURL(file)
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult()
      this.showCropper = false
      this.cropperImg = null
      this.form.pictureImageData = canvas.toDataURL()
    },
    validateForm() {
      return new Promise((resolve) => {
        this.$refs.formObserver.validate().then((valid) => {
          if (!valid) {
            setTimeout(() => {
              const errors = Object.entries(this.$refs.formObserver.errors)
                .map(([key, value]) => ({ key, value }))
                .filter((error) => error.value.length)
              this.$refs.formObserver.refs[errors[0].key].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }, 100)
          }
          resolve(valid)
        })
      })
    },
    async submit() {
      try {
        this.isLoading = true
        const formValid = await this.validateForm()
        if (!formValid) {
          this.isLoading = false
          return
        }
        if (!this.isHospitalCanPorcedure) {
          this.form.isClinicCase = false
        }
        if (!this.isHospitalCanPorcedure) {
          this.form.isNormalCase = true
        }
        const formData = new FormData()
        Object.keys(this.form).forEach((key) => {
          if (
            ![
              'pictureFile',
              'pictureImageData',
              'expertises',
              'procedureExpertises',
              'education',
              'onlineTimesheet',
            ].includes(key)
          ) {
            if (key === 'fee') {
              this.$ga.event({
                eventCategory: 'Doctor fee',
                eventAction: 'Click',
                eventLabel: this.form.fee,
              })
              formData.append(
                'fee[]',
                JSON.stringify({
                  price:
                    this.form.fee.videoCall === 'free'
                      ? 0
                      : this.form.fee.videoCall,
                  type: 'video call',
                })
              )
              formData.append(
                'fee[]',
                JSON.stringify({
                  price: this.form.fee.chat === 'free' ? 0 : this.form.fee.chat,
                  type: 'chat',
                })
              )
            } else {
              formData.append(key, this.form[key])
            }
          } else if (key === 'expertises') {
            this.form.expertises.forEach((ex) => {
              formData.append('expertiseIds[]', ex.id)
            })
          } else if (key === 'education') {
            this.form.education.forEach((e) => {
              formData.append('education[]', e.value)
            })
          } else if (key === 'procedureExpertises') {
            this.form.procedureExpertises.forEach((ex) => {
              formData.append('procedureExpertiseIds[]', ex.id)
            })
          }
        })
        if (this.form.pictureImageData) {
          formData.append('picture', dataURItoBlob(this.form.pictureImageData))
          this.form.pictureFile = null
        }
        if (this.form.onlineTimesheet) {
          formData.append(
            'onlineTimesheet',
            JSON.stringify(this.form.onlineTimesheet)
          )
        }
        await api.putDoctorProfile(formData)
        ;[this.doctor] = await Promise.all([
          api.getDoctorById(this.user.doctor.id),
          this.getUser(),
        ])
        this.isEdit = false
        if (this.form.pictureImageData) {
          formData.append('picture', dataURItoBlob(this.form.pictureImageData))
        }
        if (this.form.onlineTimesheet) {
          formData.append(
            'onlineTimesheet',
            JSON.stringify(this.form.onlineTimesheet)
          )
        }
      } catch (e) {
        console.log(e.response.data.message)
        Swal.fire('Already booked !', e.response.data.message, 'error')
      }

      this.isLoading = false
      this.form.pictureFile = null
    },
    addEducation() {
      if (this.educationInput !== '') {
        this.form.education = [
          ...this.form.education,
          { id: uuidv4(), value: this.educationInput },
        ]
        this.educationInput = ''
      }
    },
    removeEducation(index) {
      this.form.education = [
        ...this.form.education.slice(0, index),
        ...this.form.education.slice(index + 1),
      ]
    },
    addSchedule() {
      const toAddValue = {
        dayOfWeek: this.inputDay,
        start: this.inputStartTime * this.HOURS_MILLISECONDS,
        end: this.inputEndTime * this.HOURS_MILLISECONDS,
      }
      if (
        !this.form.onlineTimesheet.find(
          (t) =>
            t.dayOfWeek === toAddValue.dayOfWeek &&
            ((t.start <= toAddValue.start && t.end >= toAddValue.start) ||
              (t.start <= toAddValue.end && t.end >= toAddValue.end))
        ) &&
        toAddValue.end > toAddValue.start
      ) {
        this.form.onlineTimesheet.push(toAddValue)
      } else {
        Dialog.alert({
          type: 'is-danger',
          message: '<div style="text-align:center;">กรอกเวลาไม่ถูกต้อง</div>',
          // hasIcon: true
        })
      }
    },
    removeTime(t) {
      this.form.onlineTimesheet = this.form.onlineTimesheet.filter(
        (x) => x !== t
      )
    },
    handleCancelEdit() {
      this.isEdit = false
      this.setForm()
    },
    inputChange(index) {
      setTimeout(() => {
        const inputDom = this.$refs['input-text'][index].$el.children[0]
        inputDom.style.cssText = 'height:auto;'
        const height = inputDom.scrollHeight + 2
        inputDom.style.cssText = 'height:' + height + 'px'
      }, 0)
    },
    inputAddChange() {
      setTimeout(() => {
        const inputDom = this.$refs['input-text-add'].$el.children[0]
        inputDom.style.cssText = 'height:auto;'
        const height = inputDom.scrollHeight + 2
        inputDom.style.cssText = 'height:' + height + 'px'
      }, 0)
    },
    isDefaultExpertise(expertise) {
      return DEFAULT_PROCEDURE_EXPERTISE_ID.includes(expertise.id)
    },
    onHospitalSelected(option) {
      this.form.hospitalId = option.id
      if (this.isHospitalCanPorcedure) {
        for (const procedure of DEFAULT_PROCEDURE_EXPERTISE_ID) {
          if (!this.form.procedureExpertises.find(e => e.id === procedure)) {
            this.form.procedureExpertises.push(this.procedureExpertises.find(e => e.id === procedure))
          }
        }
        this.form.isNormalCase = true
        this.form.isClinicCase = true
      }
    }
  },
}
</script>
<style>
.vicp-img {
  object-fit: contain;
}
.vicp-wrap {
  max-width: 100%;
}
@media only screen and (max-width: 768px) {
  .db-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .vicp-wrap {
    max-width: 100%;
    height: auto !important;
  }
}
</style>
<style lang="scss" scoped>
.db-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
}
.divider {
  width: 100%;
  height: 2px;
  background-color: #dbd6d3;
  margin: 15px 0;
}
.is-title {
  color: #3e4a59;
  font-size: 20px;
  display: flex;
  justify-content: space-between;

  .edit-btn {
    border-radius: 15px;
    border: 1px solid #34beb3;
    color: #34beb3;
    width: fit-content;
    display: inline-block;
    padding: 5px 25px;
    text-align: center;
    font-size: 13px;
    cursor: pointer;
  }
}
.save-btn {
  border-radius: 15px;
  background-color: #34beb3;
  color: #fff;
  display: inline-block;
  padding: 5px 25px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  width: 120px;
}
.cancel-btn {
  border-radius: 15px;
  background-color: #fff;
  color: #4a4a4a;
  display: inline-block;
  padding: 5px 25px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  margin-right: 10px;
  width: 120px;
}
.content {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  .avatar {
    width: fit-content;
    margin: 0 auto;
    margin-top: 40px;
    img {
      width: 190px;
      height: 280px;
      border-radius: 10px;
      object-fit: cover;
      background-color: #fff;
    }
  }
  .text-upload {
    width: 220px;
    margin: 10px auto 40px;
    text-align: center;
    color: #ff434e;
    font-weight: 100;
    font-size: 12px;
  }
  .value {
    color: #888888;
    font-weight: 100;
  }
}
.detail {
  margin-top: 30px;
}
.slider-custom-tooltip {
  width: 80px;
  padding: 5px;
  background-color: #fff;
  border-radius: 30px;
  margin-top: -10px;
  color: #3e4a59;
  text-align: center;
  font-size: 13px;
  span {
    width: 20px;
    height: 20px;
    position: absolute;
    background: white;
    transform: rotate(45deg);
    bottom: -20px;
    left: 30px;
    z-index: -1;
  }
}
.logout-btn {
  margin: 100px auto 0;
  display: block;
  width: 200px;
  height: 40px;
}
.upload-img-profile-ctn {
  width: 180px;
  height: 272px;
  display: block;
  margin: 0 auto 20px;
  overflow: hidden;
  border-radius: 10px;
  .upload-img-profile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 180px;
      height: 272px;
      border-radius: 10px;
      object-fit: cover;
    }
    .overlay {
      position: absolute;
      top: 0;
      width: 180px;
      height: 272px;
      border-radius: 10px;
      text-align: center;
      color: white;
      display: flex;
      align-items: self-end;
      justify-content: center;
      padding-bottom: 10px;
      cursor: pointer;
    }
    .preview {
      object-fit: cover;
      width: 180px;
      height: 272px;
      border-radius: 10px;
    }
  }
}
.education-list {
  margin-top: 0;
}
.education-input-container {
  display: flex;
  align-items: center;

  .education-input {
    flex-grow: 1;
  }
  .add-btn {
    margin-left: 13px;
  }
}
.add-btn {
  padding: 10px 16px;
  background: #34beb3;
  color: #fff;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  font-size: 14px;
  cursor: pointer;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.list-group {
  .list-group-item {
    padding: 14px 0;
    display: flex;

    &.editing {
      cursor: grab;
    }
    .prefix {
      margin-right: 8px;
      min-width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .body {
      flex-grow: 1;
    }
    .suffix {
      margin-left: 8px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 16px;
    }
  }
}
.tagContainer .tag {
  padding: 16px;
}
.body ::v-deep .input-text {
  border-radius: 20px;
  resize: none;
  padding: 11px 15px;
}
.education-input-container ::v-deep .input-text-add {
  border-radius: 20px;
  resize: none;
  padding: 11px 15px;
}

.day-name {
  width: 60px;
}

.day-of-week {
  display: flex;

  &:not(:first-child) {
    margin-top: 16px;
  }
}

.time-container {
  display: flex;
  &:not(:first-child) {
    margin-top: 8px;
  }

  .time {
    min-width: 150px;
  }

  .remove-time {
    cursor: pointer;
  }
}
.time-add-container {
  margin-top: 24px;
  margin-bottom: 24px !important;
  border-top: #dbd6d3 1px solid;
  padding-top: 14px;
  justify-content: flex-start;
  align-items: flex-end;

  .time-add-item {
    // margin-right: 16px;
    .item-title {
      margin-bottom: 8px;
      font-weight: 400;
    }
  }
}

.cropper {
  background: #ddd;
}

.cropper-card {
  width: auto;
  padding: 16px;
  background: white;
}

.cropper-wrapper {
  height: 380px;
  background: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cropper-footer {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outline {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 100px;
  font-size: 13px;
  font-weight: 200;
  background-color: #34beb3 !important;
}
.fee-containner {
  display: grid;
  grid-template-areas: 'switch label price';
  grid-template-rows: 35px;
  grid-template-columns: 1fr 2fr 2fr;
  align-items: center;
  width: fit-content;
}
.switch {
  grid-area: switch;
}
.switch-label {
  grid-area: label;
  font-weight: 600;
  color: #888888;
}
.price-containner {
  grid-area: price;
  display: grid;
  grid-template-areas: 'btn1 input btn2';
  grid-template-columns: 35px 1fr 35px;
  column-gap: 8px;
  justify-content: center;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  color: white;
  background: #34beb3;
  border: 1px solid #e2e2e2;
  font-weight: bold;
  border-radius: 5px;
}
.price-input {
  grid-area: input;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px;
  color: #888888 !important;
  text-align: center;
  font-size: 14px;
}

.column {
  padding-left: 0px !important;
}

/* Less than mobile */
@media only screen and (max-width: 768px) {
  .db-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .price-containner {
    grid-area: price;
    display: grid;
    grid-template-areas: 'btn1 input btn2';
    grid-template-columns: 35px 70px 35px;
    column-gap: 8px;
    justify-content: center;
  }
}

@media only screen and (max-width: 1023px) {
  .content .value {
    padding: 0;
  }
}
</style>
