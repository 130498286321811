export default {
  author: 'SkinX Team',
  title: 'Effalcar H Isobiome – Moisturizer for ACNE Adjunctive therapy and Rebalance Skin Microbiome',
  image: require('./image.jpg'),
  createdAt: new Date('2023-12-7'),
  content: `Moisturizer ที่ช่วยลดอาการระคายเคืองผิวหนังที่เกิดจากยารักษาสิว มีส่วนผสมของ Post-biotic: Aqua Posay Filiformis (APF) ช่วยปรับสมดุล Skin Microbiome หนึ่งในสาเหตุหลักของการเกิดโรคสิว และไม่มีสารในกลุ่ม AHA หรือ LHA เช่น Salicylic acid จึงเหมาะกับการให้ใช้คู่กับยารักษาสิวโดยไม่ทำให้เกิดการระคายเคืองเพิ่มเติม 
 
  Effaclar H Isobiome มีทั้ง Cream Moisturizer และ Cleanser (Lavant) โดย Effaclar H Isobiome Cream มีส่วนประกอบสำคัญดังนี้ 
  1. APF (Aqua Posay Filiformis) ช่วยปรับสมดุล Skin Microbiome
  2. Niacinamide : Anti-inflammation
  3. BIX’ACTIVE (Orellana Seed Extract-สารสกัดจากเมล็ดดอกคำแสด) – ลดความมัน และช่วยเรื่องของ Hyperkeratinization
  4. PROCERAD - ลดการอักเสบและรอยสิว
  5. Panthenol
  6. Glycerin
  7. La Roche-Posay Thermal Water Effaclar H Isobiome Cleanser (Lavant) มีส่วนประกอบสำคัญดังนี้ 1. APF + Mannose
  2. Niacinamide – Anti-inflammation
  3. BIX’ACTIVE (Orellana Seed Extract-สารสกัดจากเมล็ดดอกคำแสด) - ลดความมัน และช่วยเรื่อง Hyperkeratinization
  4. Shea Butter
  5. Glycerin
  6. La Rohce-Posay Thermal Water
   
  *Effaclar H Isobiome Cream และ Cleanser เป็นผลิตภัณฑ์ในกลุ่ม Hospital Exclusive มีจัดจำหน่ายที่โรงพยาบาลและ SkinX Store เท่านั้น
  
  ![Effalcar H Isobiome – Moisturizer for ACNE Adjunctive therapy and Rebalance Skin Microbiome](${require('./image.jpg')})`
}
