import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/database'

let config
let typingStatusDbURL
let roomDbURL

if (process.env.NODE_ENV === 'test') {
  config = {
    apiKey: 'AIzaSyA2PRvy5weAGa3VnyFB6IqO4qwJ0k5ROus',
    projectId: 'skinx-test',
    appId: '1:459897964821:web:a737833861409fef97011c',
    storageBucket: 'skinx-test.appspot.com'
  }
  typingStatusDbURL =
    'https://skin-x-test-chat-typing-status.asia-southeast1.firebasedatabase.app'
  roomDbURL = 'https://skinx-test-default-rtdb.firebaseio.com'
} else if (process.env.NODE_ENV === 'production') {
  config = {
    apiKey: 'AIzaSyDJzQDW4e2XbT0UOlw_LaB8dNHEKFsfgbs',
    projectId: 'skin-x-290402',
    appId: '1:801408285918:web:c98dfcf35060881d97d64f',
    storageBucket: 'skin-x-290402.appspot.com'
  }
  typingStatusDbURL =
    'https://skin-x-chat-typing-status.asia-southeast1.firebasedatabase.app'
  roomDbURL = 'https://skin-x-290402.firebaseio.com'
} else {
  config = {
    apiKey: 'AIzaSyDgcFR_PSnrmjXNrjuF7Q4Nms62jXIk0AU',
    projectId: 'luxderm-f502b',
    appId: '1:101233225961:web:1dbec4400082508dbb4078',
    storageBucket: 'luxderm-f502b.appspot.com'
  }
  typingStatusDbURL =
    'https://luxderm-chat-typing-status.asia-southeast1.firebasedatabase.app'
  roomDbURL = 'https://luxderm-f502b.firebaseio.com'
}

const app = firebase.initializeApp(config)
const db = app.firestore()
const auth = app.auth()
const storage = app.storage()
const roomDb = app.database(roomDbURL)
const typingDb = app.database(typingStatusDbURL)

export { app, auth, db, storage, typingDb, roomDb }
