import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

// Install all rules
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('containIn', {
  params: ['array'],
  validate: (value, { array }) => {
    return array.includes(value)
  },
  message: 'The value must be contain in the list.'
})

extend('passwordStrength', {
  params: ['array'],
  validate: (value) => {
    // At least 8 characters and includes lowwercase, uppercase, number
    const atLeast8Char = new RegExp('^(?=.{8,})')
    const atLeast1Lowwercase = new RegExp('^(?=.*[a-z])')
    const atLeast1Uppercase = new RegExp('^(?=.*[A-Z])')
    const atLeast1number = new RegExp('^(?=.*[0-9])')
    if (!atLeast8Char.test(value)) {
      return 'Password must have at least 8 characters'
    }
    if (!atLeast1Lowwercase.test(value)) {
      return 'Password must have at least 1 lowwercase'
    }
    if (!atLeast1Uppercase.test(value)) {
      return 'Password must have at least 1 uppercase'
    }
    if (!atLeast1number.test(value)) {
      return 'Password must have at least 1 number'
    }
    return true
  }
})

extend('checked', {
  validate: (value) => {
    return value === true
  }
})

extend('isNotEmpty', {
  params: ['array'],
  validate: (value, { array }) => {
    return array.length > 0 || value.length !== 0
  }
})
