export default {
  author: 'SkinX Team',
  title: 'Welcome Back Package for You',
  image: require('./comback.png'),
  createdAt: new Date('2023-01-20'),
  content: `SkinX ขอเชิญชวนแพทย์ทุกท่านร่วมกิจกรรม Take care of yourself & Welcome back to SkinX  

  ต้อนรับหมอผิวที่ออฟไลน์กลับมาออนไลน์และให้คำปรึกษากับผู้ที่มีปัญหาผิวหนังในแอป SKinX ด้วยแพ็กเกจสุดพิเศษ 
  
  Promote Doctor on SkinX Channel - ทำการโปรโมทและประชาสัมพันธ์หมอผิวในทุกช่องทาง Social Media ของ SkinX เพื่อให้ผู้รับบริการรับทราบและเลือกเข้ามารับบริการได้ 
  
  CODE (ไม่ลับ)ปรึกษาแพทย์ - โค้ดพิเศษสำหรับให้คนไข้ได้ปรึกษาคุณหมอแต่ละท่านแบบไม่มีค่าใช้จ่าย 
  
   
  
  สำหรับแพทย์ท่านไหนที่สนใจกลับมาออนไลน์ พร้อมรับแพ็กเกจสุดพิเศษนี้🧑สามารถติดต่อได้ผ่านไลน์ “NICE:)” หรือแอดไลน์ ID : 2morrow_tk ได้เลยนะครับผม พร้อมยินดีให้การดูแลแพทย์ทุกท่านครับ 

  ![Welcome Back](${require('./comback.png')})`
}
