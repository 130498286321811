<template>
  <div>
    <!-- Start Select Drug -->
    <div
      v-show="!record.completed && !isTeleNotRecommended && !isPreview"
      class="white-box"
    >
      <div class="label">
        {{ titleSearch }}
      </div>
      <div class="search">
        <div class="search-input">
          <b-autocomplete
            ref="drugSearchInput"
            v-model="drugSearchInput"
            v-debounce:300ms="searchDrugs"
            :data="drugList"
            :disabled="record.completed || isTeleNotRecommended"
            :loading="isDrugSearchInputLoading"
            rounded
            open-on-focus
            clear-on-select
            @select="addDrug"
          >
            <template slot-scope="props">
              <div>
                {{ props.option.tradeName }}
                {{ props.option.isOnline ? '[online]' : '' }}
                <div
                  class="drug-generic-name"
                  v-if="props.option.drugItemType !== 2"
                >
                  {{ props.option.name }}
                </div>
              </div>
            </template>
            <template slot="empty"> No results found </template>
          </b-autocomplete>
        </div>
        <div class="search-ic">
          <img src="@/assets/svg/search-white.svg" />
        </div>
      </div>
      <div
        v-show="!record.completed && !isTeleNotRecommended && drugGroups"
        id="drug-group-selector"
        class="drug-group drug-group-selector"
      >
        <VueSlickCarousel v-bind="carouselSettings" ref="carouselDrugGroup">
          <template #prevArrow>
            <img
              src="@/assets/svg/prev-arrow.svg"
              @click="showPrev('carouselDrugGroup')"
            />
          </template>
          <template #nextArrow>
            <img
              src="@/assets/svg/next-arrow.svg"
              @click="showNext('carouselDrugGroup')"
            />
          </template>
          <div
            v-for="(dg, i) in drugGroups"
            :key="i"
            :class="[
              'dg-tag',
              { 'dg-tag-active': drugGroupActiveId === dg.id }
            ]"
            @click="filterByDrugGroup(dg.id)"
          >
            {{ dg.name }}
          </div>
        </VueSlickCarousel>
      </div>
    </div>
    <!-- End Select Drug -->

    <!-- Start Select Drug Set -->
    <div
      v-if="
        !record.completed && !isTeleNotRecommended && !isPreview && isDrugMode
      "
      class="white-box"
    >
      <div class="drug-set-header">
        <div class="label">
          Drug set (customise by doctor)
        </div>
        <div style="display: flex">
          <button
            class="btn-add-drug-set mr-2"
            @click="
              $router.push({
                name: 'DrugSet',
                params: {
                  id: 'create',
                  previousPage: {
                    name: $router.history.current.name,
                    params: $router.history.current.params
                  }
                }
              })
            "
          >
            <img class="mr-2" src="@/assets/svg/add-white.svg" />
            Add Drug Set
          </button>
          <button
            class="btn-edit-drug-set"
            @click="$router.push({ name: 'DashboardDrugSet' })"
          >
            Edit
          </button>
        </div>
      </div>
      <div
        v-if="!record.completed && !isTeleNotRecommended && drugSet.length"
        id="drug-set-selector"
        class="drug-group drug-group-selector"
      >
        <VueSlickCarousel v-bind="carouselSettings" ref="carouselDrugSet">
          <template #prevArrow>
            <img
              src="@/assets/svg/prev-arrow.svg"
              @click="showPrev('carouselDrugSet')"
            />
          </template>
          <template #nextArrow>
            <img
              src="@/assets/svg/next-arrow.svg"
              @click="showNext('carouselDrugSet')"
            />
          </template>
          <div
            v-for="ds in drugSet"
            :key="ds.id"
            class="dg-tag"
            @click="addDrugSet(ds)"
          >
            {{ ds.name }}
          </div>
        </VueSlickCarousel>
      </div>
    </div>
    <!-- End Select Drug Set -->

    <!-- Start Table -->
    <div class="table-box">
      <b-field :label="titleTable" />
      <div
        id="medical-report-drugs-table"
        class="table-content"
        ref="drugsCart"
      >
        <b-table :data="drugs && drugs.length ? drugs : []" hoverable>
          <b-table-column
            v-slot="props"
            field="name"
            label="Drug Name"
            header-class="drug-table-column-name"
          >
            {{ props.row.tradeName }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="frequencyId"
            label="Frequency *"
            header-class="drug-table-column-amount"
            v-if="isDrugMode"
          >
            <div v-if="isPreview || record.completed">
              <span>
                {{
                  (drugs[props.index].usedPrefixId &&
                    drugPrefix.find(
                      f => f.id === drugs[props.index].usedPrefixId
                    ).captionTh) ||
                    ''
                }}</span
              >
              <span v-if="drugs[props.index].type === 'Oral'">
                {{ drugs[props.index].frequencyAmount }}
                {{
                  (drugs[props.index].usedUnitId &&
                    drugUnit.find(f => f.id === drugs[props.index].usedUnitId)
                      .captionTh) ||
                    ''
                }}
              </span>
              <span>
                {{
                  (drugs[props.index].frequencyId &&
                    frequencies.find(
                      f => f.id === drugs[props.index].frequencyId
                    ).caption) ||
                    ''
                }}</span
              >
              <span>
                {{
                  (drugs[props.index].frequencyTimeId &&
                    frequenciesTimes.find(
                      f => f.id === drugs[props.index].frequencyTimeId
                    ).caption) ||
                    ''
                }}</span
              >
            </div>
            <div
              class="frequency-selector-box"
              v-if="!isPreview && !record.completed"
            >
              <div v-if="!isPreview && !record.completed">
                <b-select
                  rounded
                  v-model="drugs[props.index].usedPrefixId"
                  :disabled="isTeleNotRecommended"
                  @blur="drugTableChanged(props.index)"
                >
                  <option
                    v-for="prefix in record.completed
                      ? [...drugPrefix, { id: null, name: '-' }]
                      : drugPrefix"
                    :value="prefix.id"
                    :key="prefix.id"
                  >
                    {{ prefix.name }}
                  </option>
                </b-select>
              </div>
              <div class="freq-unit-amount">
                <ValidationProvider
                  v-if="drugs[props.index].type === 'Oral'"
                  :name="`Frequency amount_${props.index}`"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-input
                    v-model="drugs[props.index].frequencyAmount"
                    :disabled="isTeleNotRecommended"
                    type="number"
                    @blur="drugTableChanged(props.index)"
                    rounded
                  />
                  <span v-show="errors[0]" class="error-msg">{{
                      errors[0] ? errors[0].replace(`_${props.index}`, ``) : ''
                    }}</span>
                </ValidationProvider>
                <ValidationProvider
                  v-if="drugs[props.index].type === 'Oral'"
                  :name="`Unit_${props.index}`"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="unit" v-if="!isPreview && !record.completed">
                    <b-select
                      rounded
                      v-model="drugs[props.index].usedUnitId"
                      :disabled="isTeleNotRecommended"
                      @blur="drugTableChanged(props.index)"
                    >
                      <option
                        v-for="unit in record.completed
                        ? [...drugUnit, { id: null, name: '-' }]
                        : drugUnit"
                        :value="unit.id"
                        :key="unit.id"
                      >
                        {{ unit.name }}
                      </option>
                    </b-select>
                  </div>
                  <span v-show="errors[0]" class="error-msg">{{
                      errors[0] ? errors[0].replace(`_${props.index}`, ``) : ''
                    }}</span>
                </ValidationProvider>
              </div>
              <div class="frequency-selector">
                <ValidationProvider
                  :name="`Frequency${props.index}`"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-select
                    rounded
                    v-model="drugs[props.index].frequencyId"
                    :disabled="isTeleNotRecommended"
                    @blur="drugTableChanged(props.index)"
                  >
                    <option v-for="f in frequencies" :value="f.id" :key="f.id">
                      {{ f.name }}
                    </option>
                  </b-select>
                  <span v-show="errors[0]" class="error-msg">{{
                      errors[0] ? errors[0].replace(`_${props.index}`, ``) : ''
                    }}</span>
                </ValidationProvider>
              </div>

              <div class="">
                <b-select
                  rounded
                  v-model="drugs[props.index].frequencyTimeId"
                  :disabled="isTeleNotRecommended"
                  @blur="drugTableChanged(props.index)"
                >
                  <option
                    v-for="ft in record.completed
                      ? [...frequenciesTimes, { id: null, name: '-' }]
                      : frequenciesTimes"
                    :value="ft.id"
                    :key="ft.id"
                  >
                    {{ ft.name }}
                  </option>
                </b-select>
              </div>
            </div>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="duration"
            label="Duration *"
            header-class="drug-table-column-amount"
            v-if="isDrugMode"
          >
            <div v-if="isPreview || record.completed">
              <span>
                {{
                  drugs[props.index].duration !== 'Continue' &&
                  drugs[props.index].duration !== 0
                    ? drugs[props.index].duration + ' วัน'
                    : 'Continue'
                }}</span
              >
            </div>
            <ValidationProvider
              :name="`Duration_${props.index}`"
              rules="required"
              v-slot="{ errors }"
              v-if="!isPreview && !record.completed"
            >
              <b-dropdown
                v-if="!record.completed"
                aria-role="list"
                position="is-top-right"
                append-to-body
                :disabled="record.completed || isTeleNotRecommended"
                @blur="drugTableChanged(props.index)"
              >
                <button
                  class="button is-rounded duration-text"
                  slot="trigger"
                  slot-scope="{ active }"
                >
                  <span v-if="drugs[props.index].duration">
                    {{ drugs[props.index].duration }}
                    <span v-if="drugs[props.index].duration !== 'Continue'">
                      วัน
                    </span>
                  </span>
                  <span v-else-if='drugs[props.index].duration === 0 || drugs[props.index].duration === "Continue"'> Continue </span>
                  <span v-else> เลือกระยะเวลา </span>
                  <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                </button>
                <b-dropdown-item
                  custom
                  :focusable="false"
                  aria-role="listitem"
                  class="dropdown-duration-item"
                >
                  <b-input
                    rounded
                    v-model="drugs[props.index].duration"
                    type="number"
                    class="dropdown-duration-input"
                  />
                  <span> วัน </span>
                </b-dropdown-item>
                <b-dropdown-item
                  ria-role="listitem"
                  class="dropdown-duration-item"
                  @click="drugs[props.index].duration = 'Continue'"
                >
                  Continue
                </b-dropdown-item>
              </b-dropdown>
              <span v-show="errors[0]" class="error-msg">{{
                  errors[0] ? errors[0].replace(`_${props.index}`, ``) : ''
                }}</span>
            </ValidationProvider>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="total"
            label="Total Qty. *"
            header-class="drug-table-column-amount"
            centered
          >
            <div v-if="isPreview || record.completed">
              <span> {{ drugs[props.index].qty }}</span>
            </div>
            <ValidationProvider
              :name="`Total_${props.index}`"
              rules="required"
              v-slot="{ errors }"
              class="table-column-total"
              v-if="!isPreview && !record.completed"
            >
              <b-input
                v-model="drugs[props.index].qty"
                :disabled="record.completed || isTeleNotRecommended"
                type="number"
                @blur="drugTableChanged(props.index)"
                rounded
              />
              <span class="unit">{{ drugs[props.index].unit }}</span>
              <span v-show="errors[0]" class="error-msg">{{
                  errors[0] ? errors[0].replace(`_${props.index}`, ``) : ''
                }}</span>
            </ValidationProvider>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="instruction"
            label="Note"
            header-class="drug-table-column-amount"
            centered
          >
            <div v-if="isPreview || record.completed">
              <span> {{ drugs[props.index].instruction || '-' }}</span>
            </div>
            <b-input
              v-if="!isPreview && !record.completed"
              :value="
                record.completed
                  ? drugs[props.index].instruction || '-'
                  : drugs[props.index].instruction
              "
              :disabled="record.completed"
              type="text"
              rounded
              @click.native="openInstructionModal(props.index)"
            />
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="price"
            label="Price"
            header-class="drug-table-column-amount"
            centered
          >
            <div>
              <span>
                {{ drugs[props.index].price | numberWithCommas || '-' }}.-</span
              >
            </div>
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="amount"
            label="Total"
            header-class="drug-table-column-amount"
            centered
          >
            <div>
              <span>
                {{
                  drugs[props.index].price * drugs[props.index].qty ||
                    '0' | numberWithCommas
                }}.-</span
              >
            </div>
          </b-table-column>
          <b-table-column
            :visible="!record.completed && !isPreview"
            v-slot="props"
            field="close"
            header-class="drug-table-column-remove"
            centered
          >
            <img
              src="@/assets/svg/remove-drug.svg"
              class="remove-drug-img"
              @click="removeDrug(props.index)"
            />
          </b-table-column>
          <template slot="empty">
            <div>Drug basket is empty.</div>
          </template>
        </b-table>
      </div>
    </div>
    <!-- End Table -->
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import api from '@/utils/api'
import MedicalReportInstructionModal from '@/components/MedicalReportInstructionModal'

export default {
  components: {
    VueSlickCarousel
  },
  props: {
    label: {
      type: String
    },
    isTeleNotRecommended: {
      type: Boolean
    },
    drugList: {
      type: Array,
      default: () => []
    },
    drugGroups: {
      type: Array,
      default: () => []
    },
    record: {
      type: Object
    },
    drugs: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: () => 'drug'
    },
    isPreview: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    isDrugMode() {
      return this.mode === 'drug'
    },
    isOperativesMode() {
      return this.mode === 'operatives'
    },
    titleSearch() {
      if (this.isDrugMode) {
        return 'Drug and Skin cares (Suggestion)'
      }
      if (this.isOperativesMode) {
        return 'Procedure'
      }
      return 'No Title'
    },
    titleTable() {
      if (this.isDrugMode) {
        return 'Medicine basket'
      }
      if (this.isOperativesMode) {
        return 'Procedure basket'
      }
      return 'No Title'
    }
  },
  data() {
    return {
      frequencies: [],
      frequenciesTimes: [],
      drugGroupActiveId: null,
      isDrugSearchInputLoading: false,
      drugUnit: [],
      drugPrefix: [],
      drugSearchInput: '',
      carouselSettings: {
        dots: false,
        variableWidth: true,
        swipeToSlide: true,
        draggable: true,
        infinite: false
      },
      drugSet: []
    }
  },
  async created() {
    const freqs = await api.getFrequencies()
    this.drugUnit = await api.getDrugUnit()
    this.drugPrefix = await api.getDrugPrefix()
    this.frequencies = freqs.frequencies
    this.frequenciesTimes = freqs.times
    if (this.isDrugMode) {
      const drugSet = await api.getDrugSet()
      this.drugSet = drugSet.data
    }
  },
  methods: {
    showPrev(ref) {
      this.$refs[ref].prev()
    },
    showNext(ref) {
      this.$refs[ref].next()
    },
    drugTableChanged(index) {
      const drug = {
        ...this.drugs[index],
        usedUnit: this.drugUnit.find(p => p.id === this.drugs[index].usedUnitId),
        usedPrefix: this.drugPrefix.find(p => p.id === this.drugs[index].usedPrefixId),
        frequency: this.frequencies.find(p => p.id === this.drugs[index].frequencyId),
        frequencyTime: this.frequenciesTimes.find(p => p.id === this.drugs[index].frequencyTimeId)
      }
      this.$emit('drugTableChanged', {
        isDrugTable: true,
        index,
        drug: this.isDrugMode ? drug : null,
        operative: this.isDrugMode ? null : drug
      })
    },
    removeDrug(index) {
      this.$emit('removeDrug', index)
    },
    addDrug(option) {
      console.log('option', option)
      this.$emit('addDrug', option)
    },
    filterByDrugGroup(drugGroupId) {
      if (this.drugGroupActiveId === drugGroupId) {
        this.drugGroupActiveId = null
        this.searchDrugs()
      } else {
        this.drugGroupActiveId = drugGroupId
        this.searchDrugs()
        this.$refs.drugSearchInput.focus()
      }
    },
    searchDrugs() {
      this.$emit('searchDrugs', this.drugSearchInput, this.drugGroupActiveId)
    },
    openInstructionModal(index) {
      if (this.record.completed) {
        return
      }
      this.$buefy.modal.open({
        component: MedicalReportInstructionModal,
        props: {
          input: this.drugs[index].instruction
        },
        events: {
          submit: value => {
            this.drugs[index].instruction = value
            this.drugTableChanged()
          }
        }
      })
    },
    addDrugSet(ds) {
      let added = 0
      for (const item of ds.drugSetItem) {
        const drugItem = item.drugItem
        const drug = drugItem.drug
        added++
        this.$emit(
          'addDrug',
          {
            ...drugItem,
            ...drug,
            frequencyId: item.frequencyId,
            frequency: item.frequency,
            usedPrefixId: item.usedPrefixId,
            usedPrefix: item.usedPrefix,
            usedUnitId: item.usedUnitId,
            usedUnit: item.usedUnit,
            frequencyTimeId: item.frequencyTimeId,
            frequencyTime: item.frequencyTime,
            type: drug.type,
            unit: item.unit,
            name: drug.name,
            price: drugItem.sellingPrice,
            tagPrice: drugItem.tagPrice,
            tradeName: drugItem.name,
            duration: item.duration === 0 ? 'Continue' : item.duration,
            qty: item.qty,
            drugItemId: drugItem.id,
            note: item.note,
            instruction: item.note,
            id: drugItem.id
          },
          added === ds.drugSetItem.length
        )
      }
    }
  }
}
</script>

<style lang="scss">
.drug-table-column-name {
  width: 200px;
  min-width: 200px;
}
.drug-table-column-amount {
  width: 140px;
  min-width: 140px;
}
.drug-table-column-remove {
  width: 60px;
  min-width: 60px;
}
</style>

<style lang="scss" scoped>
.white-box {
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 20px 30px 30px;
  margin-bottom: 30px;

  .symptom-img {
    border-radius: 10%;
    cursor: zoom-in;
  }
}
.search {
  display: flex;
  .search-input {
    display: inline-block;
    width: calc(100% - 40px - 10px);
  }
  .search-ic {
    width: 40px;
    height: 40px;
    background-color: #d0a488;
    display: inline-block;
    border-radius: 50%;
    margin-left: 10px;
    text-align: center;
    padding: 10px;
  }
}

.table-box {
  margin-top: 40px;
  .table-content {
    padding: 0px;
    border-radius: 20px;
    background: white;
    overflow: auto;

    .duration-text {
      font-weight: lighter;
    }
    .remove-drug-img {
      margin: 3px auto 0;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
    .frequency-selector-box {
      height: fit-content;
      display: flex;
      justify-content: flex-end;
      .frequency-selector {
        width: auto;
      }
      .frequency-selector:first-child {
        margin-right: 10px;
      }
    }
  }
}
.follow-up-box {
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    line-height: 40px;
    vertical-align: middle;
  }
  .fl-input {
    width: fit-content;
    margin-left: 15px;
  }
}
.drug-group {
  width: 94%;
  margin: 25px auto 0;
  .dg-tag {
    border-radius: 22px;
    border: solid 1px #979797;
    color: #979797;
    padding: 5px 20px;
    outline: none;
    cursor: pointer;
  }
  .dg-tag-active {
    border: solid 1px #d8a283;
    color: #fff;
    background-color: #d8a283;
  }
}
.drug-set-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
@media only screen and (max-width: 768px) {
  .drug-set-header {
    flex-direction: column;
  }
}
.btn-add-drug-set {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 22px;
  border: none;
  color: #ffffff;
  background: #d0a488;
  padding: 5px 20px;
  outline: none;
  cursor: pointer;
  height: 36px;
}
.btn-edit-drug-set {
  display: inline-flex;
  align-items: center;
  border-radius: 22px;
  border: solid 1px #979797;
  color: #979797;
  background: #ffffff;
  padding: 5px 20px;
  outline: none;
  cursor: pointer;
  height: 36px;
}
</style>
