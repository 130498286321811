export default {
  author: 'SkinX Team',
  title: 'Summer’s Month in SkinX ท้าหน้าร้อนด้วยความรู้จากหมอผิว',
  image: require('./review.jpeg'),
  createdAt: new Date('2023-03-28'),
  content: `SkinX ขอส่งต่อความรู้สึกดี ๆ ด้วยการแบ่งปันการแสดงความคิดเห็นและคำชื่นชมจากผู้รับบริการหลังจากได้รับคำปรึกษาปัญหาผิวจากคุณหมอผิวหนังประจำ SkinX

  ขอขอบคุณที่ท่านเข้ามาเป็นส่วนหนึ่งที่สำคัญกับ SkinX และเราสัญญาว่าจะดูแลท่านเป็นอย่างดีให้สมกับที่ท่านเข้ามาเป็นกำลังสำคัญให้กับเรา
  
  #แอปฯหมอผิว #SkinXThailand #ดูแลผิวดูแลคุณอย่างเข้าใจ

  ![SkinX Doctor Review](${require('./review.jpeg')})`
}
