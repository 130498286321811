export default {
  author: 'SkinX Team',
  title: 'SkinX Sharing Your Love ขยายความรัก แชร์ความรู้ดูแลผิวรับเดือนแห่งความรักกับหมอผิว SkinX',
  image: require('./love.png'),
  createdAt: new Date('2023-02-14'),
  content: `ต้อนรับเดือนแห่งความรัก SkinX ชวนคุณหมอผิวมาเติมความหวานให้ทุกคนเลิฟผิวยิ่งกว่าที่เคย ผ่านกิจกรรม SkinX Sharing Your Love!

 

  ขอเชิญชวนคุณหมอมาร่วมแบ่งปันสาระความรู้ รวมไปถึงเคล็ดลับในการดูแลผิวตามหัวข้อต่าง ๆ ที่ท่านสนใจ เพื่อส่งต่อประโยชน์ดี ๆ ให้กับเหล่าคนรักผิวผ่านทุกแพลตฟอร์มของ SkinX
  
   
  
  โดยสามารถเลือกรูปแบบคอนเทนต์ได้ตามที่ท่านถนัด ไม่ว่าจะเป็น
  
  เขียนบทความสั้น ๆ เพื่อส่งต่อให้กับทีม SkinX นำไปต่อยอดและจัดทำภาพประกอบ พร้อมให้เครดิตคอนเทนต์สาระดี ๆ จากท่าน
  
   
  
  อัดคลิปแนะนำความรู้ตามสไตล์สาย TikTok โดยทางทีม SkinX สามารถช่วยท่านตัดต่อคลิปวิดีโอได้
  
   
  
  แล้วมาร่วมสนุกกันนะครับบบบ :))

  ![SkinX Sharing Your Love](${require('./love.png')})`
}
