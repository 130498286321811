<template>
  <div class="db-container" ref="con">
    <div>
      <div class="notification-explan">
        <div class="title-text">แจ้งเตือน</div>
        <div class="status-row">
          <div class="text-status">
            Status
          </div>
          <div style="margin-top: 4px;margin-bottom: 4px;display:flex">
            <div class="status">
              <div class="dot booked"></div>
              <div class="subtitle2">Booked</div>
            </div>
            <div class="status" style="margin-left:20px">
              <div class="dot available"></div>
              <div class="subtitle2">Available</div>
            </div>
            <div class="status" style="margin-left:20px">
              <div class="dot"></div>
              <div class="subtitle2">Waiting confirm</div>
            </div>
          </div>
        </div>
      </div>
      <div class="divider" />
      <DoctorCalendarWeek
        style="margin-bottom:40px"
        :data="timeSlot"
        :date="date"
        :doctor="doctor"
        :skipSlot="skipSlot"
        @showAllSlotTime="showAllSlotTime"
        @updateData="(start, end) => getAvailableAll(start, end)"
      >
      </DoctorCalendarWeek>

      <!-- <div style="margin-bottom: 30px">
        <DoctorCalendar
          :timeSlot="bookedSlot"
          :skipSlot="skipSlot"
          :isActiveCalendar="true"
          :disabled="false"
          :canSwitchActive="false"
          @onChangeMonth="fetchNewItem"
          @onClickDate="onClickDate"
          @onClickDateItem="onClickDateItem"
        />
      </div> -->

      <div class="wrapper">
        <nav>
          <label
            class="miss-call"
            v-bind:id="tabBarType"
            @click="selectTabBar(0)"
          >
            {{ getTabMessage(0) }}
          </label>
          <label class="cancel" v-bind:id="tabBarType" @click="selectTabBar(1)">
            {{ getTabMessage(1) }}
          </label>
          <label
            class="confirm"
            v-bind:id="tabBarType"
            @click="selectTabBar(2)"
          >
            {{ getTabMessage(2) }}
          </label>
          <label
            class="waiting"
            v-bind:id="tabBarType"
            @click="selectTabBar(3)"
          >
            {{ getTabMessage(3) }}
          </label>

          <div class="tab" v-bind:id="tabBarType"></div>
        </nav>
      </div>
      <div class="recently-containner">
        <!-- <div class="columns">
          <div class="column">
            <div class="subtitle">Recently</div>
          </div>
        </div> -->
        <div class="divider" />

        <div v-if="appointmentType.length">
          <div class="subtitle3">{{ getNotificationType(tabBarType) }}</div>
          <AppointmentCard :data="appointmentType" />
        </div>
        <div v-else class="no-data">ไม่มีข้อมูล</div>
      </div>
    </div>

    <!-- <b-modal
      v-model="isShowModal"
      centered
      :width="640"
      :can-cancel="['escape', 'outside']"
    >
      <AppointmentModal :data="appointmentOfDay" :chosenDate="chosenDate" />
    </b-modal> -->
    <b-modal
      v-model="isShowModal"
      centered
      :width="776"
      :can-cancel="['escape', 'outside']"
    >
      <DoctorTimeSlotDialog :data="timeSlotInDate" :doctor="doctor" />
    </b-modal>
    <InfiniteLoading
      ref="dashboardHomeInfiniteLoading"
      v-show="appointmentType.length && this.tabBarType != 0"
      spinner="spiral"
      @infinite="getMoreAppointments"
    >
      <div slot="no-more" class="no-data">ข้อมูลครบแล้ว</div>
      <div slot="no-results" class="no-data">ข้อมูลครบแล้ว</div>
    </InfiniteLoading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { mapActions, mapState } from 'vuex'
import api from '@/utils/api'
import moment from 'moment'
// import DoctorCalendar from '@/components/DoctorCalendar'
import AppointmentCard from '@/components/AppointmentCard'
// import AppointmentModal from '@/components/AppointmentModal'
import DoctorCalendarWeek from '@/components/DoctorCalendarWeek'
import DoctorTimeSlotDialog from '@/components/DoctorTimeSlotDialog'

export default {
  name: 'Notification',
  components: {
    InfiniteLoading,
    // DoctorCalendar,
    AppointmentCard,
    // AppointmentModal,
    DoctorCalendarWeek,
    DoctorTimeSlotDialog
  },
  data() {
    return {
      data: [],

      page: 1,
      perPage: 20,
      // avaliableSlot: [],
      skipSlot: [],
      isShowModal: false,
      // chosenDate: moment(),
      // appointmentOfDay: [],
      // calendarAppointment: [],
      tabBarType: 0,
      doctor: {},
      timeSlot: [],
      date: new Date(),
      timeSlotInDate: {},
      notification: {
        0: {
          status: 3,
          list: [],
          page: 1
        },
        1: {
          status: 4,
          list: [],
          page: 1
        },
        2: {
          status: 5,
          list: [],
          page: 1
        },
        3: {
          status: 0,
          list: [],
          page: 1
        }
      },
      window: {
        width: 0,
        height: 0
      }
    }
  },
  computed: {
    ...mapState({
      user: 'user'
    }),
    appointmentType() {
      return this.notification[this.tabBarType].list
    }
  },
  async created() {
    await window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.doctor = await api.getDoctorById(this.user.doctor.id)
    this.setLoading(true)
    this.getAllAppointment()
    var start = new Date()
    var end = moment(start)
      .add(7, 'days')
      .toDate()
    await this.getAvailableAll(
      new Date(start.getFullYear(), start.getMonth(), start.getDate()),
      end
    )
    this.setLoading(false)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapActions({
      setLoading: 'setLoading'
    }),
    selectTabBar(index) {
      this.tabBarType = index
    },
    handleResize() {
      this.window.width = this.$refs.con.clientWidth
      this.window.height = this.$refs.con.clientHidth
    },
    async getAllAppointment() {
      const [miss, cancel, confirm, wait] = await Promise.all([
        await this.getMissedCall(),
        await this.getAppointments(1),
        await this.getAppointments(2),
        await this.getAppointments(3)
      ])
      this.$set(this.notification[0], 'list', miss)
      this.$set(this.notification[1], 'list', cancel)
      this.$set(this.notification[2], 'list', confirm)
      this.$set(this.notification[3], 'list', wait)
    },
    async getMissedCall() {
      var result = await api.getMissedCallNotification(this.user.doctor.id)
      return result.map(e => {
        return {
          ...e,
          missedCall: true,
          patient: {
            name: e.patientName
          }
        }
      })
    },
    async getAppointments(status) {
      return await api.getAppointmentsWithStatus(
        this.notification[status].page,
        this.perPage,
        this.notification[status].status
      )
    },
    async getMoreAppointments(state) {
      if (this.tabBarType === 0) return
      this.notification[this.tabBarType].page += 1
      const response = await api.getAppointmentsWithStatus(
        this.notification[this.tabBarType].page,
        this.perPage,
        this.notification[this.tabBarType].status
      )
      if (response.length > 0) {
        const temp = [...this.notification[this.tabBarType].list, ...response]
        // this.$set(this, 'data', temp)
        this.notification[this.tabBarType].list = temp
        state.loaded()
      } else {
        state.complete()
      }
    },
    onlyUnique(slot) {
      const transformedSlot = slot.reduce((accum, current) => {
        accum[`${current.startDate};:;${current.endDay}`] = current
        return accum
      }, {})
      return Object.values(transformedSlot)
    },
    async getAvailableAll(start, end) {
      // if (moment(startDate).isSame(new Date(), 'd')) {
      //   this.date = new Date()
      // } else this.date = startDate
      this.date = start
      const [{ data: avaliableData }, skip, bookingData] = await Promise.all([
        await api.getDoctorSlotTime(this.date, end, this.user.doctor.id),
        await api.getAvailableAll(this.date, end),
        await api.getAppointmentsByMonth(this.date, end)
      ])
      const temp = avaliableData.filter(
        d =>
          !bookingData.find(data =>
            moment(d.startDate).isSame(moment(data.appointmentDate))
          )
      )
      const temp2 = bookingData.map(d => ({
        ...d,
        status: d.status,
        startDate: moment(d.appointmentDate),
        endDate: moment(d.appointmentDate)
      }))
      var avaliableSlot = [...temp, ...temp2].sort((a, b) => {
        if (moment(a.startDate).isBefore(moment(b.startDate))) {
          return -1
        }
        if (moment(a.startDate).isAfter(moment(b.startDate))) {
          return 1
        }
        return 0
      })
      this.$set(this, 'timeSlot', this.onlyUnique(avaliableSlot))
      this.$set(this, 'skipSlot', this.onlyUnique(skip.data))
    },
    showAllSlotTime(timeSlot) {
      this.timeSlotInDate = timeSlot
      this.isShowModal = true
    },
    getNotificationType(index) {
      switch (index) {
        case 0:
          return 'Missed Call'
        case 1:
          return 'Cancel Appointment'
        case 2:
          return 'Confirm Appointment'
        case 3:
          return 'Waiting to confirm'
      }
    },
    getNotificationType2(index) {
      switch (index) {
        case 0:
          return 'Missed'
        case 1:
          return 'Cancel'
        case 2:
          return 'Confirm'
        case 3:
          return 'Waiting'
      }
    },
    getTabMessage(index) {
      var title =
        this.window.width > 880
          ? this.getNotificationType(index)
          : this.getNotificationType2(index)
      var count = this.notification[index].list.filter(e => {
        return !e.doctorReadMessage
      }).length
      return this.notification[index].list.length > 0
        ? title + ' (' + count + ')'
        : title
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  background-color: #dbd6d3;
  border-radius: 12px;
  padding: 8px;
  nav {
    position: relative;
    display: flex;
    label {
      flex: 1;
      width: 25%;
      z-index: 1;
      color: #3e4a59;
      font-size: clamp(10px, 2vw, 14px);
      // font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      padding: 14px 4px;
      border: none;
      background-color: inherit;
      cursor: pointer;
      display: inline-block;
      text-align: center;
      transition: color 0.4s ease;
      -webkit-tap-highlight-color: transparent;
    }
    .miss-call[id='0'],
    .cancel[id='1'],
    .confirm[id='2'],
    .waiting[id='3'] {
      color: #34beb3;
    }
    .tab {
      height: 100%;
      width: 25%;
      position: absolute;
      background-color: #ffffff;
      color: #34beb3;
      border-radius: 12px;
      border: none;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      transition: 0.3s;
      transition-timing-function: ease;
    }
    .tab[id='0'] {
      left: 0%;
    }
    .tab[id='1'] {
      left: 25%;
    }
    .tab[id='2'] {
      left: 50%;
    }
    .tab[id='3'] {
      left: 75%;
    }
  }
}

.recently-containner {
  // padding: 0 15px;
  font-size: 13px !important;
}
.db-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding-left: 6px;
  padding-right: 6px;
}
.dot {
  height: 14px;
  width: 14px;
  margin: 0px 4px 0px 4px;
  background-color: #cacaca;
  border-radius: 50%;
}
.booked {
  background-color: #e59f91;
}
.available {
  background-color: #34beb3;
}
.notification-explan {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.status-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.text-status {
  font-weight: 400;
  font-size: 18px;
  margin-right: 20px;
  color: #3e4a59;
}

.db-card {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 30px 50px 20px;
  height: 100%;
  display: flex;
  .icon {
    width: 65px;
    display: inline-block;
  }
  .detail {
    display: inline-block;
    margin-left: 20px;
    .head {
      color: #888888;
      font-size: 16px;
    }
    .value {
      color: #3e4a59;
      font-size: 30px;
      .sub-value {
        color: #888888;
        font-size: 15px;
      }
      .currency {
        color: #3e4a59;
        font-size: 15px;
      }
    }
    .btn {
      border-radius: 15px;
      background-color: #34beb3;
      color: #fff;
      padding: 5px 25px;
      text-align: center;
      font-size: 13px;
      cursor: pointer;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #dbd6d3;
  margin: 24px 0;
}
.subtitle {
  font-size: 20px;
  color: #3e4a59;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subtitle2 {
  font-size: 14px;
  color: #3e4a59;
  // margin: 0px 30px 0px 0px;
}
.subtitle3 {
  font-size: 16px;
  color: #3e4a59;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
}
.title-text {
  font-size: 22px;
  color: #3e4a59;
}
.no-data {
  width: 100%;
  height: 60px;
  font-size: 16px;
  color: #3e4a59;
  text-align: center;
}
.status {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 560px) {
  .notification-explan {
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    display: block;
    text-align: center;
  }
  .status-row {
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    text-align: center;
    display: block;
  }
  .text-status {
    margin: 0px;
  }
  .title-text {
    margin-bottom: 10px;
  }
}

/* Less than mobile */
@media only screen and (max-width: 768px) {
  .db-container {
    padding: 0;
  }
}
</style>
