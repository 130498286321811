export default {
  author: 'SkinX Team',
  title: 'ส่งต่อความพึงพอใจจากผู้ใช้จริง',
  image: require('./summer.png'),
  createdAt: new Date('2023-03-28'),
  content: `ต้อนรับซัมเมอร์กับ SkinX ชวนคุณหมอผิวมาแชร์ความรู้สู้กับแสงแดดให้ทุกคนเลิฟผิวยิ่งกว่าที่เคย ผ่านกิจกรรม SkinX Summer’s Month

 

  ขอเชิญชวนคุณหมอมาร่วมแบ่งปันสาระความรู้ รวมไปถึงเคล็ดลับในการดูแลผิวตามหัวข้อต่าง ๆ ที่ท่านสนใจ เพื่อส่งต่อประโยชน์ดี ๆ ให้กับเหล่าคนรักผิวผ่านทุกแพลตฟอร์มของ SkinX
  
  โดยสามารถอัดคลิปแนะนำความรู้ตามสไตล์สาย TikTok โดยทางทีม SkinX สามารถช่วยท่านตัดต่อคลิปวิดีโอได้
  
  แล้วมาร่วมสนุกกันนะครับบบบ :)

  ![SkinX Doctor Review](${require('./summer.png')})`
}
