<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Note</p>
      <button type="button" class="delete" @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <b-input
        v-model="value"
        type="textarea"
      />
    </section>
    <footer class="modal-card-foot">
      <b-button
        class="button is-rounded"
        @click="$emit('close')"
      >
        ยกเลิก
      </b-button>
      <b-button
        class="button is-app-success is-rounded"
        @click="submit"
      >
        ยืนยัน
      </b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'MedicalReportInstructionModal',
  props: {
    input: {
      type: String
    }
  },
  data () {
    return {
      value: ''
    }
  },
  created () {
    this.value = this.input
  },
  methods: {
    submit () {
      this.$emit('submit', this.value)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-card {
  width: auto;
  max-width: 540px;
  margin: 0 auto !important;
}
.modal-card-head {
  background-color: #fff !important;
  border: 0 !important;
  .modal-card-title {
    font-size: 18px !important;
    font-weight: bold;
  }
}
.modal-card-foot {
  background-color: #fff !important;
  border: 0 !important;
  display: flex;
  justify-content: space-around;
}
.button {
  width: 200px;
}
</style>
