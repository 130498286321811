export default {
  author: 'SkinX Team',
  title: 'ใหม่ ES x SkinX  ‼ ES Micro Balancing Cleanser 100ml',
  image: require('./image.png'),
  createdAt: new Date('2023-10-24'),
  content: `เจลล้างหน้าผิวอ่อนโยน พัฒนาร่วมกับแพทย์ผิวหนัง SkinX เจลล้างหน้า ทำความสะอาดอย่างอ่อนโยน ช่วยปรับสมดุลความชุ่มชื้น ทำให้ผิวสุขภาพดีขึ้น
  เจลล้างหน้าปรับสมดุลผิว จัดการปัญหาผิวที่ต้นเหตุ ทำความสะอาดลึกระดับ Micro โดยไม่ทำให้ผิวแห้งตึง ช่วยลดการอักเสบ ลดรอยแดง รอยดำ จากการอักเสบของผิว
  โดยใช้สารสกัดหลักที่เป็น Organic คือ CHAMOMILLA RECUTITA FLOWER สามารถรับ-ส่งโมเลกุลน้ำ ได้มากถึงพันล้านโมเลกุลต่อวินาที สามารถลำเลียงสารสกัดที่สำคัญสู่ผิวหนัง (transdermal) อย่างอ่อนโยน พร้อมฟื้นบำรุงผิวแห้งระคายเคืองจากการแพ้ด้วย Glyceryl glucoside (กลีเซอริล กลูโคไซด์) ที่สกัดจาก "ต้นไม้คืนชีพ" มีลักษณะเป็น Aquaporins ทำหน้าที่ดูแลระดับความชุ่มชื้นให้กับผิว ช่วยเพิ่มความชุ่มชื้นในผิวหนังชั้น Epidermis อีกทั้งยังมีสารช่วยต่อต้านการแพ้ต่างๆของผิว ช่วยลดอาการอักเสบ ยับยั้งเชื้อแบคทีเรีย C.Acne ที่ก่อให้เกิดสิวด้วย Sodium PCA, Allantoin และมีสาร CytobiolTM IRIS A2 ที่ช่วยเสริมสร้างการสร้างเนื้อเยื่อ(granulation tissue) ที่สมบูรณ์ขึ้นมาใหม่ ช่วยปรับสมดุลปริมาณของการสร้างซีบัม ให้ผิวกลับมาแข็งแรง ชุ่มชื้น ไม่แห้งลอก
   
  ✓ ลดการอักเสบของสิว ลดสิวอุดตัน
  ✓  ลดการแพ้ ผดผื่น
  ✓ ลดการอักเสบของผิว 
  ✓ เสริมความแข็งแรงให้แก่ผิวหลังการเกิดสิว
  ✓ ยับยั้งเชื้อแบคทีเรีย C.Acne
   
  เหมาะกับ 
  ✓ ผู้มีปัญหาสิว 
  ✓ ผู้มีปัญหาผิวแห้ง หรือมันเพราะขาดสมดุลผิว
  ✓ ผิว เป็นขุย แดง ลอก
  ✓ ผู้มีปัญหาผิวหมองคล้ำ สีผิวไม่สม่ำเสมอ
   
  สารสกัด 
  ปราศจากแอลกอฮอล์ น้ำหอม สารกันเสียพาราเบน ซิลิโคน สี
  CHAMOMILLA RECUTITA FLOWER WATER, PEG-7 GLYCERYL COCOATE, SODIUM LAUROYL SARCOSINATE GLYCERIN, PEG-3 GLYCERYL COCOATE, GLYCERYL GLUCOSIDE,SODIUM PCA, PROPANEDIOL SALIX ALBA EXTRACT, ALLIUM CEPA (ONION) BULB EXTRACT IRIS FLORENTINA ROOT EXTRACT, CITRIC ACID, ALLANTOIN, PANTHENOL, DISODIUM EDTA.ZINC SULFATE
  
  ![ใหม่ ES x SkinX  ‼ ES Micro Balancing Cleanser 100ml](${require('./image.png')})`
}
