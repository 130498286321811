export default {
  author: 'SkinX Team',
  title: 'SkinX Sharing with You ชวนอัปเดตงานวิจัยด้านผิวหนัง!',
  image: require('./derma.png'),
  createdAt: new Date('2023-02-07'),
  content: `SkinX ชวนหมอผิวมาอัปเดทความรู้กับงานวิจัยด้านผิวหนังจากโรงพยาบาลรามาธิบดี

  หัวข้อ ‘A real world prospective cohort study of immunogenicity and reactogenicity of ChAOx1-S (recombinant) among patients with immune-meditated dermatologic diseases’
  
  ซึ่งงานวิจัยนี้ได้รับการตีพิมพ์และเผยแพร่ลงวารสารทางการแพทย์ชื่อดังของประเทศอังกฤษ British journal of Dermatology!
  
  คลิกอ่านเพิ่มเติมได้ที่ : <https://academic.oup.com/bjd/advance-article/doi/10.1093/bjd/ljac045/6765233?login=false>

  ![British journal of Dermatology](${require('./derma.png')})`
}
