<template>
  <div class="terms-and-cond">
    <div class="ct">
      Term and Condition
    </div>
    <div class="dt">
      These Terms of Use (“Terms”) govern your use of the SkinXDoctor (“SkinXDoctor”, “Company”, “we,” “us,” or “our”) and SkinXDoctor (“SkinXDoctor”, “Company”, “we,” “us,” or “our”) apps at the Apple itunes store or Google Play stores (the “Services”). These Terms apply to you whether you are a medical practitioner or health care provider (whether an individual professional or an organization) or similar institution wishing to be listed, or already listed, on our Services, including designated, authorized associates of such practitioners or institutions (“Practitioner(s)”, “you” or “User”); or a patient, his/her representatives or affiliates, searching for Practitioners through the Services (“End-User”, “you” or “User”); or otherwise a user of our Services (“you” or “User”).
    </div>
    <div class="dt">
      Please review these Terms carefully before using the Services. We may change these Terms or modify any features of the Services at any time. The most current version of the Terms can be viewed by clicking on the “Terms of Use” link posted at doctor.skinx.app. You accept the Terms by using the Services, and you accept any changes to the Terms by continuing to use the Services after we post the changes.
    </div>
    <div class="tt">
      I. Privacy
    </div>
    <div class="dt">
      By using the Services, you consent to our processing your information consistent with our Privacy Policy.
    </div>
    <div class="tt">
      II. Prohibited Conduct
    </div>
    <div class="dt">
      You may not access or use, or attempt to access or use, the Services to take any action that could harm us or any third party, interfere with the operation of the Services, or use the Services in a manner that violates any laws. For example, and without limitation, you may not:
    </div>
    <ul class="dt">
      <li>
        transmit any message or information under a false name or otherwise misrepresent your affiliation or the origin of materials you transmit;
      </li>
      <li>
        provide information that is untrue, inaccurate, not current, or incomplete;
      </li>
      <li>
        transmit any message or information that is unlawful, libelous, defamatory, obscene, fraudulent, predatory of minors, harassing, threatening, or hateful;
      </li>
      <li>
        transmit any message or information that infringes or violates the intellectual property, privacy, or publicity rights of others;
      </li>
      <li>
        reproduce, retransmit, distribute, disseminate, sell, publish, broadcast, or circulate content received through the Services to anyone without prior express permission;
      </li>
      <li>
        engage in unauthorized spidering, “scraping,” or harvesting of content or personal information, or use any other unauthorized automated means to compile information;
      </li>
      <li>
        take any action that imposes an unreasonable or disproportionately large load on our network or infrastructure;
      </li>
      <li>
        use any device, software, or routine to interfere or attempt to interfere with the proper working of the Services or any activity conducted on the Services or attempt to probe, scan, test the vulnerability of, or breach the security of any system or network;
      </li>
      <li>
        attempt to modify, translate, decipher, decompile, disassemble, reverse-engineer, or create derivative works of any of the software comprising or in any way making up a part of the Services; or
      </li>
      <li>
        engage in any other conduct that restricts or inhibits any person from using or enjoying the Services, or that, in our sole judgment, exposes us or any of our users, affiliates, or any other third party to any liability, damages, or detriment of any type.
      </li>
    </ul>
    <div class="dt">
      Violations of system or network security may result in civil or criminal liability. We may investigate and work with law enforcement authorities to prosecute users who violate the Terms. We may suspend or terminate your access to the Services for any or no reason at any time without notice.
    </div>
    <div class="tt">
      III. Intellectual Property Rights
    </div>
    <div class="dt">
      The Services are protected under the copyright laws of Thailand. All copyrights in the Services are owned by us or our third-party licensors to the full extent permitted under international copyright laws. You may not publish, reproduce, distribute, display, perform, edit, adapt, modify, or otherwise exploit any part of the Services without our written consent.
    </div>
    <div class="dt">
      All rights in the product names, company names, trade names, logos, service marks, trade dress, slogans, product packaging, and designs of the Services, whether or not appearing in large print or with the trademark symbol, belong exclusively to the Company or its licensors and are protected from reproduction, imitation, dilution, or confusing or misleading uses under national and international trademark and copyright laws. The use or misuse of these trademarks or any materials, except as authorized herein, is expressly prohibited, and nothing stated or implied on the Services confers on you any license or right under any patent or trademark of the Company, its affiliates, or any third party.
    </div>
    <div class="tt">
      IV. Third-Party Content
    </div>
    <div class="dt">
      The Services may provide links to third-party content. You acknowledge and agree that we are not responsible for the availability of such third-party content, and we do not control, endorse, sponsor, recommend, or otherwise accept responsibility for such content. Use of any linked third-party content is at the user’s own risk.
    </div>
    <div class="tt">
      V. User content
    </div>
    <div class="dt">
      The User acknowledges that they are the original authors and creators of any Content uploaded by them to the Company or its Services and that no Content uploaded by them would constitute infringement of the intellectual property rights of any other person. The Company reserves the right to remove any Content which it may determine at its own discretion as violating the intellectual property rights of any other person. The User agrees to absolve the Company from and indemnify the Company against all claims that may arise as a result of any third party intellectual property right claim that may arise from the user’s uploading of any Content on the Services. The User also agrees to absolve the Company from and indemnify the Company against all claims that may arise as a result of any third party intellectual property claim if the User downloads, copies or otherwise utilizes an image from the Services for his/her personal
    </div>
    <div class="dt">
      or commercial gain. The User hereby assigns to the Company, in perpetuity and worldwide, all intellectual property rights in any Content created by the User and uploaded by the User to the Company.
    </div>
    <div class="tt">
      VI. No doctor-patient relationship
    </div>
    <div class="dt">
      Please note that some of the content, text, data, graphics, images, information, suggestions, guidance, and other material (collectively, “Information”) that may be available on the Services (including information provided in direct response to your questions or postings) may be provided by individuals in the medical profession. The provision of such Information does not create a licensed medical professional/patient relationship, between the Company and you, and does not constitute an opinion, medical advice, or diagnosis or treatment of any particular condition.
    </div>
    <div class="dt">
      It is hereby expressly clarified that, the Information that you obtain or receive from the Company, and its employees, contractors, partners, sponsors, advertisers, licensors or otherwise on the Services is for informational purposes only. We make no guarantees, representations or warranties, whether expressed or implied, with respect to professional qualifications, quality of work, expertise or other information provided on the Services. In no event shall we be liable to you or anyone else for any decision made or action taken by you in reliance on such information.
    </div>
    <div class="dt">
      The Company is not a medical service provider, nor is it involved in providing any healthcare or medical advice or diagnosis, it shall hence not be responsible and owns no liability to either Users or Practitioners for any outcome from the consultation between the User and the Practitioner.
    </div>
    <div class="tt">
      VII. Not for emergency use or serious illness requiring physical consultation
    </div>
    <div class="dt">
      The Services are not intended to be a substitute for getting in touch with emergency healthcare. If you are an End-User facing a medical emergency (either on your or another person’s behalf), please contact an ambulance service or hospital directly.
    </div>
    <div class="dt">
      The Services are intended for general purposes only and are not meant to be used in emergencies/serious illnesses requiring physical consultation. Further, if the Practitioner adjudges that a physical examination would be required and advises ‘in-person consultation’, it is the sole responsibility of the User, to book an appointment for physical examination and in-person consultation whether the same is with the Practitioner listed on the Services or otherwise. In case of any negligence on the part of the User in acting on the same and the condition of the User deteriorates, the Company shall not be held liable. Notwithstanding anything contained herein, the Company in no manner endorses any Practitioner(s) that Users consult and is not in any manner responsible for any drug/medicines prescribed or the therapy prescribed by the Practitioner. If Practitioner responds to the User’s query, the system could trigger communications to the User, in the form of notification/text/email/others. The User further understands that the Company may send such communications like text messages/email/calls, which is provided by
    </div>
    <div class="dt">
      Practitioner, before and/or after Practitioner’s consultation (physical or online) to User’s mobile number or app, based on the Practitioner’s settings. However, and notwithstanding anything to the contrary in this Agreement, the Company does not take responsibility for timeliness of such communications.
    </div>
    <div class="tt">
      VIII. Disclaimer of Warranties; Limitation of Liability
    </div>
    <div class="dt">
      Your use of the Services is at your own risk. The Services are provided “as is” without warranties of any kind, either express or implied, including without limitation warranties of title, merchantability, fitness for a particular purpose, non-infringement, or other violation of rights. We do not warrant the adequacy, currency, accuracy, likely results, or completeness of the Services or any third-party sites linked to or from the Services, or that the functions provided will be uninterrupted, virus-free, or error-free. We expressly disclaim any liability for any errors or omissions in the content included in the Services or any third-party sites linked to or from the Services.
    </div>
    <div class="dt">
      In no event will we, or our parents, subsidiaries, affiliates, licensors, suppliers and their directors, officers, affiliates, subcontractors, employees, agents, and assigns be liable for any direct or indirect, special, incidental, consequential or punitive damages, lost profits, or other damages whatsoever arising in connection with the use of the Services, any interruption in availability of the Services, delay in operation or transmission, computer virus, loss of data, or use, misuse, reliance, review, manipulation, or other utilization in any manner whatsoever of the Services or the data collected through the Services, even if one or more of them has been advised of the possibility of such damages or loss.
    </div>
    <div class="dt">
      You agree that your sole remedy for any claim arising out of or connected with the Services will be to cease using the Services.
    </div>
    <div class="tt">
      IX. Indemnification
    </div>
    <div class="dt">
      You agree to indemnify, defend and hold us and our parents, subsidiaries, affiliates, licensors, suppliers and their directors, officers, affiliates, subcontractors, employees, agents, and assigns harmless from and against any and all loss, costs, expenses (including reasonable attorneys’ fees and expenses), claims, damages and liabilities related to or associated with your use of the Services and any alleged violation by you of these Terms. We reserve the right to assume the exclusive defense of any claim for which We are entitled to indemnification under this section. In such event, you shall provide us with such cooperation as we reasonably request.
    </div>
    <div class="tt">
      X. Choice of Law and Forum
    </div>
    <div class="dt">
      You agree that your access to and use of the Services will be governed by and will be construed in accordance with the laws of Singapore without regard to principles of conflicts of laws. You agree that any claim or dispute against us arising out of or relating to the Services must be resolved by a court located in Singapore, unless agreed upon by all parties.
    </div>
    <div class="tt">
      XI. Miscellaneous
    </div>
    <div class="dt">
      These Terms constitute the entire agreement between you and us, superseding any prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and us. In the event any provision of these Terms is held unenforceable, it will not affect the validity or enforceability of the remaining provisions and will be replaced by an enforceable provision that comes closest to the intention underlying the unenforceable provision. You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of these Terms or your access to and use of the Services.
    </div>
    <div class="dt">
      Our failure to enforce any provisions of these Terms or respond to a violation by any party does not waive our right to subsequently enforce any terms or conditions of the Terms or respond to any violations. Nothing contained in these Terms is in derogation of our right to comply with governmental, court, and law enforcement requests or requirements relating to your use of the Services or information provided to or gathered by us with respect to such use.
    </div>
    <div class="tt">
      XI. Cancellation and Refund Policy
    </div>
    <div class="dt">
      Consultations on the Services by Practitioners are paid for with “Credits”. “Credits” are purchased by Users through credit card, bank transfers, cash or other electronic forms of payment. Once payment is made for “Credits”, no refunds are allowed.
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegisterTermsAndConditions'
}
</script>

<style lang="scss" scoped>
.terms-and-cond {
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 1px solid #d2d2d2;
  border-radius: 20px;
  padding: 12px;
  font-size: 11px;
  background-color: white;
  color: #4a4a4a;
  .ct {
    width: 100%;
    text-align: center;
  }
  .tt {
    font-weight: bold;
    margin-top: 10px;
  }
  .dt {
    margin-top: 10px;
  }
  ul {
    padding-left: 20px;
    list-style-type: disc;
  }
}
</style>
