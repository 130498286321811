<template>
  <div
    class="rc"
    @click="$router.push({ name: 'MedicalReport', params: { id } })"
  >
    <div class="columns is-mobile is-multiline rc-item">
      <div class="column rc-column is-6-mobile">
        <div class="text">
          <span class="key"> ชื่อ </span>
          <span class="value">
            {{ name || '-' }}
          </span>
        </div>
        <div class="text">
          <span class="key"> อายุ </span>
          <span class="value">
            {{ birthDate | calAge }}
          </span>
        </div>
      </div>
      <div class="column rc-column is-6-mobile">
        <div class="text symptoms">
          <span class="key"> อาการ </span>
          <span class="value">
            {{ symptoms | joinSymptoms }}
          </span>
        </div>
        <div class="text">
          <span class="key"> เพศ </span>
          <span class="value">
            {{ gender | gender }}
          </span>
        </div>
      </div>
      <div class="column rc-column is-6-mobile">
        <div class="text">
          <span class="key"> วันที่ </span>
          <span class="value">
            {{ date | toDateFormat }}
          </span>
        </div>
        <div class="text">
          <span class="key"> เลขที่การปรึกษา </span>
          <span class="value">
            {{ id }}
          </span>
        </div>
      </div>
      <div class="column is-6-mobile is-narrow-desktop">
        <div class="status">
          <div v-if="status" class="completed">
            <img src="@/assets/svg/record-complete.svg" />
            <div class="status-msg">Completed</div>
          </div>
          <div v-else class="pending">+ Create Case Summary</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecordItem',
  props: {
    name: {
      type: String
    },
    birthDate: {
      type: String
    },
    symptoms: {
      type: Array
    },
    gender: {
      type: String
    },
    date: {
      type: String
    },
    id: {
      type: [String, Number]
    },
    status: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.rc-column {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rc {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 11px 15px;
  margin-bottom: 12px;
  cursor: pointer;
}
.w-46px {
  width: 46px;
}
.rc-item {
  .avatar {
    width: 46px;
    height: 46px;
    border-radius: 50%;
  }
  .text {
    .key {
      color: #9b9b9b;
      font-size: 12px;
    }
    .value {
      color: #3e4a59;
      font-size: 14px;
    }
    &.symptoms {
      text-overflow: ellipsis;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
  .status {
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;

    .completed {
      width: 160px;
      .status-msg {
        color: #9b9b9b;
        font-size: 12px;
      }
    }
    .pending {
      width: 160px;
      font-size: 12px;
      text-align: center;
      color: #ffffff;
      border-radius: 100px;
      background-color: #d8a283;
      cursor: pointer;
      padding: 10px;
    }
  }
}
/* Less than mobile */
@media only screen and (max-width: 768px) {
  .rc-column {
    justify-content: normal;
  }
  .rc-item {
    .status {
      width: 100%;
      .completed {
        width: 100%;
        text-align: left;
        img {
          line-height: 27px;
          vertical-align: middle;
          margin-right: 10px;
        }
        .status-msg {
          display: inline-block;
        }
      }
      .pending {
        width: 100%;
        max-width: 165px;
      }
    }
  }
}
</style>
