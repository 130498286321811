export default {
  'skinx/effalcar': {
    title: 'Effalcar H Isobiome – Moisturizer for ACNE Adjunctive therapy and Rebalance Skin Microbiome',
    image: require('./skinx/effalcar/image.jpg'),
    createdAt: new Date('2023-12-7')
  },
  'skinx/dercos': {
    title: 'NEW DERCOS ANTI-DANDRUFF SHAMPOO – แชมพูขจัดรังแคสูตรอ่อนโยนจากแบรนด์เวชสำอางดังจากประเทศฝรั่งเศส VICHY',
    image: require('./skinx/dercos/image.png'),
    createdAt: new Date('2023-10-31')
  },
  'skinx/es': {
    title: 'ใหม่ ES x SkinX  ‼ ES Micro Balancing Cleanser 100ml',
    image: require('./skinx/es/image.png'),
    createdAt: new Date('2023-10-24')
  },
  'skinx/world-congress-of-dermatology': {
    title: '25th World Congress of Dermatology 2023 at Singapore',
    image: require('./skinx/world-congress-of-dermatology/image.jpg'),
    createdAt: new Date('2023-07-05')
  },
  'skinx/game-day': {
    title: 'SkinX Game Day for Doctor "ชวนคุณหมอผิวมาเช็คปัญหาสิวคนไข้"',
    image: require('./skinx/game-day/image.png'),
    createdAt: new Date('2023-05-25')
  },
  'skinx/pub-med': {
    title: 'SkinX Sharing with You ชวนอัปเดตงานวิจัยด้านผิวหนัง!',
    image: require('./skinx/pub-med/image.png'),
    createdAt: new Date('2023-05-25')
  },
  'skinx/scientific-report': {
    title: 'SkinX Sharing with You ชวนอัปเดตงานวิจัยด้านผิวหนัง!',
    image: require('./skinx/scientific-report/image.png'),
    createdAt: new Date('2023-05-25')
  },
  'skinx/doctor-of-the-month-may': {
    title: 'Congrats on your success with Doctor of the month',
    image: require('./skinx/doctor-of-the-month-may/image.png'),
    createdAt: new Date('2023-05-25')
  },
  'skinx/doctor-review-05': {
    title: 'ส่งต่อความพึงพอใจจากผู้ใช้จริง',
    image: require('./skinx/doctor-review-05/image.jpeg'),
    createdAt: new Date('2023-05-25')
  },
  'skinx/summer': {
    title: 'Summer’s Month in SkinX ท้าหน้าร้อนด้วยความรู้จากหมอผิว',
    image: require('./skinx/summer/summer.png'),
    createdAt: new Date('2023-03-28')
  },
  // 'skinx/doctor-review-may': {
  //   title: 'ส่งต่อความพึงพอใจจากผู้ใช้จริง',
  //   image: require('./skinx/doctor-review-may/review.jpeg'),
  //   createdAt: new Date('2023-03-28')
  // },
  'skinx/line-jang': {
    title: 'LIENJANG COSMETICS ผลิตภัณฑ์ดูแลผิวจากเกาหลี',
    image: require('./skinx/line-jang/cover-lienjang.jpg'),
    createdAt: new Date('2023-03-7')
  },
  'skinx/dermlite': {
    title: 'เปิดทุกการมองเห็น ชัดเจนยิ่งกว่า ด้วย Dermlite',
    image: require('./skinx/dermlite/dermlite.jpg'),
    createdAt: new Date('2023-03-27')
  },
  // 'skinx/dst': {
  //   title: 'ประมวลภาพกิจกรรมบรรยากาศงานประชุม DST Annual Meeting 2023',
  //   image: require('./skinx/dst/dst.png'),
  //   createdAt: new Date('2023-03-27')
  // },
  // 'skinx/doctor-of-the-month-april': {
  //   title: 'Congrats on your success with Doctor of the month',
  //   image: require('./skinx/doctor-of-the-month-april/doctor-of-the-month.png'),
  //   createdAt: new Date('2023-03-7')
  // },
  // 'skinx/doctor-review-apirl': {
  //   title: 'Congrats on your success with Doctor of the month',
  //   image: require('./skinx/doctor-review/review.png'),
  //   createdAt: new Date('2023-03-7')
  // },
  'skinx/x-roy': {
    title: 'มั่นใจ รอยสิวจางใน ใน 2 สัปดาห์ ‼️',
    image: require('./skinx/x-roy/x-roy-2.png'),
    createdAt: new Date('2023-03-01')
  },
  'skinx/curel': {
    title: 'จัดเต็ม !! เปิดตัว Curel Product เวชสำอางอันดับ 1 จากประเทศญี่ปุ่น',
    image: require('./skinx/curel/curel.png'),
    createdAt: new Date('2023-02-14')
  },
  // 'skinx/sharing-your-love': {
  //   title:
  //     'SkinX Sharing Your Love ขยายความรัก แชร์ความรู้ดูแลผิวรับเดือนแห่งความรักกับหมอผิว SkinX',
  //   image: require('./skinx/sharing-your-love/love.png'),
  //   createdAt: new Date('2023-02-14')
  // },
  // 'skinx/doctor-review': {
  //   title: 'ส่งต่อความพึงพอใจจากผู้ใช้จริง',
  //   image: require('./skinx/doctor-review/review.png'),
  //   createdAt: new Date('2023-02-14')
  // },
  'skinx/welcome-back': {
    title: 'Welcome Back Package for You',
    image: require('./skinx/welcome-back/comback.png'),
    createdAt: new Date('2023-01-20')
  }
  // 'skinx/dermatology': {
  //   title: 'SkinX Sharing with You ชวนอัปเดตงานวิจัยด้านผิวหนัง!',
  //   image: require('./skinx/dermatology/derma.png'),
  //   createdAt: new Date('2023-02-07')
  // }

  // 'skinx/prime-time': {
  //   title: 'ของรางวัลพิเศษ สำหรับแพทย์ออนไลน์เวลา 18:00 -22:00 น.',
  //   image: require('./skinx/prime-time/banner.png'),
  //   createdAt: new Date('2022-10-12')
  // }
  // 'acne/what-is-acne': {
  //   title: 'สิว (Acne) ปัญหาผิวกวนใจ เกิดทีไรแก้ยากทุกที!',
  //   image: require('./acne/what-is-acne/สิวคืออะไร.jpg'),
  //   createdAt: new Date('2021-04-12')
  // },
  // '*-test-1': {
  //   title: '![รูปภาพสิว สิวตุ่มแดง Papule]',
  //   image: require('./acne/what-is-acne/Papule.jpg'),
  //   createdAt: new Date('2021-04-11')
  // },
  // '*-test-2': {
  //   title: '![สิว Acne]',
  //   image: require('./acne/what-is-acne/acne.jpg'),
  //   createdAt: new Date('2021-04-10')
  // },
  // '*-test-3': {
  //   title: '![รูปภาพสิว สิวอุดตัน Comedone]',
  //   image: require('./acne/what-is-acne/comedone.jpg'),
  //   createdAt: new Date('2021-04-09')
  // },
  // '*-test-4': {
  //   title: '![รูปภาพสิว สิวหัวช้าง Nodule]',
  //   image: require('./acne/what-is-acne/Nodule.jpg'),
  //   createdAt: new Date('2021-04-08')
  // },
}
