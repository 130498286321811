<template>
  <div id="forgot-password-page">
    <div class="forgot-password-container">
      <div>
        <div class="form-ctn">
          <img src="@/assets/img/forgot-password-cover.png" />
          <div class="forgot-password-form">
            <ValidationObserver v-slot="{ invalid }" ref="form">
              <b-field label="Code (Sent to your email)">
                <ValidationProvider
                  name="Code"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-input v-model="code" rounded />
                  <span class="error-msg">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
              <b-field label="New password">
                <ValidationProvider
                  name="New Password"
                  rules="required|passwordStrength"
                  v-slot="{ errors }"
                >
                  <b-input v-model="password" type="password" rounded />
                  <span class="error-msg">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
              <b-field label="Confirm new password">
                <ValidationProvider
                  name="Confirm new password"
                  :rules="`required|is:${password}`"
                  v-slot="{ errors }"
                >
                  <b-input v-model="confirmPassword" type="password" rounded />
                  <span class="error-msg">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-field>
              <b-button
                :disabled="invalid"
                :loading="isLoading"
                type="is-secondary"
                class="next-btn"
                rounded
                expanded
                @click="submit"
              >
                Confirm
              </b-button>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'ResetPassword',
  data() {
    return {
      password: '',
      confirmPassword: '',
      code: '',
      isLoading: false
    }
  },
  created() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code
    }
  },
  methods: {
    async submit() {
      this.isLoading = true
      try {
        const data = await api.putResetPassword(this.code, this.password)
        if (!data.success) {
          this.$refs.form.setErrors({ Code: ['invalid Code'] })
        } else {
          this.$router.push({ name: 'Login' })
        }
      } catch (e) {
        console.log(e)
        this.$refs.form.setErrors({ Code: ['invalid Code'] })
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
#forgot-password-page {
  .label {
    color: #8f6f56 !important;
    font-weight: normal !important;
  }
}
</style>

<style lang="scss" scoped>
.forgot-password-container {
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  padding: 32px 0;
}
.back {
  color: #888888;
  position: absolute;
  top: 40px;
  left: 30px;
  line-height: 30px;
  cursor: pointer;
  img {
    vertical-align: middle;
    margin-right: 10px;
  }
}
.forgot-password-title {
  font-size: 25px;
  color: #8f6f56;
  width: 100%;
  margin-top: 40px;
}
.forgot-password-subtitle {
  line-height: 30px;
  font-weight: 100;
  color: #8f6f56;
  margin-top: 10px;
  margin-bottom: 30px;
}
.forgot-password-form {
  width: 80%;
  margin: 30px auto 0;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #d2d2d2;
  margin: 40px 0;
}
.cancel-btn {
  height: 45px;
  box-shadow: 0;
  margin-right: 20px;
  width: calc(50% - 10px);
}
.next-btn {
  height: 45px;
  box-shadow: 0;
  width: calc(50% - 10px);
}
.form-ctn {
  margin-top: 50px;
}
/* Less than mobile */
@media only screen and (max-width: 768px) {
  .back {
    top: 10px;
    left: 1rem;
    img {
      vertical-align: middle;
      margin-right: 0;
    }
  }
}
</style>
