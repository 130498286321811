<template>
  <div class="db-container">
    <div>
      <div class="columns">
        <div class="column">
          <div class="db-card">
            <div class="icon">
              <img src="@/assets/svg/case.svg" />
            </div>
            <div class="detail">
              <div class="head">Total Cases</div>
              <div class="value">
                {{
                  totalSummary ? totalSummary.totalCases : 0 | numberWithCommas
                }}
                <span class="sub-value"
                  >/
                  {{
                    totalSummary
                      ? totalSummary.totalCases
                      : 0 | numberWithCommas
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="db-card">
            <div class="icon">
              <img src="@/assets/svg/earnings.svg" />
            </div>
            <div class="detail">
              <div class="head">Total Balance</div>
              <div class="value">
                {{ accountSummary.income | numberWithCommas }}
                <span class="currency">THB</span>
              </div>
              <div
                class="btn"
                @click="$router.push({ name: 'DashboardFinance' })"
              >
                Withdraw
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="divider" />
      <div>
        <div class="subtitle-totalcase">Total Cases</div>
        <div class="filter-container">
          <div
            class="filter-section"
            style="flex-grow: 1; width: 160px; min-width: 160px"
          >
            <label class="label-filter">ค้นหาชื่อ/เลขที่คำปรึกษา</label>
            <b-autocomplete
              rounded
              v-model="search"
              :data="searchPatient"
              placeholder="ชื่อ/เลขที่คำปรึกษา"
              icon="magnify"
              clearable
              :loading="isFetchingSearchPatient"
              field="name"
              @typing="getSearchPatient"
              @select="option => (search = option && option.name)"
            >
              <template #empty>No results found</template>
            </b-autocomplete>
          </div>

          <div class="filter-section" style="width: 12vw; min-width: 160px">
            <label class="label-filter">เลือกจากวันที่</label>
            <b-datepicker
              rounded
              v-model="selectedDate"
              placeholder="เลือกจากวันที่"
              icon="calendar-today"
              :icon-right="selectedDate ? 'close-circle' : ''"
              icon-right-clickable
              @icon-right-click.stop="clearDate"
              :date-formatter="formatDate"
            >
            </b-datepicker>
          </div>

          <div class="filter-section" style="min-width: 150px">
            <label class="label-filter">สถานะสรุปผลการรักษา</label>
            <b-select v-model="filterCases" rounded expanded>
              <option
                v-for="option in filterCaseList"
                :value="option.value"
                :key="option.value"
              >
                {{ option.name }}
              </option>
            </b-select>
          </div>

          <div
            class="filter-section"
            style="display: flex; flex-direction: row; gap: 8px;"
          >
            <b-button
              type="is-app-success"
              rounded
              icon-left="magnify"
              @click="selectFilterRecord"
            >
              <span class="button-filter">ค้นหา</span>
            </b-button>
            <b-button
              type="is-app-danger"
              rounded
              icon-left="filter-remove-outline"
              @click="clearFilter"
            >
              <span class="button-filter">Clear</span>
            </b-button>
          </div>
        </div>
      </div>

      <div class="divider" />

      <div v-if="records.length">
        <div v-for="(item, index) in records" :key="index">
          <RecordItem
            :name="item.patientName"
            :birthDate="item.patientBirthDate"
            :symptoms="item.symptoms || []"
            :gender="item.patientGender"
            :date="item.createdAt"
            :id="item.id"
            :status="item.completed"
          />
        </div>
      </div>
      <div v-else class="no-data">ไม่มีข้อมูล</div>
    </div>
    <InfiniteLoading
      ref="dashboardHomeInfiniteLoading"
      v-show="records.length"
      spinner="spiral"
      @infinite="getMoreRecords"
    >
      <div slot="no-more">ข้อมูลครบแล้ว</div>
      <div slot="no-results">ข้อมูลครบแล้ว</div>
    </InfiniteLoading>
  </div>
</template>

<script>
import RecordItem from '@/components/RecordItem'
import InfiniteLoading from 'vue-infinite-loading'
import { mapState, mapActions } from 'vuex'
import api from '@/utils/api'
import debounce from 'lodash/debounce'
import moment from 'moment'

export default {
  name: 'DashboardHome',
  components: {
    RecordItem,
    InfiniteLoading
  },
  data() {
    return {
      records: [],
      page: 1,
      perPage: 20,
      summaryCaseAmount: 0,
      filterCases: 'All',
      filterCaseList: [
        {
          name: 'แสดงทั้งหมด',
          value: 'All'
        },
        {
          name: 'Completed',
          value: 'Completed'
        },
        {
          name: 'Pending',
          value: 'Pending'
        }
      ],
      accountSummary: {},
      search: '',
      selectedDate: null,
      isFetchingSearchPatient: false,
      searchPatient: []
    }
  },
  computed: {
    ...mapState({
      user: 'user',
      totalSummary: 'totalSummary'
    })
  },
  async created() {
    this.setLoading(true)
    ;[this.accountSummary] = await Promise.all([
      api.getAccountSummary(),
      this.getRecords()
    ])
    this.setLoading(false)
  },
  methods: {
    ...mapActions({
      setLoading: 'setLoading'
    }),
    async getRecords() {
      const { data } = await api.getRecords(
        this.page,
        this.perPage,
        null,
        null,
        this.user.doctor.id,
        this.search,
        this.selectedDate
      )
      this.records = data
    },
    async getMoreRecords(state) {
      this.page += 1
      const response = await api.getRecords(
        this.page,
        this.perPage,
        this.filterCases.toLowerCase(),
        null,
        this.user.doctor.id,
        this.search,
        this.selectedDate
      )
      if (response.data.length) {
        this.records.push(...response.data)
        state.loaded()
      } else {
        state.complete()
      }
    },
    async selectFilterRecord() {
      this.$refs.dashboardHomeInfiniteLoading.stateChanger.reset()
      this.setLoading(true)
      this.page = 1
      const { data } = await api.getRecords(
        this.page,
        this.perPage,
        this.filterCases.toLowerCase(),
        null,
        this.user.doctor.id,
        this.search,
        this.selectedDate
      )
      this.records = data
      this.setLoading(false)
    },
    getSearchPatient: debounce(async function(search) {
      this.isFetchingSearchPatient = true
      try {
        const result = await api.getSearchPatientByDoctor(search)
        this.searchPatient = result
      } catch (e) {
        console.log(e)
      } finally {
        this.isFetchingSearchPatient = false
      }
    }, 500),
    clearDate() {
      this.selectedDate = null
    },
    clearFilter() {
      this.search = ''
      this.selectedDate = null
      this.filterCases = 'All'
      this.selectFilterRecord()
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
.db-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
}
.db-card {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 30px 50px 20px;
  height: 100%;
  display: flex;
  .icon {
    width: 65px;
    display: inline-block;
  }
  .detail {
    display: inline-block;
    margin-left: 20px;
    .head {
      color: #888888;
      font-size: 16px;
    }
    .value {
      color: #3e4a59;
      font-size: 30px;
      .sub-value {
        color: #888888;
        font-size: 15px;
      }
      .currency {
        color: #3e4a59;
        font-size: 15px;
      }
    }
    .btn {
      border-radius: 15px;
      background-color: #34beb3;
      color: #fff;
      padding: 5px 25px;
      text-align: center;
      font-size: 13px;
      cursor: pointer;
    }
  }
}
.divider {
  width: 100%;
  height: 2px;
  background-color: #dbd6d3;
  margin: 15px 0;
}
.label-filter {
  font-weight: lighter;
  margin-bottom: 4px;
}
.filter-section {
  display: flex;
  flex-direction: column;
}
.subtitle-totalcase {
  font-size: 20px;
  color: #3e4a59;
  margin-bottom: 16px;
}
.button-filter {
  font-weight: lighter;
}
.no-data {
  width: 100%;
  text-align: center;
}
.filter-container {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 8px;
  font-size: 14px;
}
/* Less than mobile */
@media only screen and (max-width: 768px) {
  .db-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>

<style lang="scss">
.filter-container {
  input::placeholder,
  input,
  select,
  option {
    font-size: 14px !important;
  }

  select {
    margin-top: 4px;
  }

  button {
    font-size: 14px !important;
  }
}
</style>
