<template>
  <div class="db-container">
    <div>
      <div class="is-title">
        <img
          src="@/assets/svg/back-black.svg"
          @click="() => $router.go(-1)"
          class="back-btn"
        />นโยบายความเป็นส่วนตัว
      </div>
      <div class="divider" />
      <div
        class="settings-card"
        style="cursor: pointer"
        @click="() => $router.push({ name: 'PolicyPhysician' })"
      >
        <div class="rows">
          <div class="row">
            <div class="how-to">
              <div class="item">
                <img src="@/assets/svg/policy.svg" />
                <span> เอกสารแจ้งการประมวลผลข้อมูลส่วนบุคคลสำหรับแพทย์ </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="settings-card"
        style="cursor: pointer"
        @click="() => $router.push({ name: 'PolicyApplicant' })"
      >
        <div class="rows">
          <div class="row">
            <div class="how-to">
              <div class="item">
                <img src="@/assets/svg/policy.svg" />
                <span> เอกสารแจ้งการประมวลผลข้อมูลส่วนบุคคลสำหรับผู้สมัครเป็นแพทย์ </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'Policy',
  data() {
    return {
      isLoading: true,
      setting: {
        notiEmail: null
      }
    }
  },
  computed: {},
  async created() {
    const loadingComponent = this.$buefy.loading.open({
      container: this.$refs.cnt
    })
    const { data: setting } = await api.getSetting()
    this.setting = setting
    loadingComponent.close()
    this.isLoading = false
  },
  methods: {
    async changeSetting() {
      const loadingComponent = this.$buefy.loading.open({
        container: this.$refs.cnt
      })
      const { data: newSetting } = await api.updateSetting(this.setting)
      this.setting = newSetting
      loadingComponent.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.back-btn {
  margin-right: 13px;
  width: 21px;
  cursor: pointer;
}
.db-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 24px 14px;
  height: 100vh;
}
.divider {
  width: 100%;
  height: 2px;
  background-color: #dbd6d3;
  margin: 15px 0;
}
.is-title {
  color: #3e4a59;
  font-size: 20px;
  display: flex;
}
.settings-card {
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(172, 172, 172, 0.19);
  background-color: #ffffff;
  padding: 20px;
  color: #3e4a59;
  font-weight: 400;
  font-size: 17px;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.item {
  img {
    margin-right: 27px;
  }
}
.how-to {
  display: flex;
  flex-direction: row;
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
  }
}
/* Less than mobile */
@media only screen and (max-width: 849px) {
  .db-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .content {
    flex-direction: column;
    .item {
      margin-top: 20px;
      justify-content: left;
    }
  }
}
</style>
