var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-field',{attrs:{"label":"ID Number or Passport Number"}},[_c('ValidationProvider',{attrs:{"name":"ID Number or Passport Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","rounded":""},model:{value:(_vm.form.idNumber),callback:function ($$v) {_vm.$set(_vm.form, "idNumber", $$v)},expression:"form.idNumber"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"upload-btn-ctn"},[_c('ValidationProvider',{attrs:{"name":"ID Number or Passport Number Photo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-upload',{attrs:{"accept":"image/*"},on:{"input":function (file) { return _vm.previewImage(file, 'idCardImgData'); }},model:{value:(_vm.form.idCardFile),callback:function ($$v) {_vm.$set(_vm.form, "idCardFile", $$v)},expression:"form.idCardFile"}},[_c('div',{staticClass:"button is-primary is-rounded upload-btn",attrs:{"type":"is-primary","rounded":""}},[_c('div',{staticClass:"inside"},[_c('img',{attrs:{"src":require("@/assets/svg/upload-photo.svg")}}),_vm._v(" Upload Photo ")])])]),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.form.idCardImgData)?_c('div',{staticClass:"preview-img-ctn"},[_c('img',{attrs:{"src":_vm.form.idCardImgData}}),_c('img',{staticClass:"close-btn",attrs:{"src":require("@/assets/svg/close-img.svg")},on:{"click":function($event){return _vm.clickClosePreviewImage('idCardImgData', 'idCardFile')}}})]):_vm._e(),_c('div',{staticClass:"divider"}),_c('b-field',{attrs:{"label":"Bank Account (for money transfer)"}},[_c('ValidationProvider',{attrs:{"name":"Bank Account","rules":{
          required: true,
          containIn: { array: _vm.banks.map(function (b) { return b.name; }) }
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-autocomplete',{attrs:{"data":_vm.filteredBanksData,"field":"name","clearable":"","rounded":"","open-on-focus":""},on:{"select":function (option) { return (_vm.form.bank = option); }},model:{value:(_vm.bankInput),callback:function ($$v) {_vm.bankInput=$$v},expression:"bankInput"}},[_c('template',{slot:"empty"},[_vm._v(" No results found ")])],2),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Bank Account Number (for money transfer)"}},[_c('ValidationProvider',{attrs:{"name":"Bank Account Number","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","rounded":""},model:{value:(_vm.form.bankAccNumber),callback:function ($$v) {_vm.$set(_vm.form, "bankAccNumber", $$v)},expression:"form.bankAccNumber"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"upload-btn-ctn"},[_c('ValidationProvider',{attrs:{"name":"Bank Account Photo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-upload',{attrs:{"accept":"image/*"},on:{"input":function (file) { return _vm.previewImage(file, 'bookBankImgData'); }},model:{value:(_vm.form.bookBankFile),callback:function ($$v) {_vm.$set(_vm.form, "bookBankFile", $$v)},expression:"form.bookBankFile"}},[_c('div',{staticClass:"button is-primary is-rounded upload-btn",attrs:{"type":"is-primary","rounded":""}},[_c('div',{staticClass:"inside"},[_c('img',{attrs:{"src":require("@/assets/svg/upload-photo.svg")}}),_vm._v(" Upload Photo ")])])]),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.form.bookBankImgData)?_c('div',{staticClass:"preview-img-ctn"},[_c('img',{attrs:{"src":_vm.form.bookBankImgData}}),_c('img',{staticClass:"close-btn",attrs:{"src":require("@/assets/svg/close-img.svg")},on:{"click":function($event){return _vm.clickClosePreviewImage('bookBankImgData', 'bookBankFile')}}})]):_vm._e(),_c('div',{staticClass:"divider"}),_c('b-button',{staticClass:"submit-btn",attrs:{"loading":_vm.isLoading,"disabled":invalid,"type":"is-secondary","rounded":"","expanded":""},on:{"click":function($event){return _vm.$emit('submit', _vm.form)}}},[_vm._v(" Submit ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }