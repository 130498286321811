<template>
  <div class="sidebar-page">
    <div class="is-hidden-desktop">
      <div id="db-doctor-profile-switch" class="doctor-switch">
        <span>
          {{ isDoctorOnlineStatus ? 'Online' : 'Offline' }}
        </span>
        <b-switch
          type="is-success"
          :value="isDoctorOnlineStatus"
          @click.native.prevent="switchDoctorOnlineStatus"
        />
      </div>
      <b-navbar id="dashboard-navbar">
        <template slot="brand">
          <b-navbar-item>
            <img src="@/assets/img/logo.png" />
          </b-navbar-item>
        </template>
        <template slot="start">
          <DashboardDoctorProfile />
          <b-navbar-item @click="$router.push({ name: 'ContentListPage' })">
            ข่าวสารจาก SkinX
          </b-navbar-item>
          <b-navbar-item
            class="navbar-item"
            @click="$router.push({ name: 'Dashboard' })"
          >
            หน้าหลัก
          </b-navbar-item>
          <b-navbar-item @click="$router.push({ name: 'WaitingQueueList' })">
            ห้องรอตรวจ
            {{ countWaitingQueue > 0 ? `(${countWaitingQueue})` : '' }}
            <span
              v-if="countWaitingQueue > 0"
              style="
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #fa4239;
                float: right;
                margin-top: 7px;
              "
            >
            </span>
          </b-navbar-item>
          <b-navbar-item @click="$router.push({ name: 'Notification' })">
            แจ้งเตือน
            <span
              v-if="notiData.length > 0"
              style="
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #fa4239;
                float: right;
                margin-top: 7px;
              "
            >
            </span>
          </b-navbar-item>
          <b-navbar-item @click="$router.push({ name: 'DashboardFinance' })">
            การเงิน
          </b-navbar-item>

          <b-navbar-dropdown label="ส่วนตัว">
            <b-navbar-item @click="$router.push({ name: 'DashboardPersonal' })">
              ข้อมูลส่วนตัว
            </b-navbar-item>
            <b-navbar-item
              @click="$router.push({ name: 'DashboardScheduler' })"
            >
              ตารางนัดหมาย
            </b-navbar-item>
            <b-navbar-item @click="$router.push({ name: 'DashboardDrugSet' })">
              Drug Set
            </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-item @click="$router.push({ name: 'DashboardSettings' })">
            ตั้งค่า
          </b-navbar-item>
        </template>
      </b-navbar>
    </div>
    <section class="sidebar-layout">
      <b-sidebar
        id="dashboard-sidebar"
        :can-cancel="false"
        position="fixed"
        mobile="hide"
        type="is-white"
        fullheight
        open
      >
        <DashboardDoctorProfile />
        <b-menu>
          <b-menu-list>
            <b-menu-item :active="checkPage('ContentListPage')">
              <template #label="props">
                <div
                  :class="[
                    'sidebar-menu-list',
                    {
                      'sidebar-menu-list-active': props.active
                    }
                  ]"
                  @click="$router.push({ name: 'ContentListPage' })"
                >
                  <img
                    :src="
                      props.active
                        ? require('@/assets/svg/news-active.svg')
                        : require('@/assets/svg/news.svg')
                    "
                  />
                  <span>ข่าวสารจาก SkinX</span>
                </div>
              </template>
            </b-menu-item>
            <b-menu-item :active="checkPage('Dashboard')">
              <template #label="props">
                <div
                  :class="[
                    'sidebar-menu-list',
                    { 'sidebar-menu-list-active': props.active }
                  ]"
                  @click="$router.push({ name: 'Dashboard' })"
                >
                  <img
                    :src="
                      props.active
                        ? require('@/assets/svg/home-active.svg')
                        : require('@/assets/svg/home.svg')
                    "
                  />
                  <span>หน้าหลัก</span>
                </div>
              </template>
            </b-menu-item>
            <b-menu-item :active="checkPage('WaitingQueueList')">
              <template #label="props">
                <div
                  :class="[
                    'sidebar-menu-list',
                    {
                      'sidebar-menu-list-active': props.active
                    }
                  ]"
                  @click="$router.push({ name: 'WaitingQueueList' })"
                >
                  <img
                    :src="
                      props.active
                        ? require('@/assets/svg/waiting-queue-active.svg')
                        : require('@/assets/svg/waiting-queue.svg')
                    "
                    class="ic-personal"
                  />
                  <span
                    >ห้องรอตรวจ
                    {{
                      countWaitingQueue > 0 ? `(${countWaitingQueue})` : ''
                    }}</span
                  >
                  <span
                    v-if="countWaitingQueue > 0"
                    style="
                      width: 10px;
                      height: 10px;
                      border-radius: 50%;
                      background-color: #fa4239;
                      float: right;
                      margin-top: 7px;
                    "
                  >
                  </span>
                </div>
              </template>
            </b-menu-item>
            <b-menu-item :active="checkPage('Notification')">
              <template #label="props">
                <div
                  :class="[
                    'sidebar-menu-list',
                    {
                      'sidebar-menu-list-active': props.active
                    }
                  ]"
                  @click="$router.push({ name: 'Notification' })"
                >
                  <img
                    :src="
                      props.active
                        ? require('@/assets/svg/bell-icon-active.svg')
                        : require('@/assets/svg/bell-icon.svg')
                    "
                    class="ic-personal"
                  />
                  <span>แจ้งเตือน</span>
                  <span
                    v-if="notiData.length > 0"
                    style="
                      width: 10px;
                      height: 10px;
                      border-radius: 50%;
                      background-color: #fa4239;
                      float: right;
                      margin-top: 7px;
                    "
                  >
                  </span>
                </div>
              </template>
            </b-menu-item>
            <b-menu-item :active="checkPage('DashboardFinance')">
              <template #label="props">
                <div
                  :class="[
                    'sidebar-menu-list',
                    {
                      'sidebar-menu-list-active': props.active
                    }
                  ]"
                  @click="$router.push({ name: 'DashboardFinance' })"
                >
                  <img
                    :src="
                      props.active
                        ? require('@/assets/svg/finance-active.svg')
                        : require('@/assets/svg/finance.svg')
                    "
                  />
                  <span>การเงิน </span>
                </div>
              </template>
            </b-menu-item>
            <b-menu-item :active="checkPage('DashboardPersonal')">
              <template #label="props">
                <div
                  :class="[
                    'sidebar-menu-list',
                    {
                      'sidebar-menu-list-active': props.active
                    }
                  ]"
                >
                  <img
                    :src="
                      props.active
                        ? require('@/assets/svg/person-active.svg')
                        : require('@/assets/svg/person.svg')
                    "
                    class="ic-personal"
                  />
                  <span>ส่วนตัว</span>
                  <b-icon
                    class="is-pulled-right"
                    :icon="props.expanded ? 'chevron-up' : 'chevron-down'"
                    pack=""
                  ></b-icon>
                </div>
              </template>
              <b-menu-item
                :active="
                  checkPage('DashboardPersonal') ||
                    checkPage('DashboardScheduler')
                "
              >
                <template #label="props">
                  <div
                    :class="[
                      'sub-sidebar-menu-list',
                      {
                        'sub-sidebar-menu-list-active': props.active
                      }
                    ]"
                    @click="$router.push({ name: 'DashboardPersonal' })"
                  >
                    <span>ข้อมูลส่วนตัว</span>
                  </div>
                </template>
              </b-menu-item>
              <b-menu-item :active="checkPage('DashboardScheduler')">
                <template #label="props">
                  <div
                    :class="[
                      'sub-sidebar-menu-list',
                      {
                        'sub-sidebar-menu-list-active': props.active
                      }
                    ]"
                    @click="$router.push({ name: 'DashboardScheduler' })"
                  >
                    <span>ตารางนัดหมาย</span>
                  </div>
                </template>
              </b-menu-item>
              <b-menu-item :active="checkPage('DashboardDrugSet')">
                <template #label="props">
                  <div
                    :class="[
                      'sub-sidebar-menu-list',
                      {
                        'sub-sidebar-menu-list-active': props.active
                      }
                    ]"
                    @click="$router.push({ name: 'DashboardDrugSet' })"
                  >
                    <span>Drug Set</span>
                  </div>
                </template>
              </b-menu-item>
            </b-menu-item>
            <b-menu-item :active="checkPage('DashboardSettings')">
              <template #label="props">
                <div
                  :class="[
                    'sidebar-menu-list',
                    {
                      'sidebar-menu-list-active': props.active
                    }
                  ]"
                  @click="$router.push({ name: 'DashboardSettings' })"
                >
                  <img
                    :src="
                      props.active
                        ? require('@/assets/svg/settings-active.svg')
                        : require('@/assets/svg/settings.svg')
                    "
                  />
                  <span>ตั้งค่า</span>
                </div>
              </template>
            </b-menu-item>
          </b-menu-list>
        </b-menu>

        <!-- <img class="sidebar-logo is-hidden-touch" src="@/assets/img/logo.png" /> -->
      </b-sidebar>
      <div class="dashboard-content">
        <router-view />
      </div>
      <b-modal v-model="isShowModal" :can-cancel="['escape', 'outside']">
        <HowToVideo></HowToVideo>
      </b-modal>
      <div
        class="close-modal-btn"
        v-if="this.isShowModal"
        @click="onCloseModal"
      >
        <u>Close</u>
      </div>
    </section>
  </div>
</template>

<script>
import DashboardDoctorProfile from '@/components/DashboardDoctorProfile'
import HowToVideo from '@/components/HowToVideo'
import { mapState, mapActions } from 'vuex'
import api from '@/utils/api'
import LogRocket from 'logrocket'
import Swal from 'sweetalert2'

export default {
  name: 'Dashboard',
  components: {
    DashboardDoctorProfile,
    HowToVideo
  },
  data() {
    return {
      notiData: [],
      isShowModal: false
    }
  },
  computed: {
    ...mapState({
      user: 'user',
      isDoctorOnlineStatus: 'isDoctorOnlineStatus',
      countWaitingQueue: 'countWaitingQueue'
    })
  },
  async created() {
    const data = await api.getRecordTotalSummary()
    this.setTotalSummary(data)
    this.getAppointments()
    if (process.env.NODE_ENV === 'production') {
      LogRocket.init('mstkql/skinx')
      LogRocket.identify(`doctor_${this.user.doctor.id}`, {
        name: this.user.doctor.name
      })
    }
    this.isShowModal = window.localStorage.getItem('isWatchVideo') !== '1'
  },
  methods: {
    ...mapActions({
      getUser: 'getUser',
      setIsDoctorOnlineStatus: 'setIsDoctorOnlineStatus',
      setTotalSummary: 'setTotalSummary'
    }),
    onCloseModal() {
      window.localStorage.setItem('isWatchVideo', 1)
      this.isShowModal = false
    },
    checkPage(menuName) {
      return menuName === this.$route.name
    },
    async getAppointments() {
      this.notiData = await api.getAppointments(1, 1)
    },
    async switchDoctorOnlineStatus() {
      const value = !this.isDoctorOnlineStatus
      let status = 0
      if (value) {
        status = 1
        this.$ga.event({
          eventCategory: 'Online',
          eventAction: 'Click',
          eventLabel: 'Online'
        })
      } else {
        this.$ga.event({
          eventCategory: 'Offline',
          eventAction: 'Click',
          eventLabel: 'Offline'
        })
      }
      try {
        await api.patchDoctorStatus(status)
        await this.getUser()
      } catch (e) {
        console.error(e)
        Swal.fire(
          'Network error',
          'please reconnect network and try again.',
          'error'
        )
      }
      // this.setIsDoctorOnlineStatus(value !== 0)
    }
  }
}
</script>

<style lang="scss">
.menu-list a {
  background-color: inherit !important;
  color: inherit !important;
  padding: 0 !important;
}
.menu-list li ul {
  border-left: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}
#dashboard-navbar {
  .navbar-brand {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: calc(50% + (98px / 2));
    .navbar-burger {
      margin-left: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.close-modal-btn {
  position: absolute;
  left: calc(50% - 5px);
  top: calc(50% + 250px);
  color: #ffffff;
  z-index: 99999;
  cursor: pointer;
}
.sidebar-menu-list {
  cursor: pointer;
  padding: 16px 30px;
  img {
    width: 30px;
    height: 30px;
    vertical-align: text-bottom;
    margin-right: 20px;
  }
  .ic-personal {
    width: 30px;
    height: 23px;
    vertical-align: text-bottom;
    margin-right: 20px;
  }
}
.sub-sidebar-menu-list {
  cursor: pointer;
  padding: 16px 0px 16px 80px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #e6e6e6;
  img {
    width: 30px;
    height: 30px;
    vertical-align: text-bottom;
    margin-right: 20px;
  }
  .ic-personal {
    width: 30px;
    height: 23px;
    vertical-align: text-bottom;
    margin-right: 20px;
  }
}
.sidebar-menu-list-active,
.sidebar-menu-list:hover {
  color: #34beb3;
}
.sub-sidebar-menu-list-active,
.sub-sidebar-menu-list:hover {
  color: #34beb3;
}
.dashboard-content {
  padding: 30px 15px;
  width: 100%;
  padding-left: calc(260px + 15px);
}
.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
  }
}
.sidebar-logo {
  width: 120px;
  position: fixed;
  bottom: 30px;
  left: 30px;
}
.navbar-item {
  border-top: 1px solid #efecea;
}
.doctor-switch {
  position: absolute;
  z-index: 31;
  right: 0px;
  top: 13px;
  display: flex;
  width: 112px;
  justify-content: space-between;
  align-items: center;
  span {
    color: #585858;
    font-size: 13px;
  }
}
/* Less than tablet */
@media (max-width: 1023px) {
  #dashboard-sidebar {
    display: none;
  }
  .dashboard-content {
    padding-left: 15px;
  }
}
@media (max-width: 769px) {
  .dashboard-content {
    padding: 15px 15px;
  }
}
</style>
