<template>
  <div class="db-container">
    <div class="top-section">
      <div id="finance-slide">
        <carousel-3d
          :perspective="0"
          :space="financeSlideSpace"
          :display="3"
          :border="0"
          :width="financeSlideSize"
          :height="financeSlideSize"
        >
          <slide :index="0">
            <div
              :class="{
                'slide-base': true,
                'slide-1': true,
                'slide-mobile': isMobile
              }"
            >
              <div class="inside">
                <div>
                  Available balance
                </div>
                <div class="amount-row">
                  {{ summary.withdrawable | numberWithCommas }}
                </div>
                <div>
                  THB
                </div>
              </div>
            </div>
          </slide>
          <!-- <slide :index="1">
            <ValidationObserver ref="formObserver">
              <div
                :class="{
                  'slide-base': true,
                  'slide-2': true,
                  'slide-mobile': isMobile
                }"
              >
                <div class="inside">
                  <div>
                    Withdraw
                  </div>
                  <div>
                    Amount
                  </div>
                  <div>
                    <ValidationProvider
                      :rules="{
                        max_value: summary.withdrawable,
                        min_value: 1,
                        required: true
                      }"
                      v-slot="{ errors }"
                      name="Amount"
                    >
                      <input
                        v-model="withdrawAmount"
                        class="withdraw-input"
                        type="number"
                      />
                      <span class="error-msg">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <b-button
                    :loading="isLoading"
                    class="withdraw-btn"
                    type="is-white"
                    outlined
                    @click="withdraw"
                  >
                    Withdraw
                  </b-button>
                </div>
              </div>
            </ValidationObserver>
          </slide> -->
          <slide :index="1">
            <div
              :class="{
                'slide-base': true,
                'slide-3': true,
                'slide-mobile': isMobile
              }"
            >
              <div class="inside">
                <div>
                  Total balance
                </div>
                <div class="amount-row">
                  {{ summary.balance | numberWithCommas }}
                </div>
                <div>
                  THB
                </div>
              </div>
            </div>
          </slide>
        </carousel-3d>
      </div>
      <div class="db-card">
        <div class="half-col">
          <div class="small-row">
            Total balance in this month ({{ getCurrentMonthInThai() }})
          </div>
          <div class="big-row">
            {{ summary.monthly | numberWithCommas }}
          </div>
          <div class="small-row">
            THB
          </div>
        </div>
        <div class="half-col">
          <div class="small-row">
            Total earnings
          </div>
          <div class="big-row">
            {{ summary.income | numberWithCommas }}
          </div>
          <div class="small-row">
            THB
          </div>
        </div>
      </div>
      <div class="note-container">
        Note:
        <div>
          1. The payment will be made on 15th every month
        </div>
      </div>
    </div>
    <div>
      <div class="subtitle">
        History
      </div>
      <div class="divider" />
      <div v-if="transactions.length">
        <div v-for="(tx, i) in transactions" :key="i" class="db-card">
          <div class="db-row">
            <div>
              Transaction :
              <span class="fw-500">{{ translateType(tx.type) }}</span>
            </div>
            <div></div>
          </div>
          <div class="db-row">
            <div>
              {{ tx.createdAt | toDateFormat('YYYY.MM.DD HH:MM') }}
            </div>
            <div :class="['db-red', { 'db-blue': tx.amount >= 0 }]">
              <span class="fw-500"
                ><span v-if="tx.amount >= 0">+</span>{{ tx.amount }} THB</span
              >
            </div>
          </div>
          <div class="divider" />
          <div class="db-row">
            <div>
              {{ tx.note || 'Amount after deduct a transaction fee' }}
            </div>
            <div>{{ tx.amount }} THB</div>
          </div>
          <div class="db-row">
            <div>
              Note
            </div>
            <div
              :class="[
                {
                  'db-green': tx.status === 'ถอนเงินสำเร็จ',
                  'db-orange': tx.status === 'อยู่ระหว่างดำเนินการถอนเงิน'
                }
              ]"
            >
              {{ translateStatus(tx.status) }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="note-container">
        No data.
      </div>
      <InfiniteLoading
        v-show="transactions.length"
        spinner="spiral"
        @infinite="getMoreTransactions"
      >
        <div slot="no-more">No more data.</div>
        <div slot="no-results">No more data.</div>
      </InfiniteLoading>
    </div>
  </div>
</template>

<script>
import { Slide } from 'vue-carousel-3d'
import Carousel3d from '@/components/Carousel3d'
import InfiniteLoading from 'vue-infinite-loading'
import api from '@/utils/api'

const mobileMatcher = window.matchMedia('(max-width: 768px)')

export default {
  name: 'DashboardFinance',
  components: {
    Carousel3d,
    Slide,
    InfiniteLoading
  },
  data() {
    let isMobile, financeSlideSize, financeSlideSpace
    if (mobileMatcher.matches) {
      isMobile = true
      financeSlideSize = 185
      financeSlideSpace = 205
    } else {
      isMobile = false
      financeSlideSize = 270
      financeSlideSpace = 300
    }
    return {
      summary: {},
      page: 1,
      perPage: 20,
      transactions: [],
      withdrawAmount: null,
      isLoading: false,
      isMobile: isMobile,
      financeSlideSize: financeSlideSize,
      financeSlideSpace: financeSlideSpace
    }
  },
  async created() {
    let txs
    ;[this.summary, txs] = await Promise.all([
      api.getAccountSummary(),
      api.getAccounts(this.page, this.perPage)
    ])
    this.transactions = txs.data

    mobileMatcher.addEventListener('change', this.onMobile)
  },
  unmounted() {
    mobileMatcher.removeEventListener('change', this.onMobile)
  },
  methods: {
    onMobile(m) {
      if (m.matches) {
        this.isMobile = true
        this.financeSlideSize = 185
        this.financeSlideSpace = 205
      } else {
        this.isMobile = false
        this.financeSlideSize = 270
        this.financeSlideSpace = 300
      }
    },
    getCurrentMonthInThai() {
      const now = new Date()
      const thmonth = [
        'ม.ค.',
        'ก.พ.',
        'มี.ค.',
        'เม.ย.',
        'พ.ค.',
        'มิ.ย.',
        'ก.ค.',
        'ส.ค.',
        'ก.ย.',
        'ต.ค.',
        'พ.ย.',
        'ธ.ค.'
      ]
      return (
        thmonth[now.getMonth()] +
        ' ' +
        (now.getFullYear() + 543).toString().substr(2, 2)
      )
    },
    async getMoreTransactions(state) {
      this.page += 1
      const response = await api.getAccounts(this.page, this.perPage)
      if (response.data.length >= this.perPage) {
        this.transactions.push(...response.data)
        state.loaded()
      } else {
        state.complete()
      }
    },
    validateForm() {
      return new Promise(resolve => {
        this.$refs.formObserver.validate().then(valid => {
          if (!valid) {
            setTimeout(() => {
              const errors = Object.entries(this.$refs.formObserver.errors)
                .map(([key, value]) => ({ key, value }))
                .filter(error => error.value.length)
              this.$refs.formObserver.refs[errors[0].key].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              })
            }, 100)
          }
          resolve(valid)
        })
      })
    },
    async withdraw() {
      this.isLoading = true
      const formValid = await this.validateForm()
      if (!formValid) {
        this.isLoading = false
        return
      }
      this.$ga.event({
        eventCategory: 'Withdraw',
        eventAction: 'Click',
        eventLabel: 'Withdraw',
        eventValue: this.withdrawAmount
      })
      try {
        const result = await api.postAccountWithdraw(this.withdrawAmount)
        if (result.success) {
          this.page = 1
          this.withdrawAmount = null
          const { data } = await api.getAccounts(this.page, this.perPage)
          this.transactions = data
          this.$buefy.toast.open({
            type: 'is-success',
            message: 'ถอนเงินสำเร็จ',
            position: 'is-top-right'
          })
        }
      } catch (e) {
        console.error(e)
        this.$buefy.toast.open({
          type: 'is-danger',
          message: e.response?.data.message ?? 'Unexpected Error',
          position: 'is-top-right'
        })
      }
      this.isLoading = false
    },
    translateType(type) {
      if (type === 'ค่าบริการให้คำปรึกษา') {
        return 'Doctor fee'
      } else if (type === 'ยกเลิกรายการ') {
        return 'Voided'
      } else {
        return 'Withdrawal'
      }
    },
    translateStatus(status) {
      if (status === 'ถอนเงินสำเร็จ') {
        return 'Withdrawn success'
      } else if (status === 'อยู่ระหว่างดำเนินการถอนเงิน') {
        return 'In procress of withdrawing money'
      } else {
        return 'Cannot be withdrawn'
      }
    }
  }
}
</script>

<style lang="scss">
#finance-slide {
  margin-bottom: 30px;
  .carousel-3d-slide {
    background: none !important;
  }
}
/* Less than mobile */
@media only screen and (max-width: 768px) {
  #finance-slide {
    width: 100vw;
    margin-left: -30px;
  }
}
</style>

<style lang="scss" scoped>
.db-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: #dbd6d3;
  margin: 15px 0;
}
.top-section {
  padding-bottom: 28px;
  width: fit-content;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
}
.slide-base {
  width: 270px;
  color: white;
  border-radius: 50%;
  height: 270px;
  text-align: center;
  padding: 8px;
  outline: none;
  cursor: pointer;
  .inside {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 0;
    font-size: 20px;
    .amount-row {
      font-weight: bold;
      font-size: 40px;
    }
    .error-msg {
      padding-left: 0;
    }
  }

  &.slide-mobile {
    width: 185px;
    height: 185px;
    .inside {
      padding: 28px 0;
      font-size: 12px;
      .amount-row {
        font-weight: bold;
        font-size: 30px;
      }

      .withdraw-input {
        font-size: 12px;
      }
      .withdraw-btn {
        font-size: 12px;
      }

      .error-msg {
        font-size: 12px;
        line-height: 0.9;
      }
    }

    &.slide-2 {
      .inside {
        padding: 18px 0;
      }
    }
  }
}
.slide-1 {
  background-image: linear-gradient(to bottom, #353535, #747676);
  .inside {
    border: 4px solid #34beb3;
  }
}
.slide-2 {
  background-image: linear-gradient(to bottom, #a5ebe6, #34b4aa);
  .inside {
    border: 4px solid #80beb9;
  }
}
.slide-3 {
  background-image: linear-gradient(to bottom, #353535, #747676);
  .inside {
    border: 4px solid #be3499;
  }
}
.withdraw-input {
  border-radius: 20px;
  background-color: #43aba3;
  border: 0;
  text-align: center;
  padding: 8px;
  font-size: 20px;
  width: 70%;
  color: #fff;
  outline: none;
  margin-top: 10px;
  margin-bottom: 20px;
}
.withdraw-btn {
  cursor: pointer;
  border-radius: 30px;
  width: fit-content;
  margin: 0 auto;
  font-size: 15px;
  width: 50%;
  padding: 15px;
  line-height: 0px;
  height: 100%;
}
.withdraw-btn:hover {
  background: transparent !important;
  color: #fff !important;
}
.db-card {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px 17px;
  box-shadow: 0 4px 10px 0 rgba(172, 172, 172, 0.19);
  margin-bottom: 17px;
  font-weight: 100;
}
.half-col {
  width: calc(50% - 0.5px);
  display: inline-block;
  text-align: center;
  .small-row {
    color: #888888;
    font-size: 16px;
  }
  .big-row {
    color: #3e4a59;
    font-size: 40px;
    font-weight: bold;
  }
}
.half-col:first-child {
  border-right: 1px solid #dbd6d3;
}
.note-container {
  color: #888888;
  font-size: 14px;
  font-weight: 100;
}
.db-row {
  display: flex;
  justify-content: space-between;
}
.db-red {
  color: #ff434e;
}
.db-orange {
  color: #f5c079;
}
.db-green {
  color: #7dc778;
}
.db-blue {
  color: #34beb3;
}
.fw-500 {
  font-weight: 500;
}
.error-msg {
  margin-top: -15px;
  margin-bottom: 5px;
  display: block;
  font-weight: 100;
}
/* Less than mobile */
@media only screen and (max-width: 768px) {
  .db-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .half-col {
    width: 100%;
    display: block;
    text-align: center;
  }
  .half-col:first-child {
    border-right: none;
    border-bottom: 1px solid #dbd6d3;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .top-section {
    min-width: 0;
  }
}
</style>
