import api from '@/utils/api'

export async function mapUserNameFromSnapshot(snapshot) {
  const channels = []
  let ids = {}
  snapshot.forEach(doc => {
    const data = {
      ...doc.data(),
      id: doc.id
    }
    ids[data?.permission?.owner] = true
    channels.push(data)
  })
  if (channels.length > 0) {
    ids = Object.keys(ids)
    const data = await api.getUsers(ids)
    channels.forEach(channel => {
      channel.permission.ownerName = data[channel.permission?.owner]?.name
      channel.permission.ownerData = data[channel.permission?.owner]
    })
  }
  return channels
}
