<template>
  <div class="db-container" ref="cnt">
    <div>
      <div class="is-title">ตั้งค่า</div>
      <div class="divider" />
      <div class="settings-card">
        <div class="rows">
          <div class="row">
            <div class="how-to">
              <div class="item">
                <img src="@/assets/svg/notification.svg" width="24px" />
                <span> แจ้งเตือน </span>
              </div>
              <div class="action">
                <b-checkbox
                  v-model="setting.notiEmail"
                  @input="changeSetting()"
                  type="is-app-success"
                >
                  อีเมล
                </b-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="settings-card"
        style="cursor: pointer"
        @click="() => $router.push({ name: 'HowToPDF' })"
      >
        <div class="rows">
          <div class="row">
            <div class="how-to">
              <div class="item">
                <img src="@/assets/svg/user-guide.svg" width="24px" />
                <span> วิธีการใช้งาน </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="settings-card"
        style="cursor: pointer"
        @click="() => $router.push({ name: 'Policy' })"
      >
        <div class="rows">
          <div class="row">
            <div class="how-to">
              <div class="item">
                <img src="@/assets/svg/policy.svg" width="24px" />
                <span> นโยบายความเป็นส่วนตัว </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="settings-card"
        style="cursor: pointer"
        @click="deleteAccount()"
      >
        <div class="rows">
          <div class="row">
            <div class="how-to">
              <div class="item">
                <img src="@/assets/svg/delete-account.svg" width="24px" />
                <span>แจ้งลบบัญชีผู้ใช้</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="settings-card">
        <div class="rows">
          <div class="row">
            <div class="contact-us">
              <div class="item">
                <img src="@/assets/svg/support.svg" width="24px" />
                <span> ติดต่อเรา </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="content">
              <a class="item" href="tel:+6620385505">
                <img src="@/assets/svg/tel.svg" />
                02-038-5505
              </a>
              <a class="item" href="mailto:service@skinx.app">
                <img src="@/assets/svg/email.svg" />
                service@skinx.app
              </a>
              <a class="item" href="https://www.facebook.com/SkinX.TH/">
                <img src="@/assets/svg/facebook.svg" />
                skinx
              </a>
              <a class="item" href="https://lin.ee/AAiOVZp">
                <img src="@/assets/svg/line.svg" />
                @skinx_doctor
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'
import Swal from 'sweetalert2'
import { sendMessageToAdminLineOA } from '@/utils/utils'
import { mapState } from 'vuex'

export default {
  name: 'DashboardSettings',
  data() {
    return {
      isLoading: true,
      setting: {
        notiEmail: null
      }
    }
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  async created() {
    const loadingComponent = this.$buefy.loading.open({
      container: this.$refs.cnt
    })
    const { data: setting } = await api.getSetting()
    this.setting = setting
    loadingComponent.close()
    this.isLoading = false
  },
  methods: {
    async changeSetting() {
      const loadingComponent = this.$buefy.loading.open({
        container: this.$refs.cnt
      })
      const { data: newSetting } = await api.updateSetting(this.setting)
      this.setting = newSetting
      loadingComponent.close()
    },
    async deleteAccount() {
      Swal.fire({
        title: 'แจ้งลบบัญชี',
        text:
          'เราเสียใจที่ท่านไม่อยากมีบัญชี SkinX อีก หากท่านลบบัญชีแล้ว ระบบจะลบข้อมูลทั้งหมดและไม่สามารถกู้คืนได้',
        icon: 'warning',
        showCancelButton: true,
        width: '26rem',
        padding: '18px',
        confirmButtonColor: '#34BEB3',
        confirmButtonText: 'ต้องการลบบัญชี',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
          ]
          const isMobile = toMatch.some(toMatchItem => {
            return navigator.userAgent.match(toMatchItem)
          })
          const url = sendMessageToAdminLineOA(
            `แจ้งลบ Account แพทย์ ${this.user.doctor.name}`,
            isMobile
          )
          window.location = url
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.db-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
}
.divider {
  width: 100%;
  height: 2px;
  background-color: #dbd6d3;
  margin: 15px 0;
}
.is-title {
  color: #3e4a59;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.settings-card {
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(172, 172, 172, 0.19);
  background-color: #ffffff;
  padding: 20px;
  color: #3e4a59;
  font-weight: 400;
  font-size: 17px;
  .ic-img {
    margin-right: 20px;
  }
  .ic-text {
    line-height: 50px;
    vertical-align: top;
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.contact-us {
  display: flex;
  justify-content: center;
  margin: 0px 0px 16px 0px;
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
.item {
  img {
    margin-right: 27px;
  }
}
.how-to {
  display: flex;
  flex-direction: row;
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
  }
  .action {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #3e4a59;
    img {
      margin-right: 15px;
    }
  }
}
/* Less than mobile */
@media only screen and (max-width: 849px) {
  .db-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .content {
    flex-direction: column;
    .item {
      margin-top: 20px;
      justify-content: left;
    }
  }
}
</style>
