import moment from 'moment'

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = unescape(dataURI.split(',')[1])
  }
  // separate out the mime component
  var mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}

export function getFirstDayOfMonth(currentDate, day) {
  day = getDayOfWeek(day)
  const firstDate = moment(currentDate).set({
    date: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  })
  return moment(firstDate).day(day)
}

export function getDayOfWeek(dayString) {
  const dayOfWeek = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 0
  }
  return dayOfWeek[dayString]
}

export function getDayNameOfWeek(dayIndex) {
  const dayOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ]
  return dayOfWeek[dayIndex]
}
export function getMonthNameOfYear(dayIndex) {
  const dayOfWeek = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  return dayOfWeek[dayIndex]
}
export function getMonthFullNameOfYear(dayIndex) {
  const dayOfWeek = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Augus',
    'September',
    'October',
    'November',
    'December'
  ]
  return dayOfWeek[dayIndex]
}
export function getDayNameOfWeekWithOutDay(dayIndex) {
  const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return dayOfWeek[dayIndex]
}
export function sendMessageToAdminLineOA(message, isMobile) {
  if (!isMobile) {
    return 'https://lin.ee/AAiOVZp'
  }
  let baseURL
  if (process.env.NODE_ENV === 'production') {
    baseURL = 'https://line.me/R/oaMessage/@934jvavp'
  } else if (process.env.NODE_ENV === 'test') {
    baseURL = 'https://line.me/R/oaMessage/@494zcywd'
  } else {
    baseURL = 'https://line.me/R/oaMessage/@494zcywd'
  }
  var messageEncode = encodeURIComponent(message)

  return `${baseURL}/?${messageEncode}`
}
