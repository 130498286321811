export default {
  author: 'SkinX Team',
  title: 'เปิดทุกการมองเห็น ชัดเจนยิ่งกว่า ด้วย Dermlite',
  image: require('./dermlite.jpg'),
  createdAt: new Date('2023-03-27'),
  content: `มิติใหม่ของบริการทางการแพทย์ผิวหนัง

  เปิดทุกการมองเห็น ชัดเจนยิ่งกว่า ติดตามอาการผิวได้สะดวกมากขึ้น!
  
  Dermlite ตัวช่วยสำหรับเครื่องมือแพทย์ผิวหนังอันดับ 1 พร้อมให้คุณเป็นเจ้าของบนแอปฯ SkinX แล้ววันนี้
  
  ลดทันที 10%
  
  ผ่อนได้ 0% นานสูงสุด 3 เดือน
  
  จัดส่งฟรี
  
  .
  
  ลดพิเศษเฉพาะเดือน 3 เท่านั้น! ตั้งแต่ มี.ค. - พ.ค. 2566 เข้าแอปฯ SkinX แล้วไปช้อปเลย!! <http://bit.ly/SkinXapp>
  
  หรือศึกษารายละเอียดเพิ่มเติมได้ที่ <https://drive.google.com/file/d/1uDbTVzKbIgXQrJECwlQgk28MDNKolaeL/view>

  #SkinXapp #SkinXตัวจริงเรื่องผิว

  ![dermlite](${require('./dermlite.jpg')})`
}
