<template>
  <div class="db-container">
    <div>
      <div class="is-title">
        <img
          src="@/assets/svg/back-black.svg"
          @click="() => $router.go(-1)"
          class="back-btn"
        />วิธีการใช้งาน
      </div>
      <div class="divider" />
      <div style="position: relative">
        <pdf
          v-for="i in pageCount"
          :key="i"
          :page="i"
          src="../Appointment_Feature.pdf"
          @loaded="loaded"
          style="margin-bottom: 10px"
        />
        <b-loading v-model="isLoading" :is-full-page="true"></b-loading>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: 'DashboardSettings',
  components: {
    pdf
  },
  data() {
    return {
      height: window.innerHeight - 30 - 32 - 48,
      isLoading: true,
      pageLoaded: 0,
      pageCount: 8
    }
  },
  methods: {
    loaded() {
      this.pageLoaded += 1
      this.isLoading = this.pageLoaded < this.pageCount
    }
  }
}
</script>

<style lang="scss" scoped>
.back-btn {
  margin-right: 13px;
  width: 21px;
  cursor: pointer;
}
.db-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 24px 14px;
}
.divider {
  width: 100%;
  height: 2px;
  background-color: #dbd6d3;
  margin: 15px 0;
}
.is-title {
  color: #3e4a59;
  font-size: 20px;
  display: flex;
}
.settings-card {
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(172, 172, 172, 0.19);
  background-color: #ffffff;
  padding: 20px;
  color: #3e4a59;
  font-weight: 100;
  font-size: 23px;
  .ic-img {
    margin-right: 20px;
  }
  .ic-text {
    line-height: 50px;
    vertical-align: top;
  }
}
.contact-us {
  display: flex;
  justify-content: center;
  margin: 0px 0px 16px 0px;
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
.item {
  img {
    margin-right: 27px;
  }
}
.how-to {
  display: flex;
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

/* Less than mobile */
@media only screen and (max-width: 849px) {
  .db-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .content {
    flex-direction: column;
    .item {
      margin-top: 20px;
      justify-content: left;
    }
  }
}
</style>
