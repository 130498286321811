<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <b-field label="Email">
        <ValidationProvider
          name="Email"
          rules="required|email"
          v-slot="{ errors }"
        >
          <b-input v-model="form.email" rounded @input="handleChange()" />
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <div>
        <div class="label">
          Password
        </div>
        <div class="sub-label">
          (At least 8 characters and includes lowwercase, uppercase, number)
        </div>
        <ValidationProvider
          name="Password"
          rules="required|passwordStrength"
          v-slot="{ errors }"
        >
          <b-input v-model="form.password" type="password" rounded />
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <!-- <b-field label="Password (At lease 8 characters and includes lowwer-case, upper-case, number)">
      </b-field> -->
      <b-field label="Confirm Password (เหมือนรหัสผ่าน)">
        <ValidationProvider
          name="Confirm password"
          :rules="`required|is:${form.password}`"
          v-slot="{ errors }"
        >
          <b-input v-model="form.confirmPassword" type="password" rounded />
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <div class="divider" />
      <b-field label="Prefix (ภาษาไทย เช่น นพ. พญ. เป็นต้น)">
        <ValidationProvider name="Prefix" rules="required" v-slot="{ errors }">
          <b-input
            v-model="form.prefix"
            type="text"
            rounded
            @input="handleChange()"
          />
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <b-field label="Name (ภาษาไทย)">
        <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
          <b-input
            v-model="form.name"
            type="text"
            rounded
            @input="handleChange()"
          />
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <b-field label="Surname (ภาษาไทย)">
        <ValidationProvider name="Surname" rules="required" v-slot="{ errors }">
          <b-input
            v-model="form.surname"
            type="text"
            rounded
            @input="handleChange()"
          />
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <b-field label="Nickname (ภาษาไทย)">
        <ValidationProvider
          name="Nickname"
          rules="required"
          v-slot="{ errors }"
        >
          <b-input
            v-model="form.nickname"
            type="text"
            rounded
            @input="handleChange()"
          />
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <b-field label="Phone Number">
        <ValidationProvider
          name="Phone Number"
          rules="required|numeric|length:10"
          v-slot="{ errors }"
        >
          <b-input
            v-model="form.tel"
            type="text"
            placeholder="0899999999"
            rounded
            @input="handleChange()"
          />
          <span class="error-msg">{{ errors[0] }}</span>
        </ValidationProvider>
      </b-field>
      <b-button
        :disabled="invalid"
        :loading="isLoading"
        type="is-secondary"
        class="next-btn"
        rounded
        expanded
        @click="$emit('next', form)"
      >
        Next
      </b-button>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'RegisterFormEmail',
  props: {
    isLoading: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      registerSession: {},
      form: {
        email: '',
        password: '',
        confirmPassword: '',
        prefix: '',
        name: '',
        surname: '',
        nickname: '',
        tel: ''
      }
    }
  },
  created() {
    const registerSession = JSON.parse(
      localStorage.getItem('registerEmailSession')
    )
    if (registerSession) {
      this.registerSession = registerSession
      this.form.email = registerSession.email ?? ''
      this.form.prefix = registerSession.prefix ?? ''
      this.form.name = registerSession.name ?? ''
      this.form.surname = registerSession.surname ?? ''
      this.form.nickname = registerSession.nickname ?? ''
      this.form.tel = registerSession.tel ?? ''
    }
  },
  methods: {
    handleChange() {
      const form = { ...this.form }
      delete form.password
      delete form.confirmPassword
      localStorage.setItem('registerEmailSession', JSON.stringify(form))
    }
  }
}
</script>

<style lang="scss" scoped>
.divider {
  width: 100%;
  height: 1px;
  background-color: #d2d2d2;
  margin: 40px 0;
}
.next-btn {
  height: 60px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  margin-top: 60px;
}
.sub-label {
  font-size: 13px;
  font-weight: 100;
  margin-bottom: 10px;
}
</style>
