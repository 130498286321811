<template>
  <div>
    <div class="header">
      <div class="title">
        <img
          src="@/assets/svg/back-black.svg"
          @click="$router.go(-1)"
          style="margin-right: 16px; height: 20px;"
        />
        {{ content.title }}
      </div>
    </div>
    <div class="divider" />
    <div class="content">
      <!-- <div class="font-header-2">{{ content.title }}</div> -->
      <div class="profile-layout">
        <div class="profile">
          <img
            :src="require('@/assets/svg/skinx-logo-white.svg')"
            alt="profile image"
            :style="'padding: 4px;object-fit: scale-down;'"
          />

          <div class="detail">
            <span class="name">{{ content.author }}</span>
            <span class="date">{{
              (content ? content.createdAt : null)
                | toDateFormat('DD MMMM YYYY')
            }}</span>
          </div>
        </div>
        <div class="view-layout" v-if="view > 0">
          <img :src="require('@/assets/svg/eye.svg')" alt="view" />
          <label class="view">{{ view }}</label>
        </div>
      </div>

      <!-- <div class="content-image">
            <img :src="content.image" alt="banner" class="image" />
          </div> -->
      <vue-markdown
        v-if="content"
        class="content-html"
        :source="content.content"
        toc
        :toc-anchor-link="false"
        @toc-rendered="handleTocRendered"
        :anchorAttributes="{ target: '_blank' }"
      ></vue-markdown>
    </div>
  </div>
</template>

<script>
import { db } from '@/utils/firebase'
export default {
  name: 'ContentPage',
  data() {
    return {
      content: {
        title: 'test'
      },
      view: 0,
      timeOut: null
    }
  },
  async created() {
    await this.getData()
    this.getView()
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    async $route(to, from) {
      await this.getData()
    }
  },
  computed: {
    url() {
      return window.location.href
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleTocRendered(_toc) {
      if (this.$route.hash) {
        setTimeout(() => {
          document
            .getElementById(decodeURI(this.$route.hash.slice(1)))
            .scrollIntoView()
        }, 0)
      }
    },
    async getData() {
      try {
        this.content = (
          await require('../../assets/contents/' + this.$route.params.path)
        ).default
      } catch (error) {
        console.log(error)
        this.$router.push({ path: '/dashboard/contents' })
      }
    },
    getView() {
      const path = this.$route.params.path.split('/')
      const contentRef = db
        .collection('content-view')
        .doc(path[path.length - 1])
      contentRef.get().then(docSnapshot => {
        if (docSnapshot.exists) {
          this.view = docSnapshot.data().view ?? 0
        }
        this.timeOut = setTimeout(() => {
          if (docSnapshot.exists) {
            const data = docSnapshot.data()
            if (data.view != null) {
              contentRef.update({ view: data.view + 1 })
            }
          } else {
            contentRef.set({ view: 1 })
          }
        }, 3500)
      })
    }
  },
  destroyed() {
    clearTimeout(this.timeOut)
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: baseline;
}
.title {
  font-size: 20px;
  display: flex;
  align-items: center;
}

.content {
  margin: 24px auto 40px auto;
  max-width: 1024px;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  padding: 24px 24px;

  .profile-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .profile {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #3e4a59;

      .detail {
        display: flex;
        flex-direction: column;
        margin-left: 8px;

        .name {
          font-size: 18px;
        }
        .date {
          font-size: 12px;
          color: #bbbbbb;
        }
      }
      img {
        background: #e7a191;
        border-radius: 50%;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        width: 40px !important;
        height: 40px !important;
        object-fit: cover;
      }
    }
    .view-layout {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #3e4a59;
      .view {
        margin-left: 8px;
        font-size: 18px;
      }
    }
  }

  .content-image {
    width: 100%;
    display: flexbox;
    flex-direction: column;
    text-align: center;
    .image {
      object-fit: cover;
      aspect-ratio: 16 / 9;
      border-radius: 20px;
      width: 100%;
    }
    .credit {
      font-size: 14px;
      font-weight: 300;
      font-style: italic;
      color: #3e4a59;
    }
  }
}

::v-deep .content-html {
  color: var(--text-primary-color);
  box-sizing: border-box;
  font-family: 'Prompt', sans-serif !important;
  white-space: break-spaces;
  word-wrap: break-word;
}
::v-deep .content-html a {
  text-decoration: underline !important;
  word-wrap: break-word;
}
::v-deep .content-html img {
  display: block;
  margin: auto;
  // max-width: 500px;
  width: 100%;
  border-radius: 20px;
}
</style>
