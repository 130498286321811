<template>
  <div class="message-containner">
    <video
      height="240"
      v-for="(videoUrl, index) in videoUrlWithPreviews"
      :key="index"
      @click="showImgLightBox(index)"
      style="padding: 5px;"
      controls
    >
      <source :src="videoUrl" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  props: ['videoUrls'],
  data() {
    return {
      isImageModalActive: false,
      index: 0
    }
  },
  methods: {
    showImgLightBox(index) {
      this.isImageModalActive = true
      this.index = index
    }
  },
  computed: {
    videoUrlWithPreviews() {
      return this.videoUrls.map(url => `${url}#t=0.001`)
    }
  }
}
</script>

<style scoped>
.media {
  height: auto;
  cursor: pointer;
  width: 50%;
  padding: 5px;
  margin: 0px !important;
  border: 0px !important;
}
.message-containner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
</style>
