<template>
  <div>
    <div class="header">
      <div class="title">ห้องรอตรวจ</div>
      <div id="db-doctor-profile-switch" class="doctor-switch">
        <b-tooltip
          type="is-light"
          position="is-bottom"
          multilined
          size="is-large"
          style="margin-right: 12px"
        >
          <img class="info-icon" src="@/assets/svg/info-2.svg" />
          <template v-slot:content>
            <span class="quere-tooltip-content">
              ระบบเปิด-ปิด รับคิวสำหรับการให้บริการของแพทย์<br />
              *เมื่อแพทย์ปิดรับคิว User จะไม่สามารถกดเข้ามาเพื่อ ขอรับบริการได้
              แต่จะยังทำการนัดหมายแพทย์ได้ตามปกติ<br />
              <span style="color: red">*กรุณาเปิดรับคิวอยู่เสมอนะคะ</span><br />
            </span>
          </template>
        </b-tooltip>
        <span class="switch-description">
          {{ isOpenQueue ? 'Open Queue' : 'Close Queue' }}
        </span>
        <b-switch
          :value="isOpenQueue"
          type="is-success"
          @input="switchStatus"
        />
      </div>
    </div>
    <div v-if="waitingRoomList.length === 0" class="no-appointment-container">
      <img
        class="no-appointment-img"
        src="@/assets/svg/no-appointment.svg"
        alt="no-appointment"
      />
      <div class="no-appointment-title">ยังไม่มีคิวรอตรวจ</div>
      <div class="no-appointment-desc">
        <span style="font-weight: 600; font-style: italic"
          >อย่าลืมสร้างตารางนัดหมายแพทย์</span
        ><br />
        เพื่อให้คนไข้สามารถเลือกช่วงเวลาที่เหมาะสม<br />
        ในการนัดหมายและเข้าพบคุณหมอได้สะดวกยิ่งขึ้น<br />
        <span style="color: red">*กรุณาเปิดรับคิวอยู่เสมอนะคะ</span>
      </div>
      <b-button
        type="is-light"
        rounded
        @click="
          $router.push({
            name: 'DashboardScheduler',
          })
        "
        class="no-appointment-btn"
      >
        สร้างตารางนัดหมาย >
      </b-button>
    </div>
    <WaitingQueueListItem
      v-for="(ticket, index) in waitingRoomList"
      :key="ticket.id"
      :patientName="ticket.patient.name"
      :appointmentDate="
        ticket.appointment ? ticket.appointment.appointmentDate : null
      "
      :symptoms="ticket.record.symptom"
      :patientJoinAt="ticket.patientJoinAt"
      :enabled="index === 0"
      :recordId="ticket.record ? ticket.record.id : null"
      :ticketId="ticket.ticketNumber"
      :type="ticket.record.type"
    />
  </div>
</template>

<script>
import api from '@/utils/api'
import WaitingQueueListItem from '@/components/WaitingQueueListItem.vue'
import { db } from '@/utils/firebase'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'WaitingQueueList',
  components: { WaitingQueueListItem },
  data() {
    return {
      waitingRoomList: [],
      waitingQueueUnsubscriber: null,
    }
  },
  computed: {
    ...mapState({
      user: 'user',
      isOpenQueue: 'isOpenQueue',
    }),
  },
  async created() {
    this.subscribeWaitingQueue()
  },
  beforeDestroy() {},
  methods: {
    ...mapActions({
      setIsOpenQueue: 'setIsOpenQueue',
    }),
    async fetchWaitingRoomList() {
      const loadingComponent = this.$buefy.loading.open({
        container: this.$refs.cnt,
      })
      const { data } = await api.getWaitingRoomList()
      this.waitingRoomList = data
      loadingComponent.close()
      this.isLoading = false
    },
    subscribeWaitingQueue() {
      this.doctorStatusUnsubscribe = db
        .collection(`doctor-status/${this.user.doctor.id}/waiting-queue`)
        .onSnapshot((querySnapshot) => {
          const breakChangeIndex = querySnapshot
            .docChanges()
            .findIndex((change) => change.type !== 'modified')
          console.log(breakChangeIndex)
          if (breakChangeIndex !== -1) {
            this.fetchWaitingRoomList()
          }
        })
    },
    switchStatus(value) {
      this.setIsOpenQueue(value)
      if (value === true) {
        this.$ga.event({
          eventCategory: 'OpenQueue',
          eventAction: 'Click',
          eventLabel: 'Open',
        })
      } else {
        this.$ga.event({
          eventCategory: 'CloseQueue',
          eventAction: 'Click',
          eventLabel: 'Close',
        })
      }
      api.patchIsOpenQueue(value)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.title {
  font-size: 20px;
}
.doctor-switch {
  display: flex;
  align-items: center;

  .info-icon {
    margin-top: 4px;
    height: 20px;
    width: 20px;
  }
}
.switch-description {
  margin-right: 16px;
  font-weight: 400;
}
.no-appointment-container {
  display: flex;
  min-height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .no-appointment-img {
    height: 200px;
  }

  .no-appointment-title {
    margin-top: 16px;
  }

  .no-appointment-desc {
    margin-top: 16px;
    font-weight: 100;
    font-size: 12px;
    text-align: center;
  }

  .no-appointment-btn {
    margin-top: 16px;
    font-weight: 100;
  }
}

.quere-tooltip-content {
  font-weight: 100;
  font-size: 11px;
  text-align: left;
}
</style>
