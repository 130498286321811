var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-containner"},[_c('div',{staticClass:"modal-card"},[_c('div',{staticClass:"modal-header"},[_vm._v("Choose time for Appointment")]),_c('div',{staticClass:"modal-chosen-date"},[_vm._v(" "+_vm._s(_vm._f("toDateFormat")(_vm.chosenDate,'DD MMMM YYYY'))+" ")]),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-time-slot-containner"},_vm._l((_vm.chosenDateItems),function(item,index){return _c('div',{key:index,class:[
            'modal-time-slot',
            {
              deactive: item.busy,
              active: _vm.seletedItem && item && _vm.seletedItem.id === item.id,
            } ],on:{"click":function($event){return _vm.onSlectItem(item)}}},[_vm._v(" "+_vm._s(_vm._f("toDateFormat")(item.startDate,'HH:mm'))+" ")])}),0),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"modal-footer-containner"},[_c('div',{staticClass:"modal-footer-button modal-footer-button-outline",on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('div',{class:[
            'modal-footer-button',
            {
              disabled: !_vm.seletedItem,
            } ],on:{"click":_vm.onConfirm}},[_vm._v(" Confirm ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }