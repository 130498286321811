export default {
  author: 'SkinX Team',
  title: 'จัดเต็ม !! เปิดตัว Curel Product เวชสำอางอันดับ 1 จากประเทศญี่ปุ่น',
  image: require('./curel.png'),
  createdAt: new Date('2023-02-14'),
  content: `SkinX Store ชวนหมอผิวมาทำความรู้จักกับผลิตภัณฑ์จาก Curel แบรนด์เวชสำอางอันดับ 1 จากประเทศญี่ปุ่น ด้วยรายละเอียดข้อมูลที่เจาะลึก จัดเต็มกับทุกความสามารถของผลิตภัณฑ์ ให้มีความเข้าใจมากยิ่งขึ้น เพื่อผลลัพธ์ที่ดีที่สุดในการให้คำปรึกษาและแนะนำผู้รับบริการได้รับการดูแลรักษาอย่างตรงจุด



  สามารถดูข้อมูลเพิ่มเติมได้ตามลิงค์ด้านล่างนี้ คลิกเลย !! https://docs.google.com/presentation/d/1mnHw8r7OItW1paKB1KrPSzhLWdTU_yPHUFLYt6foZ_o/edit#slide=id.p

  ![Curel Product](${require('./curel.png')})`
}
