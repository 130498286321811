import axios from 'axios'
import { auth } from './firebase'

let baseURL
if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://api.skinx.app'
} else if (process.env.NODE_ENV === 'staging') {
  baseURL = 'https://api-xjdddpc5ya-as.a.run.app'
} else if (process.env.NODE_ENV === 'test') {
  baseURL = 'https://api-bgwbpciw2a-as.a.run.app'
} else {
  baseURL = 'http://localhost:3000'
  // baseURL = 'https://api.skinx.app'
  // baseURL = 'https://api-xjdddpc5ya-as.a.run.app'
  // baseURL = 'https://api-bgwbpciw2a-an.a.run.app'
}

const api = axios.create({ baseURL })

let cancelGetDiagnoses
let cancelGetDrugs

const getToken = () => {
  return `Bearer ${localStorage.getItem('access-token')}`
}

const getRefreshTokenData = () => {
  return {
    token: localStorage.getItem('refresh-token'),
    userId: localStorage.getItem('user-id')
  }
}

// Add a response interceptor
api.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  async function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      try {
        const { data } = await api.post(
          '/auth/exchange-token',
          getRefreshTokenData()
        )
        localStorage.setItem('access-token', data.access_token)
        localStorage.setItem('refresh-token', data.refreshToken)
        localStorage.setItem('user-id', data.user.id)
        localStorage.setItem('firebase-token', data.firebaseToken)
        auth.signInWithCustomToken(data.firebaseToken)
        error.config.headers.Authorization = getToken()
        if (window.webkit) {
          window.webkit.messageHandlers.onExchangeToken.postMessage(data)
        }
        return api.request(error.config)
      } catch (e) {
        if (window.webkit) {
          window.webkit.messageHandlers.logOutHandler.postMessage({})
        }
        return Promise.reject(error)
      }
    } else {
      return Promise.reject(error)
    }
  }
)

export default {
  async getHospitals() {
    const { data } = await api.get('/hospitals?skipCountDoctor=true')
    return data
  },

  async getSpecialties() {
    const { data } = await api.get('/specialties')
    return data
  },

  async getExpertises() {
    const { data } = await api.get('/expertises')
    return data
  },
  async getprocedureExpertises() {
    const { data } = await api.get('/expertises?filterGroupId=999')
    return data
  },

  async getBanks() {
    const { data } = await api.get('/banks')
    return data
  },

  async registDoctor(formData) {
    const { data } = await api.post('/doctors/register', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return data
  },

  async postRegistDoctor(formData) {
    const { data } = await api.post('/doctors/post-register', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: getToken()
      }
    })
    return data
  },

  async postLogin(email, password) {
    const { data } = await api.post('/auth/sign-in/password', {
      email,
      password
    })
    return data
  },

  async getRecordById(id, ticket) {
    const { data } = await api.get(`/records/${id}`, {
      headers: {
        Authorization: getToken()
      },
      params: {
        ticket
      }
    })
    return data
  },

  async putRecordById(id, params) {
    const { data } = await api.put(`/records/${id}`, params, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },

  async getAuthMe() {
    const { data } = await api.get('/auth/me', {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },

  async getRecords(
    page = 1,
    size = 20,
    status = 'all',
    patientId,
    doctorId,
    search,
    date
  ) {
    const { data } = await api.get('/records/by-doctor', {
      headers: {
        Authorization: getToken()
      },
      params: {
        page,
        size,
        status,
        patientId,
        doctorId,
        ...(search ? { s: search } : {}),
        ...(date ? { date } : {})
      }
    })
    return data
  },

  async postSummary(form) {
    const { data } = await api.post('/summaries', form, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },

  async getDiagnoses(query = '') {
    if (cancelGetDiagnoses) {
      cancelGetDiagnoses()
    }
    const { data } = await api.get('/diagnoses', {
      params: {
        q: query
      },
      cancelToken: new axios.CancelToken(c => {
        cancelGetDiagnoses = c
      })
    })
    return data
  },

  async getSummaryReportById(id) {
    const { data } = await api.get(`/summaries/${id}`, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },

  async putRecordToCompleted(id, form) {
    const { data } = await api.put(`/records/${id}/complete`, form, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },

  async getDrugs(params) {
    if (cancelGetDrugs) {
      cancelGetDrugs()
    }
    const { data } = await api.get('/drugs', {
      headers: {
        Authorization: getToken()
      },
      params,
      cancelToken: new axios.CancelToken(c => {
        cancelGetDrugs = c
      })
    })
    return data
  },

  async getFrequencies() {
    const { data } = await api.get('/frequencies', {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },

  async patchDoctorStatus(status) {
    const { data } = await api.patch(
      '/doctors/status',
      { status },
      {
        headers: {
          Authorization: getToken()
        }
      }
    )
    return data
  },

  async getDoctorById(doctorId) {
    const { data } = await api.get(`/doctors/${doctorId}`, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },

  async getRecordTotalSummary() {
    const { data } = await api.get('/records/total-summary', {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },

  async putDoctorProfile(formData) {
    const { data } = await api.put('/doctors', formData, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },

  async postRequestResetPasswordCode(email) {
    const { data } = await api.post(
      '/users/request-reset-password-code',
      { email },
      {
        headers: {
          Authorization: getToken()
        }
      }
    )
    return data
  },

  async putResetPassword(code, newPassword) {
    const { data } = await api.put(
      '/users/reset-password',
      { code, newPassword },
      {
        headers: {
          Authorization: getToken()
        }
      }
    )
    return data
  },

  async getAccountSummary() {
    const { data } = await api.get('/accounts/summary', {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },

  async getAccounts(page, size) {
    const { data } = await api.get('/accounts', {
      params: { page, size },
      headers: { Authorization: getToken() }
    })
    return data
  },

  async postAccountWithdraw(amount) {
    const { data } = await api.post(
      '/accounts/withdraw',
      { amount },
      {
        headers: { Authorization: getToken() }
      }
    )
    return data
  },

  async getDrugGroups() {
    const { data } = await api.get('/drug-groups')
    return data
  },

  async getAppointments(page, size) {
    const { data } = await api.get('/appointments', {
      params: { page, size },
      headers: { Authorization: getToken() }
    })
    return data
  },
  async getAppointmentsWithStatus(page, size, status) {
    const { data } = await api.get('/appointments/appointmentStatus', {
      params: { page, size, status },
      headers: { Authorization: getToken() }
    })
    return data
  },

  async getAppointmentsByMonth(startDate, endDate) {
    const { data } = await api.get('/appointments/by-month', {
      params: { startDate, endDate },
      headers: { Authorization: getToken() }
    })
    return data
  },

  async updateDoctorReserveStatus(data) {
    return await api.put('/doctors/reserve', data, {
      headers: { Authorization: getToken() }
    })
  },

  async postScheduler(data) {
    return await api.post('/calendar/events', data, {
      headers: { Authorization: getToken() }
    })
  },

  async getDoctorSlotTime(startDate, endDate, doctorId) {
    return await api.get(`calendar/timeslot/${doctorId}`, {
      params: {
        startDate,
        endDate
      },
      headers: { Authorization: getToken() }
    })
  },

  async getAvailableAll(startDate, endDate) {
    const userId = localStorage.getItem('user-id')
    return await api.get(`calendar/available/${userId}`, {
      params: {
        startDate,
        endDate
      },
      headers: { Authorization: getToken() }
    })
  },

  async getSetting() {
    return await api.get('/doctors/setting', {
      headers: { Authorization: getToken() }
    })
  },

  async updateSetting(setting) {
    return await api.patch('/doctors/setting', setting, {
      headers: { Authorization: getToken() }
    })
  },

  async getWaitingRoomList() {
    return await api.get('/waiting-queue/me', {
      headers: { Authorization: getToken() }
    })
  },

  async createRoomToken(recordId) {
    const { data } = await api.post(
      '/rooms/waiting-room-token',
      { recordId },
      { headers: { Authorization: getToken() } }
    )
    if (window.webkit) {
      window.webkit.messageHandlers.onJoinRoom.postMessage(data)
    }
  },

  async joinRoom(ticketId) {
    await api.post(
      `/waiting-queue/join/${ticketId}`,
      {},
      { headers: { Authorization: getToken() } }
    )
  },

  async callPatient(ticketId) {
    await api.post(
      `/rooms/call-patient?ticketId=${ticketId}`,
      {},
      { headers: { Authorization: getToken() } }
    )
  },

  async patchIsOpenQueue(value) {
    await api.patch(
      '/doctors/is-open-queue',
      { isOpenQueue: value },
      { headers: { Authorization: getToken() } }
    )
  },

  async getMissedCallNotification(doctorId) {
    const { data } = await api.get('/records/missed-call', {
      headers: { Authorization: getToken() }
    })
    return data
  },

  async reCallLink(recordId) {
    const { data } = await api.post(
      '/doctors/noti-patient-recall-demo',
      { recordId: recordId },
      { headers: { Authorization: getToken() } }
    )
    return data
  },

  async reCall(recordId) {
    const { data } = await api.post(
      '/doctors/noti-patient-recall',
      { recordId: recordId },
      { headers: { Authorization: getToken() } }
    )
    return data
  },

  async getDrugUnit() {
    const { data } = await await api.get('/drug-unit')
    return data
  },

  async getDrugPrefix() {
    const { data } = await api.get('/drug-prefix')
    return data
  },

  async updateDoctorReadNotificationOnAppointment(appointmentId) {
    await api.post(
      `/appointments/updateDoctorReadNotification/${appointmentId}`,
      {},
      { headers: { Authorization: getToken() } }
    )
  },

  async updateDoctorReadNotificationOnRecord(id) {
    await api.post(
      `/records/updateDoctorReadNotification/${id}`,
      {},
      { headers: { Authorization: getToken() } }
    )
  },

  async getUsers(ids) {
    const { data } = await api.get('/users', {
      params: { ids },
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },

  async readChat(chatId, messageId) {
    await api.patch(`/chat/${chatId}/message/${messageId}/read`, null, {
      headers: {
        Authorization: getToken()
      }
    })
  },

  async postChat(chatId, data) {
    await api.post(`chat/${chatId}/message`, data, {
      headers: {
        Authorization: getToken()
      }
    })
  },

  async getSummaryRecommendationByDoctorId(doctorId) {
    const { data } = await api.get(
      `/summaries/recommendation/doctor/${doctorId}`,
      {
        headers: {
          Authorization: getToken()
        }
      }
    )
    return data
  },
  async getSummaryRecommendation(id) {
    const { data } = await api.get(`/summaries/recommendation/${id}`, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },
  async postSummaryRecommendation(form) {
    const { data } = await api.post('/summaries/recommendation', form, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },
  async updateSummaryRecommendation(form) {
    const { data } = await api.put('/summaries/recommendation/', form, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },
  async deleteSummaryRecommendation(recommendationId) {
    const { data } = await api.delete(
      `/summaries/recommendation/${recommendationId}`,
      {
        headers: {
          Authorization: getToken()
        }
      }
    )
    return data
  },
  async getDrugSet() {
    const { data } = await api.get('/drug-set/doctor', {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },
  async createDrugSet(payload) {
    const { data } = await api.post('/drug-set', payload, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },
  async getDrugSetById(id) {
    const { data } = await api.get('/drug-set/' + id, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },
  async updateDrugSetById(id, payload) {
    const { data } = await api.put('/drug-set/' + id, payload, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },
  async getDrugByDrugItemId(id) {
    const { data } = await api.get('/drug/' + id, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },
  async deleteDrugSetById(id) {
    const { data } = await api.delete('/drug-set/' + id, {
      headers: {
        Authorization: getToken()
      }
    })
    return data
  },
  async getSearchPatientByDoctor(search, page = 1, size = 10) {
    const { data } = await api.get('/patients/by-doctor', {
      headers: {
        Authorization: getToken()
      },
      params: {
        s: search,
        page,
        size
      }
    })
    return data
  },
  async getHolidays(year) {
    const { data } = await api.get('/holiday/' + year, {
      headers: { Authorization: getToken() }
    })
    return data
  }
}
