export default {
  author: 'SkinX Team',
  title: 'SkinX Game Day for Doctor "ชวนคุณหมอผิวมาเช็คปัญหาสิวคนไข้"',
  image: require('./image.png'),
  createdAt: new Date('2023-05-25'),
  content: `กิจกรรมร่วมสนุกสำหรับคุณหมอผิวประจำ SkinX ทุกท่านกับกิจกรรม "ชวนคุณหมอผิวมาเช็คปัญหาสิวคนไข้" ลุ้นรับของรางวัล Starbuck Voucher สูงสุด 1,500 บาท

 

  โดยเงื่อนไขการร่วมสนุก เพียงคุณหมอผิว
  
  1.ให้คำปรึกษากับคนไข้ผ่านแอปฯ SkinX ในรายที่มีปัญหาสิวหรือรอยแดง, ดำจากสิว
  
  2.พิจารณาสั่งผลิตภัณฑ์ Lotion PX, X Roy ในเคสที่ให้การรักษา
  
  3.ยอดการสั่งรวมมากกว่า 30 ชิ้น มีสิทธิลุ้นรับของรางวัลแน่นอน !
  
   
  
  ระยะเวลาในการร่วมสนุกเริ่มตั้งแต่วันนี้จนถึงวันที่ 31 พฤษภาคม 2566
  
  ประกาศผลวันที่ 7 มิถุนายน 2566
  
   
  
  #แล้วมาร่วมสนุกด้วยกันนะครับบบ

  ![SkinX Doctor Review](${require('./image.png')})`
}
