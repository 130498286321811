var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-field',{attrs:{"label":"Email"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"rounded":""},on:{"input":function($event){return _vm.handleChange()}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"label"},[_vm._v(" Password ")]),_c('div',{staticClass:"sub-label"},[_vm._v(" (At least 8 characters and includes lowwercase, uppercase, number) ")]),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|passwordStrength"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"password","rounded":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Confirm Password (เหมือนรหัสผ่าน)"}},[_c('ValidationProvider',{attrs:{"name":"Confirm password","rules":("required|is:" + (_vm.form.password))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"password","rounded":""},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"divider"}),_c('b-field',{attrs:{"label":"Prefix (ภาษาไทย เช่น นพ. พญ. เป็นต้น)"}},[_c('ValidationProvider',{attrs:{"name":"Prefix","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","rounded":""},on:{"input":function($event){return _vm.handleChange()}},model:{value:(_vm.form.prefix),callback:function ($$v) {_vm.$set(_vm.form, "prefix", $$v)},expression:"form.prefix"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Name (ภาษาไทย)"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","rounded":""},on:{"input":function($event){return _vm.handleChange()}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Surname (ภาษาไทย)"}},[_c('ValidationProvider',{attrs:{"name":"Surname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","rounded":""},on:{"input":function($event){return _vm.handleChange()}},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Nickname (ภาษาไทย)"}},[_c('ValidationProvider',{attrs:{"name":"Nickname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","rounded":""},on:{"input":function($event){return _vm.handleChange()}},model:{value:(_vm.form.nickname),callback:function ($$v) {_vm.$set(_vm.form, "nickname", $$v)},expression:"form.nickname"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-field',{attrs:{"label":"Phone Number"}},[_c('ValidationProvider',{attrs:{"name":"Phone Number","rules":"required|numeric|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text","placeholder":"0899999999","rounded":""},on:{"input":function($event){return _vm.handleChange()}},model:{value:(_vm.form.tel),callback:function ($$v) {_vm.$set(_vm.form, "tel", $$v)},expression:"form.tel"}}),_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"next-btn",attrs:{"disabled":invalid,"loading":_vm.isLoading,"type":"is-secondary","rounded":"","expanded":""},on:{"click":function($event){return _vm.$emit('next', _vm.form)}}},[_vm._v(" Next ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }