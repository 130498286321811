<template>
  <div>
    <div v-if="isWaitingPatient" class="waiting-padding">
      <div class="waiting-container">
        <img src="@/assets/img/logo.png" />
        <img src="@/assets/img/waiting.png" class="waiting-logo" />
        <span class="waiting-label">Waiting...</span>
        <span class="waiting-timer">{{ waitingRemainingText }}</span>
      </div>
      <div class="space" />
      <div class="patient-card">
        <span class="patient-card-title">ชื่อผู้ป่วย</span>
        <span class="patient-card-content">{{
          record && record.patientName ? record.patientName : ''
        }}</span>
        <span class="patient-card-title">อาการ</span>
        <span class="patient-card-content">{{
          record && record.symptoms && record.symptoms.length > 0
            ? record.symptoms[0]
            : ''
        }}</span>
        <span class="patient-card-title">มีอาการมาแล้ว</span>
        <span class="patient-card-content">{{
          record && record.amount && record.period
            ? `${record.amount} ${record.period}`
            : ''
        }}</span>
        <span class="room-type-badge">Chat</span>
      </div>
    </div>
    <div v-if="!isWaitingPatient" class="chat-box-container">
      <div class="header">
        <div class="left">
          <span v-if="!isChatLoading">
            <div class="name">
              {{ room && room.permission ? room.permission.ownerName : '' }}
            </div>
          </span>
        </div>
        <div class="desktop-right">
          <div>{{ remainingTimeText }}</div>
          <div
            class="report-btn"
            @click="
              $router.push({
                name: 'MedicalReport',
                params: { id: recordId },
                query: { inChatRoom: 'true' }
              })
            "
          >
            <img
              src="@/assets/svg/medical-report-icon.svg"
              style="max-width: initial;"
            />
            <span class="text">ประวัติการรักษา</span>
          </div>
          <div class="hangup-btn" @click="onHangup()">
            <img src="@/assets/svg/hangup.svg" style="max-width: initial;" />
            <span class="text">จบการสนทนา</span>
          </div>
        </div>
        <div class="mobile-right">
          <div>{{ remainingTimeText }}</div>
          <div
            class="report-btn"
            @click="
              $router.push({
                name: 'MedicalReport',
                params: { id: recordId },
                query: { inChatRoom: 'true' }
              })
            "
          >
            <img
              src="@/assets/svg/medical-report-icon.svg"
              style="max-width: initial;"
            />
          </div>
          <div class="hangup-btn" @click="onHangup()">
            <img src="@/assets/svg/hangup.svg" style="max-width: initial;" />
          </div>
        </div>
      </div>
      <div ref="conversation" class="conversation">
        <vue-element-loading
          :active="isChatLoading"
          size="38"
          color="#4DB2A0"
        />
        <vue-element-loading :active="isChatError">
          <div>Error chat not found!</div>
        </vue-element-loading>
        <infinite-loading
          direction="top"
          @infinite="loadMoreMessages"
          v-if="this.room && messages.length > 0"
        />
        <div v-for="(message, index) in messages" :key="index">
          <div
            class="date-divider"
            v-if="
              !messages[index - 1] ||
                isChangeDay(message.createdAt, messages[index - 1].createdAt)
            "
          >
            <span>
              {{ message.createdAt | toDateFormat }}
            </span>
          </div>
          <MessageBalloon :message="message" />
        </div>
        <div v-if="isRemoteTyping">
          <div
            class="date-divider"
            v-if="
              !messages[messages.length - 1] ||
                isChangeDay(new Date(), messages[messages.length - 1].createdAt)
            "
          ></div>
          <MessageBalloon
            :message="{
              body: 'กำลังพิมพ์',
              type: 'typing',
              dateCreated: new Date(),
              senderType: 'Patient'
            }"
          />
        </div>
      </div>
      <!-- <div class="float-bollon-containner">
        <div class="float-bollon-btn">เวลาคงเหลือ {{ remainingTimeText }}</div>
        <div class="float-bollon-btn" @click="sendGreeting">
        แนะนำตัว
      </div>
      </div> -->
      <div class="msg-box">
        <b-upload
          v-model="files"
          accept="image/*"
          class="upload-btn"
          @input="sendMedia"
          multiple
          :disabled="viewOnly"
        >
          <img src="@/assets/svg/picture-btn.svg" />
        </b-upload>
        <b-upload
          v-model="files"
          accept="video/*"
          class="upload-btn"
          @input="sendMedia"
          multiple
          :disabled="viewOnly"
        >
          <img src="@/assets/svg/video-btn.svg" />
        </b-upload>
        <b-upload
          v-model="files"
          accept=".pdf"
          class="upload-btn"
          @input="sendMedia"
          multiple
          :disabled="viewOnly"
        >
          <img src="@/assets/svg/file-btn.svg" />
        </b-upload>
        <!-- <div class="upload-btn">
      </div> -->
        <div class="input-box">
          <b-input
            type="textarea"
            custom-class="input-text"
            placeholder="เริ่มบทสนทนา"
            expanded
            v-model="inputText"
            rows="1"
            @input="inputChange($event)"
            @blur="inputBlur()"
            ref="input-text"
            @keypress.enter.exact.prevent.native="onEnter"
            @keydown.enter.shift.native="onShiftEnter"
            :disabled="viewOnly"
          />
        </div>
        <div class="send-btn" @click="sendMessage">
          <vue-element-loading
            :active="sending"
            color="white"
            background-color="tranparent"
            size="30"
          >
          </vue-element-loading>
          <img v-if="!sending" src="@/assets/svg/send-white.svg" />
        </div>
      </div>
      <b-modal v-model="isModalActive">
        <div class="card">
          <div
            style="
              width: 100%;
              background-color: #ffffff;
              min-height: 300px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            "
          >
            <div v-for="(e, index) in errorFile" :key="index">
              <div style="color: red">
                Error: File name {{ e.name }} reason {{ e.message }}
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import VueElementLoading from 'vue-element-loading'
import api from '@/utils/api'
import { mapActions, mapState } from 'vuex'
import MessageBalloon from './components/MessageBalloon'
import { mapUserNameFromSnapshot } from '@/utils/common'
import moment from 'moment'
import { db, storage, typingDb, roomDb } from '@/utils/firebase'
import Swal from 'sweetalert2'

export default {
  name: 'CallChatPage',
  components: {
    InfiniteLoading,
    VueElementLoading,
    MessageBalloon
  },
  data() {
    return {
      member: '',
      messages: [],
      inputText: '',
      room: null,
      files: null,
      participantName: '',
      patientId: null,
      isChatLoading: false,
      isChatError: false,
      inputRow: 1,
      lastPdoId: null,
      isRecord: false,
      errorFile: [],
      isModalActive: false,
      unsubMessages: null,
      unsubRoom: null,
      chatId: null,
      recordId: this.$route.params.id,
      record: null,
      page: 1,
      isRemoteTyping: false,
      remoteTypingTimer: null,
      lastTyping: null,
      viewOnly: this.$route.params.viewOnly === 'true',
      endTime: null,
      timer: null,
      remainingtime: 15 * 60 * 1000,
      statusSubscriber: null,
      sending: false,
      isWaitingPatient: true,
      waitingRemaining: 90 * 1000,
      waitingTimer: null
    }
  },
  computed: {
    ...mapState({ user: 'user' }),
    remainingTimeText() {
      return moment.utc(this.remainingtime).format('mm:ss')
    },
    waitingRemainingText() {
      return moment.utc(this.waitingRemaining).format('mm:ss')
    }
  },
  async mounted() {
    this.startWaitingTimer()
    roomDb
      .ref(`room_${this.recordId}/patientStatus`)
      .on('value', async snapshot => {
        if (snapshot.val() === 'receive') {
          this.isWaitingPatient = false
          this.endWaitingTimer()
          await this.setRecordRefTime()
          this.startTimer()
        }
      })
    await this.getRecord()
    this.initRecord()
    this.listeningRoom()
    this.listeningTyping()
    await this.listeningMessaage(this.page)
    setTimeout(() => {
      this.scrollToBottom()
    })
    if (this.messages.length > 0) {
      this.flagReadMessage()
    }
  },
  destroyed() {
    this.unsubMessages()
    this.unsubRoom()
    this.cancelTimer()
  },
  methods: {
    ...mapActions({
      setLoading: 'setLoading'
    }),
    async initRecord() {
      roomDb.ref(`room_${this.recordId}/status`).set('watched') // hack fix : firebase trigger onUpdate crash when triggered from firestore create ????
      roomDb.ref(`room_${this.recordId}/status`).set('receive')

      this.statusSubscriber = roomDb
        .ref(`room_${this.recordId}/status`)
        .on('value', async snap => {
          const status = snap.val()
          const patientStatus = await roomDb
            .ref(`room_${this.recordId}/patientStatus`)
            .get()
          console.log(patientStatus.val())
          if (status === 'complete' && patientStatus.val() === 'receive') {
            this.completeRoom()
          }
        })
    },
    async setRecordRefTime() {
      const snapshot = await roomDb.ref(`room_${this.recordId}`).once('value')
      const room = snapshot.val()
      if (!room.endTime) {
        this.endTime = moment().add({ minute: 15 })
        roomDb.ref(`room_${this.recordId}/startTime`).set(Date.now() / 1000) // set timestamp in seconds
        roomDb
          .ref(`room_${this.recordId}/endTime`) // FIXME: should use in second, but chatpage in patient-app also use milliseconds too, so we forced to use milliseconds
          .set(this.endTime.valueOf())
      } else {
        this.endTime = moment(room.endTime)
        this.remainingtime = this.endTime.diff(moment())
      }
    },
    async completeRoom() {
      await Promise.all([
        roomDb.ref(`room_${this.recordId}/status`).set('complete'),
        roomDb.ref(`room_${this.recordId}/doctorLeaveAt`).set(Date.now() / 1000)
      ])
      window.webkit.messageHandlers.onCompleteRoomChat.postMessage({
        recordId: `${this.recordId}`
      })
    },
    async missedCallRoom() {
      await Promise.all([
        roomDb.ref(`room_${this.recordId}/status`).set('complete'),
        roomDb.ref(`room_${this.recordId}/doctorLeaveAt`).set(Date.now() / 1000)
      ])
      window.webkit.messageHandlers.onMissedCallRoomChat.postMessage({
        recordId: `${this.recordId}`
      })
    },
    async getRecord() {
      const data = await api.getRecordById(this.recordId, 'true')
      console.log('getRecordInChat', data)
      this.record = data
      this.chatId = data.chatId
    },
    async flagReadMessage() {
      await api.readChat(
        this.chatId,
        this.messages[this.messages.length - 1].id
      )
    },
    async listeningTyping() {
      const ref = typingDb.ref(`${this.chatId}`)
      ref.on('value', snapshot => {
        const data = snapshot.val()
        if (!data) {
          if (this.remoteTypingTimer) {
            clearTimeout(this.remoteTypingTimer)
          }
          this.isRemoteTyping = false
          return
        }
        let latestTypingDate
        for (const key in data) {
          if (
            key !== `${this.user.id}` &&
            Object.hasOwnProperty.call(data, key)
          ) {
            const element = data[key]
            const typingDate = moment(element.lastTyping)
            if (!latestTypingDate || latestTypingDate < typingDate) {
              latestTypingDate = typingDate
            }
          }
        }
        const end = latestTypingDate?.add({ seconds: 30 })
        if (latestTypingDate && end > moment()) {
          this.isRemoteTyping = true
          this.scrollToBottom()
          this.remoteTypingTimer = setTimeout(() => {
            this.isRemoteTyping = false
          }, end.diff(moment(), 'milliseconds'))
        } else {
          if (this.remoteTypingTimer) {
            clearTimeout(this.remoteTypingTimer)
          }
          this.isRemoteTyping = false
        }
      })
    },
    async onTyping() {
      if (this.lastTyping && this.lastTyping.add({ seconds: 10 }) > moment()) {
        return
      }

      const ref = typingDb.ref(`${this.chatId}/${this.user.id}/lastTyping`)
      this.lastTyping = moment()
      ref.set(this.lastTyping.format())
    },
    onShiftEnter() {
      this.inputChange()
    },
    listeningMessaage(page) {
      if (this.unsubMessages) {
        this.unsubMessages()
      }
      return new Promise(resolve => {
        this.unsubMessages = db
          .collection('chat')
          .doc(this.chatId)
          .collection('message')
          .orderBy('createdAt', 'desc')
          .limit(20 * page)
          .onSnapshot(querySnapshot => {
            const messages = []
            querySnapshot.forEach(doc => {
              const docData = doc.data()
              const data = {
                ...docData,
                id: doc.id,
                createdAt: docData.createdAt.toDate()
              }
              messages.push(data)
            })
            this.$set(this, 'messages', messages.reverse())
            if (messages.length > 0) {
              this.flagReadMessage()
            }
            resolve(true)
          })
      })
    },
    listeningRoom() {
      if (this.unsubRoom) {
        this.unsubRoom()
      }
      this.unsubRoom = db
        .collection('chat')
        .doc(this.chatId)
        .onSnapshot(async doc => {
          if (!this.room) {
            const rooms = await mapUserNameFromSnapshot([doc])
            this.room = rooms[0]
          }
          this.room = {
            ...this.room,
            readBy: doc.data().readBy
          }
          let isLastMessageRead = true
          const messages = this.messages.map((doc, index) => {
            if (
              index - 1 >= 0 &&
              this.room.readBy[this.room.permission.owner]?.lastMessageSeen ===
                this.messages[index - 1].id
            ) {
              isLastMessageRead = false
            } else if (
              !this.room.readBy[this.room.permission.owner] ||
              !this.room.readBy[this.room.permission.owner]?.lastMessageSeen ||
              this.room.readBy[this.room.permission.owner]?.lastMessageSeen
                ?.length === 0
            ) {
              isLastMessageRead = false
            }
            doc = {
              ...doc,
              isRead: isLastMessageRead
            }
            return doc
          })
          this.$set(this, 'messages', messages)
        })
    },
    onEnter() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ]
      const isMobile = toMatch.some(toMatchItem => {
        return navigator.userAgent.match(toMatchItem)
      })
      if (isMobile) {
        this.inputText += '\n'
      } else {
        this.sendMessage()
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.conversation) {
          this.$refs.conversation.scrollTop = this.$refs.conversation.scrollHeight
        }
      })
    },
    async loadMoreMessages($state) {
      if (this.room.messageCount > this.messages.length) {
        await this.listeningMessaage(++this.page)
        $state.loaded()
      } else {
        $state.complete()
      }
    },
    isChangeDay(current, previous) {
      const currentDate = moment(new Date(current))
      const previousDate = moment(new Date(previous))
      return (
        currentDate.dayOfYear !== previousDate.dayOfYear ||
        currentDate.year !== previousDate.year
      )
    },
    async sendMessage() {
      const inputDom = this.$refs['input-text'].$el.children[0]
      inputDom.focus()
      if (this.viewOnly || this.sending) {
        return
      }
      this.inputText = this.inputText.trim()
      if (this.inputText.length === 0) {
        return
      }
      this.sending = true
      await api.postChat(this.chatId, {
        message: this.inputText,
        messageType: 'text',
        senderType: 'Doctor'
      })
      this.inputText = ''
      this.sending = false
      const ref = typingDb.ref(`${this.chatId}/${this.user.id}/lastTyping`)
      ref.set(null)
      this.lastTyping = null
      this.scrollToBottom()
      setTimeout(() => {
        const inputDom = this.$refs['input-text'].$el.children[0]
        inputDom.style.cssText = 'height: 48px;'
      }, 0)
    },
    async sendMedia() {
      const result = this.checkValidateFile()
      if (result.isAllValid) {
        if (result.imageFile.length > 0) {
          this.messages.push({
            createdAt: new Date(),
            messageData: result.imageFile.map(img => URL.createObjectURL(img)),
            messageType: 'picture',
            senderType: 'Doctor',
            isPreview: true
          })
        }
        if (result.videoFile.length > 0) {
          this.messages.push({
            createdAt: new Date(),
            messageData: result.videoFile.map(vdo => URL.createObjectURL(vdo)),
            messageType: 'video',
            senderType: 'Pharmacist',
            isPreview: true
          })
        }
        if (result.pdfFile.length > 0) {
          this.messages.push({
            createdAt: new Date(),
            messageData: result.pdfFile.map((pdf, index) => ({
              url: URL.createObjectURL(pdf),
              name: pdf.name,
              size: pdf.size
            })),
            messageType: 'file',
            senderType: 'Doctor',
            isPreview: true
          })
        }
        this.scrollToBottom()
        const imageSnapShot = await Promise.all(
          result.imageFile.map(file => {
            return storage
              .ref()
              .child('1111_chat_media/' + Date.now() + '-' + file.name)
              .put(file)
          })
        )
        const videoSnapShot = await Promise.all(
          result.videoFile.map(file => {
            return storage
              .ref()
              .child('1111_chat_media/' + Date.now() + '-' + file.name)
              .put(file)
          })
        )

        const pdfSnapShot = await Promise.all(
          result.pdfFile.map(file => {
            return storage
              .ref()
              .child('1111_chat_media/' + Date.now() + '-' + file.name)
              .put(file)
          })
        )

        const imageUrls = await Promise.all(
          imageSnapShot.map(snapShort => snapShort.ref.getDownloadURL())
        )
        const videoUrls = await Promise.all(
          videoSnapShot.map(snapShort => snapShort.ref.getDownloadURL())
        )
        const pdfUrls = await Promise.all(
          pdfSnapShot.map(snapShort => snapShort.ref.getDownloadURL())
        )
        if (imageUrls && imageUrls.length > 0) {
          await api.postChat(this.chatId, {
            messageData: imageUrls,
            messageType: 'picture',
            senderType: 'Doctor'
          })
        }
        if (videoUrls && videoUrls.length > 0) {
          await api.postChat(this.chatId, {
            messageData: videoUrls,
            messageType: 'video',
            senderType: 'Doctor'
          })
        }
        if (pdfUrls && pdfUrls.length > 0) {
          await api.postChat(this.chatId, {
            messageData: pdfUrls.map((url, index) => ({
              url,
              name: result.pdfFile[index].name,
              size: result.pdfFile[index].size
            })),
            messageType: 'file',
            senderType: 'Doctor'
          })
        }
      }
    },
    checkValidateFile() {
      this.errorFile = []
      const imageFile = []
      const videoFile = []
      const pdfFile = []
      this.files.forEach(file => {
        if (~file.type.search('image')) {
          if (file.size > 5000000) {
            this.errorFile.push({
              name: file.name,
              message: 'maximum picutre size is 5MB'
            })
          } else {
            imageFile.push(file)
          }
        } else if (~file.type.search('video')) {
          if (file.size > 50000000) {
            this.errorFile.push({
              name: file.name,
              message: 'maximum video size is 50MB'
            })
          } else {
            videoFile.push(file)
          }
        } else if (~file.type.search('pdf')) {
          if (file.size > 50000000) {
            this.errorFile.push({
              name: file.name,
              message: 'maximum pdf size is 50MB'
            })
          } else {
            pdfFile.push(file)
          }
        } else {
          this.errorFile.push({
            name: file.name,
            message: 'Unsupport file only video, pdf and image are allowed'
          })
        }
      })
      this.files = []
      this.isModalActive = this.errorFile.length !== 0
      return {
        isAllValid: this.errorFile.length === 0,
        imageFile,
        videoFile,
        pdfFile
      }
    },
    inputBlur() {
      const ref = typingDb.ref(`${this.chatId}/${this.user.id}/lastTyping`)
      ref.set(null)
      this.lastTyping = null
    },
    inputChange() {
      this.onTyping()

      setTimeout(() => {
        const inputDom = this.$refs['input-text'].$el.children[0]
        inputDom.style.cssText = 'height:auto;'
        const height = Math.min(inputDom.scrollHeight + 2, 100)
        inputDom.style.cssText = 'height:' + height + 'px'
      }, 0)
    },
    handleBackButton() {
      if (this.$route.query.inCallRoom === 'true') {
        window.webkit.messageHandlers.onCloseWebview.postMessage('')
      } else {
        this.$router.go(-1)
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        const diff = this.endTime.diff(moment())
        if (diff <= 0) {
          this.completeRoom()
        } else {
          this.remainingtime = diff
        }
      }, 1000)
    },
    endTimer() {
      clearInterval(this.timer)
    },
    startWaitingTimer() {
      this.waitingTimer = setInterval(() => {
        if (this.waitingRemaining <= 0) {
          this.endWaitingTimer()
          this.missedCallRoom()
        } else {
          this.waitingRemaining -= 1000
        }
      }, 1000)
    },
    endWaitingTimer() {
      clearInterval(this.waitingTimer)
    },
    async onHangup() {
      const result = await Swal.fire({
        title:
          '<div style="font-size: 24px;"><strong>ต้องการวางสายหรือไม่</strong></div>',
        html: '',
        showCancelButton: true,
        focusCancel: true,
        focusConfirm: false,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#34beb3',
        confirmButtonColor: '#c3c3c3'
      })
      if (result.isConfirmed) {
        this.completeRoom()
      }
    },
    cancelTimer() {
      this.endWaitingTimer()
      clearTimeout(this.remoteTypingTimer)
      clearInterval(this.timer)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-box-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 13px 24px;
  .left {
    display: flex;
    align-items: center;
    flex-grow: 1;
    img {
      margin-right: 10px;
      cursor: pointer;
    }
    .name {
      font-weight: bold;
      word-break: break-word;
      margin-right: 10px;
    }
    .ids {
      display: flex;
      margin-right: 10px;
      .id {
        color: #9b9b9b;
        line-height: 0.7;
        font-size: 15px;
        width: 150px;
      }
    }
  }
  .desktop-right {
    display: flex;
    align-items: center;
    justify-content: right;
    button {
      cursor: pointer;
    }
    .disable {
      opacity: 0.1;
    }
  }
  .mobile-right {
    display: none;
  }
  .inprogress {
    background: #fec400 !important;
    margin-left: 12px;
  }
  .newcase {
    background: #fa726a !important;
  }
  .close {
    background: #4db2a0 !important;
    margin-left: 12px;
  }
}
.float-bollon-containner {
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 100px;
  left: 15px;
  right: 15px;
  .float-bollon-btn {
    width: 200px;
    height: 40px;
    background: #e59f91;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    font-size: 20px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 100;
  }
}
.msg-box {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 13px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .upload-btn {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 4px;
    cursor: pointer;
  }
  .input-box {
    width: 100%;
  }
  .send-btn {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    background: #459d8e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 13px;
    cursor: pointer;
  }
}
.conversation {
  flex-grow: 1;
  padding: 0 15px 80px;
  overflow: auto;
  .date-divider {
    width: 100%;
    height: 1px;
    background: #9b9b9b;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    span {
      background-color: #f3ece7;
      position: absolute;
      top: -10px;
      width: 80px;
      left: calc(50% - 40px);
      text-align: center;
    }
  }
}
.input-box ::v-deep .input-text {
  border-radius: 23px;
  resize: none;
}

/* Less than tablet */
@media (max-width: 1023px) {
  .desktop-right {
    display: none !important;
  }
  .mobile-right {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
  }
  .conversation {
    height: calc(100vh - 57px - 66px - 52px);
  }
  .upload-btn {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    img {
      width: 22px;
    }
  }
  .send-btn {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    img {
      width: 20px;
    }
  }
  .msg-box {
    padding: 13px 4px;
    .upload-btn {
      margin-right: 0;
      min-width: 35px;
    }
  }
}
.summary-btn {
  color: #ffffff;
  width: 150px;
  font-size: 22px;
  height: 40px;
}
.outline {
  border: 1px solid #3e4a59;
  border-radius: 30px;
  color: #3e4a59;
  background: transparent;
  margin-left: 12px;
}
.hangup-btn {
  margin-left: 12px;
  padding: 6px 16px;
  border-radius: 30px;
  color: white;
  background: #ff3850;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .text {
    margin-left: 8px;
  }
}
.report-btn {
  margin-left: 12px;
  border: 1px solid #5bd1c7;
  padding: 6px 16px;
  border-radius: 30px;
  color: #5bd1c7;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .text {
    margin-left: 8px;
  }
}
.waiting-padding {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0px;
  width: 100vw;
  height: 100vh;
}
.waiting-container {
  width: 50%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.waiting-logo {
  margin-top: 40px;
  @media (max-width: 400px) {
    margin-top: 20px;
  }
}
.waiting-label {
  margin-top: 40px;
  color: grey;
  font-weight: 100;
  font-size: 18px;
  @media (max-width: 400px) {
    margin-top: 20px;
    font-size: 12px;
  }
}
.waiting-timer {
  margin-top: 20px;
  font-weight: 100;
  font-size: 40px;
  @media (max-width: 400px) {
    margin-top: 10px;
    font-size: 20px;
  }
}
.space {
  flex: 1;
}
.patient-card {
  background-color: white;
  padding: 10px 20px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 5px 10px;
  width: 80%;
  max-width: 320px;
}
.patient-card-title {
  font-size: 14px;
}
.patient-card-content {
  font-size: 14px;
  color: grey;
  font-weight: 100;
}
.room-type-badge {
  font-size: 14px;
  color: #30b0c7;
  border: 1px solid #30b0c7;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
}
</style>
